import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import './MarketAlert.scss';
import { userService } from "../../../services";
import { LoadStatus, ToastUtil, KeyCodeUtils, CommonObject, stepValues, CommonUtils, EXCHANGE, LanguageUtils } from "../../../utils";
import RetryableContent from "../../../components/RetryableContent";
import { NumberInput2 } from "../../../components/Input";
import { FormattedPrice, FormattedVolume } from 'components/Formating';
import SymbolSuggestPlaceOrderQuick from 'containers/QuickOrder/PlaceOrderQuickOrder/SymbolSuggestPlaceOrderQuick/SymbolSuggestPlaceOrderQuick';
import BlockInfoSymbolMarket from './BlockInfoSymbolMarket';
import { DatePicker } from "../../../components/Input";
import * as socket from "../../../socket";
import moment from 'moment';
import config from '../../../combineConfig';
import shallowCompare from 'react-addons-shallow-compare';
import { emitter } from 'utils/EventEmitter';
import * as Config from '../../../constants/config';
import * as actions from "../../../store/actions";
import _ from 'lodash';
import { TYPE_ACCOUNT } from './../../../utils/constants';
import CustomScrollbars from './../../../components/CustomScrollbars';
import SymbolDetailAction from 'components/SymbolDetailAction/SymbolDetailAction';

const listPercentOrder = [
    {
        key: 1,
        title: "-10%",
        value: -0.10,
    },
    {
        key: 2,
        title: "-5%",
        value: -0.05,
    },
    {
        key: 3,
        title: "5%",
        value: 0.05,
    },
    {
        key: 4,
        title: "10%",
        value: 0.1,
    }
]

const compareValue = {
    PRICE: 1,
    VOLUMN: 2,
    VALUE: 3
}

class MarketAlert extends Component {

    constructor(props) {
        super(props);
        this.confirmBtnRef = React.createRef();
        this.state.currentSymbolCode = ""
        this.state.isValidSymbol = false;
        this.symbolSearchRef = React.createRef();
        const currentDate = new Date(this.props.currentDate * 1000);
        currentDate.setHours(0, 0, 0, 0);

        this.state.fromDate = currentDate
        this.state.toDate = moment(currentDate).add(30, 'days').toDate();
    }
    initState = {
        fromDate: null,
        toDate: null,
        records: [],
        loadStatus: LoadStatus.NONE,
        filter: null,
        filterVal: null,
        currentSelectFilter: "0", // Giá trị mặc định chọn tăng
        currentSelectToValue: "closePrice",
        isDisableFilterValue: { closePrice: false, totalTrading: true, totalTradingValue: true },
        isDisableSaveBtn: true,
        priceValid: false,
        volumeValid: false,
        valueValid: false,
        price: 0,
        volume: 0,
        value: 0,
        filterCheck: { INCREASE: true, DECREASE: false },
        foundList: [],
        currentSymbol: "",
        symbolToDisplay: "",
        isShowSearch: true,
        currentActiveCompare: compareValue.PRICE,
        symbolObj: null,
        symbolToSearch: []
        // currentSymbolCode: "",
        // isValidSymbol: false
    };

    state = { ...this.initState }

    filter = {
        INCREASE: "0",
        DECREASE: "1"
    };

    operationText = {
        0: "Tăng",
        1: "Giảm",
    };

    filterValid = {
        TO_PRICE: "closePrice",
        TO_AMOUNT: "totalTrading",
        TO_VALUE: "totalTradingValue",
        TO_INDEX: "marketIndex",
        TO_VOLUMN: "totalVolume",
        TO_VALUE_INDEX: "totalValue"
    };

    callerId = 'market-alert-id';

    ALERT_TYPE = {
        STOCK_INFO: "stockinfor",
        MARKET_INFO: "marketinfor"
    };

    getSymbolToDisplay(symbolObj) {
        let _id = (symbolObj && symbolObj.id) || ''
        return _id
    }

    loadData = () => {
        // let _symbolToSearch = this.addIndexSearchItem()
        this._setState({ loadStatus: LoadStatus.LOADING });
        userService.getAlert()
            .then((data) => {
                if (data && data.length) {
                    data.forEach(item => {
                        if (item.conditionKey === this.filterValid.TO_PRICE || item.conditionKey === this.filterValid.TO_VALUE || item.conditionKey === this.filterValid.TO_INDEX || item.conditionKey === this.filterValid.TO_VALUE_INDEX) {
                            item.conditionValue = CommonUtils.convertPriceValueForPriceInput(item.conditionValue, false);
                        }
                    })
                }
                this._setState({ records: data, loadStatus: LoadStatus.LOADED });
            })
            .catch((error) => {
                this._setState({ loadStatus: LoadStatus.FAIL });
                ToastUtil.errorApi(error, 'common.fail-to-load-stock-alert-setting');
            });
    };

    onSymbolChange = (symbol, valid, symbolObj) => {
        let _symbolToDisplay = this.getSymbolToDisplay(symbolObj);
        this._setState({ currentSymbol: symbol, symbolToDisplay: _symbolToDisplay, isValidSymbol: valid, symbolObj: symbolObj }, () => {
            this.checkValidSave();
            if (valid) {
                this.onSetShowSearch(false);
                this.registerSymbol(symbol, symbolObj);
            }
            else {
                this.onSetShowSearch(true);
            }
        })
    };

    clearValue = () => {
        const currentDate = new Date(this.props.currentDate * 1000);
        currentDate.setHours(0, 0, 0, 0);
        let fromDate = moment(currentDate).toDate();
        let toDate = moment(currentDate).add(30, 'days').toDate();
        this._setState({
            ...this.initState,
            currentActiveCompare: this.state.currentActiveCompare,
            fromDate,
            toDate
        })
    }

    onPriceChange = (price, valid) => {
        this._setState({ price: price, priceValid: valid }, () => {
            this.checkValidSave();
        });
    };

    onVolumeChange = (volume, valid) => {
        this._setState({ volume: volume, volumeValid: valid }, () => {
            this.checkValidSave();
        });
    };

    onValueChange = (value, valid) => {
        this._setState({ value: value, valueValid: valid }, () => {
            this.checkValidSave();
        });
    };


    checkValidSave = () => {
        // const { currentSelectFilter, currentSelectToValue, priceValid,
        //     volumeValid, valueValid, currentSymbolCode, isValidSymbol } = this.state;
        // if (currentSelectFilter !== null && currentSelectToValue !== null && currentSymbolCode && currentSymbolCode !== "" && isValidSymbol) {
        //     if (currentSelectToValue === this.filterValid.TO_PRICE) {
        //         if (priceValid) {
        //             this._setState({ isDisableSaveBtn: false });
        //             return;
        //         }
        //     } else if (currentSelectToValue === this.filterValid.TO_AMOUNT) {
        //         if (volumeValid) {
        //             this._setState({ isDisableSaveBtn: false });
        //             return;
        //         }
        //     } else if (currentSelectToValue === this.filterValid.TO_VALUE) {
        //         if (valueValid) {
        //             this._setState({ isDisableSaveBtn: false });
        //             return;
        //         }
        //     }
        // }
        // this._setState({ isDisableSaveBtn: true });
    };


    onDeleteSetting = (id) => {
        userService.deleteAlert(id)
            .then(() => {
                ToastUtil.success('settings.personal.stock-alert.delete-success');
                this.loadData();
            }).catch(error => {
                this._setState({ loadStatus: LoadStatus.FAIL });
                ToastUtil.errorApi(error, 'settings.personal.stock-alert.fail-to-delete-alert-setting');
            })
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }
    
    // Called only one time after component loaded
    componentDidMount() {
        this.mounted = true
        const { symbolId } = this.props;
        if (symbolId) {
            this.setSymbolFromDetail(symbolId)
        }
        //document.addEventListener('keydown', this.handlerKeyDown);
        this.loadData();
    }

    // Set dữ liệu cho ô tìm kiếm mã CK
    setSymbolFromDetail = (symbolId) => {
        let symbolObjects = this.addIndexSearchItem();
        let symbolFound = symbolObjects[`${symbolId}`] || {};
        this._setState({
            symbolToDisplay: this.getSymbolToDisplay(symbolFound),
            currentSymbol: symbolId,
            isValidSymbol: true,
            symbolObj: symbolFound,
        })
        if (symbolId) {
            this.onSetShowSearch(false)
        } else {
            this.onSetShowSearch(true)
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handlerKeyDown);
        socket.unregisterCallerId(this.callerId);
        this.mounted = false
    }

    handlerKeyDown = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            if (!this.confirmBtnRef.current || this.confirmBtnRef.current.disabled) return;
            this.confirmBtnRef.current.click();
        }
    }

    // Called everytime props or state changed
    componentDidUpdate(prevProps, prevStates) {
        const { currentSymbol } = this.state;
        const { currentSymbol: prevCurrentSymbol } = prevStates;
        // if (!(_.isEqual(currentSymbol, prevCurrentSymbol))) {
        //     if (currentSymbol && currentSymbol.id) {
        //         this.onSetShowSearch(false)
        //     } else {
        //         this.onSetShowSearch(true)
        //     }
        // }
    }

    //Nhận tín hiệu cho các index
    listenToTheEmitter(marketCode) {
        if (marketCode) {
            emitter.on(
                Config.Event.UPDATE_LATEST_MARKET_INFO + marketCode,
                marketInfo => {
                    let convertedMarketInfo = CommonUtils.populateMarketInfo(marketInfo);
                    this.props.setMarketInfoDetailData(convertedMarketInfo);
                }
            );
            emitter.on(Config.Event.ON_INIT_DAY, () => {
                // this.clearData();
            });
        }
    }

    registerSymbol = (symbol, symbolObj) => {
        if (symbolObj['alertType'] && symbolObj['alertType'] === 'index') {
            symbol && socket.registerTopics([socket.socketRoomName.index + ':' + symbol], this.callerId);
            // const { marketInfos } = this.props;
            // marketInfos[`${symbol}`] && this.props.setMarketInfoDetailData(marketInfos[`${symbol}`]);
            // symbol && this.listenToTheEmitter(symbol); 
        } else {
            symbol && socket.registerTopics([socket.socketRoomName.instrument + ':' + symbol], this.callerId);

        }
    }

    checkShowBlock = () => {
        const { isShowSearch } = this.state
        let a = document.getElementById(`search-symbol-quick-order-market-alert`)
        let b = document.getElementById(`block-info-symbol-market-alert`)
        if (a && b) {
            if (isShowSearch) {
                a.style.display = "block";
                b.style.display = "none";
            } else {
                a.style.display = "none";
                b.style.display = "block";
            }
        }
    }

    onSetShowSearch = (status, isEdit = false, isFocus) => {
        if (isEdit) return
        if (isFocus) {
            let a = document.getElementById(`search-symbol-quick-order-market-alert`)
            a.style.display = "block";
            this.symbolSearchRef.current.onHandleFocus();
        }
        this._setState({ isShowSearch: status })
    }

    setPriceWithPercent = (value, symbolObj) => {
        const { currentSymbol, price } = this.state;
        const { instruments } = this.props;
        let isIndex = symbolObj && symbolObj['alertType'] && symbolObj['alertType'] === 'index' // Có phải cảnh báo cho chỉ số index không
        let newPrice = price;
        if (!isIndex) {
            const instrument = currentSymbol ? instruments[`${currentSymbol}`] || {} : {};
            if (currentSymbol && !_.isEmpty(instrument) && parseFloat(instrument['CP'])) {
                let closePrice = CommonUtils.convertPriceValueForPriceInput(parseFloat(instrument['CP']), false);
                if (value < 0) {
                    newPrice = parseFloat(closePrice) - parseFloat(closePrice) * Math.abs(value);
                } else {
                    newPrice = parseFloat(closePrice) + parseFloat(closePrice) * parseFloat(value);
                }
            }
        } else {
            let closePrice = parseFloat(symbolObj['marketIndex']);
            if (value < 0) {
                newPrice = parseFloat(closePrice) - parseFloat(closePrice) * Math.abs(value);
            } else {
                newPrice = parseFloat(closePrice) + parseFloat(closePrice) * parseFloat(value);
            }
        }
        this._setState({ price: newPrice });
    }

    onChangeActiveCompareValue = (valueToCompare) => {
        const { currentActiveCompare } = this.state;
        if (valueToCompare !== currentActiveCompare) {
            this._setState({ currentActiveCompare: valueToCompare, price: 0, volume: 0, value: 0 });
        }
    }

    onFromDateChanged = (dates) => {
        this._setState({ fromDate: dates[0] });
    };

    onToDateChanged = (dates) => {
        this._setState({ toDate: dates[0] });
    };

    onHandleKeyDownPricePercent = (event, value, symbolObj) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            this.setPriceWithPercent(value, symbolObj)
        }
    }

    onSaveAlert = () => {
        const { currentActiveCompare,
            currentSymbol, price, volume, value, fromDate, toDate, valueValid, priceValid, volumeValid, symbolObj } = this.state;
        const { instruments } = this.props;
        const instrument = currentSymbol ? instruments[`${currentSymbol}`] || {} : {};

        if (!currentSymbol || !symbolObj) {
            ToastUtil.error('common.fail-to-load-data', 'trade.order.invalid-symbol');
            return;
        }

        const momentFromDate = moment(fromDate);
        const momentToDate = moment(toDate);
        if (!momentFromDate.isValid() || !momentToDate.isValid() || momentToDate.isBefore(momentFromDate)) {
            ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid');
            return;
        }

        let conditionValue = 0;
        let increase = false;
        let currentSelectToValue = '';
        let isIndex = symbolObj['alertType'] && symbolObj['alertType'] === 'index' // Có phải cảnh báo cho chỉ số index không
        if (currentActiveCompare === compareValue.PRICE) {
            if (!priceValid) {
                ToastUtil.error('common.fail-to-load-data', 'common.invalid-input');
                return;
            }
            conditionValue = CommonUtils.convertPriceValueForValidate(price, false);
            if (!isIndex) {
                if (!_.isEmpty(instrument) && parseFloat(instrument['CP'])) {
                    increase = conditionValue > parseFloat(instrument['CP']);
                }
                currentSelectToValue = this.filterValid.TO_PRICE;
            } else {
                increase = true;
                currentSelectToValue = this.filterValid.TO_INDEX;
            }
        } else if (currentActiveCompare === compareValue.VOLUMN) {
            if (!volumeValid) {
                ToastUtil.error('common.fail-to-load-data', 'common.invalid-input');
                return;
            }
            conditionValue = volume;
            increase = true;
            if (!isIndex) {
                currentSelectToValue = this.filterValid.TO_AMOUNT;
            } else {
                currentSelectToValue = this.filterValid.TO_VOLUMN;
            }
        } else if (currentActiveCompare === compareValue.VALUE) {
            if (!valueValid) {
                ToastUtil.error('common.fail-to-load-data', 'common.invalid-input');
                return;
            }
            // conditionValue = CommonUtils.convertPriceValueForValidate(value, false);
            conditionValue = parseFloat(value) * 1000000000
            increase = true;
            if (!isIndex) {
                currentSelectToValue = this.filterValid.TO_VALUE;
            } else {
                currentSelectToValue = this.filterValid.TO_VALUE_INDEX;
            }
        }

        let alertSettingBody = {
            "type": isIndex ? 'marketinfor' : 'stockinfor',
            "code": symbolObj['id'],
            "increase": increase,
            "conditionKey": currentSelectToValue,
            "conditionValue": parseFloat(conditionValue),
            "fromdate": moment(fromDate).format(config.app.SERVER_DATE_FORMAT),
            "todate": moment(toDate).format(config.app.SERVER_DATE_FORMAT)
        };

        userService.saveAlert(alertSettingBody)
            .then(() => {
                ToastUtil.success('settings.personal.stock-alert.success-to-save-alert-setting');
                this.clearValue();
                this.loadData();
            }).catch(error => {
                this._setState({ loadStatus: LoadStatus.FAIL });
                ToastUtil.errorApi(error, 'settings.personal.stock-alert.fail-to-save-alert-setting');
            })
    }

    getQuoteInfo(currentSymbol) {
        const { instruments, marketInfos } = this.props;
        const { symbolObj } = this.state;
        if (symbolObj && currentSymbol) {
            if (symbolObj['alertType'] && symbolObj['alertType'] === 'index') {
                return {
                    Id: symbolObj['id'],
                    EX: symbolObj['floorCode'] || '',
                    CP: parseFloat(symbolObj['marketIndex']) ? parseFloat(symbolObj['marketIndex']) : 0,
                    CH: parseFloat(symbolObj['changedIndex']) ? parseFloat(symbolObj['changedIndex']) : 0,
                    CHP: parseFloat(symbolObj['percentIndex']) ? parseFloat(symbolObj['percentIndex']) : 0,
                    FN: symbolObj['floorCode']
                }
            } else {
                let instrument = instruments[`${currentSymbol}`] ? instruments[`${currentSymbol}`] : {};
                return {
                    Id: symbolObj['id'],
                    EX: symbolObj['exchange'],
                    CP: instrument['CP'],
                    CH: instrument['CH'],
                    CHP: instrument['CHP'],
                    FN: instrument['FN'],
                }
            }
        }

    }

    addIndexSearchItem = () => {
        const { marketInfos, symbolWithIndex } = this.props;
        let indexes = config.ALL_INDEXES.map(item => { return item.code });
        let indexSearchInfo = {};
        indexes.forEach(item => {
            let marketInfo = marketInfos[`${item}`] ? marketInfos[`${item}`] : {};
            let marketConfigInfo = config.ALL_INDEXES.find(configItem => { return configItem.code === item });
            if (marketConfigInfo) {
                indexSearchInfo[`${marketConfigInfo['searchKey']}`] = {
                    ...marketInfo,
                    FullName: marketInfo['floorCode'] || '',
                    desc: marketInfo['floorCode'] || '',
                    id: marketConfigInfo['searchKey'],
                    symbol: marketConfigInfo['searchKey'],
                    exchange: marketInfo['floorCode'],
                    stockType: marketInfo['floorCode'],
                    StockType: marketInfo['floorCode'],
                    alertType: 'index',
                    subName: marketConfigInfo['subName'],
                }
            }
        });
        return { ...symbolWithIndex, ...indexSearchInfo };
    }


    shouldComponentUpdate(nextProps, nextState, snapshot) {
        const { currentSymbol } = this.state;
        const { currentSymbol: nextCurrentSymbol } = nextState;
        const { symbolWithIndex, quotes, instruments, marketInfos, ...otherProps } = this.props;
        const { symbolWithIndex: nextSymbolWithIndex, quotes: nextQuotes, instruments: nextInstruments, marketInfos: nextMarketInfos, ...nextOtherProps } = nextProps;

        // Chỉ render lại khi một số trường của instrument hoặc marketinfo thay đổi
        const quote = currentSymbol && quotes[`${currentSymbol}`] ?
            {
                ceiling: quotes[`${currentSymbol}`]['ceiling'],
                reference: quotes[`${currentSymbol}`]['reference'],
                floor: quotes[`${currentSymbol}`]['floor']
            }
            : CommonObject.emptyObject;
        const instrument = currentSymbol && instruments && instruments[`${currentSymbol}`] ?
            {
                CP: instruments[`${currentSymbol}`]['CP'],
                CH: instruments[`${currentSymbol}`]['CH'],
                CHP: instruments[`${currentSymbol}`]['CHP'],
                TT: instruments[`${currentSymbol}`]['TT']
            }
            || {} : {};
        const nextquote = nextCurrentSymbol && nextQuotes[`${nextCurrentSymbol}`] ?
            {
                ceiling: nextQuotes[`${nextCurrentSymbol}`]['ceiling'],
                reference: nextQuotes[`${nextCurrentSymbol}`]['reference'],
                floor: nextQuotes[`${nextCurrentSymbol}`]['floor']
            }
            : CommonObject.emptyObject;
        const nextInstrument = nextCurrentSymbol && nextInstruments && nextInstruments[`${nextCurrentSymbol}`] ?
            {
                CP: nextInstruments[`${nextCurrentSymbol}`]['CP'],
                CH: nextInstruments[`${nextCurrentSymbol}`]['CH'],
                CHP: nextInstruments[`${nextCurrentSymbol}`]['CHP'],
                TT: nextInstruments[`${nextCurrentSymbol}`]['TT']
            }
            || {} : {};

        const marketInfo = currentSymbol && marketInfos[`${currentSymbol}`] ?
            {
                marketIndex: marketInfos[`${currentSymbol}`]['marketIndex'],
                changedIndex: marketInfos[`${currentSymbol}`]['changedIndex'],
                percentIndex: marketInfos[`${currentSymbol}`]['percentIndex']
            }
            : CommonObject.emptyObject;

        const nextMarketInfo = nextCurrentSymbol && nextMarketInfos[`${nextCurrentSymbol}`] ?
            {
                marketIndex: nextMarketInfos[`${nextCurrentSymbol}`]['marketIndex'],
                changedIndex: nextMarketInfos[`${nextCurrentSymbol}`]['changedIndex'],
                percentIndex: nextMarketInfos[`${nextCurrentSymbol}`]['percentIndex']
            }
            : CommonObject.emptyObject;


        return shallowCompare({ props: otherProps, state: this.state }, nextOtherProps, nextState)
            || JSON.stringify(quote) != JSON.stringify(nextquote)
            || JSON.stringify(instrument) != JSON.stringify(nextInstrument)
            || JSON.stringify(marketInfo) != JSON.stringify(nextMarketInfo)
            || marketInfos !== nextMarketInfos
            ;
    }

    render() {
        const { priceValid, volumeValid, valueValid,
            price, volume, value, isValidSymbol,
            symbolToDisplay, currentSymbol, currentActiveCompare, records, symbolObj } = this.state;

        const { quotes, instruments } = this.props;
        const quote = currentSymbol ? quotes[`${currentSymbol}`] : null;
        const instrument = currentSymbol ? instruments[`${currentSymbol}`] || {} : {};
        this.checkShowBlock();
        const quoteInfo = this.getQuoteInfo(currentSymbol);

        let symbolToSearch = this.addIndexSearchItem();
        let isIndex = symbolObj && symbolObj['alertType'] && symbolObj['alertType'] === 'index' // Có phải cảnh báo cho chỉ số index không
        return (
            <RetryableContent status={this.state.loadStatus} disableRetry={true} className="h-100">
                <div className="market-alert-content h-100">
                    <div className="row gutters-5 h-100">

                        {/** Danh sách cảnh báo */}
                        <div className="col-12 col-md-6 col-lg-8 p-style">
                            <div className="col-12 block-right">
                                <div className='headB---700-16-24 title' style={{ paddingLeft: "10px" }}>
                                    <FormattedMessage id="market-alert.warning-list" />
                                </div>
                                <div className='container-block p-table-10'>
                                    <CustomScrollbars className="basic-table">
                                        <table className="basic-table table-custom">
                                            <thead>
                                                <tr>
                                                    <th className="action-col text-active text-center txt---400-12-18" >

                                                    </th>
                                                    <th className="text-active text-center txt---400-12-18" >
                                                        <FormattedMessage id="market-alert.table.stock-code" />
                                                    </th>
                                                    <th className="text-active text-center txt---400-12-18" >
                                                        <FormattedMessage id="market-alert.table.up-and-down" />
                                                    </th>
                                                    <th className="text-active text-center txt---400-12-18" >
                                                        <FormattedMessage id="market-alert.table.go-to-price" />
                                                    </th>
                                                    <th className="text-active text-center txt---400-12-18" >
                                                        <FormattedMessage id="market-alert.table.go-to-volume" />
                                                    </th>
                                                    <th className="text-active text-center txt---400-12-18" >
                                                        <FormattedMessage id="market-alert.table.go-to-value" />
                                                    </th>
                                                    <th className="text-active text-center txt---400-12-18" >
                                                        <FormattedMessage id="market-alert.table.since" />
                                                    </th>
                                                    <th className="text-active text-center txt---400-12-18" >
                                                        <FormattedMessage id="market-alert.table.to-date" />
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {records && records.length > 0 && records.map((item, index) => {
                                                    if (item.type === this.ALERT_TYPE.STOCK_INFO) {
                                                        return (
                                                            <tr key={`alert-${index}`} >
                                                                <td className="action-col text-center text-active custom-form-group action-container" >
                                                                    <button
                                                                        className='custom-form-control txt---400-12-18'

                                                                        onClick={() => { this.onDeleteSetting(item.id) }}
                                                                    ><FormattedMessage id="market-alert.delete" /></button>
                                                                </td>
                                                                <td className="text-left text-active txt---400-12-18" >
                                                                    <SymbolDetailAction symbol={item.code} />
                                                                </td>
                                                                <td className="text-center text-active txt---400-12-18" >{item.increase ? LanguageUtils.getMessageByKey("market-alert.increase", this.props.language) : LanguageUtils.getMessageByKey("market-alert.reduce", this.props.language)}</td>
                                                                <td className="text-right text-active txt---400-12-18" >{item.conditionKey === this.filterValid.TO_PRICE ? <FormattedPrice value={item.conditionValue} shortForm={true} isNotThousand={true} /> : '-'}</td>
                                                                <td className="text-center text-active txt---400-12-18" >{item.conditionKey === this.filterValid.TO_AMOUNT ? <FormattedVolume value={item.conditionValue} /> : '-'}</td>
                                                                <td className="text-right text-active txt---400-12-18" >{item.conditionKey === this.filterValid.TO_VALUE ? <FormattedPrice value={CommonUtils.divide(item.conditionValue, 1000000)} decimalScale={2} shortForm={true} isNotThousand={true} /> : '-'}</td>
                                                                <td className="text-right text-active txt---400-12-18" >{item.fromdate}</td>
                                                                <td className="text-right text-active txt---400-12-18" >{item.todate}</td>
                                                            </tr>)
                                                    } else {
                                                        return (
                                                            <tr key={`alert-${index}`} >
                                                                <td className="action-col text-center text-active custom-form-group action-container" >
                                                                    <button
                                                                        className='btn-delete txt---400-12-18'

                                                                        onClick={() => { this.onDeleteSetting(item.id) }}
                                                                    ><FormattedMessage id="market-alert.delete" /></button>
                                                                </td>
                                                                <td className="text-left text-active txt---400-12-18" >
                                                                    <SymbolDetailAction symbol={item.code} isIndex={true} />
                                                                </td>
                                                                <td className="text-center text-active txt---400-12-18" >{item.increase ? LanguageUtils.getMessageByKey("market-alert.increase", this.props.language) : LanguageUtils.getMessageByKey("market-alert.reduce", this.props.language)}</td>
                                                                <td className="text-right text-active txt---400-12-18" >{item.conditionKey === this.filterValid.TO_INDEX ? <FormattedPrice value={item.conditionValue} /> : '-'}</td>
                                                                <td className="text-center text-active txt---400-12-18" >{item.conditionKey === this.filterValid.TO_VOLUMN ? <FormattedVolume value={item.conditionValue} /> : '-'}</td>
                                                                <td className="text-right text-active txt---400-12-18" >{item.conditionKey === this.filterValid.TO_VALUE_INDEX ? <FormattedPrice value={item.conditionValue} decimalScale={2} /> : '-'}</td>
                                                                <td className="text-right text-active txt---400-12-18" >{item.fromdate}</td>
                                                                <td className="text-right text-active txt---400-12-18" >{item.todate}</td>
                                                            </tr>)
                                                    }
                                                })}
                                            </tbody>
                                        </table>
                                    </CustomScrollbars>
                                </div>
                            </div>
                        </div>

                        {/** Thêm cảnh báo */}
                        <div className="col-12 col-md-6 col-lg-4 add-alert">
                            <div className='block'>
                                <div className='headB---700-16-24 title' >
                                    <FormattedMessage id="market-alert.more-warnings" />
                                </div>
                                <div id='search-symbol-quick-order-market-alert' className='col-12 search-symbol-quick-order-market-alert'>
                                    <SymbolSuggestPlaceOrderQuick
                                        ref={this.symbolSearchRef}
                                        isOrder={true}
                                        symbolValid={isValidSymbol}
                                        currentSymbol={currentSymbol}
                                        symbolToDisplay={symbolToDisplay}
                                        isDisplayLabel={false}
                                        handleInputChange={this.onSymbolChange}
                                        isRedirect={false}
                                        suggestId="symbol-search-market-alert"
                                        placeholder={LanguageUtils.getMessageByKey("common.placeholder-symbol", this.props.language)}
                                        symbolWithIndex={symbolToSearch}
                                        disabled={false}
                                    />
                                </div>
                                <div className='col-12 block-info-symbol-market-alert' id="block-info-symbol-market-alert" onClick={() => this.onSetShowSearch(true, false, true)}>
                                    {quoteInfo && (<BlockInfoSymbolMarket
                                        isIndex={isIndex}
                                        typeAccount={TYPE_ACCOUNT.BASE}
                                        quoteInfo={quoteInfo}
                                    />)}
                                </div>

                                <div className="capacity-container">
                                    <div className="info-capacity">
                                        {!isIndex && (
                                            <div className="row-capacity txt---400-12-18" style={{ marginBottom: "4px" }}>
                                                <div className="capacity-content capacity-price">
                                                    <div className="text-ceil-price" >
                                                        {
                                                            quote ? (quote.exchange === EXCHANGE.DERIVATIVE ? CommonUtils.roundToTwo(quote.ceiling, 1) : CommonUtils.roundToTwo(quote.ceiling, 1000)) : "0.00"
                                                        }
                                                    </div>
                                                    <div className="text-ref-price" >
                                                        {
                                                            quote ? (quote.exchange === EXCHANGE.DERIVATIVE ? CommonUtils.roundToTwo(quote.reference, 1) : CommonUtils.roundToTwo(quote.reference, 1000)) : "0.00"
                                                        }
                                                    </div>
                                                    <div className="text-floor-price" >
                                                        {
                                                            quote ? (quote.exchange === EXCHANGE.DERIVATIVE ? CommonUtils.roundToTwo(quote.floor, 1) : CommonUtils.roundToTwo(quote.floor, 1000)) : "0.00"
                                                        }
                                                    </div>
                                                </div>
                                                <div className="capacity-content">
                                                    <div className="label">
                                                        <FormattedMessage id="market-alert.matching-volume" />
                                                    </div>&nbsp;
                                                    <div className="value">
                                                        <FormattedPrice value={instrument ? instrument["TT"] : 0} />
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {isIndex && (
                                            <div className="row-capacity index txt---400-12-18" style={{ marginBottom: "4px" }}>
                                                <div className="capacity-content text-center">
                                                    <p>
                                                        <span className='label'><FormattedMessage id="market-alert.matching-volume" /></span>&nbsp;
                                                        <span className='value'><FormattedVolume value={symbolObj['totalShareTraded']} /></span>
                                                    </p>
                                                </div>
                                            </div>
                                        )}

                                    </div>
                                </div>

                                <div className='sep-line'></div>

                                <div className='row col-12 input-row'>
                                    <div className='col-10 txt---500-14-20 pd-0'>          <FormattedMessage id="market-alert.price" />
                                    </div>
                                    <div className='col-2 custom-form-group radio-check'>
                                        <input className='custom-form-control' type='radio' name='value-check'
                                            checked={currentActiveCompare === compareValue.PRICE}
                                            onChange={() => { this.onChangeActiveCompareValue(compareValue.PRICE) }}
                                        />
                                    </div>

                                    {currentActiveCompare === compareValue.PRICE && (
                                        <Fragment>
                                            <div className='col-12 custom-form-group-effect input-value'>
                                                <NumberInput2
                                                    name="To_Price"
                                                    className="custom-form-control"
                                                    min={null}
                                                    max={999999999999}
                                                    step={stepValues.PRICE}
                                                    value={price}
                                                    valid={priceValid}
                                                    onChange={this.onPriceChange}
                                                    allowZero={false}
                                                    allowDecimal={true}
                                                    allowNegative={false}
                                                    disabled={false}
                                                    decimalScale={2}
                                                    allowDecimalNumb={2}
                                                    onKeyDown={this.handlerKeyDown}
                                                >
                                                </NumberInput2>
                                            </div>
                                            <div className="col-12 type-view present-order">
                                                <div className="content content-right item-center txt---400-14-20">
                                                    {listPercentOrder.map((item, index) => {
                                                        return <div tabindex="-1" className={"select-present txt---400-14-20 "}
                                                            onClick={() => { this.setPriceWithPercent(item.value, symbolObj) }}
                                                        // onKeyDown={(e) => { this.onHandleKeyDownPricePercent(e, item.value, symbolObj) }}
                                                        >
                                                            {item.title}
                                                        </div>
                                                    })}
                                                </div>
                                            </div>
                                        </Fragment>
                                    )}
                                </div>

                                <div className='sep-line'></div>

                                <div className='row col-12 input-row'>
                                    <div className='col-10 txt---500-14-20 pd-0' > <FormattedMessage id="market-alert.mass" /></div>
                                    <div className='col-2 custom-form-group radio-check'>
                                        <input className='custom-form-control' type='radio' name='value-check'
                                            checked={currentActiveCompare === compareValue.VOLUMN}
                                            onChange={() => { this.onChangeActiveCompareValue(compareValue.VOLUMN) }}
                                        />
                                    </div>
                                    {currentActiveCompare === compareValue.VOLUMN && (
                                        <Fragment>
                                            <div className='col-12 custom-form-group-effect input-value'>
                                                <NumberInput2
                                                    name="To_Amount"
                                                    className="custom-form-control"
                                                    min={null}
                                                    max={999999999999}
                                                    step={stepValues.QUANTITY}
                                                    value={volume}
                                                    valid={volumeValid}
                                                    onChange={this.onVolumeChange}
                                                    allowZero={false}
                                                    allowDecimal={false}
                                                    allowNegative={false}
                                                    disabled={false}
                                                    onKeyDown={this.handlerKeyDown}
                                                >
                                                </NumberInput2>
                                            </div>
                                        </Fragment>
                                    )}

                                </div>

                                <div className='sep-line'></div>

                                <div className='row col-12 input-row'>
                                    <div className='col-10 txt---500-14-20 pd-0'> <FormattedMessage id="market-alert.value-billion" /></div>
                                    <div className='col-2 custom-form-group radio-check'>
                                        <input className='custom-form-control' type='radio' name='value-check'
                                            checked={currentActiveCompare === compareValue.VALUE}
                                            onChange={() => { this.onChangeActiveCompareValue(compareValue.VALUE) }}
                                        />
                                    </div>

                                    {currentActiveCompare === compareValue.VALUE && (
                                        <div className='col-12 custom-form-group-effect input-value'>
                                            <NumberInput2
                                                name="To_Value"
                                                min={null}
                                                max={999999999999}
                                                step={stepValues.PRICE}
                                                value={value}
                                                valid={valueValid}
                                                onChange={this.onValueChange}
                                                allowZero={false}
                                                allowDecimal={true}
                                                allowNegative={false}
                                                disabled={false}
                                                decimalScale={2}
                                                allowDecimalNumb={2}
                                                onKeyDown={this.handlerKeyDown}
                                            >
                                            </NumberInput2>
                                        </div>)}
                                </div>

                                <div className='sep-line'></div>

                                <div className='row col-12'>
                                    <div className='col-3 txt---500-14-20 pd-0'> <FormattedMessage id="market-alert.effect" /></div>
                                    <div className='col-9 custom-form-group date-picker'>
                                        <DatePicker
                                            className="custom-form-control txt---500-14-20"
                                            value={this.state.fromDate}
                                            onChange={this.onFromDateChanged}
                                        // minDate={this.minDate}
                                        />
                                    </div>

                                    <div className='offset-3 col-9 custom-form-group date-picker'>
                                        <DatePicker
                                            className="custom-form-control txt---500-14-20"
                                            value={this.state.toDate}
                                            onChange={this.onToDateChanged}
                                        // minDate={this.minDate}
                                        />
                                    </div>
                                </div>

                                <div className='row col-12 custom-form-group button-container pd-0'>
                                    <button
                                        className="custom-form-control headM---500-16-24 btn-action"
                                        onClick={this.onSaveAlert}
                                        ref={this.confirmBtnRef}
                                        onKeyDown={this.handlerKeyDown}
                                    >
                                        <FormattedMessage id="market-alert.confirm" />
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div >


                </div>


            </RetryableContent>
        );
    }

}

const mapStateToProps = (state) => {
    let screenModaldata = state.layout.listIsOpenModal["C&B"]["ScreenModal"].data;
    return {
        marketInfos: state.marketInfoStore.marketInfos,
        currentAccount: state.account.currentAccount,
        language: state.app.language,
        symbolWithIndex: state.symbol.allQuotesWithIndex,
        quotes: state.symbol.quotes,
        instruments: state.symbol.instruments,
        symbolId: screenModaldata['symbolId'] ? screenModaldata['symbolId'] : '',
        currentDate: state.app.currentDate,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setMarketInfoDetailData: (marketInfo) => dispatch(actions.setMarketInfoDetailData(marketInfo)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MarketAlert);
