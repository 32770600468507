import React, { Component } from 'react';
import _ from "lodash";
import { connect } from 'react-redux';

import * as actions from "../../../store/actions";
import {
    CommonWidgetUtils, ORDER_TYPE_USE, TYPE_ACCOUNT
} from "../../../utils"
import BlockInfoSymbol from '../../../containers/QuickOrder/PlaceOrderQuickOrder/BlockInfoSymbol/BlockInfoSymbol';

import MixedOrderbook from './MixedOrderbook';

import './OrderbookTrade.scss';
import MarketInfoBlock from './MarketInfoBlock';
import { FormattedMessage } from 'react-intl';
class OrderbookTrade extends Component {

    componentDidMount() {
        const { curSymbolTrade } = this.props;
        if (curSymbolTrade && curSymbolTrade.id) {
            this.props.fetchSymbolOrderbook(curSymbolTrade.id);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { curSymbolTrade } = this.props;
        const { curSymbolTrade: prevCurSymbolTrade } = prevProps;
        if (!_.isEqual(curSymbolTrade, prevCurSymbolTrade)) {
            if (curSymbolTrade && curSymbolTrade.id) {
                this.props.fetchSymbolOrderbook(curSymbolTrade.id);
            }
        }
    }

    render() {
        const { curSymbolTrade, mode, isSymbolDetail } = this.props
        return (
            <div className='order-book-trade'>
                {isSymbolDetail === true &&
                    <div className="title txt---700-14-20">
                        <FormattedMessage id="trade.symbol-detail-modal.depthChart" />
                    </div>
                }
                {(curSymbolTrade || isSymbolDetail === true) && (
                    <div className='block-info-container'>
                        <div id="block-info-symbol" className="block-info-symbol" >
                            <BlockInfoSymbol
                                typeAccount={TYPE_ACCOUNT.BASE} // Hiển thị như tài khoản flex
                                symbolId={curSymbolTrade.id}
                                orderTypeUse={ORDER_TYPE_USE.QUICK_ORDER} // Hiển thị như quickorder
                            />
                        </div>
                    </div>
                )}
                <MixedOrderbook
                    mode={mode}
                    currentSymbol={curSymbolTrade}
                />

                <MarketInfoBlock />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    // let quote = null
    let _currentLayoutPageActive = null
    _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
    let _curSymbolTrade = _layoutPage && _layoutPage["symbols"] && _layoutPage["symbols"]["curSymbolTrade"] || {}
    // quote = _curSymbolTrade && state.symbol.quotes[_curSymbolTrade["id"]]
    // let _accountInfo = _layoutPage && _layoutPage["accountInfo"]
    // const instrument = _curSymbolTrade ? state.symbol.instruments[_curSymbolTrade.id] || {} : {};
    const orderbook = _curSymbolTrade ? state.symbol.orderbooks[_curSymbolTrade.id] || [] : [];

    return {
        // config: state.user.userInfo.config,
        curSymbolTrade: _curSymbolTrade,
        // quote,
        language: state.app.language,
        // role: state.user.userInfo.role,
        // accountInfo: _accountInfo,
        // instrument: instrument,
        orderbook: orderbook,

        // socketSEAction: state.socketRealtime.socketSEAction,
        // socketCIAction: state.socketRealtime.socketCIAction
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchSymbolOrderbook: (symbolId) => dispatch(actions.fetchSymbolOrderbook(symbolId)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(OrderbookTrade);
