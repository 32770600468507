import React, { Component } from 'react';
import { connect } from 'react-redux';
import { LanguageUtils, LoadStatus, ToastUtil, } from "../../../utils";
import { injectIntl, FormattedMessage } from 'react-intl';
import { withScriptjs, withGoogleMap } from "react-google-maps"
import { ReactComponent as SearchIconDark } from '../../../assets/icons/header/SearchIcon_Dark.svg';
import { ReactComponent as SearchIconLight } from '../../../assets/icons/header/SearchIcon_Light.svg';
import './NetModal.scss';
import { userService } from 'services';
import MapIframe from './MapIframe';
import DraggableModal from 'components/Modal/DraggableModal';

export const defaultConfig = {
    defaultCenter: {
        lat: 21.0254327, lng: 105.8570285
    },
    defaultZoom: 8
}
class NetModal extends Component {

    constructor(props) {
        super(props);
    }

    initialState = {
        branchs: [],
        userInput: "",
        lat: Number(1),
        lng: Number(1),
        checkInput: false,
        filterBranchs: [],
        postionActive: "my_location"
    };

    state = {
        ...this.initialState
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }
	
	componentWillUnmount() {
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
        this.loadBranchs()
        this.position()
    }

    search = (userInput) => {
        const { branchs, checkInput } = this.state;
        let data = branchs.filter((item) => this.props.language === 'en' ? item.name_en.includes(userInput) : item.name.includes(userInput))
        if (userInput && userInput !== "") {
            //console.log("userInput 111--- : 1", userInput, data)
            this._setState({ filterBranchs: data, userInput: userInput, checkInput: true });
        } else {
            //console.log("userInput 111--- : 2", userInput, data)
            this._setState({ branchs: branchs, userInput: userInput, checkInput: false });
        }
    };

    distance(lat1, lon1, lat2, lon2, isMiles) {
        const squared = x => x * x;
        const toRad = x => (x * Math.PI) / 180;
        const R = 6371; // Earth’s mean radius in km

        const dLat = toRad(lat1 - lat2);
        const dLon = toRad(lon1 - lon2);

        const dLatSin = squared(Math.sin(dLat / 2));
        const dLonSin = squared(Math.sin(dLon / 2));

        const a = dLatSin +
            (Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * dLonSin);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        let distance = R * c;
        // distance /= 1.609344
        if (isMiles) distance /= 1.609344;
        return distance.toFixed(2) + " km"
        // if (distance > 1000) {
        //     let km = distance /= 1.609344
        //     return km.toFixed(2) + "Km"
        // } else {
        //     let m = distance
        //     return m.toFixed(2) + "m"
        // }
    }

    // Lấy thông tin tiền bán chờ về
    loadBranchs = () => {
        this._setState({ loadStatus: LoadStatus.LOADING });
        userService.getClientConfig()
            .then((data) => {
                //console.log("netmodal --- : ", data)
                this._setState({ branchs: data.configData.branch, loadStatus: LoadStatus.LOADED });
            })
            .catch((error) => {
                ToastUtil.errorApi(error, 'common.fail-to-load-data');
                this._setState({ loadStatus: LoadStatus.FAIL });
            });
    }

    close = () => {
        this.props.setIsOpenContactMobile(false)
    }

    position = async () => {
        await navigator.geolocation.getCurrentPosition(
            position =>
                this._setState({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                    postionActive: "my_location"
                }),
            err =>
                console.log(err),
            { maximumAge: 60000, timeout: 5000, enableHighAccuracy: true }
        );
    }

    genderIcon = () => {
        let { defaultTheme } = this.props
        return defaultTheme === 'dark' ? < SearchIconDark /> : < SearchIconLight />
    }

    handlePostion = (lat, long, name) => {
        //console.log("action netmodal current: ", this.state.lat, this.state.lng)
        //console.log("action netmodal new: ", Number(lat), Number(long))
        this._setState({ lat: Number(lat), lng: Number(long), postionActive: name });
    }

    renderItemShow = (item) => {
        const { language } = this.props
        const { postionActive } = this.state
        //console.log("language === ", language)
        const { lat, lng } = this.state
        let _name = language === 'en' ? item.name_en : item.name
        let _address = language === 'en' ? item.address_en : item.address
        return (
            <div className={"item-branch" + (postionActive === item.name ? " active" : "")}>
                <div className='left-item'>
                    <div className='txt---500-14-20'>{_name}</div>
                    <div className='text-near-active txt---400-14-20'>{_address}</div>
                    <div className=' txt---400-14-20'>{this.distance(item.lat, item.long, lat, lng, false)}</div>
                </div>
                <div className='right-item'>
                    <div className='circle' onClick={() => this.handlePostion(item.lat, item.long, item.name)}>
                        <i className="fa fa-crosshairs"></i>
                    </div>
                </div>
            </div>
        )
    }

    // shouldComponentUpdate(nextProps, nextState) {
    //     const props = this.props;
    //     const state = this.state;
    //     //     return state.userInput === nextState.userInput
    // }

    render() {
        const { isOpen, language } = this.props;
        const { branchs, userInput, filterBranchs, checkInput } = this.state
        //console.log("action netmodal state netmodal", this.state.lat, this.state.lng)
        return (
            <DraggableModal
                isOpen={isOpen}
                toggle={() => this.close()}
                onClose={() => this.close()}
                className={"net-modal"}
                titleId={"common.network"}
            >
                <div className="body">
                    <div className='div-map' style={{ height: "300px" }}>
                        {/* <Map
                                google={this.props.google}
                                center={{ lat: this.state.lat, lng: this.state.lng }}
                                height="300px"
                                zoom={15}
                                positions={this.state.branchs}
                            /> */}
                        <MapIframe
                            lat={this.state.lat}
                            lng={this.state.lng} />
                    </div>
                    <div className='my-location  txt---500-14-20' onClick={() => this.position()}> <FormattedMessage id="common.my-postion" /></div>
                    <div className='div-list'>
                        <div className='col-12 search-custom'>
                            <div className='col-1 p-0'>
                                {this.genderIcon()}
                            </div>
                            <div className='col-10 p-0'>
                                <input
                                    type="text"
                                    name="search"
                                    className="search-bar"
                                    autoComplete="off"
                                    placeholder={LanguageUtils.getMessageByKey("common.search", language)}
                                    value={userInput}
                                    onChange={(e) => {
                                        this.search(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className='branch-list'>
                            {
                                checkInput ?
                                    filterBranchs.map((item, i) => (
                                        this.renderItemShow(item)
                                    ))
                                    :
                                    branchs.map((item, i) => (
                                        this.renderItemShow(item)
                                    ))
                            }
                        </div>
                    </div>
                </div>
            </DraggableModal>
        );
    }

}

const mapStateToProps = state => {
    const defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme
    return {
        defaultTheme: defaultTheme,
        language: state.app.language,
    };
};

const mapDispatchToProps = dispatch => {
    return {

    };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(withScriptjs(withGoogleMap(NetModal))));