import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import RetryableContent from "../../../components/RetryableContent";
import * as actions from "../../../store/actions";
import { LoadStatus } from "../../../utils";
import { ReactComponent as CheckIcon } from '../../../assets/icons/screenmodal/symboldetail/CheckIcon.svg'
import { ReactComponent as PlusDarkIcon } from '../../../assets/icons/screenmodal/symboldetail/PlusDarkIcon.svg'
import { ReactComponent as PlusLightIcon } from '../../../assets/icons/screenmodal/symboldetail/PlusLightIcon.svg'

import './AddWatchListModal.scss';

class AddWatchListModal extends Component {

    static getDerivedStateFromProps(nextProps, prevState) {
        const { watchLists } = nextProps;
        const { watchLists: prevWatchLists } = prevState;
        if (JSON.stringify(watchLists) !== JSON.stringify(prevWatchLists)) {
            return {
                watchLists: watchLists,
            };
        }
        return null;
    }


    constructor(props) {
        super(props);
        this.TransAuthExternalRef = React.createRef();
    }

    initialState = {
        processStatus: LoadStatus.NONE
    };

    state = {
        ...this.initialState
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }
	
    componentDidMount() {
        this.mounted = true
	}	
		
	componentWillUnmount() {
        this.mounted = false
    }
    
    toggle = () => {
        this.props.setIsOpenAddWatchListModal(false)
    };

    removeSymbol = (colectionID) => {
        this._setState({ processStatus: LoadStatus.LOADING });
        const { watchLists } = this.state;
        const { currentSymbol } = this.props;
        let updateWatchList = watchLists.find(item => item.id === colectionID);
        if (updateWatchList) {
            let newWatchListSymbol = [...updateWatchList.symbols];
            let index = newWatchListSymbol.indexOf(currentSymbol.id);
            if (index > -1) {
                newWatchListSymbol.splice(index, 1);
            }

            this.props.updateWatchList({ ...updateWatchList, symbols: newWatchListSymbol }, this.props.isLoggedIn)
                .then(() => {
                    this.props.fetchWatchLists(false, this.props.isLoggedIn);
                    this._setState({ processStatus: LoadStatus.LOADED });
                });
        }
    }

    addSymbol = (colectionID) => {
        this._setState({ processStatus: LoadStatus.LOADING });
        const { watchLists } = this.state;
        const { currentSymbol } = this.props;
        let updateWatchList = watchLists.find(item => item.id === colectionID);
        if (updateWatchList) {
            let newWatchListSymbol = [...updateWatchList.symbols];
            newWatchListSymbol.push(currentSymbol.id);

            this.props.updateWatchList({ ...updateWatchList, symbols: newWatchListSymbol }, this.props.isLoggedIn)
                .then(() => {
                    this.props.fetchWatchLists(false, this.props.isLoggedIn);
                    this._setState({ processStatus: LoadStatus.LOADED });
                });
        }

    }

    genderIcon = (keyIcon, item) => {
        let { defaultTheme } = this.props
        switch (keyIcon) {
            case 1:
                return defaultTheme === 'dark' ? <PlusDarkIcon onClick={() => this.addSymbol(item.id)} /> : <PlusLightIcon onClick={() => this.addSymbol(item.id)} />
            default:
                break;
        }
    }


    render() {
        const { isOpen, currentSymbol } = this.props;
        const { watchLists, processStatus } = this.state;

        return (
            <Modal
                isOpen={isOpen}
                centered={true}
                className="add-watchlist-modal"
            >
                <RetryableContent status={processStatus} disableRetry={true}>
                    <div className="custom-header">
                        <div className=" headB---700-20-30">
                            <FormattedMessage id="symbol-detail-page.add-watch-list" />
                        </div>
                        <div className="btn-icon-fm" onClick={this.toggle}>
                            <i className="fal fa-times"></i>
                        </div>
                    </div>
                    <ModalBody className="custom-body">
                        {/* Danh sách danh mục yêu thích */}
                        {watchLists.length > 0 && watchLists.map(item => {
                            let symbolsLength = item.symbols.filter(symbolItem => { return symbolItem !== '' }).length;
                            let isContainSymbol = item.symbols.includes(currentSymbol.id);
                            return (
                                <div className="row gutters-5 align-items-center h-row watchlist-item">
                                    <div className="col-10 label-container">
                                        <p className="txt---400-16-24 watch-list-name">
                                            {item.name}
                                        </p>
                                        <p className="txt---400-14-20 watch-list-length">
                                            {symbolsLength} <FormattedMessage id="symbol-detail-page.symbol" />
                                        </p>
                                    </div>
                                    <div className="col-2 action-container text-center">
                                        <div>
                                            {isContainSymbol ?
                                                <CheckIcon onClick={() => this.removeSymbol(item.id)} />
                                                :
                                                this.genderIcon(1, item)
                                            }

                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                    </ModalBody>
                </RetryableContent >
            </Modal >
        );
    }

}

const mapStateToProps = state => {
    let defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme

    return {
        defaultTheme: defaultTheme,
        watchLists: state.symbol.watchLists,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateWatchList: (watchList, isLoggedIn) => dispatch(actions.updateWatchList(watchList, isLoggedIn)),
        fetchWatchLists: (forceReload, isLoggedIn) => dispatch(actions.fetchWatchList(forceReload, isLoggedIn))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddWatchListModal);