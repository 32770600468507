import React, { Component, Fragment } from "react";
import { FormattedMessage } from "react-intl";
import './ModalComfirmPlaceOrderTPSL.scss';
import DraggableModal from '../../../../../components/Modal/DraggableModal';
import { connect } from 'react-redux';
import { LoadStatus } from '../../../../../utils';
import { FormattedPrice, FormattedPercentage } from '../../../../../components/Formating';
import TradeAuth2 from '../../../../../components/TradeAuth2/TradeAuth2';
import { tradeApiService } from 'services';
import RetryableContent from './../../../../../components/RetryableContent';
import CustomRowItem from 'components/CustomRowItem/CustomRowItem';
import CommonUtils from './../../../../../utils/CommonUtils';
import { TYPE_ACTION_TPSL } from './../ModalPlaceOrderTPSL';
class ModalComfirmPlaceOrderTPSL extends Component {

    initialState = {
        loadStatus: LoadStatus.NONE,
    };

    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState
        }
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }
	
	componentWillUnmount() {
        this.mounted = false
    }
	
    componentDidMount() {
        this.mounted = true
	}	

    setLoadStatus = (status) => {
        this._setState({
            loadStatus: status
        });
    };


    processTradeAuth = (authInfo) => {
        return new Promise(function (resolve, reject) {
            return resolve(authInfo);
        });
    };

    twoFactorAuthCb = (data) => {
        const { twoFactorAuthCb } = this.props
        twoFactorAuthCb(data);
    };


    onFinish = () => {
        const { onClose } = this.props;
        onClose()
    };


    UNSAFE_componentWillReceiveProps(nextProps) {
        const { loadStatusComfirmPlaceOrderTPSL } = nextProps
        this.setLoadStatus(loadStatusComfirmPlaceOrderTPSL ? loadStatusComfirmPlaceOrderTPSL : this.state.loadStatus)
    }


    renderTPSLOrderTableCancel = () => {
        const { listReconfirmOrder } = this.props;
        const { quantity, fromDate, toDate, takeProfit, stopLoss, symbolCurrent, costPrice, profitLossTP, profitLossTPRate,
            profitLossSL, profitLossSLRate, accountID
        } = listReconfirmOrder

        return (
            <>
                <div>
                    <CustomRowItem
                        showLabel="trade.order.account"
                        showValue={accountID}
                    />
                    <CustomRowItem
                        showLabel="trade.order.symbol"
                        showValue={symbolCurrent && symbolCurrent.id}
                    />
                    <CustomRowItem
                        showLabel="Giá vốn"
                        showValue={costPrice}
                        isPrice={true}
                        isToFixed={true}
                        isNotThousand={false}
                    />
                    <CustomRowItem
                        showLabel="trade.order.qtty"
                        showValue={quantity && quantity}
                        isQtty={true}
                        isToFixed={true}
                    />
                    <CustomRowItem
                        showLabel="Take profit"
                        showValue={takeProfit && takeProfit}
                        isPrice={true}
                        isToFixed={true}
                    />
                    <div className="custom-row-group" >
                        <div className="custom-row-form row m-0">
                            <div className="col-6 p-0">
                                <div className="label txt---400-14-20">
                                    <FormattedMessage id="open-position.value-totalplamt" />
                                </div>
                            </div>
                            <div className="col-6 p-0">
                                <div className="value txt---400-14-20">
                                    <span className={"" + (CommonUtils.getClassCheckValue(profitLossTP))}>
                                        <span>
                                            {profitLossTP > 0 && "+"}
                                        </span>
                                        <FormattedPrice value={profitLossTP} />
                                    </span>&nbsp;
                                    <span className={"" + (CommonUtils.getClassCheckValue(profitLossTPRate))}>
                                        {"("}<span>
                                            {profitLossTPRate > 0 && "+"}
                                        </span>
                                        <FormattedPercentage value={profitLossTPRate} />%{")"}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <CustomRowItem
                        showLabel="Stop Loss"
                        showValue={stopLoss && stopLoss}
                        isToFixed={true}
                        isPrice={true}
                    />
                    <div className="custom-row-group" >
                        <div className="custom-row-form row m-0">
                            <div className="col-6 p-0">
                                <div className="label txt---400-14-20">
                                    <FormattedMessage id="open-position.value-totalplamt" />
                                </div>
                            </div>
                            <div className="col-6 p-0">
                                <div className="value txt---400-14-20">
                                    <span className={"" + (CommonUtils.getClassCheckValue(profitLossSL))}>
                                        <span>
                                            {profitLossSL > 0 && "+"}
                                        </span>
                                        <FormattedPrice value={profitLossSL} />
                                    </span>&nbsp;
                                    <span className={"" + (CommonUtils.getClassCheckValue(profitLossSLRate))}>
                                        {"("}<span>
                                            {profitLossSLRate > 0 && "+"}
                                        </span>
                                        <FormattedPercentage value={profitLossSLRate} />%{")"}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };


    renderTPSLOrderTableOrder = () => {
        const { listReconfirmOrder } = this.props;
        const { quantity, fromDate, toDate, takeProfit, stopLoss, symbolCurrent, costPrice, profitLossTP, profitLossTPRate,
            profitLossSL, profitLossSLRate, accountID
        } = listReconfirmOrder

        return (
            <>
                <div>
                    <CustomRowItem
                        showLabel="trade.order.account"
                        showValue={accountID}
                    />
                    <CustomRowItem
                        showLabel="trade.order.symbol"
                        showValue={symbolCurrent && symbolCurrent.id}
                    />
                    <CustomRowItem
                        showLabel="open-position.cost-price"
                        showValue={costPrice}
                        isPrice={true}
                        isToFixed={true}
                        isNotThousand={false}
                    />
                    <CustomRowItem
                        showLabel="trade.order.qtty"
                        showValue={quantity && quantity}
                        isQtty={true}
                        isToFixed={true}
                    />
                    <CustomRowItem
                        showLabel="Take profit"
                        showValue={takeProfit && takeProfit}
                        isPrice={true}
                        isToFixed={true}
                    />
                    <div className="custom-row-group" >
                        <div className="custom-row-form row m-0">
                            <div className="col-6 p-0">
                                <div className="label txt---400-14-20">
                                    <FormattedMessage id="open-position.value-totalplamt" />
                                </div>
                            </div>
                            <div className="col-6 p-0">
                                <div className="value txt---400-14-20">
                                    <span className={"" + (CommonUtils.getClassCheckValue(profitLossTP))}>
                                        <span>
                                            {profitLossTP > 0 && "+"}
                                        </span>
                                        <FormattedPrice value={profitLossTP} />
                                    </span>&nbsp;
                                    <span className={"" + (CommonUtils.getClassCheckValue(profitLossTPRate))}>
                                        {"("}<span>
                                            {profitLossTPRate > 0 && "+"}
                                        </span>
                                        <FormattedPercentage value={profitLossTPRate} />%{")"}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <CustomRowItem
                        showLabel="Stop Loss"
                        showValue={stopLoss && stopLoss}
                        isToFixed={true}
                        isPrice={true}
                    />
                    <div className="custom-row-group" >
                        <div className="custom-row-form row m-0">
                            <div className="col-6 p-0">
                                <div className="label txt---400-14-20">
                                    <FormattedMessage id="open-position.value-totalplamt" />
                                </div>
                            </div>
                            <div className="col-6 p-0">
                                <div className="value txt---400-14-20">
                                    <span className={"" + (CommonUtils.getClassCheckValue(profitLossSL))}>
                                        <span>
                                            {profitLossSL > 0 && "+"}
                                        </span>
                                        <FormattedPrice value={profitLossSL} />
                                    </span>&nbsp;
                                    <span className={"" + (CommonUtils.getClassCheckValue(profitLossSLRate))}>
                                        {"("}<span>
                                            {profitLossSLRate > 0 && "+"}
                                        </span>
                                        <FormattedPercentage value={profitLossSLRate} />%{")"}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    render() {
        const { actionSelect } = this.state
        const { isOpen, onClose, listReconfirmOrder, onHandleCancelOrder, typeActionTPSL } = this.props;
        let _className = 'modal-comfirm-place-order-tpsl'
        let titleId = typeActionTPSL === TYPE_ACTION_TPSL.PLACE_ORDER ? 'trade.auth.title' : 'trade.auth.cancel-title'

        const genAuthFunc = tradeApiService.resendTwoFactorCode;    // gen lại mã xác thực ở tradeApi
        const twoFactorAuthFunc = this.processTradeAuth // check biến (transFunction) để biết xem nên gọi function validate mã xác thực ở server nào (trade, transaction)
        const initAuthFunc = tradeApiService.initTwoFactorAuth; // check biến (transFunction) để biết xem nên gọi function init Transaction (trade, transaction)

        return (
            <DraggableModal
                isOpen={isOpen}
                onClose={onClose}
                className={_className}
                titleId={titleId}
                toggle={onClose}
            >
                <RetryableContent status={this.state.loadStatus} disableRetry={true} className="trade-auth-comfirm-place-order-tpsl">
                    <div className="body">
                        <div className="wrap-body">
                            {typeActionTPSL === TYPE_ACTION_TPSL.CANCEL && this.renderTPSLOrderTableCancel()}
                            {typeActionTPSL === TYPE_ACTION_TPSL.PLACE_ORDER && this.renderTPSLOrderTableOrder()}
                            <TradeAuth2
                                containerClass="row gutters-5 align-items-center auth-container"
                                labelClass="col-5"
                                inputClass="col-7 custom-form-group"
                                inputClassAuth="col-7 custom-form-group-effect"
                                btnContainerClass=""
                                onFinish={this.onFinish}

                                initAuthFunc={initAuthFunc}
                                genAuthFunc={genAuthFunc}
                                twoFactorAuthFunc={twoFactorAuthFunc}
                                twoFactorAuthCb={this.twoFactorAuthCb}
                                setLoadStatus={status => this.setLoadStatus(status)}
                                // autoCb={autoCb}
                                isDisableConfirmBtn={this.state.loadStatus == LoadStatus.LOADING ? true : false}
                                showOrderSwitch={true}
                                autoFocus={true}
                                fillPassword={true}

                                isOrder={true}
                                noteMessageId={listReconfirmOrder && listReconfirmOrder.noteMessageId}
                                noteMessageClass="txt---400-14-20 text-not-active font-italic"
                            />
                        </div>
                    </div>
                </RetryableContent>
            </DraggableModal>
        );
    }

}

const mapStateToProps = (state, ownProps) => {
    return {
        defaultTheme: state.user.userInfo && state.user.userInfo.defaultTheme
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ModalComfirmPlaceOrderTPSL);

