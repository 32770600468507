import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import './ShortCutListModal.scss';
import DraggableModal from 'components/Modal/DraggableModal';
import CustomScrollbars from 'components/CustomScrollbars';

const shortCutList = [
    {
        nameId: 'Alt + 2',
        functionalDescIdCustomer: 'shortcut-modal.alt2-customer',
        functionalDescIdBroker: 'shortcut-modal.alt2-broker'
    },
    {
        nameId: 'Alt + 3',
        functionalDescIdCustomer: 'shortcut-modal.alt3-customer',
        functionalDescIdBroker: 'shortcut-modal.alt3-broker'
    },
    {
        nameId: 'Alt + 4',
        functionalDescIdCustomer: 'shortcut-modal.alt4',
        functionalDescIdBroker: 'shortcut-modal.alt4'
    },
    {
        nameId: 'Alt + 5',
        functionalDescIdCustomer: 'shortcut-modal.alt5',
        functionalDescIdBroker: 'shortcut-modal.alt5'
    }
]

class NetModal extends Component {

    constructor(props) {
        super(props);
    }

    initialState = {
        branchs: [],
        userInput: "",
        lat: Number(1),
        lng: Number(1),
        checkInput: false,
        filterBranchs: [],
        postionActive: "my_location"
    };

    state = {
        ...this.initialState
    };

    close = () => {
        this.props.setIsOpenShortCutList && this.props.setIsOpenShortCutList(false)
    }

    render() {
        const { isOpen, language, isBroker, intl } = this.props;
        const { branchs, userInput, filterBranchs, checkInput } = this.state
        //console.log("action netmodal state netmodal", this.state.lat, this.state.lng)
        return (
            <DraggableModal
                isOpen={isOpen}
                toggle={() => this.close()}
                onClose={() => this.close()}
                className={"shortcut-modal"}
                titleId={"menu-sidebar.title-8.5"}
            >
                <div className="body">
                    <CustomScrollbars
                        className="table-shortcut"
                        autoHeight={true}
                        autoHeightMin={220}
                        autoHeightMax={400}
                    >
                        <table className="basic-table">
                            <thead>
                                <tr>
                                    <th className="text-center"><FormattedMessage id="shortcut-modal.STT" /></th>
                                    <th className="text-center shortcut-name"><FormattedMessage id="shortcut-modal.shortcut-name" /></th>
                                    <th className="text-center"><FormattedMessage id="shortcut-modal.shortcut-func" /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {shortCutList && shortCutList.length > 0 && shortCutList.map((shortcutInfo, index) => {
                                    let { nameId, functionalDescIdCustomer, functionalDescIdBroker } = { ...shortcutInfo };
                                    let functionalDescId = isBroker ? functionalDescIdBroker : functionalDescIdCustomer;
                                    return (
                                        <tr key={index} >
                                            <td className={"text-center"}>
                                                {index + 1}
                                            </td>
                                            <td className="text-center shortcut-name"> {nameId}</td>
                                            <td className="">{intl.formatMessage({ id: functionalDescId }, { breakingLine: '\n' })}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </CustomScrollbars>
                </div>
            </DraggableModal>
        );
    }

}

const mapStateToProps = state => {
    const defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme
    return {
        defaultTheme: defaultTheme,
        language: state.app.language,
    };
};

const mapDispatchToProps = dispatch => {
    return {

    };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(NetModal));