import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { KeyCodeUtils } from "utils";

import { MODAL_BANK_TYPE } from './AdditionBank'
import DraggableModal from 'components/Modal/DraggableModal';


import './BankModal.scss';
import SelectSearchBySuggest from "components/SearchBySuggest/SelectSearchBySuggest";
import RetryableContent from "components/RetryableContent";


class BankModal extends Component {
    constructor(props) {
        super(props);
        this.refSearch = React.createRef();
        this.refBranchSearch = React.createRef();
        this.confirmBtnRef = React.createRef();
        this.refreshBtnRef = React.createRef();
        this.deleteBtnRef = React.createRef();
    }
    state = {
        isShowConfirm: false
    }

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }
	
	componentWillUnmount() {
        this.mounted = false
    }
	
    componentDidMount() {
        this.mounted = true
	}	

    overLoadHandleConfirm = () => {
        const { handleConfirm } = this.props
        handleConfirm()
    }

    handleConfirmDeleteBank = () => {
        this._setState({ isShowConfirm: true })
    }

    handleCancelConfirmDeleteBank = () => {
        this._setState({ isShowConfirm: false })
        //this.props.onClose();

    }

    onHandleKeyDownSubmit = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            event.preventDefault();
            this.confirmBtnRef.current && this.confirmBtnRef.current.click();
        }
    }

    onHandleKeyDownRefresh = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            event.preventDefault();
            this.refreshBtnRef.current && this.refreshBtnRef.current.click();
        }
    }

    onHandleKeyDownDelete = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            event.preventDefault();
            this.deleteBtnRef.current && this.deleteBtnRef.current.click();
        }
    }

    renderView = (props) => {
        const { data, handleDelete } = props

        const { isShowConfirm } = this.state
        return (
            // isShowConfirm ?
            // (
            //     <div className="modal-bank-body">
            //         <div className="d-flex justify-content-center pb-4 flex-column gutters-5 align-items-center h-row">
            //             <div className="image">
            //                 <CautionIconDark />
            //             </div>
            //             <div className="msg pt-2 txt---400-14-20">
            //                 <FormattedMessage id={'settings.personal.bank-addition.confirm'}
            //                     values={{ name: data && data.benefitbankname ? data.benefitbankname : '', b: chunks => <b>"{chunks}"</b>, }}
            //                 >{data}</FormattedMessage>
            //             </div>
            //         </div>

            //         {/* Thao tác */}
            //         <div className="row gutters-5 align-items-center h-row btn-container">
            //             {/* Làm mới */}
            //             <div className="col-6">
            //                 <button className="btn-refresh txt---500-16-24 btn" onClick={() => this.handleCancelConfirmDeleteBank()} >
            //                     <FormattedMessage id="common.btn-cancel" />
            //                 </button>
            //             </div>
            //             {/* Tiếp tục */}
            //             <div className="col-6">
            //                 <button
            //                     className="btn-confirm txt---500-16-24 btn" onClick={() => handleDelete(data)} >
            //                     <FormattedMessage id="common.btn-continue" />
            //                 </button>
            //             </div>
            //         </div>
            //     </div>
            // ) :
            (
                <div className="modal-bank-body">
                    <div className="modal-property  h-row">
                        <div>
                            <label className="label txt---400-14-20 ">
                                <FormattedMessage id={'settings.personal.bank-addition.accountBankName'} />
                            </label>
                        </div>
                        <div >
                            <div className=" txt---400-14-20">
                                {data && data.bankacname}
                            </div>
                        </div>
                    </div>

                    <div className="modal-property  h-row">
                        <div >
                            <label className="label txt---400-14-20">
                                <FormattedMessage id={'settings.personal.bank-addition.accountBankNumber'} />
                            </label>
                        </div>
                        <div >
                            <div className="txt---400-14-20">
                                {data && data.bankacc}
                            </div>
                        </div>
                    </div>

                    <div className="modal-property h-row">
                        <div >
                            <label className="label txt---400-14-20">
                                <FormattedMessage id={'settings.personal.bank-addition.bank'} />
                            </label>
                        </div>
                        <div>
                            <div className=" txt---400-14-20">
                                {data && data.bankname}
                            </div>
                        </div>
                    </div>

                    <div className="modal-property h-row">
                        <div >
                            <label className="label txt---400-14-20">
                                <FormattedMessage id={'settings.personal.bank-addition.bank-branch'} />
                            </label>
                        </div>
                        <div>
                            <div className=" txt---400-14-20">
                                {data && data.citybank}
                            </div>
                        </div>
                    </div>

                    {/* Thao tác */}
                    <div className="btn-delete-bank row gutters-5 align-items-center h-row container-btn">
                        {/* Xóa */}
                        <div className="col-12">
                            <button className="btn-refresh txt---500-16-24 btn bg-sell btn-delete-bank" onClick={() => handleDelete(data)} ref={this.deleteBtnRef} onKeyDown={this.onHandleKeyDownDelete} autoFocus>
                                <FormattedMessage id="common.btn-delete" />
                            </button>
                        </div>
                    </div>
                </div>)
        )
    }

    renderAdd = (props) => {
        const { bankList, bankId, customerBankNumber, customerBankName,
            onChangeBankList, onChangeCustomerBankNumber, onChangeCustomerBankName, bankbranch,
            handleCancel, onChangeBankBranch, bankBranchList
        } = props
        return (

            <div className="modal-bank-body">

                <div className="col-add row gutters-5 align-items-center h-row">
                    <div className="col-5">
                        <label className="label txt---400-14-20">
                            <FormattedMessage id={'settings.personal.bank-addition.accountBankName'} />
                        </label>
                    </div>
                    <div className="col-7">
                        <div className="custom-form-group">
                            {/* {customerBankName} */}
                            <input
                                type="tel"
                                className={"custom-form-control"}
                                value={customerBankName}
                                disabled={true}
                            // onChange={(e) => onChangeCustomerBankName(e)}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-add row gutters-5 align-items-center h-row">
                    <div className="col-5">
                        <label className="label txt---400-14-20">
                            <FormattedMessage id={'settings.personal.bank-addition.accountBankNumber'} />
                        </label>
                    </div>
                    <div className="col-7">
                        <div className="custom-form-group-effect">
                            <input
                                type="tel"
                                className="custom-form-control"
                                value={customerBankNumber}
                                onChange={(e) => onChangeCustomerBankNumber(e)}
                                autoFocus={true}
                                onKeyDown={this.onHandleKeyDownSubmit}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-add row gutters-5 align-items-center h-row">
                    <div className="col-5">
                        <label className="label txt---400-14-20">
                            <FormattedMessage id={'settings.personal.bank-addition.bank'} />
                        </label>
                    </div>
                    <div className="col-7">
                        <SelectSearchBySuggest
                            ref={this.refSearch}
                            onChange={onChangeBankList}
                            currentSymbol={bankId}
                            symbolValid={false}
                            isRedirect={false}
                            isDisplayLabel={false}
                            isDisplayError={false}
                            symbolSearchArray={bankList}
                            isUpperCase={false}
                            onKeyDown={this.onHandleKeyDownSubmit}
                        />
                    </div>
                </div>

                <div className="col-add row gutters-5 align-items-center h-row">
                    <div className="col-5">
                        <label className="label txt---400-14-20">
                            <FormattedMessage id={'settings.personal.bank-addition.bank-branch'} />
                        </label>
                    </div>
                    <div className="col-7">
                        <SelectSearchBySuggest
                            ref={this.refBranchSearch}
                            onChange={onChangeBankBranch}
                            currentSymbol={bankbranch}
                            symbolValid={false}
                            isRedirect={false}
                            isDisplayLabel={false}
                            isDisplayError={false}
                            symbolSearchArray={bankBranchList}
                            isUpperCase={false}
                            onKeyDown={this.onHandleKeyDownSubmit}
                        />
                    </div>
                </div>


                {/* Thao tác */}
                <div className="col-add row gutters-5 align-items-center h-row container-btn">
                    {/* Làm mới */}
                    <div className="col-6">
                        <button className="btn-refresh txt---500-16-24 btn" onClick={() => handleCancel()} ref={this.refreshBtnRef} onKeyDown={this.onHandleKeyDownRefresh}>
                            <FormattedMessage id="common.btn-cancel" />
                        </button>
                    </div>
                    {/* Tiếp tục */}
                    <div className="col-6">
                        <button
                            // disabled={!validRegisterVoucherAmount}
                            className="btn-confirm txt---500-16-24 btn" onClick={() => this.overLoadHandleConfirm()} ref={this.confirmBtnRef} onKeyDown={this.onHandleKeyDownSubmit}>
                            <FormattedMessage id="common.btn-continue" />
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const { isOpen, onClose, typeModal, loadStatus
        } = this.props;

        const { isShowConfirm } = this.state

        let _className = 'modal-bank'
        let titleId = 'settings.personal.bank-addition.add-bank'
        if (typeModal === MODAL_BANK_TYPE.ADD) {
            titleId = 'settings.personal.bank-addition.add-bank'
        } else if (typeModal === MODAL_BANK_TYPE.VIEW) {
            if (isShowConfirm) {
                titleId = 'settings.personal.bank-addition.warning'
            } else {
                titleId = 'settings.personal.bank-addition.view-bank'
            }
        }

        return (
            <DraggableModal
                isOpen={isOpen}
                onClose={onClose}
                className={_className}
                titleId={titleId}
            >
                <RetryableContent status={loadStatus} disableRetry={true} className="re-try-content-bank-addition">
                    <>
                        {typeModal === MODAL_BANK_TYPE.VIEW && this.renderView(this.props)}

                        {typeModal === MODAL_BANK_TYPE.ADD && this.renderAdd(this.props)}
                    </>
                </RetryableContent>

            </DraggableModal>
        );
    }

}

export default BankModal;