import { push } from "connected-react-router";
import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { CustomInput } from 'reactstrap';

// import NumberFormatCustom from "../../../../../NumberFormatCustom";
import { FormattedPriceDiff, NumberFormatCustom } from 'components/Formating';
import RenderGridCellRealTime from 'components/Table/TableExtreme/RenderGridCellRealTime';
import TableExtremeCustom2 from 'components/Table/TableExtreme/TableExtremeCustom2';
import Util from 'modules/util';
import { emitter, FOCUS_PRICE_INPUT } from 'utils/EventEmitter';

import { ReactComponent as IconInfoActive } from '../../../assets/icons_new/icon_info_active.svg'
import RetryableContent from '../../../components/RetryableContent';
import RenderGridCell from '../../../components/Table/TableExtreme/RenderGridCell';
import TextToolTip from "../../../components/TextToolTip";
import { fdsInquiryService, inquiryService } from '../../../services';
import * as socket from "../../../socket";
import * as actions from "../../../store/actions";
import { CommonOrderUtils, LoadStatus, ORDER_TYPE_USE, orderActions, Random, Role, ToastUtil, TYPE_ACCOUNT, FOCUS_KEYS, LanguageUtils, ModalConfirmType } from "../../../utils";
import CommonUtils from "../../../utils/CommonUtils";
import { SELECTOR_ACCOUNT_SCREEN } from './../../../utils/constants';
import ModalPlaceOrderTPSL from './ModalPlaceOrderTPSL/ModalPlaceOrderTPSL';
import { history } from '../../../redux';
import $ from 'jquery';

import './OpenPositionsNew.scss';
import ModalPlaceSellAllOpenposition from "./ModalPlaceSellAllOpenposition/ModalPlaceSellAllOpenposition";

let listSymbolsSocket = [] // list symbolSocket
let keyRealTime = 'symbol' // list Column cần realtime dữ liệu theo instrument
let keyStoreUpdate = 'symbol_accountId'
let CM_RECORDS = {}

// {
//     dataField: "tpsl",
//     fixed: false,
//     fixedPosition: "left", // Cố định cột
//     dataType: "string",
//     allowReordering: true,  // không cho di chuyển
//     // width: "7%",
//     minWidth: "80",
//     width: '100',
//     visible: true,
//     alignment: "center",
//     visibleIndex: 1,
//     allowFiltering: false
// },
// {
//     dataField: "accountID",
//     fixed: false,
//     fixedPosition: "left", // Cố định cột
//     dataType: "string",
//     allowReordering: true,
//     // width: "7%",
//     minWidth: "90",
//     width: '100',
//     visible: true,
//     alignment: "center",
//     visibleIndex: 2,
// },
// {
//     dataField: "total",
//     fixed: false,
//     fixedPosition: "left", // Cố định cột
//     dataType: "string",
//     allowReordering: true,
//     // width: "7%",
//     minWidth: "90",
//     width: '100',
//     visible: true,
//     alignment: "right",
//     visibleIndex: 4,
// },

let columnsDefault =
    [
        {
            dataField: "action",
            fixed: false,
            fixedPosition: "left", // Cố định cột
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            // width: "7%",
            minWidth: "120",
            width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 0,
            allowFiltering: false
        },
        {
            dataField: "al_afacctno",
            fixed: false,
            dataType: "string",
            allowReordering: true,
            fixedPosition: "left", // Cố định cột
            // width: "7%",
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "center",
            visibleIndex: 1,
            isCellRaw: true,
            // format: ",##0"
        },
        {
            dataField: "symbol",
            fixed: false,
            fixedPosition: "left", // Cố định cột
            dataType: "string",
            allowReordering: true,
            // width: "7%",
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "center",
            visibleIndex: 2,
        },
        {
            dataField: "totalExt",
            fixed: false,
            fixedPosition: "left", // Cố định cột
            dataType: "string",
            allowReordering: true,
            // width: "7%",
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "right",
            visibleIndex: 3,
            isCellRaw: true,
            format: ",##0"
        },
        {
            dataField: "trade",
            fixed: false,
            dataType: "string",
            allowReordering: true,
            fixedPosition: "left", // Cố định cột
            // width: "7%",
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "right",
            visibleIndex: 4,
            isCellRaw: true,
            format: ",##0"
        },
        {
            dataField: "group",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            alignment: "right",
            visible: true,
            visibleIndex: 5,
            isCellRaw: true,
            format: ",##0",
            listColumnGroup: [
                {
                    dataField: "receivingT0",
                    dataType: "string",
                    allowReordering: true,  // không cho di chuyển
                    // width: "5%",
                    width: "80",
                    minWidth: "60",
                    alignment: "right",
                    visible: true,
                    visibleIndex: 15,
                    name: "group",
                    isCellRaw: true,
                    format: ",##0"
                },
                {
                    dataField: "receivingT1",
                    dataType: "string",
                    allowReordering: true,  // không cho di chuyển
                    // width: "5%",
                    width: "80",
                    minWidth: "60",
                    alignment: "right",
                    visible: true,
                    visibleIndex: 16,
                    name: "group",
                    isCellRaw: true,
                    format: ",##0"
                },
                {
                    dataField: "receivingT2",
                    dataType: "string",
                    allowReordering: true,  // không cho di chuyển
                    // width: "5%",
                    width: "80",
                    minWidth: "60",
                    alignment: "right",
                    visible: true,
                    visibleIndex: 17,
                    name: "group",
                    isCellRaw: true,
                    format: ",##0"
                }
            ],
        },
        {
            dataField: "group1",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            alignment: "right",
            visible: true,
            visibleIndex: 6,
            isCellRaw: true,
            format: ",##0",
            listColumnGroup: [
                {
                    dataField: "sendingT0",
                    dataType: "string",
                    allowReordering: true,  // không cho di chuyển
                    // width: "5%",
                    width: "80",
                    minWidth: "60",
                    alignment: "right",
                    visible: true,
                    visibleIndex: 15,
                    name: "group1",
                    isCellRaw: true,
                    format: ",##0"
                },
                {
                    dataField: "sendingT1",
                    dataType: "string",
                    allowReordering: true,  // không cho di chuyển
                    // width: "5%",
                    width: "80",
                    minWidth: "60",
                    alignment: "right",
                    visible: true,
                    visibleIndex: 16,
                    name: "group1",
                    isCellRaw: true,
                    format: ",##0"
                },
                {
                    dataField: "sendingT2",
                    dataType: "string",
                    allowReordering: true,  // không cho di chuyển
                    // width: "5%",
                    width: "80",
                    minWidth: "60",
                    alignment: "right",
                    visible: true,
                    visibleIndex: 17,
                    name: "group1",
                    isCellRaw: true,
                    format: ",##0"
                }
            ],
        },
        {
            dataField: "costPrice",
            dataType: "string",
            allowReordering: true, // không cho di chuyển
            // width: "7%",
            minWidth: "90",
            // fixedPosition: "right", // Cố định cột
            fixed: false,
            width: '100',
            visible: true,
            alignment: "right",
            visibleIndex: 7,
            // isCellRaw: true,
            // format: ",##0"
        },

        {
            dataField: "costPriceAmt",
            dataType: "string",
            allowReordering: true,
            // width: "7%",
            minWidth: "120",
            // fixedPosition: "right", // Cố định cột
            fixed: false,
            width: '150',
            visible: true,
            alignment: "right",
            visibleIndex: 8,
            isCellRaw: true,
            format: ",##0"
        },
        {
            dataField: "basicPrice",
            dataType: "string",
            allowReordering: true,
            // width: "7%",
            // fixedPosition: "right", // Cố định cột
            fixed: false,
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "right",
            visibleIndex: 9,
            // isCellRaw: true,
            // format: ",##0"
        },
        {
            dataField: "basicPriceAmt",
            dataType: "string",
            allowReordering: true,
            // fixedPosition: "right", // Cố định cột
            fixed: false,
            // width: "7%",
            minWidth: "120",
            width: '150',
            visible: true,
            alignment: "right",
            visibleIndex: 10,
            isCellRaw: true,
            format: ",##0"
        },
        // chưa có
        {
            dataField: "mrratioloan",
            dataType: "string",
            allowReordering: true,
            // fixedPosition: "right", // Cố định cột
            fixed: false,
            // width: "7%",
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "right",
            visibleIndex: 11,
        },
        {
            dataField: "proportion",
            dataType: "string",
            allowReordering: true,
            // fixedPosition: "right", // Cố định cột
            // fixed: false,
            // width: "auto", // cột cuối cùng mặc định để auto
            // width: "7%",
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "right",
            visibleIndex: 12,
        },
        {
            dataField: "pnlamt",
            dataType: "string",
            allowReordering: true,
            // fixedPosition: "right", // Cố định cột
            // fixed: false,
            // width: "7%",
            minWidth: "120",
            width: '150',
            visible: true,
            alignment: "right",
            visibleIndex: 13,
        },
        {
            dataField: "pnlrate",
            dataType: "string",
            allowReordering: true,
            // fixedPosition: "right", // Cố định cột
            fixed: false,
            // width: "7%",
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "right",
            visibleIndex: 14,
        }
    ]


let columnsDefaultTPRLNM =
    [
        {
            dataField: "action",
            fixed: false,
            fixedPosition: "left", // Cố định cột
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            // width: "7%",
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 0,
            allowFiltering: false
        },
        {
            dataField: "al_afacctno",
            fixed: false,
            dataType: "string",
            allowReordering: true,
            fixedPosition: "left", // Cố định cột
            // width: "7%",
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "center",
            visibleIndex: 1,
            isCellRaw: true,
            // format: ",##0"
        },
        {
            dataField: "symbol",
            fixed: false,
            fixedPosition: "left", // Cố định cột
            dataType: "string",
            allowReordering: true,
            // width: "7%",
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "center",
            visibleIndex: 2,
        },
        {
            dataField: "trade",
            fixed: false,
            dataType: "string",
            allowReordering: true,
            fixedPosition: "left", // Cố định cột
            // width: "7%",
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "right",
            visibleIndex: 3,
            isCellRaw: true,
            format: ",##0"
        },
        {
            dataField: "group",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            alignment: "right",
            visible: true,
            visibleIndex: 4,
            isCellRaw: true,
            listColumnGroup: [
                {
                    dataField: "receivingT0",
                    dataType: "string",
                    allowReordering: true,  // không cho di chuyển
                    // width: "5%",
                    width: "80",
                    minWidth: "60",
                    alignment: "right",
                    visible: true,
                    visibleIndex: 5,
                    name: "group",
                    isCellRaw: true,
                }
            ],
        },
        {
            dataField: "group1",
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            alignment: "right",
            visible: true,
            visibleIndex: 6,
            isCellRaw: true,
            listColumnGroup: [
                {
                    dataField: "sendingT0",
                    dataType: "string",
                    allowReordering: true,  // không cho di chuyển
                    // width: "5%",
                    width: "80",
                    minWidth: "60",
                    alignment: "right",
                    visible: true,
                    visibleIndex: 7,
                    name: "group1",
                    isCellRaw: true,
                }
            ],
        },
        {
            dataField: "totalExt",
            fixed: false,
            fixedPosition: "left", // Cố định cột
            dataType: "string",
            allowReordering: true,
            // width: "7%",
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "right",
            visibleIndex: 8,
            isCellRaw: true,
            format: ",##0"
        },
        {
            dataField: "costPrice",
            dataType: "string",
            allowReordering: true, // không cho di chuyển
            // width: "7%",
            minWidth: "90",
            // fixedPosition: "right", // Cố định cột
            fixed: false,
            width: '100',
            visible: true,
            alignment: "right",
            visibleIndex: 9,
            // isCellRaw: true,
            // format: ",##0"
        },

        {
            dataField: "costPriceAmt",
            dataType: "string",
            allowReordering: true,
            // width: "7%",
            minWidth: "120",
            // fixedPosition: "right", // Cố định cột
            fixed: false,
            width: '150',
            visible: true,
            alignment: "right",
            visibleIndex: 10,
            isCellRaw: true,
            format: ",##0"
        },
        {
            dataField: "basicPrice",
            dataType: "string",
            allowReordering: true,
            // width: "7%",
            // fixedPosition: "right", // Cố định cột
            fixed: false,
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "right",
            visibleIndex: 11,
            // isCellRaw: true,
            // format: ",##0"
        },
        {
            dataField: "basicPriceAmt",
            dataType: "string",
            allowReordering: true,
            // fixedPosition: "right", // Cố định cột
            fixed: false,
            // width: "7%",
            minWidth: "120",
            width: '150',
            visible: true,
            alignment: "right",
            visibleIndex: 12,
            isCellRaw: true,
            format: ",##0"
        },
        // chưa có
        {
            dataField: "mrratioloan",
            dataType: "string",
            allowReordering: true,
            // fixedPosition: "right", // Cố định cột
            fixed: false,
            // width: "7%",
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "right",
            visibleIndex: 13,
        },
        {
            dataField: "proportion",
            dataType: "string",
            allowReordering: true,
            // fixedPosition: "right", // Cố định cột
            // fixed: false,
            // width: "auto", // cột cuối cùng mặc định để auto
            // width: "7%",
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "right",
            visibleIndex: 14,
        },
        {
            dataField: "pnlamt",
            dataType: "string",
            allowReordering: true,
            // fixedPosition: "right", // Cố định cột
            // fixed: false,
            // width: "7%",
            minWidth: "120",
            width: '150',
            visible: true,
            alignment: "right",
            visibleIndex: 15,
        },
        {
            dataField: "pnlrate",
            dataType: "string",
            allowReordering: true,
            // fixedPosition: "right", // Cố định cột
            fixed: false,
            // width: "7%",
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "right",
            visibleIndex: 16,
        }
    ]


let columnsDefaultFDS =
    [
        {
            dataField: "action",
            fixed: false,
            fixedPosition: "left", // Cố định cột
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            // width: "7%",
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 0,
            allowFiltering: false
        },
        {
            dataField: "dtacctno",  // tiểu khoản 
            fixed: false,
            fixedPosition: "left",
            dataType: "string",
            allowReordering: true,
            // width: "7%",
            minWidth: "90",
            width: '100',
            visible: true,
            alignment: "center",
            visibleIndex: 1,
        },
        {
            dataField: "symbol", //symbol
            dataType: "string",
            fixed: false,
            fixedPosition: "left",
            allowReordering: true,
            // width: "7%",
            minWidth: "120",
            width: '150',
            visible: true,
            alignment: "center",
            visibleIndex: 2,
        },
        {
            dataField: "qttyFake", //vị thế nắm giữ
            dataType: "string",
            fixed: false,
            fixedPosition: "left",
            allowReordering: true,
            // width: "7%",
            minWidth: "120",
            width: '150',
            visible: true,
            alignment: "right",
            visibleIndex: 3,
        },
        {
            dataField: "pendinglqtty", //KL mua chờ khớp
            dataType: "string",
            allowReordering: true,
            // width: "7%",
            minWidth: "120",
            width: '150',
            visible: true,
            alignment: "right",
            visibleIndex: 4,
        },
        {
            dataField: "pendingsqtty", //KL bán chờ khớp
            dataType: "string",
            allowReordering: true,
            // width: "7%",
            minWidth: "120",
            width: '150',
            visible: true,
            alignment: "right",
            visibleIndex: 5,
        },
        {
            dataField: "vwap", //giá vốn
            dataType: "string",
            allowReordering: true,
            // width: "7%",
            minWidth: "120",
            width: '150',
            visible: true,
            alignment: "right",
            visibleIndex: 6,
        },
        {
            dataField: "price_secured", //giá khớp gần nhất";
            dataType: "string",
            allowReordering: true,
            // width: "7%",
            minWidth: "120",
            width: '150',
            visible: true,
            alignment: "right",
            visibleIndex: 7,
        },
        {
            dataField: "totalplamt", //Lãi/lỗ dự kiến
            dataType: "string",
            allowReordering: true,
            // width: "7%",
            minWidth: "120",
            width: '150',
            visible: true,
            alignment: "right",
            visibleIndex: 8,
        },
        {
            dataField: "vrimamt", //Nghĩa vụ IM
            dataType: "string",
            allowReordering: true,
            // width: "7%",
            minWidth: "120",
            width: '150',
            visible: true,
            alignment: "right",
            visibleIndex: 9,
        },
        {
            dataField: "delisteddt", //Ngày đáo hạn
            dataType: "string",
            allowReordering: true,
            // width: "7%",
            minWidth: "120",
            width: '150',
            visible: true,
            alignment: "center",
            visibleIndex: 10,
        },
        {
            dataField: "proportion", //Nghĩa vụ IM
            dataType: "string",
            allowReordering: true,
            // width: "7%",
            minWidth: "120",
            width: '150',
            visible: true,
            alignment: "right",
            visibleIndex: 11,
        },

    ]

const totalDefault =
    [
        {
            column: "totalExt",
            summaryType: "sum",
            displayFormat: "{0}",
            // valueFormat: ",##0.###",
            valueFormat: ",##0",
        },
        {
            column: "trade",
            summaryType: "sum",
            displayFormat: "{0}",
            // valueFormat: ",##0.###",
            valueFormat: ",##0",
        },
        // {
        //     column: "costPrice",
        //     summaryType: "custom",
        //     displayFormat: "{0}",
        //     // valueFormat: ",##0.###",
        //     valueFormat: ",##0",
        //     isSumCustom: true,
        // },
        {
            column: "costPriceAmt",
            summaryType: "sum",
            displayFormat: "{0}",
            // valueFormat: ",##0.###",
            valueFormat: ",##0",
        },
        {
            column: "basicPriceAmt",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "pnlamt",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
            priceColor: true,
        },
        {
            column: "proportion",
            summaryType: "sum",
            displayFormat: "{0} %",
            valueFormat: ",##0.##"
        },
        {
            column: "sumPnlrate",
            summaryType: "custom",
            showInColumn: "pnlrate",
            displayFormat: "{0} %",
            valueFormat: ",##0.##",
            isSumCustom: true,
            // priceColor: true,
        },
        {
            column: "receivingT0",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "receivingT1",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "receivingT2",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
    ]

const totalDefaultFDS =
    [
        {
            column: "qttyFake",
            summaryType: "sum",
            displayFormat: "{0}",
            // valueFormat: ",##0.###",
            valueFormat: ",##0",

        },
        {
            column: "pendinglqtty",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "pendingsqtty",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
            priceColor: true
        },
        {
            column: "totalplamt",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0.##",
            priceColor: true
        },
        {
            column: "proportion",
            summaryType: "sum",
            displayFormat: "{0} %",
            valueFormat: ",##0.##"
        },
    ]

const totalDefaultTPRLNM =
    [
        {
            column: "totalExt",
            summaryType: "sum",
            displayFormat: "{0}",
            // valueFormat: ",##0.###",
            valueFormat: ",##0",
        },
        {
            column: "trade",
            summaryType: "sum",
            displayFormat: "{0}",
            // valueFormat: ",##0.###",
            valueFormat: ",##0",
        },
        // {
        //     column: "costPrice",
        //     summaryType: "custom",
        //     displayFormat: "{0}",
        //     // valueFormat: ",##0.###",
        //     valueFormat: ",##0",
        //     isSumCustom: true,
        // },
        {
            column: "costPriceAmt",
            summaryType: "sum",
            displayFormat: "{0}",
            // valueFormat: ",##0.###",
            valueFormat: ",##0",
        },
        {
            column: "basicPriceAmt",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "pnlamt",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
            priceColor: true,
        },
        {
            column: "proportion",
            summaryType: "sum",
            displayFormat: "{0} %",
            valueFormat: ",##0.##"
        },
        {
            column: "sumPnlrate",
            summaryType: "custom",
            showInColumn: "pnlrate",
            displayFormat: "{0} %",
            valueFormat: ",##0.##",
            isSumCustom: true,
            // priceColor: true,
        },
        {
            column: "receivingT0",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "receivingT1",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "receivingT2",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
    ]
class OpenPositionsNew extends Component {

    initialState = {
        loadStatus: LoadStatus.NONE,
        onReloadData: true,
        isOpenModalTPSL: false,
        isOpenModalSellAll: false,
        dataForSellAll: [],
        fillDataModalTPSL: {},
        dataDetailTPSL: {},
        customSumTable: {
            sumPnlamt: 0, //Giá trị lãi lỗ
            sumCostPriceAmt: 0, //Giá trị vốn
            sumTotal: 0, // Tổng khối lượng
        },
        listSymbol: [],
        records: [],
    };
    callerId = Random.randomComponentId();
    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState
        }
        this.iconInfoRef = React.createRef();
        this.tableExRef = React.createRef();
        this.idTableEx = Random.randomComponentId();
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
            this.setState(obj, callback);
        }
    }

    isBroker = () => {
        const { role } = this.props;
        return role === Role.BROKER;
    };

    onReset = () => {
        this._setState({
            isOpenModalTPSL: false,
            isOpenModalSellAll: false,
            dataForSellAll: [],
            fillDataModalTPSL: {},
            dataDetailTPSL: {}
        })
    }


    onHandleTPSL = (record) => {
        const { updateProTrade, updatePlaceQuickOrder, accountInfo } = this.props
        const { typeAccount, currentAccountId } = { ...accountInfo }
        //console.log("binh_onHandleTPSL", record)
        let _dataFill = {
            ...record
        }

        if (record['bl_tpsl_quoteid'] !== null) {
            inquiryService.getCondorderDetailTPSL(record.accountID, record.bl_tpsl_quoteid)
                .then((data) => {
                    if (data && data.length > 0) {
                        this._setState({ isOpenModalTPSL: true, fillDataModalTPSL: _dataFill, dataDetailTPSL: data[0] })
                    }
                })
                .catch((error) => {
                    ToastUtil.errorApi(error, 'CM2');
                });
        } else {
            this._setState({ isOpenModalTPSL: true, fillDataModalTPSL: _dataFill, dataDetailTPSL: {} })
        }
    }

    onHandlerSellAll = () => {
        this.props.updateConfirmCheckAuth({
            typeInfo: ModalConfirmType.SELL_ALL_OPENPOSITION_NOTE,
            dataInfo: {
                title: "open-position.sell-all",
                onSubmit: () => {
                    this.onOpenSellAllModal();

                    this.props.setIsOpenModalHaveData("ScreenConfirm", {
                        isOpenScreenConfirm: false,
                    });
                }
            },
        })

        this.props.setIsOpenModalHaveData("ScreenConfirm", {
            isOpenScreenConfirm: true,
            isCheckAuth2: false,
        });

    }

    onOpenSellAllModal = () => {
        const { isOpenModalSellAll } = this.state;
        this._setState({ isOpenModalSellAll: !isOpenModalSellAll }, () => {
            if (this.state.isOpenModalSellAll === true) {
                let recordToProcess = _.filter(CM_RECORDS[this.idTableEx], item => { return item.issell === 'Y' })
                this.setState({ dataForSellAll: recordToProcess });
            }
        })
    }

    onHandleBuy = (record) => {
        const { updateProTrade, updatePlaceQuickOrder, accountInfo, isAssetTotal, updatedSymbolLayoutPage, setAccountIdScreen, clearDataPlaceQuickOrder, setCustodycdCustomerScreen } = this.props
        const { typeAccount } = { ...accountInfo }

        updatedSymbolLayoutPage(null)
        updatedSymbolLayoutPage(record.symbol, "search_layout")
        let getInfoOrderLayoutCurrent = CommonOrderUtils.getInfoOrderLayoutCurrent()
        const { listOrderLayoutCurrent } = getInfoOrderLayoutCurrent
        if (listOrderLayoutCurrent && listOrderLayoutCurrent.length > 0) {
            if (listOrderLayoutCurrent.includes(ORDER_TYPE_USE.NORMAL_ORDER)) {
                updatePlaceQuickOrder({
                    action: orderActions.BUY,
                    isCheckFillPrice: true,
                }, ORDER_TYPE_USE.NORMAL_ORDER)
                let timer = setTimeout(function () { emitter.emit(FOCUS_PRICE_INPUT, ""); clearTimeout(timer) }, 0);
            }
            if (listOrderLayoutCurrent.includes(ORDER_TYPE_USE.PRO_TRADE_ORDER)) {
                updateProTrade({
                    action: orderActions.BUY,
                    isCheckFillPrice: true,

                })
                let timer = setTimeout(function () { emitter.emit(FOCUS_PRICE_INPUT, orderActions.BUY); clearTimeout(timer) }, 0);
            }
        } else {
            // CommonUtils.isBroker() && setCustodycdCustomerScreen(record.custodycd, SELECTOR_ACCOUNT_SCREEN.QUICK_ORDER)
            this.props.setFocusOnRenderOrder(FOCUS_KEYS.PRICE_INPUT);
            let _accountID = record.accountID

            CommonUtils.isBroker() && setCustodycdCustomerScreen(record.custodycd, SELECTOR_ACCOUNT_SCREEN.QUICK_ORDER, null, '', _accountID);

            if (typeAccount === TYPE_ACCOUNT.FDS) {
                _accountID = `FDS${_accountID}`
            }
            setAccountIdScreen(_accountID, SELECTOR_ACCOUNT_SCREEN.QUICK_ORDER)

            let timer = setTimeout(() => {
                // clearDataPlaceQuickOrder()
                CommonUtils.setPlaceQuickOrder(() => { emitter.emit("onChangeTabActiveQuickOrder", 1) })
                updatePlaceQuickOrder({
                    symbolCurrent: record.symbol,
                    action: orderActions.BUY,
                    isCheckFillPrice: true,
                }, ORDER_TYPE_USE.QUICK_ORDER)
                clearTimeout(timer)
            }, 0)
        }
    }

    onHandleSell = (record) => {
        const { updateProTrade, updatePlaceQuickOrder, accountInfo, isAssetTotal, updatedSymbolLayoutPage, setAccountIdScreen, clearDataPlaceQuickOrder, setCustodycdCustomerScreen } = this.props
        const { typeAccount } = { ...accountInfo }


        this.props.updatedSymbolLayoutPage(null)
        updatedSymbolLayoutPage(record.symbol, "search_layout")
        let getInfoOrderLayoutCurrent = CommonOrderUtils.getInfoOrderLayoutCurrent()
        const { listOrderLayoutCurrent } = getInfoOrderLayoutCurrent
        if (listOrderLayoutCurrent && listOrderLayoutCurrent.length > 0) {
            if (listOrderLayoutCurrent.includes(ORDER_TYPE_USE.NORMAL_ORDER)) {
                updatePlaceQuickOrder({
                    action: orderActions.SELL,
                    isCheckFillPrice: true,
                }, ORDER_TYPE_USE.NORMAL_ORDER)
                let timer = setTimeout(function () { emitter.emit(FOCUS_PRICE_INPUT, ""); clearTimeout(timer) }, 0);
            }
            if (listOrderLayoutCurrent.includes(ORDER_TYPE_USE.PRO_TRADE_ORDER)) {
                updateProTrade({
                    action: orderActions.SELL,
                    isCheckFillPrice: true,
                })
                let timer = setTimeout(function () { emitter.emit(FOCUS_PRICE_INPUT, orderActions.SELL); clearTimeout(timer) }, 0);
            }
        } else {
            // CommonUtils.isBroker() && setCustodycdCustomerScreen(record.custodycd, SELECTOR_ACCOUNT_SCREEN.QUICK_ORDER)
            this.props.setFocusOnRenderOrder(FOCUS_KEYS.PRICE_INPUT);
            let _accountID = record.accountID

            CommonUtils.isBroker() && setCustodycdCustomerScreen(record.custodycd, SELECTOR_ACCOUNT_SCREEN.QUICK_ORDER, null, '', _accountID);

            if (typeAccount === TYPE_ACCOUNT.FDS) {
                _accountID = `FDS${_accountID}`
            }
            setAccountIdScreen(_accountID, SELECTOR_ACCOUNT_SCREEN.QUICK_ORDER)
            let timer = setTimeout(() => {
                // clearDataPlaceQuickOrder()
                CommonUtils.setPlaceQuickOrder(() => { emitter.emit("onChangeTabActiveQuickOrder", 1) })
                updatePlaceQuickOrder({
                    symbolCurrent: record.symbol,
                    action: orderActions.SELL,
                    isCheckFillPrice: true,
                }, ORDER_TYPE_USE.QUICK_ORDER)
                clearTimeout(timer)
            }, 0)
        }
    }

    // test = (a) => {
    //     alert(1)
    // }

    isCheckSymbolWFT = (symbol) => {
        if (!symbol) {
            return true
        }
        const config = '_WFT'
        if (symbol.endsWith(config)) {
            return false
        }
        return true
    }


    renderTPSL = (dataObj) => {
        const record = dataObj.row.data;
        //console.log("binh_dataObj", dataObj, record)
        let isShowActionWFT = this.isCheckSymbolWFT(record.symbol)

        if (!isShowActionWFT) {
            return
        }
        return (
            <div className="extra">
                <CustomInput
                    className="tpsl-switch"
                    type="switch"
                    id={record["symbol"]}
                    checked={record['bl_tpsl_quoteid'] !== null}
                    onChange={() => this.onHandleTPSL(record)}
                />
            </div>
        )
    }

    renderProfitAndLossPercent = (record, decimalScale) => {
        let decimalValue = decimalScale ? decimalScale : null;
        let result = 0;
        //Tính lại lãi lỗ + % lãi lỗ
        if (record.costPriceAmt == 0) {
            let compareTotal = record.total - record.sending;
            if (compareTotal != 0) {
                result = 100;
            } else if (compareTotal == 0) {
                result = 0;
            }
        } else {
            result = (record.pnlamt * 100) / record.costPriceAmt;
        }
        result = CommonUtils.getValidValue(result)
        return (
            <Fragment>
                <div className={" style-text-ellipsis " + (CommonUtils.getClassCheckValue(result))}>
                    <FormattedPriceDiff isFormatZero={true} decimalScale={decimalValue} value={result || 0} shortForm={false} prefix={(result > 0 ? '+' : '')} suffix="%" />
                </div>
            </Fragment>
        )
    }

    renderSwitch = (dataField) => {
        const { accountInfo, lang, isAssetTotal } = this.props
        const { typeAccount } = { ...accountInfo }
        // return (e) => <div>{e.row.data[dataField]}</div>
        if (typeAccount === TYPE_ACCOUNT.FDS) {
            switch (dataField) {
                case 'action':
                    return (e) => RenderGridCell.renderGridCellAction(e, lang, this.onHandleBuy, this.onHandleSell)
                case 'dtacctno':
                    return (e) => RenderGridCell.renderGridCellCommon(e.row.data.dtacctno, lang);
                case 'symbol':
                    return (e) => RenderGridCell.renderGridCellCommon(e.row.data.symbol, lang);
                case 'qttyFake':
                    return (e) => RenderGridCell.renderGridCellQuantity(e.row.data.qtty, lang);
                case 'pendinglqtty':
                    return (e) => RenderGridCell.renderGridCellQuantity(e.row.data.pendinglqtty, lang);
                case 'pendingsqtty':
                    return (e) => RenderGridCell.renderGridCellQuantity(e.row.data.pendingsqtty, lang);
                case 'vwap':
                    return (e) => RenderGridCell.renderGridCellPriceDecimal(e.row.data.vwap);
                case 'price_secured':
                    return (e) => RenderGridCell.renderGridCellPriceDecimal(e.row.data.price_secured);
                case 'totalplamt':
                    return (e) => RenderGridCell.renderGridCellPrice(e.row.data.totalplamt, 'PRICE', true);
                case 'vrimamt':
                    return (e) => RenderGridCell.renderGridCellPrice(e.row.data.vrimamt, 'PRICE');
                case 'delisteddt':
                    return (e) => RenderGridCell.renderGridCellConvertDateFDS(e.row.data.delisteddt);
                case 'proportion':
                    return (e) => RenderGridCell.renderGridCellPercentage(e.row.data.proportion, 1);
                default:
                    return null;
            }
        } else {
            switch (dataField) {
                case 'action':
                    return (e) => {
                        const record = e.row.data;
                        if (isAssetTotal && record && record.al_afacctno && record.al_afacctno == "06") { // Ẩn nút mua bán ở màn tài sản với loại tiểu khoản TPRL
                            return <></>
                        }
                        return RenderGridCell.renderGridCellActionOpenPosition(e, lang, this.onHandleBuy, this.onHandleSell)
                    }
                // case 'tpsl':
                //     return (e) => this.renderTPSL(e)
                // case 'accountID':
                //     return (e) => RenderGridCell.renderGridCellCommon(e.row.data.accountID, lang);
                case 'symbol':
                    return (e) => RenderGridCell.renderGridCellSymbolOpenPosition(e.row.data.symbol, lang);
                case 'totalExt':
                    return (e) => RenderGridCell.renderGridCellQuantity(e.row.data.totalExt, lang);
                case 'al_afacctno':
                    return (e) => RenderGridCell.renderGridCellCommon(e.row.data.al_afacctno);
                case 'trade':
                    return (e) => RenderGridCell.renderGridCellQuantity(e.row.data.trade, lang);
                case 'group':
                    return (e) => RenderGridCell.renderGridCellQuantity(e.row.data.group, lang);
                case 'group1':
                    return (e) => RenderGridCell.renderGridCellQuantity(e.row.data.group1, lang);
                case 'receivingT0':
                    return (e) => RenderGridCell.renderGridCellPrice(e.row.data.receivingT0, 'PRICE');
                case 'receivingT1':
                    return (e) => RenderGridCell.renderGridCellPrice(e.row.data.receivingT1, 'PRICE');
                case 'receivingT2':
                    return (e) => RenderGridCell.renderGridCellPrice(e.row.data.receivingT2, 'PRICE');
                case 'sendingT0':
                    return (e) => RenderGridCell.renderGridCellPrice(e.row.data.sendingT0, 'PRICE');
                case 'sendingT1':
                    return (e) => RenderGridCell.renderGridCellPrice(e.row.data.sendingT1, 'PRICE');
                case 'sendingT2':
                    return (e) => RenderGridCell.renderGridCellPrice(e.row.data.sendingT2, 'PRICE');
                case 'costPrice':
                    return (e) => RenderGridCell.renderGridCellPriceConvert(e.row.data.costPrice);
                case 'costPriceAmt':
                    return (e) => RenderGridCell.renderGridCellPrice(e.row.data.costPriceAmt, 'PRICE');
                case 'basicPrice':
                    return (e) => RenderGridCell.renderGridCellPriceConvert(e.row.data.basicPrice);
                case 'basicPriceAmt':
                    return (e) => RenderGridCell.renderGridCellPrice(e.row.data.basicPriceAmt, 'PRICE');
                case 'pnlrate':
                    return (e) => this.renderProfitAndLossPercent(e.row.data, 2);
                case 'pnlamt':
                    return (e) => RenderGridCell.renderGridCellPrice(e.row.data.pnlamt, 'PRICE', true);
                case 'proportion':
                    return (e) => RenderGridCell.renderGridCellPercentage(e.row.data.proportion, 1);
                case 'mrratioloan':
                    return (e) => this.renderGridCellMrratioloan(e.row.data.mrratioloan);
                default:
                    return null;
            }
        }

    }

    renderGridCellMrratioloan = (item) => {
        if (item === "--")
            return <div className='text-near-active style-text-ellipsis'>
                -
            </div>
        return (
            <Fragment>
                <div className='text-near-active style-text-ellipsis'>
                    <NumberFormatCustom value={item} decimalScale={2} />%
                </div>
            </Fragment>
        )
    }

    renderCustomCosPriceHeader = () => {
        const iconTooltip = document.getElementById(
            'cost-price-icon-tt'
        );
        return (
            <Fragment>
                <FormattedMessage id='open-position.cost-price' />&nbsp;
                <IconInfoActive ref={this.iconInfoRef} id='cost-price-icon-tt' />
                <TextToolTip
                    tooltipText={<FormattedMessage id="open-position.costprice-tooltip" />}
                    targetID={this.iconInfoRef}
                    placement='bottom'
                ></TextToolTip>
            </Fragment>
        )
    }

    returnCaption = (dataField) => {
        const { accountInfo } = this.props
        const { typeAccount } = { ...accountInfo }
        if (typeAccount === TYPE_ACCOUNT.FDS) {
            switch (dataField) {
                case 'action':
                    return "open-position.action";
                case 'dtacctno':
                    return "open-position.accountId";
                case 'symbol':
                    return "open-position.symbol";
                case 'qttyFake':
                    return "open-position.holding-position";
                case 'pendinglqtty':
                    return "open-position.pendinglqtty";
                case 'pendingsqtty':
                    return "open-position.pendingsqtty";
                case 'vwap':
                    return "open-position.cost-price";
                case 'price_secured':
                    return "open-position.price-secured";
                case 'totalplamt':
                    return "open-position.value-totalplamt";
                case 'vrimamt':
                    return "open-position.vrimamt";
                case 'delisteddt':
                    return "open-position.delisteddt";
                case 'proportion':
                    return "open-position.proportion";
                default:
                    return null;
            }
        } else {
            switch (dataField) {
                case 'action':
                    return "open-position.action";
                // case 'tpsl':
                //     return "open-position.tpsl";
                // case 'accountID':
                //     return "open-position.accountId";
                case 'symbol':
                    return "open-position.symbol";
                case 'al_afacctno':
                    return "open-position.afacctno";
                case 'totalExt':
                    return "open-position.total";
                case 'trade':
                    return "open-position.trade";
                case 'group':
                    return "open-position.receiving-wait-back";
                case 'group1':
                    return "open-position.sending-wait-back";
                case 'receivingT0':
                    return "open-position.receivingT0";
                case 'receivingT1':
                    return "open-position.receivingT1";
                case 'receivingT2':
                    return "open-position.receivingT2";
                case 'sendingT0':
                    return "open-position.sendingT0";
                case 'sendingT1':
                    return "open-position.sendingT1";
                case 'sendingT2':
                    return "open-position.sendingT2";
                case 'costPrice':
                    // return "open-position.cost-price";
                    return this.renderCustomCosPriceHeader;
                case 'costPriceAmt':
                    return "open-position.vwap";
                case 'basicPrice':
                    return "open-position.market-price";
                case 'basicPriceAmt':
                    return "open-position.market-value-price";
                case 'pnlrate':
                    return "open-position.percentage-upnl";
                case 'pnlamt':
                    return "open-position.upnl";
                case 'proportion':
                    return "open-position.proportion";
                case 'mrratioloan':
                    return "open-position.mrratioloan";
                default:
                    return null;
            }
        }
    }


    getSymbolOfSymbolSpecial = (symbolSpecial) => {
        const config = '_WFT'
        if (symbolSpecial.endsWith(config)) {
            return symbolSpecial.replace(config, "")
        }
        return symbolSpecial
    }

    setReloadData = () => {
        this.apiSeq = new Date().getTime();
        listSymbolsSocket = []
        let onReloadData = !this.state.onReloadData
        this._setState({ onReloadData })
    }



    setReloadDataBySocket = async () => {
        let data = await this.debounceLoadData()
        if (this.tableExRef && this.tableExRef.current && this.tableExRef.current.setReloadDataBySocket) {
            // let _data = _.cloneDeep(data)
            // data[0].symbol = "123"
            // if (data && data.length>0){
            // data[0].al_afacctno = Random.randomComponentId()
            // data = _.filter(data, e => e.symbol !== "CII")
            // data[5] = {...{}, ...data[2]}
            // data[5].symbol_accountId = "HHH_123"
            // data[5].symbol = "HHH"

            // data[0].symbol_accountId = "123_999"
            if (data) {
                this.tableExRef.current.setReloadDataBySocket(data)
            }
            // }   
        }
    }


    registerTopics(listSymbols) {
        // let instruments = CommonUtils.getInstruments();
        // //console.log('OpenPositionsNew.:registerTopics.:', listSymbols, instruments)
        // let _instruments = []
        socket.unregisterCallerId(this.callerId);
        const topics = _.map(listSymbols, (symbol) => {
            // _instruments.push(instruments && instruments[symbol])
            return socket.socketRoomName.instrument + ':' + symbol;
        });
        socket.registerTopics(topics, this.callerId);
    }

    componentDidMount() {
        this.mounted = true
        // let self=this
        // setInterval( function(params) {
        //     self.setReloadData()
        // }, 3000);
        let isCheckIdTableEx = _.findIndex(Object.keys(CM_RECORDS), (e) => e == this.idTableEx)
        if (isCheckIdTableEx > -1) {
            this.idTableEx = Random.randomComponentId()
            CM_RECORDS[this.idTableEx] = []
        }
        this.setReloadData();
        this.listenToTheEmitter();
        // test socket
        // let self = this
        // setInterval(function () {
        //     self.setReloadDataBySocket()
        // }, 2000)

    };

    callback = (tabItem) => {
        let self = this
        if (tabItem) {
            if (self.props.scrollbarRef && self.props.scrollbarRef.current) {
                let timer = setTimeout(() => { self.props.scrollbarRef.current.scrollToBottom(); clearTimeout(timer) }, 200);
                this.props.onTabSelected(tabItem);
            }
        }
    }

    listenToTheEmitter() {
        let self = this
        emitter.on("SCROLL_TO_BOTTOM", this.callback);

    }

    componentDidUpdate(prevProps, prevState) {
        const { placeProTrade, accountInfo, currentSymbol,
            socketCIAction, socketSEAction, socketDOAction, socketCODAction, language, curCustomer
        } = this.props;

        const { currentAccountId, typeAction } = { ...accountInfo }
        const { placeProTrade: prevPlaceProTrade, accountInfo: prevAccountInfo, currentSymbol: prevCurrentSymbol, typeAction: prevTypeAction,
            socketCIAction: prevSocketCIAction,
            socketSEAction: prevSocketSEAction,
            socketDOAction: prevSocketDOAction,
            socketCODAction: prevSocketCODAction,
            curCustomer: prevCurCustomer
        } = prevProps;
        const { currentAccountId: prevCurrentAccountId } = { ...prevAccountInfo }

        // Cần bắt thêm thay đổi tài khoản bởi màn này có tiểu khoản all (case khi thay đổi tài khoản mà tiểu khoản vẫn ở all sẽ không gọi lại api)
        if (currentAccountId !== prevCurrentAccountId || !_.isEqual(curCustomer, prevCurCustomer)) {
            CM_RECORDS[this.idTableEx] = []
            this.idTableEx = Random.randomComponentId();
            this.setReloadData();
            this.addBtnCancel();
        }

        if (socketCIAction[currentAccountId] !== prevSocketCIAction[currentAccountId] ||
            socketSEAction[currentAccountId] !== prevSocketSEAction[currentAccountId] ||
            socketDOAction[currentAccountId] !== prevSocketDOAction[currentAccountId] ||
            socketCODAction[currentAccountId] !== prevSocketCODAction[currentAccountId]
        ) {
            this.setReloadDataBySocket();
        }


        // if (socketCODAction[currentAccountId] !== prevSocketCODAction[currentAccountId]) {
        //     this.setReloadData();
        //     this.onReset();
        // }
        //for AssetTotal
        if (currentAccountId !== prevCurrentAccountId && this.props.isAssetTotal === true && accountInfo.accounttype === 'ALL') {
            this.setReloadData();
        }


        if (language !== prevProps.language) {
            this.setReloadData();
        }
    }

    // shouldComponentUpdate(nextState) {
    //     const state = this.state;
    //     return state.onReloadData !== nextState.onReloadData
    // }


    getValidSumValue = (val) => {
        if (val == null || Number.isNaN(val)) {
            return 0;
        }
        return val;
    };


    isActiveLayoutNomalTrade = () => {
        const { currentLayoutPageActive } = this.props
        return currentLayoutPageActive.includes("C#CS#TRADE#CB")
    }

    processOrderData = (dataArr) => {
        //console.log("processOrderData_1", dataArr)

        let newRecords = _.cloneDeep(dataArr)
        //console.log("processOrderData_2", newRecords)
        if (newRecords.length > 0) {
            //console.log("processOrderData_3", newRecords)
            let _sumCostValue = 0, _sumCostMarket = 0;
            let instruments = CommonUtils.getInstruments();
            _.map(newRecords, (record, index) => {
                let instrument = instruments[CommonOrderUtils.getSymbolOfSymbolSpecial(record.symbol)] || {}
                record.closePrice = instrument['CP'] || record['basicPrice']
                if (CommonUtils.checkSeqApi(instrument.s, record.s)) {
                    record.closePrice = record['basicPrice'] || 0
                }
                record['basicPrice'] = record.closePrice // Gán đè dùng để hiển thị
                record['action'] = record.issell || ''; // Do hiển thị nút bán phụ thuộc vào issell nên phải gán trường action (với case reload lại theo socket thì action sẽ load lại theo thay đổi của issell và ẩn hiện được nút bán)
                // record.basicPriceAmt = this.getValidSumValue(closePrice * record['totalpnl']);
                record.basicPriceAmt = CommonUtils.getValidValue(record['closePrice'] * (record['total'] - record['sending']))
                record.pnlamt = CommonUtils.getValidValue(record.basicPriceAmt - record.costPriceAmt)
                if (record.pnlrate == "----") {
                    record.pnlrate = 0
                }
                let compareTotal = record.total - record.sending;
                if (record.costPriceAmt == 0) {
                    if (compareTotal != 0) {
                        record.pnlrate = 100;
                    } else if (compareTotal == 0) {
                        record.pnlrate = 0;
                    }
                } else {
                    record.pnlrate = (record.pnlamt * 100) / record.costPriceAmt;
                }
                // //console.log("processOrderData ====> return 12334444", { newRecords, costPriceAmt: record.costPriceAmt, symbol: record.symbol })
                record.totalExt = record['total'] - record['sending'] // Tổng KL theo spec VIX
                // _sumCostValue += this.getValidSumValue(record['costPrice'] * (record['total'] - record['sending']));
                _sumCostValue += record.costPriceAmt;
                _sumCostMarket += this.getValidSumValue(record['closePrice'] * (record['total'] - record['sending']));
                record.sumPnlrate = record.costPriceAmt + "|" + record.pnlamt + "|" + record.totalpnl
                record[keyStoreUpdate] = record.symbol + "_" + record.accountID
                // _sumUpnlAmt += this.getValidSumValue((record['total'] - record['withDraw']) * (closePrice - record['costPrice']));

            })

            _.map(newRecords, (record) => {
                record.proportion = this.getValidSumValue((record.basicPriceAmt * 100) / _sumCostMarket)// Tỷ trọng
                record.proportion = CommonUtils.convertRound(record.proportion)
            })

            //console.log("processOrderData ====> return", newRecords)
            CM_RECORDS[this.idTableEx] = newRecords
            this._setState({
                listSymbol: newRecords,
                records: newRecords
            })
            return newRecords
        } else {
            //console.log("processOrderData_else", newRecords)
            return []
        }
    }



    processOrderDataFDS = (dataArr) => {
        // For AssetTotal
        let newRecords = _.cloneDeep(dataArr)
        if (newRecords.length > 0) {
            let _sumCostValue = 0, _sumCostMarket = 0, _sumUpnlAmt = 0, _percentPnl = 0
            let instruments = CommonUtils.getInstruments();
            _.map(newRecords, (record) => {
                let instrument = instruments[record.symbol] || {}
                record.closePrice = instrument['CP'] || 0
                if (CommonUtils.checkSeqApi(instrument.s, record.s)) {
                    record.closePrice = record['price_secured'] || 0
                }
                record['price_secured'] = record.closePrice // Gán đè dùng để hiển thị
                // record.basicPriceAmt = closePrice * record.sumUpnlAmt;
                record.qttyFake = Math.abs(record.qtty) //fake KL để hiển thị cả dấu âm nhưng + tổng là trị tuyệt đối
                record.qtty = record.qtty
                // record['capvalue'] = Math.abs(record.capvalue)
                record['capvalue'] = Number(record.capvalue)
                let marvalue = this.getValidSumValue((Number(record.closePrice) * Number(record['qtty'])));
                record['marvalue'] = Number(marvalue)
                record['pnlamt'] = 0
                record['pnlrate'] = 0

                // if (record.pecentnonrplamt != null && record.pecentnonrplamt != 0) {
                //     record['pnlamt'] = record['marvalue'] - record['capvalue']
                //     if (itemFDS['capvalue'] > 0) record['pnlrate'] = (record['pnlrate'] * 100) / record['capvalue'];
                // }
                record['pnlamt'] = this.getValidSumValue(record['marvalue'] - record['capvalue'])
                // if (record['capvalue'] > 0) {
                record['pnlrate'] = this.getValidSumValue((record['pnlamt'] * 100) / Math.abs(record['capvalue']));
                // }

                _sumCostMarket += this.getValidSumValue(record['marvalue'] * record['nvalue'])
                // _sumCostValue += this.getValidSumValue(record['capvalue'] * record['nvalue'])
                // _sumUpnlAmt += this.getValidSumValue((record['marvalue'] - record['capvalue']) * record['nvalue'])
                record['totalplamt'] = 0
                if (instrument && Object.keys(instrument).length > 0) {
                    record['totalplamt'] = this.getValidSumValue(record.pnlamt * record.nvalue)
                }
                //convert data 
                record['accountID'] = record['dtacctno']
                record.sumPnlrate = record.costPriceAmt + "|" + record.pnlamt
            })


            _.map(newRecords, (record) => {
                record.proportion = this.getValidSumValue((((record['marvalue'] * record['nvalue']) * 100) / _sumCostMarket))// Tỷ trọng
                record.proportion = CommonUtils.convertRound(record.proportion)
            })
            //console.log("processOrderData ====> return", newRecords)
            // _percentPnl = (_sumUpnlAmt * 100 / _sumCostValue)
            this._setState({
                listSymbol: newRecords
            })
            return newRecords
        } else {
            return []
        }
    };

    convertData = (dataArr, isConvert = false) => {
        const { accountInfo } = this.props;
        const { typeAccount } = { ...accountInfo }
        if (isConvert) {
            return this.processOrderData(dataArr)
        }
        if (typeAccount === TYPE_ACCOUNT.FDS) {
            // //console.log("convertData===data2", dataArr, this.processOrderDataFDS(dataArr))
            return this.processOrderDataFDS(dataArr)
        } else {
            // //console.log("convertData===data2", dataArr, this.processOrderData(dataArr))
            return this.processOrderData(dataArr)
        }
        return []
    };

    apiSeq = new Date().getTime();
    debounceLoadData = async (obj) => {
        this.apiSeq = new Date().getTime();
        return this.apiLoadAll(obj, this.apiSeq);
    }

    apiLoadAll = async (obj, apiSeq) => {
        try {
            listSymbolsSocket = []
            //let { page, limitRow, listSymbolsSocket } = { ...obj } //gọi api load all ko phân trang thì ko cần lấy từ obj ở table truyền ra
            let self = this
            const { accountInfo, listAccounts } = this.props;
            // const getSecuritiesPortfolioFunc = this.isBroker() ? inquiryService.getAllSecuritiesPortfolio : inquiryService.getAllSecuritiesPortfolio;
            //console.log("apiLoadAll===", accountInfo, this.props.curCustomer)
            let custid = CommonUtils.isBroker() ? (this.props.curCustomer && this.props.curCustomer.custid) : this.props.userInfo.custid
            // CM_RECORDS[this.idTableEx] = []
            if (accountInfo && accountInfo.accounttype === 'ALL') {
                //ALL Cơ sở
                return inquiryService.getAllSecuritiesPortfolio(custid)
                    .then((data) => {
                        if (apiSeq < this.apiSeq) return CM_RECORDS[this.idTableEx];
                        listSymbolsSocket = [...new Set(data.map(x => x.symbol))]
                        data = this.convertData(data, true)
                        if (this.props.isAssetTotal === true) {
                            this.props.setDataFromChild(false, data)
                        }
                        self.registerTopics(listSymbolsSocket)
                        ////console.log('_data setDataFromChild all: ', listSymbolsSocket)

                        ////console.log('_data setDataFromChild result: ', result)
                        return data
                    })
                    .catch((error) => {
                        ToastUtil.errorApi(error, 'common.fail-to-load-data');
                        return []
                    });
            }
            else {
                const { currentAccountId, typeAccount } = { ...accountInfo }
                // if (this.props.isAssetTotal === true && accountInfo.accounttype === 'ALL') return;
                // return inquiryService.getSecuritiesPortfolio(currentAccountId)
                // this._setState({ loadStatus: LoadStatus.LOADING });
                const getSecuritiesPortfolioFunc = this.isBroker() ? inquiryService.getSecuritiesPortfolio : inquiryService.getSecuritiesPortfolio;
                const getSecuritiesPortfolioFDSFunc = this.isBroker() ? fdsInquiryService.getSecuritiesPortfolioFDS : fdsInquiryService.getSecuritiesPortfolioFDS;
                if (currentAccountId) {
                    if (typeAccount === TYPE_ACCOUNT.FDS) {
                        return getSecuritiesPortfolioFDSFunc(currentAccountId, custid)
                            .then((data) => {
                                if (apiSeq < this.apiSeq) return CM_RECORDS[this.idTableEx];
                                //console.log('apiLoadAll.:typeAccount === TYPE_ACCOUNT.FDS=1', listSymbolsSocket, data)
                                data = this.convertData(data)
                                if (this.props.isAssetTotal === true) {
                                    this.props.setDataFromChild(true, data)
                                }
                                listSymbolsSocket = [...new Set(data.map(x => x.symbol))]
                                self.registerTopics(listSymbolsSocket)
                                ////console.log('_data setDataFromChild result: ', result)
                                return data
                            })
                            .catch((error) => {
                                ToastUtil.errorApi(error, 'common.fail-to-load-data');
                                return []
                            });
                    } else {
                        // this._setState({ loadStatus: LoadStatus.LOADED });
                        return getSecuritiesPortfolioFunc(currentAccountId, custid)
                            .then((data) => {
                                if (apiSeq < this.apiSeq) return CM_RECORDS[this.idTableEx];
                                data = this.convertData(data)
                                if (this.props.isAssetTotal === true) {
                                    this.props.setDataFromChild(false, data)
                                }
                                listSymbolsSocket = [...new Set(data.map(x => x.symbol))]
                                self.registerTopics(listSymbolsSocket)
                                ////console.log('_data setDataFromChild result: ', result)
                                return data
                            })
                            .catch((error) => {
                                ToastUtil.errorApi(error, 'common.fail-to-load-data');
                                return []
                            });
                    }
                }
            }
            return []
        } catch (e) {
            //console.log('apiLoadAll().:Err.:', e)
            return []
        }
    }

    apiPaging = async (obj) => {
        // API phân trang để test thử
        try {
            let self = this
            let _obj = {
                custid: '0001001010',
                execType: 'ALL',
                fromDate: '12/08/2021',
                page: obj.page,
                limitRow: obj.limitRow,
                orderStatus: 'ALL',
                symbol: 'ALL',
                toDate: '01/07/2022',
            }
            return inquiryService.getOrderHistoryAllBase(_obj.custid, _obj.fromDate, _obj.toDate, _obj.page, _obj.limitRow).then((data) => {
                if (data && data.d) {
                    data = data.d
                }
                listSymbolsSocket = [...listSymbolsSocket, ...new Set(data.map(x => x.symbol))]
                self.registerTopics(listSymbolsSocket)
                return data
            })
        } catch (e) {
            //console.log('apiPaging().:Err.:', e)
            return []
        }
    }

    apiPagingReturnTotal = async (obj) => {
        // API phân trang để test thử
        try {
            let self = this
            let _obj = {
                custid: '0001000973',
                execType: 'ALL',
                fromDate: '12/08/2021',
                page: obj.page,
                limitRow: obj.limitRow,
                orderStatus: 'ALL',
                symbol: 'ALL',
                toDate: '01/07/2022',
            }
            return inquiryService.getOrderHistoryAllBase(_obj.custid, _obj.fromDate, _obj.toDate, _obj.page, _obj.limitRow).then((data) => {
                if (data && data.d) {
                    data = data.d
                }
                listSymbolsSocket = [...listSymbolsSocket, ...new Set(data.map(x => x.symbol))]
                self.registerTopics(listSymbolsSocket)
                return data
            })
        } catch (e) {
            //console.log('apiPagingReturnTotal().:Err.:', e)
            return []
        }
    }


    calculateData = (records) => {
        const { accountInfo } = this.props;

        if (this.props.isAssetTotal) {
            const { accounttype } = { ...accountInfo }
            if (accounttype == "FDS") {
                //console.log("calculateData__", accountInfo)
                return this.calculateDataFDS(records)
            } else {
                return this.calculateDataBase(records)
            }
        } else {
            const { typeAccount } = { ...accountInfo }
            if (typeAccount === TYPE_ACCOUNT.FDS) {
                return this.calculateDataFDS(records)
            } else {
                return this.calculateDataBase(records)
            }
        }
        return []
    };

    calculateDataBase = (records) => {
        let newRecords = _.cloneDeep(records)
        if (newRecords.length > 0) {
            let _sumCostMarket = 0, _sumPnlamt = 0, _sumCostPriceAmt = 0, _sumTotal = 0
            newRecords = _.map(newRecords, (record) => {
                record.basicPriceAmt = this.getValidSumValue(record['basicPrice'] * (record['total'] - record['sending']));
                _sumCostMarket += this.getValidSumValue(record['basicPrice'] * record['totalpnl']);
                return record
            })

            newRecords = _.map(newRecords, (record) => {
                record.proportion = this.getValidSumValue((record.basicPriceAmt * 100) / _sumCostMarket)
                record.proportion = CommonUtils.convertRound(record.proportion)
                return record
            })

            return newRecords || []
        } else {
            return []
        }
    }


    calculateDataFDS = (records) => {
        return null
        let newRecords = _.cloneDeep(records)
        let instruments = CommonUtils.getInstruments();
        if (newRecords.length > 0) {
            let _sumCostMarket = 0
            _.map(newRecords, (record) => {
                let instrument = instruments[CommonOrderUtils.getSymbolOfSymbolSpecial(record.symbol)] || {}
                record.closePrice = instrument['CP'] || 0
                if (CommonUtils.checkSeqApi(instrument.s, record.s)) {
                    record.closePrice = record['price_secured'] || 0
                }
                record['price_secured'] = record.closePrice // Gán đè dùng để hiển thị

                record.basicPriceAmt = record.closePrice * record.sumUpnlAmt;
                //console.log('calculateData.5:', record.closePrice)

                record.qtty = Number(record.qtty)
                record['capvalue'] = Number(record.capvalue)
                record['marvalue'] = Number(this.getValidSumValue((Number(record.closePrice) * Number(record['qtty']))))
                record['pnlamt'] = 0
                record['pnlrate'] = 0
                _sumCostMarket += this.getValidSumValue(Math.abs(record['marvalue']) * record['nvalue'])
                record['price_secured'] = record.closePrice
                record['pnlamt'] = this.getValidSumValue(record['marvalue'] - record['capvalue'])
                record['totalplamt'] = this.getValidSumValue(record.pnlamt * record.nvalue)
            })
            _.map(newRecords, (record) => {
                record.proportion = this.getValidSumValue((((Math.abs(record['marvalue']) * record['nvalue']) * 100) / _sumCostMarket))// Tỷ trọng
                record.proportion = CommonUtils.convertRound(record.proportion)
            })
            return newRecords || []
        } else {
            return []
        }
    }

    convertDataRealTime = (_instrument) => {
        const { accountInfo } = this.props
        const { typeAccount } = accountInfo
        // const { records } = this.state
        let _records = _.cloneDeep(CM_RECORDS[this.idTableEx])
        let instrument = _.cloneDeep(_instrument)
        let _keyStoreUpdate = _instrument.keyStoreUpdate
        // //console.log('convertDataRealTime.start', _records, CM_RECORDS, _keyStoreUpdate)
        if (_records && _records.length > 0) {
            let indexUpdate = _.findIndex(_records, (item, index) => {
                return item[keyStoreUpdate] == _keyStoreUpdate
            })
            if (indexUpdate > -1) {
                let oldData = _records[indexUpdate]
                let key = "basicPrice"
                if (typeAccount === TYPE_ACCOUNT.FDS) {
                    key = "price_secured"
                }
                let closePrice = instrument['CP'] || oldData[key]
                if (CommonUtils.checkSeqApi(instrument.s, oldData.s)) {
                    closePrice = oldData[key] || 0
                }
                _records[indexUpdate][key] = closePrice
                // let listItem = dataSource._items || []
                // //console.log('convertDataRealTime.1:', oldData, instrument)
                let basicPriceAmt = CommonUtils.getValidValue(closePrice * (oldData.total - oldData.sending))
                let pnlamt = CommonUtils.getValidValue(basicPriceAmt - oldData.costPriceAmt)
                let pnlrate = 0
                //Tính lại lãi lỗ + % lãi lỗ
                let compareTotal = oldData.total - oldData.sending;
                if (oldData.costPriceAmt == 0) {
                    if (compareTotal != 0) {
                        pnlrate = 100;
                    } else if (compareTotal == 0) {
                        pnlrate = 0;
                    }
                } else {
                    pnlrate = (pnlamt * 100) / oldData.costPriceAmt;
                }
                _records[indexUpdate].basicPriceAmt = basicPriceAmt
                _records[indexUpdate].pnlamt = pnlamt
                _records[indexUpdate].pnlrate = pnlrate
                _records[indexUpdate].sumPnlrate = oldData.costPriceAmt + "|" + pnlamt
                let newRecords = this.calculateData(_records)
                CM_RECORDS[this.idTableEx] = newRecords
                // this._setState({ _records: newRecords })
                if (this.props.isAssetTotal === true) {
                    this.props.onRowDataChange(oldData.SB, newRecords[indexUpdate], newRecords)
                }
                return newRecords


            }
        }

        return null
    }


    customStyleTotalColumn = (e) => {
        if (e.rowType === 'totalFooter') {
            if (e.summaryItems && e.summaryItems.length > 0 && e.summaryItems[0]) {
                if (e.summaryItems[0].column == "pnlamt" || e.summaryItems[0].column == "sumPnlrate") {
                    let value = e.summaryItems[0] && e.summaryItems[0].value
                    // if (e.summaryItems[0].column == "sumPnlrate") {
                    //     // //console.log("binh_customStyleTotalColumn", e.summaryItems)
                    //     value = e.summaryItems[0].customizeText()
                    //     // //console.log("binh_customStyleTotalColumn", e, value, value.split("%"))
                    //     value = value.split("%")[0].trim() // "2,515.72 %"; => 2515.72
                    //     value = value.replace(/,/g, '');
                    //     // //console.log("binh_customStyleTotalColumn2", e, value)
                    // }
                    if (value > 0) {
                        e.cellElement.className = 'green-TotalItem';
                    }
                    if (value == 0) {
                        e.cellElement.className = 'yellow-TotalItem';
                    }
                    if (value < 0) {
                        e.cellElement.className = 'red-TotalItem';
                    }
                }
            }

        }
    }


    renderSumCustomExt = (column, dataGrid) => {
        const { customSumTable } = this.state
        let resultValue = ""
        let _sumPnlamt = 0;
        let _sumPnl = 0;
        let _sumCostPriceAmt = 0
        let cachedPagingData = (dataGrid && dataGrid._controllers && dataGrid._controllers.columns
            && dataGrid._controllers.columns._dataSource && dataGrid._controllers.columns._dataSource._cachedPagingData
        ) || []

        //console.log("renderSumCustomExt_2", cachedPagingData)

        _.map(cachedPagingData, (record) => {
            // //console.log("renderSumCustomExt_23", record.costPriceAmt, record.pnlamt, record.symbol)
            _sumPnlamt = _sumPnlamt + Number(record.pnlamt) // Giá trị lãi lỗ
            _sumCostPriceAmt = _sumCostPriceAmt + Number(record.costPriceAmt) // Giá trị lãi lỗ
            _sumPnl = _sumPnl + Number(record.totalpnl) // Khối lượng khả dụng
        })

        //console.log("renderSumCustomExt_sum", { _sumPnlamt, _sumCostPriceAmt, _sumPnl })
        // //console.log("renderSumCustomExt_start", customSumTable, column)
        if (column) {
            if (column == "pnlrate") {
                if (Number(_sumCostPriceAmt == 0)) {//Nếu giá vốn = 0 
                    if (_sumPnl != 0) { //Nếu giá vốn = 0  và tổng kl khả dụng !=0thì mặc định lãi/lỗ 100% (custom VIX)
                        resultValue = 100
                    } else { //Nếu giá vốn = 0  và tổng kl khả dụng =0thì mặc định lãi/lỗ 0% (custom VIX)
                        resultValue = 0
                    }
                } else {
                    resultValue = (Number(_sumPnlamt) * 100) / Number(_sumCostPriceAmt) // Giá trị lãi lỗ / Giá trị vốn
                    resultValue = Util.formatAccountingWithZero(resultValue, 2) || 0
                }
                resultValue = `${resultValue} %`
            }
        }
        return resultValue
    }

    componentWillUnmount() {
        emitter.removeListener("SCROLL_TO_BOTTOM", this.callback);
        socket.unregisterCallerId(this.callerId);
        delete CM_RECORDS[this.idTableEx];
        this._setState({
            ...this.initialState
        })
        this.iconInfoRef = undefined
        this.mounted = false
    }

    onCloseModalTPSL = () => {
        this._setState({ isOpenModalTPSL: false })
    }

    onCloseModalSellAll = () => {
        this._setState({ isOpenModalSellAll: false, dataForSellAll: [] });
    }



    onRowClick = (e) => {
        let record = e.data || {};
        const { isAssetTotal } = this.props
        let isAllowClick = true
        if (isAssetTotal && record && record.al_afacctno && record.al_afacctno == "06") {
            isAllowClick = false
        }
        if (record['symbol'] && isAllowClick) {
            const { updateProTrade, updatePlaceQuickOrder, accountInfo, isAssetTotal, updatedSymbolLayoutPage, setAccountIdScreen, clearDataPlaceQuickOrder, setCustodycdCustomerScreen } = this.props
            const { typeAccount } = { ...accountInfo }

            updatedSymbolLayoutPage(null)
            updatedSymbolLayoutPage(record.symbol, "search_layout")
            let getInfoOrderLayoutCurrent = CommonOrderUtils.getInfoOrderLayoutCurrent()
            const { listOrderLayoutCurrent } = getInfoOrderLayoutCurrent
            if (listOrderLayoutCurrent && listOrderLayoutCurrent.length > 0) {
                if (listOrderLayoutCurrent.includes(ORDER_TYPE_USE.NORMAL_ORDER)) {
                    updatePlaceQuickOrder({
                        isCheckFillPrice: true,
                    }, ORDER_TYPE_USE.NORMAL_ORDER)
                    let timer = setTimeout(function () { emitter.emit(FOCUS_PRICE_INPUT, ""); clearTimeout(timer) }, 0);
                }
                if (listOrderLayoutCurrent.includes(ORDER_TYPE_USE.PRO_TRADE_ORDER)) {
                    updateProTrade({
                        isCheckFillPrice: true,
                    })
                    let timer = setTimeout(function () { emitter.emit(FOCUS_PRICE_INPUT,); clearTimeout(timer) }, 0);
                }
            } else {
                let _accountID = record.accountID

                CommonUtils.isBroker() && setCustodycdCustomerScreen(record.custodycd, SELECTOR_ACCOUNT_SCREEN.QUICK_ORDER, null, '', _accountID);
                if (typeAccount === TYPE_ACCOUNT.FDS) {
                    _accountID = `FDS${_accountID}`
                }
                setAccountIdScreen(_accountID, SELECTOR_ACCOUNT_SCREEN.QUICK_ORDER)

                let timer = setTimeout(() => {
                    clearDataPlaceQuickOrder()
                    CommonUtils.setPlaceQuickOrder(() => { emitter.emit("onChangeTabActiveQuickOrder", 1) })
                    updatePlaceQuickOrder({
                        symbolCurrent: record.symbol,
                        isCheckFillPrice: true,
                    }, ORDER_TYPE_USE.QUICK_ORDER)
                    clearTimeout(timer)
                }, 0)
            }
        }
    }


    addBtnCancel = () => {
        const isBroker = this.isBroker();
        if (isBroker) return; // Không hiển thị btn bán với môi giới
        const { accountInfo } = this.props
        let func = this.onHandlerSellAll
        let text = LanguageUtils.getMessageByKey("open-position.sell-all", this.props.language)
        let isDisabled = (accountInfo && accountInfo.accounttype === 'ALL') || !accountInfo || !CM_RECORDS[this.idTableEx] || CM_RECORDS[this.idTableEx].length === 0;
        let btn = isDisabled ? `<button class="bg-sell sell-all-btn btn-action txt---400-12-18" disabled=${isDisabled} >${text}</button>`
            : `<button class="bg-sell sell-all-btn btn-action txt---400-12-18" >${text}</button>`

        let _interval = setInterval(function () {
            let _getclassfilter = $('.open-positions-table-new-tableEx .dx-footer-row > td')
            // let _getclassheader = $('.dx-header-row .dx-command-select')
            let _getclassheader = $('.open-positions-table-new-tableEx > td')
            if (_getclassfilter && _getclassfilter.length > 0) {
                _getclassfilter[0].innerHTML = btn
                // _getclassfilter.addClass("dx-command-select-cancel")
                _getclassheader.addClass("dx-command-select-cancel")
                _getclassfilter[0].onclick = func
                _interval && clearInterval(_interval)
                return null
            }
        }, 200)
    }

    render() {
        let { onReloadData, records, listSymbol, dataForSellAll } = this.state;
        const { accountInfo, currentLayoutPageActive } = this.props
        const { typeAccount } = { ...accountInfo }
        //console.log("binh_render_RenderGridCellRealTime", records, listSymbol)

        const isShowQuickOrder = !history.location.pathname.includes("trade")
        return (
            <RetryableContent className="open-positions-new" status={this.state.loadStatus} onRetry={this.loadData}>
                <div className="table-open-positions-new">
                    {this.state.isOpenModalTPSL && <ModalPlaceOrderTPSL
                        isOpen={this.state.isOpenModalTPSL}
                        toggle={this.onCloseModalTPSL}
                        onClose={this.onCloseModalTPSL}
                        fillDataModalTPSL={this.state.fillDataModalTPSL}
                        dataDetailTPSL={this.state.dataDetailTPSL}
                        orderTypeUse={isShowQuickOrder ? ORDER_TYPE_USE.QUICK_ORDER : ORDER_TYPE_USE.NORMAL_ORDER}
                    />}

                    {this.state.isOpenModalSellAll && <ModalPlaceSellAllOpenposition
                        isOpen={this.state.isOpenModalSellAll}
                        toggle={this.onCloseModalSellAll}
                        onClose={this.onCloseModalSellAll}
                        data={dataForSellAll}
                    />}

                    {/*<button className="sell-all-btn btn-action txt---400-12-18"
                        onClick={() => { this.onHandlerSellAll() }}
                        disabled={(accountInfo && accountInfo.accounttype === 'ALL') || !accountInfo || !CM_RECORDS[this.idTableEx] || CM_RECORDS[this.idTableEx].length === 0}
                    ><FormattedMessage id='open-position.sell-all' />
                    </button>*/}

                    {listSymbol && listSymbol.length > 0 && listSymbol.map((item, index) => {
                        let _item = _.cloneDeep(item)
                        return (
                            <RenderGridCellRealTime
                                keyRealTime={keyRealTime}
                                data={_item}
                                keyStoreUpdate={keyStoreUpdate}
                            />
                        )
                    })}
                    <TableExtremeCustom2
                        storeType={"ArrayStore"}
                        ref={this.tableExRef}
                        OBJLAYOUT={currentLayoutPageActive}
                        keyStoreUpdate={keyStoreUpdate}
                        columnsDefault={(CommonUtils.getOrderTypeUseLayout() == ORDER_TYPE_USE.TPRL_NM_ORDER || CommonUtils.getOrderTypeUseLayout() == ORDER_TYPE_USE.TPRL_OR_ORDER) ? columnsDefaultTPRLNM : (typeAccount === TYPE_ACCOUNT.FDS ? columnsDefaultFDS : columnsDefault)}  // ds cột
                        totalDefault={(CommonUtils.getOrderTypeUseLayout() == ORDER_TYPE_USE.TPRL_NM_ORDER || CommonUtils.getOrderTypeUseLayout() == ORDER_TYPE_USE.TPRL_OR_ORDER) ? totalDefaultTPRLNM : (typeAccount === TYPE_ACCOUNT.FDS ? totalDefaultFDS : totalDefault)} // ds sum (nếu có)
                        renderSwitch={this.renderSwitch} // func render dữ liệu
                        returnCaption={this.returnCaption} // func render column name
                        isUseSelection={false} // true/false: hiện/ẩn chọn row
                        isLoadPanel={false} // true/false: hiện/ẩn iconLoadPanel
                        addClass="open-positions-table-new" // class cha
                        isPager={false} // hiển thị giao diện phân trang
                        apiLoadAll={this.debounceLoadData}  // sử dụng Api load all: không cần trả ra totalCount
                        // apiPaging={this.apiPaging} // sử dụng Api phân trang: không trả ra totalCount
                        // apiPagingReturnTotal={this.apiPagingReturnTotal} // sử dụng Api phân trang: trả ra totalCount, có thể trả thêm sumColumn
                        onReloadData={onReloadData} // thay đổi giá trị này để render tableEx
                        convertData={this.convertData} // convert data row
                        keyRealTime={keyRealTime}
                        // list Column dữ liệu RealTime theo instrument, khi sử dụng realtime=> isPager=false, isLoadPanel=false
                        convertDataRealTime={this.convertDataRealTime} // convert dataRealTime
                        isCustomTopAndBottomSticky={false}
                        showFilter={true}
                        customStyleTotalColumn={this.customStyleTotalColumn} // thay đổi css của dòng tổng
                        renderSumCustomExt={this.renderSumCustomExt}
                        onRowClick={this.onRowClick}
                        listData={CM_RECORDS}
                        emitType={['UPDATE_REALTIME_EXTREME']}
                        addActionToSelection={this.addBtnCancel}
                    // isSetting={true}
                    // setReloadData={this.setReloadData}
                    />
                </div>
            </RetryableContent>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    let _currentLayoutPageActive = state.layout.currentLayoutPageActive
    let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
    let _accountInfo = _layoutPage && _layoutPage["accountInfo"]
    let { curCustomer } = _accountInfo
    const userInfo = state.user.userInfo;
    const permissionInfo = userInfo.permissionInfo ? userInfo.permissionInfo : null;
    if (ownProps.isAssetTotal === true) {
        return {
            userInfo: userInfo,
            accountInfo: ownProps.currentAccount,
            curCustomer: curCustomer,
            language: state.app.language,
            lang: state.app.language,
            role: ownProps.role,
            currentLayoutPageActive: _currentLayoutPageActive,
            socketSEAction: state.socketRealtime.socketSEAction,
            socketCIAction: state.socketRealtime.socketCIAction,
            socketDOAction: state.socketRealtime.socketDOAction,
            socketCODAction: state.socketRealtime.socketCODAction,
        };
    } else {
        return {
            config: state.user.userInfo.config,
            permissionInfo: permissionInfo,
            userInfo: userInfo,
            accountInfo: _accountInfo,
            curCustomer: curCustomer,
            language: state.app.language,
            lang: state.app.language,
            role: state.user.userInfo.role,
            currentLayoutPageActive: _currentLayoutPageActive,
            socketSEAction: state.socketRealtime.socketSEAction,
            socketCIAction: state.socketRealtime.socketCIAction,
            socketDOAction: state.socketRealtime.socketDOAction,
            socketCODAction: state.socketRealtime.socketCODAction,
        };
    }
};

const mapDispatchToProps = dispatch => {
    return {
        navigate: (path) => dispatch(push(path)),
        clearDataPlaceQuickOrder: (typeOrder, arrKeep) => dispatch(actions.clearDataPlaceQuickOrder(typeOrder, arrKeep)),
        updatePlaceQuickOrder: (data, typeOrder) => dispatch(actions.updatePlaceQuickOrder(data, typeOrder)),
        updateProTrade: (data, typeOrder) => dispatch(actions.updateProTrade(data, typeOrder)),
        updatedLayoutPage: (data, key) => dispatch(actions.updatedLayoutPage(data, key)),
        updateInfoCheckAuth: (data, key) => dispatch(actions.updateInfoCheckAuth(data, key)),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
        updatedSymbolLayoutPage: (symbolId, from, id) => dispatch(actions.updatedSymbolLayoutPage(symbolId, from, id)),
        setAccountIdScreen: (accountId, typeScreen) => dispatch(actions.setAccountIdScreen(accountId, typeScreen)),
        setCustodycdCustomerScreen: (custodycd, typeScreen, callback, saveCustodycd, accountId) => dispatch(actions.setCustodycdCustomerScreen(custodycd, typeScreen, callback, saveCustodycd, accountId)),
        setFocusOnRenderOrder: (focusKey) => dispatch(actions.setFocusOnRenderOrder(focusKey)),
        updateConfirmCheckAuth: (data) => dispatch(actions.updateConfirmCheckAuth(data)),
    };
};

// export default connect(mapStateToProps, mapDispatchToProps)(OpenPositionsNew);
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(OpenPositionsNew);

