import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import './ModalPlaceSellAllOpenposition.scss';
import DraggableModal from '../../../../components/Modal/DraggableModal';
import { connect } from 'react-redux';
import { activeOrderExecType } from '../../../../utils/constants';
import { FormattedPrice, FormattedQuantity } from '../../../../components/Formating';
import CustomScrollbars from './../../../../components/CustomScrollbars';
import SymbolSuggestTrans from "components/SymbolSuggestTrans";
import { CommonUtils, LanguageUtils, Random, ToastUtil, LoadStatus, CommonWidgetUtils, Role, EXCHANGE, CommonOrderUtils, subOrderTypes } from "../../../../utils";
import _ from 'lodash'
import { symbolService, tradeApiService } from "services";
import { getMarketStatusKey } from '../../../../services/marketinfo.service';
import * as actions from "../../../../store/actions";
import ModalPlaceMultiOrder from "./ModalPlaceMultiOrder";
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit.svg';
import { ReactComponent as CheckIcon } from '../../../../assets/icons/screenmodal/symboldetail/CheckIcon.svg'
import { PriceInput } from "components/Input";
import QuantityInputOrder from "components/Input/QuantityInputOrder";
import RetryableContent from "components/RetryableContent";
import TextToolTip from "../../../../components/TextToolTip";
import { ReactComponent as IconInfoActive } from '../../../../assets/icons_new/icon_info_active.svg'

const SELL_RATIOS = [
    { id: 1, valueRatio: 100, titleId: 'trade.sell-all-open-position.trade-ratio.100' },
    { id: 2, valueRatio: 70, titleId: 'trade.sell-all-open-position.trade-ratio.70' },
    { id: 3, valueRatio: 50, titleId: 'trade.sell-all-open-position.trade-ratio.50' }
]

const PRICE_TYPES = [
    { id: 1, valueType: 'LO', titleId: 'trade.sell-all-open-position.price-type.LO' },
    { id: 2, valueType: 'TT', titleId: 'trade.sell-all-open-position.price-type.TT' },
]

class ModalCancelDraftOrder extends Component {

    initialState = {
        symbol: '',
        currentRatio: 100,
        currentData: [],
        currentPriceType: 'LO',
        marketData: null,
        checkedAll: false,

        orderToPlaceList: [],
        symbolSelectList: [], // Key để tích chọn (danh mục không có mã trùng ====> Dùng làm key)
        isOpenModalPlaceMultiOrder: false,
        listOrderSuccessPrecheck: [],
        listDataFillModal: [],
        loadStatusPlaceMultiOrder: LoadStatus.NONE,

        loadStatusSellAllModal: LoadStatus.NONE,

        editingOrder: null, // Dòng đang được chỉnh sửa

        filterData: null, // data filter
        symbolToSearch: '',

        instrumentSnapshot: {} // snapshot instrument khi mở modal
    };

    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState
        }
        this.iconInfoRef = React.createRef();
    };
    componentDidMount() {
        this.loadIndexData();
    }

    loadIndexData = () => {
        symbolService.getMarketInfoSnap(['HNX', 'HOSE', 'UPCOM'])
            .then(data => {
                let convertData = {};
                data.forEach(item => {
                    convertData[item.marketCode] = item;
                    convertData[item.marketCode]['status'] = item['marketStatus'] // Lấy trạng thái phiên (ATO,ATC)
                })
                this.setState({ marketData: convertData });
            }).catch(err => {

            })
    }

    fetchDataInstrumentBySymbolArr = (symbolArr, cb) => {
        let instrumentSnapshot = {};
        if (symbolArr && symbolArr.length > 0) {
            symbolService.getInstrument(symbolArr)
                .then(async data => {
                    let tranformedData = CommonUtils.transformSymbolsDataInstruments(data);
                    instrumentSnapshot = tranformedData.symbolInfoForInstrument;
                    this.setState({ instrumentSnapshot: instrumentSnapshot }, () => {
                        cb && cb();
                    })
                })
        } else {
            this.setState({ instrumentSnapshot: instrumentSnapshot }, () => {
                cb && cb();
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { data } = this.props;
        const { data: prevData } = prevProps;
        if (!_.isEqual(data, prevData)) {
            // this.setState({ currentData: this.props.data });
            // this.setDatatoPlace(this.props.data);
            let cb = () => { this.setDatatoPlace(this.props.data); }
            if (this.props.data) {
                let symbolList = [...new Set(this.props.data.map(x => x.symbol))];
                this.fetchDataInstrumentBySymbolArr(symbolList, cb);
            }
        }

    }

    setDatatoPlace = (data) => {
        const { instrumentSnapshot } = this.state;
        const { quotes } = this.props;
        let newData = data;
        newData.forEach(item => {
            let instrumentData = instrumentSnapshot[item.symbol] || {};

            // Khối lượng đặt Lô chẵn và làm tròn xuống
            let redundant = item.trade % 100;
            item.placeVol = item.trade - redundant;

            // Giá đặt ban đầu là LO (Nếu giá khớp === 0 hoặc không có giá khớp lấy giá sàn từ quote )
            if (instrumentData['CP']) {
                // item.placePrice = item.closePrice;
                // item.displayPrice = item.closePrice;
                if (CommonUtils.checkSeqApi(instrumentData.s, item.s)) {
                    item.placePrice = item.basicPrice;
                    item.displayPrice = item.basicPrice;
                } else {
                    item.placePrice = instrumentData['CP'];
                    item.displayPrice = instrumentData['CP'];
                }
            } else {
                let quote = quotes[item['symbol']] || {};
                item.placePrice = quote.floor || 0;
                item.displayPrice = quote.floor || 0;
            }
            item.pricetype = 'LO';
            item.action = 'sell'; // Mặc định
            item.autoid = item.symbol + Random.randomRequestIdToServer(); // không có (tự gen theo mã và random)
            item.orderValue = item.placePrice * item.placeVol;
        })
        newData = _.filter(newData, item => { return item.placeVol > 0 });
        // console.log('laojahackgame=====> NEW DATA', newData);
        this.setState({ currentData: newData });
    }

    onSymbolChanged = (symbol) => {
        this.setState({ symbol: symbol });
    };

    onRatioChanged = (event) => {
        this.setState({ currentRatio: event.target.value }, () => {
            this.calcPlaceVolume();
        });
    };

    calcPlaceVolume = () => {
        const { currentRatio, currentData } = this.state;
        let newData = [...currentData];
        newData.forEach(item => {
            item.placeVol = Math.floor(parseFloat((item.trade * currentRatio) / 100));
            let redundant = item.placeVol % 100; // Lô chẵn và làm tròn xuống
            item.placeVol = item.placeVol - redundant;

            item.orderValue = item.placePrice * item.placeVol;
        })


        // newData = _.filter(newData, item => { return item.placeVol > 0 });
        this.setState({ currentData: newData });
    }

    onPriceTypeChange = (event) => {
        this.setState({ currentPriceType: event.target.value }, () => {
            this.calcPlacePrice();
        });
    }

    calcPlacePrice = () => {
        const { quotes } = this.props;
        const { currentPriceType, currentData, marketData, instrumentSnapshot } = this.state;
        let newData = [...currentData];
        newData.forEach(item => {
            let instrumentData = instrumentSnapshot[item.symbol] || {};
            if (currentPriceType === 'LO') {
                // Nếu giá khớp === 0 hoặc không có giá khớp lấy giá sàn từ quote
                if (instrumentData['CP']) {
                    // item.placePrice = item.closePrice;
                    // item.displayPrice = item.closePrice;
                    if (CommonUtils.checkSeqApi(instrumentData.s, item.s)) {
                        item.placePrice = item.basicPrice;
                        item.displayPrice = item.basicPrice;
                    } else {
                        item.placePrice = instrumentData['CP'];
                        item.displayPrice = instrumentData['CP'];
                    }
                } else {
                    let quote = quotes[item['symbol']] || {};
                    item.placePrice = quote.floor || 0;
                    item.displayPrice = quote.floor || 0;
                }
                item.pricetype = 'LO';
                item.orderValue = item.placePrice * item.placeVol;
            } else {
                // Giá thị trường
                let quote = quotes[item['symbol']] || {};
                const { exchange } = quote;
                // console.log('laojahackgame======> MARKET DATA', marketData, exchange, item['symbol']);
                let marketStatusKey = exchange && marketData && marketData[exchange] ? getMarketStatusKey(marketData[exchange]) : '';
                if (marketStatusKey === 'ato') {
                    item.placePrice = 'ATO'
                    item.displayPrice = 'ATO';
                    item.pricetype = 'ATO'
                } else if (marketStatusKey === 'atc') {
                    item.placePrice = 'ATC';
                    item.displayPrice = 'ATC';
                    item.pricetype = 'ATC'
                } else if (exchange === 'HOSE') {
                    item.placePrice = 'MP';
                    item.displayPrice = 'MP';
                    item.pricetype = 'MP'
                } else if (exchange === 'HNX') {
                    item.placePrice = 'MTL';
                    item.displayPrice = 'MTL';
                    item.pricetype = 'MTL'
                } else if (exchange === 'UPCOM') {
                    item.placePrice = quote.reference || 0;
                    item.displayPrice = quote.reference || 0;
                    item.pricetype = 'LO'
                }

                item.orderValue = quote.ceiling * item.placeVol; // Giá thị trường ===> giá trị lệnh bằng giá trần * KL đặt
            }
        })

        this.setState({ currentData: newData });
    }

    handleCheckAll = () => {
        let { currentData } = this.state;

        this.setState({
            checkedAll: !this.state.checkedAll,
        }, () => {
            if (this.state.checkedAll === true) {
                this.setState({
                    orderToPlaceList: currentData,
                    symbolSelectList: currentData.map(item => { return item.symbol })
                })
            } else {
                this.setState({
                    orderToPlaceList: [],
                    symbolSelectList: []
                })
            }
        })
    };

    handleCheck = (record, uncheck = false) => {
        const { symbolSelectList, orderToPlaceList, currentData } = this.state;
        let newSymbolList = symbolSelectList;
        let newOrderList = orderToPlaceList;
        if (uncheck === true) {
            newSymbolList = _.filter(newSymbolList, item => { return item !== record.symbol });
            newOrderList = _.filter(newOrderList, item => { return item.symbol !== record.symbol });
        } else {
            newSymbolList.push(record.symbol);
            newOrderList.push(record);
        }

        this.setState({ symbolSelectList: newSymbolList, orderToPlaceList: newOrderList });
        if (newOrderList.length === currentData.length) {
            this.setState({ checkedAll: true });
        } else {
            this.setState({ checkedAll: false });
        }
    }

    getPlaceMultiDraftOrderData = (listOrderConfirm) => {
        let listOrders = []
        _.forEach(listOrderConfirm, (item, index) => {
            const orderType = CommonUtils.getOrderTypeFromPriceType(item['pricetype']);

            switch (orderType) {
                case 'limit':
                    item.limitPrice = Number(item.price);
                    break;
                case 'market':
                    item.limitPrice = item['pricetype'];
                    break;
                default:
                    break;
            }

            listOrders.push({
                "iid": item.autoid, // không có (tự gen theo mã và random)
                // "quoteId": item.odorderid, // không có
                "accountid": item.accountID,
                "instrument": item.symbol,
                "qty": item.placeVol,
                "side": "sell", // mặc định
                "type": orderType,
                "limitPrice": item.placePrice,
                "stopPrice": 0,
                "durationType": "",
                "durationDateTime": 0,
                "stopLoss": 0,
                "takeProfit": 0,
                "digitalSignature": ""
            })
        });
        return listOrders
    }

    // placeMultiOrderStart = () => {
    //     const { listOrderIdConfirm, orderToPlaceList, currentData } = this.state;
    //     if (!orderToPlaceList || orderToPlaceList.length === 0) {
    //         ToastUtil.error('common.no-confirm-order-allow');
    //         return;
    //     }
    //     // Đồng bộ currentData vào danh sách đặt lệnh do có thể bị thay đổi về giá , khối lượng đặt
    //     let newPlaceList = [];
    //     orderToPlaceList.forEach(item => {
    //         let foundIndex = _.findIndex(currentData, itemCurrent => { return itemCurrent.autoid === item.autoid });
    //         if (foundIndex >= 0) {
    //             newPlaceList.push(currentData[foundIndex]);
    //         }
    //     })

    //     this.setState({ orderToPlaceList: newPlaceList }, () => {
    //         this.onPlaceMultiOrder();
    //     })
    // }

    onPlaceMultiOrder = () => {
        const { listOrderIdConfirm, orderToPlaceList } = this.state


        if (!orderToPlaceList || orderToPlaceList.length === 0) {
            ToastUtil.error('common.no-confirm-order-allow');
            return;
        }

        let listOrders = this.getPlaceMultiDraftOrderData(orderToPlaceList)
        let requestId = Random.randomOrderRequestId();
        if (orderToPlaceList && orderToPlaceList.length < 0) {
            return
        }
        let body = {
            orders: listOrders,
            requestId
        }

        const precheckAndCb = () => {
            tradeApiService.initTwoFactorAuth()
                .then((data) => {
                    if (data) {
                        const { transactionId, tokenid, authtypes, challengeQuestion, authtype, verified } = data;
                        this.props.updateInfoCheckAuth({
                            transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
                        })
                        this.setState({ isOpenModalPlaceMultiOrder: true, loadStatusSellAllModal: LoadStatus.LOADED }) // Mở modal xác nhận
                    }
                })
                .catch(error => {
                    this.setState({ loadStatusSellAllModal: LoadStatus.FAIL })
                    ToastUtil.errorApi(error, 'CM2');
                });
        };

        this.setState({ loadStatusSellAllModal: LoadStatus.LOADING })
        tradeApiService.tradePreCheckMultiOrder(body)
            .then((data) => {
                this.handlePreCheckMultiplePlaceFinish(orderToPlaceList, data, precheckAndCb);
            }).catch(error => {
                this.setState({ loadStatus: LoadStatus.FAIL })
                ToastUtil.errorApi(error, 'CM2');
            });

    };


    handlePreCheckMultiplePlaceFinish = (listOrder, res, callback) => {
        const { intl, language } = this.props;

        let successListTotal = res.filter((item) => {
            return item["errcode"] == "0"
        })

        let errListTotal = res.filter((item) => {
            return item["errcode"] != "0"
        })

        let _listDataFillModal = listOrder

        if (errListTotal && errListTotal.length > 0) {
            _.forEach(errListTotal, (err) => {
                let autoid = err['iid'];
                _listDataFillModal = listOrder.map((item, index) => {
                    let _item = item
                    if (item['autoid'] == autoid) {
                        _item["errMsg"] = err.errmsg
                    }
                    return _item
                })
            });
        }

        let succmsgList = successListTotal && successListTotal.length > 0 && successListTotal.map(succ => {
            let autoid = succ['iid'];
            let order = _.find(listOrder, od => {
                return od['autoid'] == autoid;
            });
            order["errMsg"] = null;
            return order
        });

        this.setState({ listOrderSuccessPrecheck: succmsgList, listDataFillModal: _listDataFillModal })
        callback && callback()
    }


    onCloseModalPlaceMultiOrder = () => {
        this.setState({ isOpenModalPlaceMultiOrder: !this.state.isOpenModalPlaceMultiOrder })
    }

    setLoadStatusModal = (status) => {
        this.setState({ loadStatusPlaceMultiOrder: status })
    }

    callApiPlaceMultiOrder = (authInfo) => {
        const { listOrderIdConfirm, orderToPlaceList, listOrderSuccessPrecheck } = this.state
        let listOrders = this.getPlaceMultiDraftOrderData(listOrderSuccessPrecheck)
        let requestId = Random.randomOrderRequestId()

        let body = {
            ...authInfo,
            orders: listOrders,
            requestId,
        }

        this.setLoadStatusModal(LoadStatus.LOADING)

        // console.log('laojahackgame=====> CALL API DATA', { body });
        tradeApiService.tradePlaceSellMultiOrder(body)
            .then((data) => {
                this.setLoadStatusModal(LoadStatus.LOADED)
                //console.log("binh_check_data", data)
                this.handleMultiplePlaceFinish(orderToPlaceList, data);
            }
            ).catch(error => {
                // console.log('laojahackgame=====> CALL API DATA ERROR', { error });

                this.setLoadStatusModal(LoadStatus.FAIL)
                ToastUtil.errorApi(error, 'trade.place-order.place-order-fail');
            });
    }

    onFinishMultiOrderSell = () => {
        CommonUtils.middlewareOrder()
        this.onCloseModalPlaceMultiOrder();
        this.props.onClose();
    };

    handleMultiplePlaceFinish = (listOrder, res) => {
        const { intl, language } = this.props;
        this.onFinishMultiOrderSell();

        let successListTotal = res.filter((item) => {
            return item["errcode"] == "0"
        })

        let errListTotal = res.filter((item) => {
            return item["errcode"] != "0"
        })

        //console.log("binh_succmsgList_1", listOrder, successListTotal, errListTotal)
        let succmsgList = successListTotal && successListTotal.length > 0 && successListTotal.map(succ => {
            let autoid = succ['iid'];
            let order = _.find(listOrder, od => {
                return od['autoid'] == autoid;
            });
            let side = LanguageUtils.getMessageByKey('common.exec-types.NS', language) // mặc định
            let symbol = order['symbol']
            let accountId = order['al_afacctno']
            return `${accountId} : ${side} - ${symbol}`;
        });
        //console.log("binh_succmsgList_4", succmsgList)
        let errmsgList = errListTotal && errListTotal.length > 0 && errListTotal.map(err => {
            let autoid = err['iid'];
            let order = _.find(listOrder, od => {
                return od['autoid'] == autoid;
            });
            let side = LanguageUtils.getMessageByKey('common.exec-types.NS', language)
            let symbol = order['symbol']
            let accountId = order['al_afacctno']
            let errmsg = err.errmsg
            return `${accountId} : ${side} - ${symbol} (${errmsg})`;
        });
        //console.log("binh_succmsgList_result", succmsgList, errmsgList)

        if (succmsgList && succmsgList.length > 0) ToastUtil.successRaw('trade.place-order.place-order-success', succmsgList);
        if (errmsgList && errmsgList.length > 0) ToastUtil.errorRaw('trade.place-order.place-order-fail', errmsgList, 15000);
        this.setState({ successListTotal: [], errListTotal: [] });
    }

    isBroker = () => {
        const { role } = this.props;
        return role === Role.BROKER;
    };

    onEditOrder = (record) => {
        this.setState({ editingOrder: record })
    }

    getSubOrderType = (config, exchange) => {
        const { marketOrder } = config;
        if (!marketOrder || marketOrder.length === 0) {
            return []
        }
        if (marketOrder) {
            for (let i = 0; i < marketOrder.length; i++) {
                const exchangeConfig = marketOrder[i];
                if (exchangeConfig.name === exchange) {
                    let allowedOrderTypes = _.split(exchangeConfig.value, '|');
                    // Filter empty values
                    allowedOrderTypes = _.filter(allowedOrderTypes, (value) => {
                        return value !== '';
                    });

                    const mapObject = _.mapKeys(allowedOrderTypes);
                    return _.filter(subOrderTypes, (element) => {
                        return mapObject[element] != null;
                    });
                }
            }
        }

        return [];
    };

    onPriceEditChanged = (price, valid) => {
        const { editingOrder } = this.state;
        let newEditingOrder = { ...editingOrder }
        newEditingOrder.placePrice = price;
        newEditingOrder.displayPrice = price;
        newEditingOrder.orderValue = this.calOrderValue(newEditingOrder);
        this.setState({ editingOrder: newEditingOrder })
    }

    onQuantityEditChanged = (quantity, valid) => {
        const { quotes } = this.props;
        const { editingOrder } = this.state;
        let newEditingOrder = { ...editingOrder }
        newEditingOrder.placeVol = quantity;
        newEditingOrder.orderValue = this.calOrderValue(newEditingOrder);
        this.setState({ editingOrder: newEditingOrder })
    };


    calOrderValue = (order) => {
        const { quotes } = this.props;
        let orderValue = 0;
        let quote = quotes[order['symbol']] || null;

        if (_.isNumber(order.placePrice)) {
            orderValue = order.placePrice * order.placeVol;
        } else {
            orderValue = quote ? quote.ceiling * order.placeVol : 0;
        }
        return orderValue;
    }

    onApplyChange = () => {
        const { currentData, editingOrder, orderToPlaceList } = this.state;
        const { quotes, config } = this.props;

        let quote = quotes[editingOrder['symbol']] || null;

        // Check giá 
        const subOrderTypesSelects = quote ? this.getSubOrderType(config, quote.exchange) : [];

        // const isValidMarketPriceToSet = this.checkValidMarketPrice(subOrderTypesSelects, editingOrder.placePrice);
        if (!subOrderTypesSelects.includes(editingOrder.placePrice)) {
            if (_.isNumber(editingOrder.placePrice)) {
                // Check trần sàn
                if (quote) { // Nếu không tìm thấy quote ===> Để DB check
                    if (parseFloat(editingOrder.placePrice) > quote.ceiling || parseFloat(editingOrder.placePrice) < quote.floor) {
                        ToastUtil.error('trade.place-order.invalid-price-message');
                        return;
                    }
                }

            } else {
                // Sai giá thị trường
                ToastUtil.error('common.invalid-market-price');
                return;
            }
        }


        let newData = currentData;
        let newPlaceList = orderToPlaceList;
        let foundIndex = _.findIndex(currentData, item => { return item.autoid === editingOrder.autoid });
        let foundIndexPlaceList = _.findIndex(orderToPlaceList, item => { return item.autoid === editingOrder.autoid });
        if (foundIndex >= 0) {
            newData[foundIndex] = editingOrder;
        }
        if (foundIndexPlaceList >= 0) {
            newPlaceList[foundIndexPlaceList] = editingOrder;
        }
        this.setState({ currentData: newData, orderToPlaceList: newPlaceList, editingOrder: null });

    }

    searchBySymbol = () => {
        this.setState({ symbolToSearch: this.state.symbol });
    }


    render() {
        const { currentData, symbolSelectList, editingOrder, symbolToSearch, orderToPlaceList } = this.state
        const { isOpen, onClose, data, accountInfo, userInfo, quotes } = this.props;
        const { typeAccount, curCustomer } = { ...accountInfo }
        // console.log('laojahackgame=====> DATA TO PLACE SELL', { curCustomer });
        let _className = 'modal-cancel-draft-order'
        let titleId = 'trade.auth.cancel-title';
        const isBroker = this.isBroker();
        let fullName = userInfo.fullname || '';
        let custodycd = userInfo.custodycd || '';
        if (isBroker) {
            custodycd = curCustomer.custodycd || '';
            fullName = curCustomer.fullName || '';
        }
        console.log('laojahackgame=====> ISOPEN', isOpen);
        return (
            <DraggableModal
                isOpen={isOpen}
                onClose={onClose}
                className={_className}
                titleId={"open-position.sell-all"}
                toggle={onClose}
            >
                <div className="body">
                    {this.state.isOpenModalPlaceMultiOrder &&
                        <ModalPlaceMultiOrder
                            isOpen={this.state.isOpenModalPlaceMultiOrder}
                            twoFactorAuthCb={this.callApiPlaceMultiOrder}
                            onClose={this.onCloseModalPlaceMultiOrder}
                            listReconfirmOrder={this.state.listDataFillModal}
                            loadStatusCancelMultiNormalOrder={this.state.loadStatusPlaceMultiOrder}
                            accountInfo={accountInfo}
                            custodycd={custodycd}
                            fullName={fullName}
                        />
                    }
                    <RetryableContent status={this.state.loadStatusSellAllModal} disableRetry={true} className="wrap-body">
                        {/*<div className="wrap-body">*/}

                        <div className="action-container">
                            <div className={'d-flex container-select ' + (this.props.isDeskTop ? 'flex-wrap' : '')}>
                                <SymbolSuggestTrans
                                    handleInputChange={this.onSymbolChanged}
                                    currentSymbol={this.state.symbol}
                                    symbolToDisplay={this.state.symbol}
                                    suggestId="summary-order-matching-symbol "
                                    isRedirect={false}
                                    isDisplayError={false}
                                    placeholder={LanguageUtils.getMessageByKey("summary-order-matching.search.all-symbol", this.props.language)}
                                />

                                <div>
                                    <button ref={this.searchBtnRef} className="txt---400-14-20 btn-search" onClick={this.searchBySymbol} onKeyDown={this.handlerKeyDown}>
                                        <FormattedMessage id="common.search" />
                                    </button>
                                </div>

                                <div className="custom-form-group-n">
                                    <select
                                        value={this.state.currentRatio}
                                        onChange={this.onRatioChanged}
                                        className="custom-form-control txt---400-14-20">
                                        {SELL_RATIOS.map((item) => {
                                            return (
                                                <FormattedMessage id={item.titleId} key={item.id}>
                                                    {(txt) => (
                                                        <option
                                                            value={item.valueRatio}
                                                        >{txt}</option>
                                                    )}
                                                </FormattedMessage>
                                            );
                                        })}

                                    </select>
                                </div>

                                <div className="custom-form-group-n">
                                    <select
                                        value={this.state.currentPriceType}
                                        onChange={this.onPriceTypeChange}
                                        className="custom-form-control txt---400-14-20">
                                        {PRICE_TYPES.map((item) => {
                                            return (
                                                <FormattedMessage id={item.titleId} key={item.id}>
                                                    {(txt) => (
                                                        <option
                                                            value={item.valueType}
                                                        >{txt}</option>
                                                    )}
                                                </FormattedMessage>
                                            );
                                        })}

                                    </select>
                                </div>

                                {/*<div className="custom-form-group-n">
                                    <div className='align-self-center label txt---400-14-20'><FormattedMessage id="summary-order-matching.search.order" /></div>
                                    <select
                                        value={this.state.execType}
                                        onChange={this.onExecTypeChanged}
                                        className="custom-form-control txt---400-14-20">
                                        {execTypes.map((execType) => {
                                            return (
                                                <FormattedMessage id={'summary-order-matching.search.order-values.' + execType} key={execType}>
                                                    {(txt) => (
                                                        <option
                                                            value={execType}
                                                        >{txt}</option>
                                                    )}
                                                </FormattedMessage>
                                            );
                                        })}

                                    </select>
                                </div>*/}

                            </div>
                        </div>

                        <CustomScrollbars
                            className="table-sell-all-order"
                            autoHeight={true}
                            autoHeightMin={300}
                            // autoHeightMax={500}
                        >
                            <table className="basic-table">
                                <thead>
                                    <tr>
                                        <th className="txt---400-14-20 text-center text-not-active" >
                                            <input type="checkbox"
                                                onChange={this.handleCheckAll}
                                                checked={this.state.checkedAll}
                                            />
                                        </th>
                                        <th className="txt---400-14-20 text-center text-not-active" ><FormattedMessage id="trade.order.symbol" /></th>
                                        <th className="txt---400-14-20 text-center text-not-active" style={{ width: "30%" }} ><FormattedMessage id="trade.order.qtty" /></th>
                                        <th className="txt---400-14-20 text-center text-not-active" style={{ width: "30%" }} >
                                            <FormattedMessage id="trade.order.price" />&nbsp;
                                            <IconInfoActive ref={this.iconInfoRef} id='place-sell-all-icon-tt' />
                                            <TextToolTip
                                                tooltipText={<FormattedMessage id="open-position.sell-all-price-tooltip" />}
                                                targetID={this.iconInfoRef}
                                                placement='top'
                                            ></TextToolTip>

                                        </th>
                                        <th className="txt---400-14-20 text-center text-not-active" ><FormattedMessage id="trade.order.action" /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentData && currentData.length > 0 && currentData.map(item => {
                                        let isCheked = symbolSelectList.includes(item.symbol);
                                        let isEditing = editingOrder && editingOrder.autoid === item.autoid;
                                        let isSearch = symbolToSearch ? true : false;
                                        let quote = quotes[item['symbol']] || {};
                                        let instrument = {
                                            RE: quote.reference,
                                            CL: quote.ceiling,
                                            FL: quote.floor
                                        }

                                        if (!symbolToSearch || isSearch && item.symbol.includes(symbolToSearch)) {
                                            if (!isEditing) {
                                                return (
                                                    <tr  >
                                                        <td className="txt---400-14-20 text-center text-not-active" >
                                                            <input type="checkbox"
                                                                checked={isCheked}
                                                                onChange={() => this.handleCheck(item, isCheked)}
                                                            />
                                                        </td>
                                                        <td className="text-center txt---400-14-20">
                                                            {item.symbol}
                                                        </td>

                                                        <td className="text-center txt---400-14-20">
                                                            <FormattedQuantity value={item.placeVol} />
                                                        </td>

                                                        <td className="text-center txt---400-14-20">
                                                            <FormattedPrice value={item.displayPrice} shortForm={true} isFormatZero={true} instrument={instrument} />
                                                        </td>
                                                        <td className="edit-col text-center txt---400-14-20" onClick={() => { this.onEditOrder(item) }}>
                                                            <EditIcon />
                                                        </td>
                                                    </tr>
                                                )
                                            } else {
                                                let stepInputQuantity = quote ? (quote.exchange == EXCHANGE.HOSE ? 100 : (quote.exchange === 'HNFX' ? 1 : 100)) : 1;
                                                let instrumentQtty = {
                                                    EX: quote && quote.exchange,
                                                    ST: quote && quote.StockType
                                                }

                                                let subOrderType = this.getSubOrderType(this.props.config, quote.exchange);
                                                return (
                                                    <tr  >
                                                        <td className="txt---400-14-20 text-center text-not-active" >
                                                            <input type="checkbox"
                                                                checked={isCheked}
                                                                onChange={() => this.handleCheck(item, isCheked)}
                                                            />
                                                        </td>
                                                        <td className="text-center txt---400-14-20">
                                                            {item.symbol}
                                                        </td>
                                                        {/*<td className="text-center txt---400-14-20">
                                                            {item.custodycd}
                                                        </td>
                                                        <td className="text-center txt---400-14-20">
                                                            {item.al_afacctno}
                                                        </td>*/}
                                                        <td className="text-center txt---400-14-20 custom-form-group">
                                                            {/*<input type="numberic" className="custom-form-control" />*/}
                                                            <QuantityInputOrder
                                                                name="quantity"
                                                                step={stepInputQuantity}
                                                                min={null}
                                                                max={999999999999}
                                                                instrument={instrumentQtty}
                                                                value={(editingOrder && editingOrder.placeVol) || 0}
                                                                valid={true}
                                                                onChange={this.onQuantityEditChanged}
                                                                // onKeyDown={this.onInputKeyDown}
                                                                allowZero={false}
                                                                allowDecimal={false}
                                                                allowNegative={false}
                                                                orderType={'limit'}
                                                                ref={this.quantityRef}
                                                                // onFocus={this.onFocusQtty}
                                                                // onBlur={this.onBlurQtty}
                                                                // readOnly={(isEdittingOrder && (currentSymbol && currentSymbol.exchange == EXCHANGE.HOSE)) || isDefaultState}
                                                                // disabled={(isEdittingOrder && (currentSymbol && currentSymbol.exchange == EXCHANGE.HOSE)) || isDefaultState}
                                                                // isNotOddLot={validUntilOrder === TYPE_DAY.MUCH_DAY}
                                                                // onFocus={this.onFocusQuantity}
                                                                // onKeyDown={this.handlerKeyDownQuantity}
                                                                tabIndex={0}
                                                                quote={quote}
                                                            />
                                                        </td>
                                                        {/**Có rule đối với giá thị trường */}
                                                        <td className="text-center txt---400-14-20 custom-form-group">
                                                            {/*<input type="text" className="custom-form-control" />*/}
                                                            <PriceInput
                                                                name="limit-price"
                                                                ref={this.priceOrderRef}
                                                                // subOrderTypes={[]}
                                                                quote={quote}
                                                                step={10}
                                                                min={null}
                                                                max={999999999999}
                                                                value={(editingOrder && editingOrder.displayPrice) || 0}
                                                                orderType={'limit'}
                                                                subOrderType={'limit'}
                                                                valid={true}
                                                                validStep={true}
                                                                onChange={this.onPriceEditChanged}
                                                                subOrderTypes={subOrderType}
                                                                // onKeyDown={this.onInputKeyDown}
                                                                // onBlur={this.onPriceBlur}
                                                                allowZero={false}
                                                                allowDecimal={true}
                                                                allowNegative={false}

                                                                decimalScale={3}
                                                                tabIndex={0}
                                                                id='quick-order-input' // check focus
                                                            />
                                                        </td>
                                                        <td className="edit-col text-center txt---400-14-20" onClick={() => { this.onApplyChange() }}>
                                                            <CheckIcon />
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        }

                                    }
                                    )}
                                </tbody>
                            </table>
                        </CustomScrollbars>
                        <div className={"btn-container"}>
                            <button className="btn-action" onClick={onClose}>
                                <FormattedMessage id="trade.auth.action-close" />
                            </button>
                            <button
                                ref={this.confirmBtnRef}
                                className="btn-action bg-primary text-custom"
                                onClick={() => this.onPlaceMultiOrder()}
                                disabled={editingOrder !== null}
                            // onKeyDown={this.handlerKeyDown}
                            >
                                <FormattedMessage id="trade.auth.action-auth" />
                                <span> </span>
                                <span>{orderToPlaceList && orderToPlaceList.length > 0 ? "(" + orderToPlaceList.length + ")" : ""} </span>
                            </button>
                        </div>
                        {/*</div>*/}
                    </RetryableContent>
                </div>
            </DraggableModal>
        );
    }

}

const mapStateToProps = (state, ownProps) => {
    let _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
    let _accountInfo = _layoutPage && _layoutPage["accountInfo"]
    return {
        defaultTheme: state.user.userInfo && state.user.userInfo.defaultTheme,
        language: state.app.language,
        quotes: state.symbol.quotes,
        accountInfo: _accountInfo,
        userInfo: state.user.userInfo,
        role: state.user.userInfo.role,
        config: state.user.userInfo.config,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateInfoCheckAuth: (data, key) => dispatch(actions.updateInfoCheckAuth(data, key)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ModalCancelDraftOrder);

