import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from "lodash";
import { FormattedMessage } from "react-intl";
import moment from "moment/moment";
import { tradeApiService, userService } from '../../../../services';
import * as actions from "../../../../store/actions";
import { ACTION_METHOD_CONDITION, derivativeExchanges, EXCHANGE, CommonOrderUtils, CommonUtils, LoadStatus, Random, ToastUtil, KeyCodeUtils } from "../../../../utils";
import config from './../../../../combineConfig';

import DraggableModal from '../../../../components/Modal/DraggableModal';
import accountService from './../../../../services/accountService';
// import { emitter, emitter2 } from 'utils/EventEmitter';
import { FormattedPercentage, FormattedPrice, FormattedQuantity } from './../../../../components/Formating';
import PriceInputOrder from './../../../../components/Input/PriceInputOrder';
import QuantityInputOrder from './../../../../components/Input/QuantityInputOrder';
import './ModalPlaceOrderTPSL.scss';
import RealtimeInstrumentComponent from 'components/RealtimeInstrumentComponent/RealtimeInstrumentComponent';
import ModalComfirmPlaceOrderTPSL from './ModalComfirmPlaceOrderTPSL/ModalComfirmPlaceOrderTPSL';

export const TYPE_ACTION_TPSL = {
    PLACE_ORDER: "1",
    CANCEL: "2",
}

class ModalPlaceOrderTPSL extends Component {

    initialState = {
        sysvar: null,
        takeProfitPriceCheck: 0,
        stopLimitPriceCheck: 0,
        orderTPSL: {
            symbolCurrent: null,
            quantity: 0, // khối lượng
            quantityValid: false,

            takeProfit: 0,
            stepTakeProfitPriceValid: false,
            quoteTakeProfitPriceValid: false,

            stopLoss: 0,
            stepstopLossPriceValid: false,
            quotestopLossPriceValid: false,

            fromDate: null,
            toDate: null,

            editingOrder: null, // Danh sách sửa lệnh

            isDisableQuantity: false,
            isDisableTakeProfit: false,
            isDisableStopLimit: false,

        },
        typeActionTPSL: TYPE_ACTION_TPSL.PLACE_ORDER,
        listReconfirmOrder: {},
        isOpenModalComfirmPlaceOrderTPSL: false,
        loadStatusComfirmPlaceOrderTPSL: LoadStatus.NONE,
    };

    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState
        }
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    componentWillUnmount() {
        this._setState({
            ...this.initialState
        })
        this.mounted = false
    }

    async componentDidMount() {
        this.mounted = true
        const { fillDataModalTPSL, dataDetailTPSL } = this.props
        await this.getSysvar();
        await this.updateOrderTPSL({
            symbolCurrent: fillDataModalTPSL.symbol || "",
        }, () => {
            if (dataDetailTPSL && Object.keys(dataDetailTPSL).length > 0) {
                let _price_stop = dataDetailTPSL.price_stop.split("-")
                let _takeProfit = CommonUtils.divide(Number(_price_stop[0].trim()), 1)
                let _stopLoss = CommonUtils.divide(Number(_price_stop[1].trim()), 1)
                this.updateOrderTPSL({
                    quantity: dataDetailTPSL.qtty, // khối lượng
                    quantityValid: true,

                    takeProfit: _takeProfit,
                    stepTakeProfitPriceValid: true,
                    quoteTakeProfitPriceValid: true,

                    stopLoss: _stopLoss,
                    stepstopLossPriceValid: true,
                    quotestopLossPriceValid: true,

                    isDisableQuantity: dataDetailTPSL.qtty ? true : false,
                    isDisableTakeProfit: _takeProfit ? true : false,
                    isDisableStopLimit: _stopLoss ? true : false,
                })
            }
            this.renderPrice();
        })
    }

    setLoadStatusModal = (status) => {
        this._setState({ loadStatusComfirmPlaceOrderTPSL: status })
    }

    onRowDataChange = (symbolId, instrument) => {
        this.renderPrice(symbolId, instrument);
    };

    renderPrice = (symbolId, instrument = {}) => {

        //console.log("renderPrice", instrument)
        const { orderTPSL } = this.state
        const { actionMethodConditon, quote } = this.props;
        const { symbolCurrent } = orderTPSL
        let value = "0.00"
        let _instrument = null

        if (symbolCurrent) {
            if (instrument && Object.keys(instrument).length > 0) {
                _instrument = instrument
            } else {
                let instruments = CommonUtils.getInstruments();
                _instrument = instruments[symbolCurrent.symbol]
            }
            let isCheck = CommonOrderUtils.checkCompareCeilFloorReference({ quote, instrument: _instrument })

            if (quote && _instrument) {
                if (isCheck) {
                    if (actionMethodConditon === ACTION_METHOD_CONDITION.TOP_WAIT_MATCH) {
                        value = CommonUtils.getPriceInOutHourByKey(_instrument, "S1", quote.reference)
                    } else {
                        value = CommonUtils.getPriceInOutHourByKey(_instrument, "CP", quote.reference)
                    }
                } else {
                    value = quote.reference
                }
            }
        }
        this._setState({
            takeProfitPriceCheck: value,
            stopLimitPriceCheck: value,
        })
    }

    componentDidUpdate(prevProps, prevState) {
        const { actionMethodConditon, quote
        } = this.props;

        const {
            actionMethodConditon: prevActionMethodConditon,
            quote: prevQuote,
        } = prevProps;
        if (actionMethodConditon !== prevActionMethodConditon) {
            this.renderPrice();
        }

        if (!(_.isEqual(quote, prevQuote))) {
            this.renderPrice();
        }
    }


    getSysvar = async () => {
        userService.getSysvarByTypeAccount(false) // TPSL chỉ cho cơ sở
            .then(responses => {
                let [orderDate, orderPriceStep] = responses
                if (orderDate) {
                    this._setState({ sysvar: orderDate[0] }
                        , () => { this.setDateDisplay() })
                }
            }).catch(error => {
                ToastUtil.errorApi(error, 'common.fail-to-load-data');
            })
    }

    updateOrderTPSL = (data, callback) => {
        const { orderTPSL } = this.state
        const { allQuotes } = this.props
        let _orderTPSL = _.cloneDeep(orderTPSL)
        let _data = _.cloneDeep(data)
        if (_data && _data["symbolCurrent"]) {
            const foundSymbol = _.find(allQuotes, (element) => (element.id === _data["symbolCurrent"]))
            _data["symbolCurrent"] = foundSymbol
        }

        _orderTPSL = {
            ..._orderTPSL,
            ..._data
        }
        this._setState({ orderTPSL: _orderTPSL })

        if (callback) {
            callback()
        }
    }

    setDateDisplay = () => {
        const { orderTPSL } = this.state
        const { symbolCurrent, actionMethodConditon } = this.props
        let currentDate = this.props.currentDate * 1000;
        const { fromDate, toDate } = orderTPSL
        const { sysvar } = this.state;
        let _toDate = currentDate
        if (orderTPSL) {
            // Nếu có mã rồi thì check xem có ngày đáo hạn không nếu có có thì lấy min((ngày hệ thống + số ngày tối đa), ngày đáo hạn)
            let numberToMax = (sysvar && sysvar.varvalue * 24 * 60 * 60 * 1000) || 0 //number config flex
            let systemDate = currentDate + numberToMax
            _toDate = systemDate
        }
        this.updateOrderTPSL({
            fromDate: currentDate,
            toDate: _toDate
        })
    }


    onQuantityChanged = (quantity, valid) => {
        this.updateOrderTPSL({
            quantity: quantity,
            quantityValid: valid
        })
    };


    onTakeProfitPriceChanged = (price, valid, validStep, isValidMarketPrice = true) => {
        this.updateOrderTPSL({
            takeProfit: price || 0,
            stepTakeProfitPriceValid: validStep,
            quoteTakeProfitPriceValid: valid,
        })
    }



    onStopLossPriceChanged = (price, valid, validStep, isValidMarketPrice = true) => {
        const { placeQuickOrderCurrent } = this.props;
        const { action } = { ...placeQuickOrderCurrent }
        this.updateOrderTPSL({
            stopLoss: price || 0,
            stepstopLossPriceValid: validStep,
            quotestopLossPriceValid: valid,
        })
    }

    getPlaceOrderData = () => {
        const { orderTPSL } = this.state
        const { quote, actionMethodConditon, dataDetailTPSL, fillDataModalTPSL } = this.props;
        const {
            quantity,
            quantityValid,
            takeProfit,
            stepTakeProfitPriceValid,
            quoteTakeProfitPriceValid,
            stopLoss,
            stepstopLossPriceValid,
            quotestopLossPriceValid,
            toDate,
            fromDate,
            symbolCurrent
        } = orderTPSL

        let _fromDate = moment(new Date(fromDate)).format(config.app.SERVER_DATE_FORMAT)
        let _toDate = moment(new Date(toDate)).format(config.app.SERVER_DATE_FORMAT)
        //console.log("fillDataModalTPSL_binh", fillDataModalTPSL, fillDataModalTPSL.bl_tpsl_quoteid)
        const data = {
            "instrument": symbolCurrent && symbolCurrent.id,
            // "quoteId": ((fillDataModalTPSL && fillDataModalTPSL['bl_tpsl_quoteid']) ? dataDetailTPSL['bl_tpsl_quoteid'] : null),
            "quoteId": fillDataModalTPSL.bl_tpsl_quoteid,
            "qttyTP": Number(quantity),

            "sideTP": "sell",
            "priceTP": Number(takeProfit),

            'qttySL': Number(quantity),
            'sideSL': "sell",
            'priceSL': stopLoss,
            "activetype": actionMethodConditon,

            'fromdate': _fromDate,
            'todate': _toDate,
        };

        return data;
    }


    validateValue = () => {
        const { quote } = this.props
        let ceilPrice = 0;
        let floorPrice = 0;
        const { orderTPSL, takeProfitPriceCheck, stopLimitPriceCheck } = this.state
        const {
            quantity,
            quantityValid,
            takeProfit,
            stepTakeProfitPriceValid,
            quoteTakeProfitPriceValid,
            stopLoss,
            stepstopLossPriceValid,
            quotestopLossPriceValid,
            toDate,
            fromDate,
            symbolCurrent,
            isDisableQuantity,
            isDisableTakeProfit,
            isDisableStopLimit,
        } = orderTPSL

        if (orderTPSL) {
            if (quantity) {
                if (quantity == "0") {
                    ToastUtil.error('FE003006');
                    return false
                }

                if (!quantityValid) {
                    ToastUtil.error('FE003017');
                    return false
                }
            } else {
                ToastUtil.error('FE003006');
                return false
            }

            if ((takeProfit == 0 && stopLoss == 0) ||
                (isDisableTakeProfit && stopLoss == 0) ||
                (isDisableStopLimit && takeProfit == 0)) {
                ToastUtil.error('FE003021');
                return false
            }
        }

        if (takeProfit) {
            if (!stepTakeProfitPriceValid) {
                ToastUtil.error('FE003023')
                return false
            }

            if (takeProfitPriceCheck) {
                let _value = Number(takeProfitPriceCheck)
                if (takeProfit < _value) {
                    ToastUtil.error('FE003022');
                    return false
                }
            }
        }

        if (stopLoss) {

            if (!stepstopLossPriceValid) {
                ToastUtil.error('FE003025')
                return false
            }

            if (stopLimitPriceCheck) {
                let _value = Number(stopLimitPriceCheck)
                if (stopLoss > _value) {
                    ToastUtil.error('FE003024');
                    return false
                }
            }
        }

        return true
    }

    precheck = (doOrder) => {
        let data = this.getPlaceOrderData();
        const { fillDataModalTPSL } = this.props
        const { orderTPSL } = this.state
        const { editingOrder } = orderTPSL
        const { accountID } = fillDataModalTPSL
        const isEdittingOrder = editingOrder != null;


        //validate
        if (!this.validateValue()) {
            return
        }


        const precheckAndCb = () => {
            tradeApiService.initTwoFactorAuth()
                .then((data) => {
                    const { transactionId, tokenid, authtypes, challengeQuestion, authtype, verified } = data;
                    this.props.updateInfoCheckAuth({
                        transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
                    })
                })
                .catch(error => {
                    this._setState({ loadStatus: LoadStatus.FAIL })
                    ToastUtil.errorApi(error, 'CM2');
                });
        };

        accountService.precheckConditionTPSL(accountID, data)
            .then((data) => {
                precheckAndCb();
            }).catch(error => {
                if (error.errorCode === "-700137") { // FIX: Mã lỗi trạng thái tiểu khoản => chặn đặt lệnh (Fix nếu có thêm lỗi cần chặn)
                    ToastUtil.errorApi(error, isEdittingOrder ? 'common.fails-to-edit-order-broker' : 'common.fails-to-place-order-broker');
                } else {
                    ToastUtil.errorApi(error, 'CM2');
                }
            });
    }

    placeOrder = async () => {
        let getDataShow = this.getDataShow()
        const { orderTPSL } = this.state
        const { fillDataModalTPSL, orderTypeUse, quote } = this.props
        const { symbolCurrent } = orderTPSL

        if (quote && symbolCurrent && symbolCurrent.id) {
            let isCheck = await CommonOrderUtils.checkInfoQuote(symbolCurrent.id, quote)
            if (!isCheck) {
                return
            }
        }

        let dataShow = {
            ...orderTPSL,
            ...getDataShow,
            ...fillDataModalTPSL
        }
        this._setState({ listReconfirmOrder: dataShow, typeActionTPSL: TYPE_ACTION_TPSL.PLACE_ORDER })
        this.precheck();
    }

    onFinishPlaceOrder = () => {
        const { onClose } = this.props
        CommonUtils.middlewareOrder()
        this.onCloseModalComfirmPlaceOrderTPSL()
        onClose()
    };


    precheck = (doOrder) => {
        let data = this.getPlaceOrderData();
        const { fillDataModalTPSL } = this.props
        const { orderTPSL } = this.state
        const { editingOrder } = orderTPSL
        const { accountID } = fillDataModalTPSL
        const isEdittingOrder = editingOrder != null;


        //validate
        if (!this.validateValue()) {
            return
        }


        const precheckAndCb = () => {
            tradeApiService.initTwoFactorAuth()
                .then((data) => {
                    if (data) {
                        const { transactionId, tokenid, authtypes, challengeQuestion, authtype, verified } = data;
                        this.props.updateInfoCheckAuth({
                            transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
                        })
                        this._setState({ isOpenModalComfirmPlaceOrderTPSL: true })
                    }
                })
                .catch(error => {
                    this._setState({ loadStatus: LoadStatus.FAIL })
                    ToastUtil.errorApi(error, 'CM2');
                });
        };

        accountService.precheckConditionTPSL(accountID, data)
            .then((data) => {
                precheckAndCb();
            }).catch(error => {
                if (error.errorCode === "-700137") { // FIX: Mã lỗi trạng thái tiểu khoản => chặn đặt lệnh (Fix nếu có thêm lỗi cần chặn)
                    ToastUtil.errorApi(error, isEdittingOrder ? 'common.fails-to-edit-order-broker' : 'common.fails-to-place-order-broker');
                } else {
                    ToastUtil.errorApi(error, 'CM2');
                }
            });
    }


    twoFactorAuthCb = (authInfo) => {
        const { orderTPSL, typeActionTPSL } = this.state
        if (typeActionTPSL === TYPE_ACTION_TPSL.CANCEL) {
            this.callApiCancelOrder(authInfo)
        } else if (typeActionTPSL === TYPE_ACTION_TPSL.PLACE_ORDER) {
            this.callApiPlaceOrder(authInfo)
        }
    }

    callApiPlaceOrder = (authInfo) => {
        const { orderTPSL, typeActionTPSL } = this.state
        const { userInfo, setIsOpenModalHaveData, fillDataModalTPSL } = this.props;
        const { accountID } = fillDataModalTPSL

        let data = this.getPlaceOrderData();
        const requestId = 'C' + Random.randomOrderRequestId(userInfo.username);
        let _data = { ...data, ...authInfo };


        this.setLoadStatusModal(LoadStatus.LOADING)
        tradeApiService.tradePlaceOrderConditionTPSL(accountID, requestId, _data)
            .then(() => {
                this.setLoadStatusModal(LoadStatus.LOADED)
                ToastUtil.success('trade.place-order.place-order-success');
                this.onFinishPlaceOrder();
            })
            .catch((error) => {
                this.setLoadStatusModal(LoadStatus.FAIL)
                ToastUtil.errorApi(error, 'trade.place-order.place-order-fail');
                CommonUtils.clearTradeAuthInfo();
            });
    }


    onCancelOrder = () => {
        const { orderTPSL } = this.state
        const { dataDetailTPSL, fillDataModalTPSL, orderTypeUse } = this.props;
        const { accountID } = fillDataModalTPSL
        let getDataShow = this.getDataShow()

        let dataShow = {
            ...orderTPSL,
            ...getDataShow,
            ...fillDataModalTPSL
        }

        this._setState({ listReconfirmOrder: dataShow, typeActionTPSL: TYPE_ACTION_TPSL.CANCEL })

        tradeApiService.initTwoFactorAuth()
            .then((data) => {
                const { transactionId, tokenid, authtypes, challengeQuestion, authtype, verified } = data;
                this.props.updateInfoCheckAuth({
                    transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
                })
                this._setState({ isOpenModalComfirmPlaceOrderTPSL: true })
            })
            .catch((error) => {
                ToastUtil.errorApi(error, 'common.fail-to-load-customer-acccounts-list');
                return;
            })

        // let listReconfirmOrder = this.getListReconfirmOrder([{ ...fillDataModalTPSL }]);
    };

    callApiCancelOrder = (authInfo, requestId) => {
        const { fillDataModalTPSL } = this.props
        let body = {
            ...authInfo,
            requestId,
            orderId: fillDataModalTPSL['bl_tpsl_quoteid'],
            quoteId: fillDataModalTPSL['bl_tpsl_quoteid'],
            orderType: "TPSL",
        }

        tradeApiService.tradeCancelOrderCondition(fillDataModalTPSL['accountID'], body)
            .then((data) => {
                if (data) {
                    this.onFinishPlaceOrder();
                    ToastUtil.success('trade.order.cancel-order-success');
                }
            })
            .catch((error) => {
                this.props.setIsOpenModalHaveData("QuickOrder", {
                    loadStatusQuickOrderModalTrade: LoadStatus.FAIL,
                })
                ToastUtil.errorApi(error, 'trade.order.cancel-order-fail');
                CommonUtils.clearTradeAuthInfo();
            });
    }


    getDataShow = () => {
        const { orderTPSL } = this.state
        const { fillDataModalTPSL } = this.props;
        const {
            quantity,
            takeProfit,
            stopLoss,
        } = orderTPSL
        let data = { ...fillDataModalTPSL }
        let profitLossTP = ((takeProfit - Number(fillDataModalTPSL.costPrice)) * Number(quantity))
        let profitLossSL = ((stopLoss - Number(fillDataModalTPSL.costPrice)) * Number(quantity))
        let profitLossTPRate = Number(quantity) > 0 ? ((Number(profitLossTP) / (Number(fillDataModalTPSL.costPrice) * Number(quantity)))) * 100 : 0
        let profitLossSLRate = Number(quantity) > 0 ? ((Number(profitLossSL) / (Number(fillDataModalTPSL.costPrice) * Number(quantity)))) * 100 : 0

        data.profitLossTP = Number(takeProfit) != 0 ? Number(profitLossTP) : 0 // Lãi lỗ dự kiến = (price - giá vốn) * với KL 
        data.profitLossSL = Number(stopLoss) != 0 ? Number(profitLossSL) : 0 // Lãi lỗ dự kiến = (price - giá vốn) * với KL
        data.profitLossTPRate = Number(takeProfit) != 0 ? Number(profitLossTPRate) : 0// % Lãi lỗ dự kiến = Lãi lỗ dự kiến chia cho ( giá vốn nhân với khối lượng)
        data.profitLossSLRate = Number(stopLoss) != 0 ? Number(profitLossSLRate) : 0// % Lãi lỗ dự kiến = Lãi lỗ dự kiến chia cho ( giá vốn nhân với khối lượng)


        data.noteMessageId = ["CM000003"]
        return data
    }

    onCloseModalComfirmPlaceOrderTPSL = () => {
        this._setState({ isOpenModalComfirmPlaceOrderTPSL: !this.state.isOpenModalComfirmPlaceOrderTPSL })
    }

    onHandleKeyDownPriceOrder = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            this.placeOrder()
        }
    }

    render() {
        const { actionSelect, orderTPSL, takeProfitPriceCheck, stopLimitPriceCheck } = this.state
        const { isOpen, onClose, data, accountInfo, fillDataModalTPSL, dataDetailTPSL, quote } = this.props;
        const {
            symbolCurrent,
            quantity,
            quantityValid,
            takeProfit,
            stepTakeProfitPriceValid,
            quoteTakeProfitPriceValid,
            stopLoss,
            stepstopLossPriceValid,
            quotestopLossPriceValid,

            isDisableQuantity,
            isDisableTakeProfit,
            isDisableStopLimit,
        } = orderTPSL

        let _className = 'modal-place-order-tpsl'
        let titleId = 'TP/SL'

        let instrumentQtty = {
            EX: quote && quote.exchange,
            ST: quote && quote.StockType
        }
        let getDataShow = this.getDataShow()

        let ceilPrice = null;
        let floorPrice = null;
        if (quote != null) {
            ceilPrice = CommonUtils.divide(quote.ceiling, 1000)
            floorPrice = CommonUtils.divide(quote.floor, 1000)
        }
        let stepInputQuantity = symbolCurrent ? (symbolCurrent.exchange == EXCHANGE.HOSE ? 100 : (quote.exchange === derivativeExchanges[0] ? 1 : 100)) : 1
        //console.log("binh_TPSL_render", this.state, this.props, getDataShow)
        let isOpenBtnConfirm = dataDetailTPSL && dataDetailTPSL.iscancel === "Y"
        if (isDisableTakeProfit && isDisableStopLimit) {
            isOpenBtnConfirm = isOpenBtnConfirm && false
        } else if (!isDisableTakeProfit && !isDisableStopLimit) {
            isOpenBtnConfirm = true
        } else {
            isOpenBtnConfirm = isOpenBtnConfirm && (isDisableTakeProfit || isDisableStopLimit)
        }
        //console.log("binh_isOpenBtnConfirm", isOpenBtnConfirm)
        return (
            <DraggableModal
                isOpen={isOpen}
                onClose={onClose}
                className={_className}
                titleId={titleId}
                toggle={onClose}
            >
                <div className="body">
                    {this.state.isOpenModalComfirmPlaceOrderTPSL &&
                        <ModalComfirmPlaceOrderTPSL
                            isOpen={this.state.isOpenModalComfirmPlaceOrderTPSL}
                            twoFactorAuthCb={this.twoFactorAuthCb}
                            onClose={this.onCloseModalComfirmPlaceOrderTPSL}
                            listReconfirmOrder={this.state.listReconfirmOrder}
                            loadStatusComfirmPlaceOrderTPSL={this.state.loadStatusComfirmPlaceOrderTPSL}
                            typeActionTPSL={this.state.typeActionTPSL}
                        />
                    }
                    <div className="wrap-body">
                        <div className="place-order-tpsl">
                            <div style={{ display: "none" }}>
                                <RealtimeInstrumentComponent
                                    record={fillDataModalTPSL && fillDataModalTPSL}
                                    onRowDataChange={this.onRowDataChange}
                                />
                            </div>
                            <div className="type-view">
                                <div className="content content-left text-left txt---500-14-20">
                                    <FormattedMessage id="trade.order.account-id" />
                                </div>
                                <div className="content content-right">
                                    {fillDataModalTPSL.accountID}
                                </div>
                            </div>
                            <div className="type-view">
                                <div className="content content-left text-left txt---500-14-20">
                                    <FormattedMessage id="trade.order.symbol" />
                                </div>
                                <div className="content content-right">
                                    {symbolCurrent && symbolCurrent.id}
                                </div>
                            </div>
                            <div className="type-view">
                                <div className="content content-left text-left txt---500-14-20">
                                    <FormattedMessage id="open-position.cost-price" />
                                </div>
                                <div className="content content-right">
                                    <FormattedPrice value={getDataShow.costPrice} shortForm={true} />
                                </div>
                            </div>
                            <div className="type-view price-order">
                                <div className="content content-left text-left txt---500-14-20">
                                    <FormattedMessage id="trade.place-order.mass" />
                                </div>
                                <div className="content content-right txt---400-14-20">
                                    <div className="row-input">
                                        <div className="block-input-qtty">
                                            <QuantityInputOrder
                                                name="quantity"
                                                step={stepInputQuantity}
                                                min={null}
                                                max={999999999999}
                                                instrument={instrumentQtty}
                                                value={quantity}
                                                valid={quantityValid}
                                                onChange={this.onQuantityChanged}
                                                // onKeyDown={this.onInputKeyDown}
                                                allowZero={false}
                                                allowDecimal={false}
                                                allowNegative={false}
                                                orderType={''}
                                                ref={this.quantityRef}
                                                // onFocus={this.onFocusQtty}
                                                onBlur={this.onBlurQtty}
                                                readOnly={isDisableQuantity}
                                                isNotOddLot={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="row-note text-left">
                                        {"≤ "} <FormattedQuantity value={getDataShow.trade} />
                                    </div>
                                </div>
                            </div>
                            <div className="type-view price-order">
                                <div className="content content-left text-left txt---500-14-20">
                                    Take profit
                                </div>
                                <div className="content content-right txt---400-14-20">
                                    <div className="row-input">
                                        <div className="block-input-price">
                                            <PriceInputOrder
                                                ref={this.priceOrderRef}
                                                name="limit-price"
                                                subOrderTypes={""} // Hay gây lỗi
                                                quote={quote ? quote : ""}
                                                step={10}
                                                min={0}
                                                max={99999999999}
                                                value={takeProfit}
                                                orderType={"limit"}
                                                subOrderType={"LO"}
                                                valid={quoteTakeProfitPriceValid}
                                                validStep={stepTakeProfitPriceValid}
                                                onChange={this.onTakeProfitPriceChanged}
                                                onKeyDown={this.onInputKeyDown}
                                                onBlur={this.onBlurPrice}
                                                allowZero={false}
                                                allowDecimal={true}
                                                allowNegative={false}
                                                onFocus={this.onFocusPrice}
                                                decimalScale={2}
                                                readOnly={isDisableTakeProfit}
                                            />
                                        </div>
                                    </div>
                                    <div className="row-note text-left">
                                        {"≥ "}
                                        <span id="take-profit-price-tpsl">
                                            <FormattedPrice value={takeProfitPriceCheck} shortForm={true} isFormatZero={true} />
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="type-view">
                                <div className="content content-left text-left txt---500-14-20">
                                    <FormattedMessage id="open-position.value-totalplamt" />
                                </div>
                                <div className="content content-right">
                                    <span className={"" + (CommonUtils.getClassCheckValue(getDataShow.profitLossTP))}>
                                        <span>
                                            {getDataShow.profitLossTP > 0 && "+"}
                                        </span>
                                        <FormattedPrice value={getDataShow.profitLossTP} />
                                    </span>&nbsp;
                                    <span className={"" + (CommonUtils.getClassCheckValue(getDataShow.profitLossTPRate))}>
                                        {"("}<span>
                                            {getDataShow.profitLossTPRate > 0 && "+"}
                                        </span>
                                        <FormattedPercentage value={getDataShow.profitLossTPRate} />%{")"}
                                    </span>
                                </div>
                            </div>

                            <div className="type-view price-order">
                                <div className="content content-left text-left txt---500-14-20">
                                    Stop Loss
                                </div>
                                <div className="content content-right txt---400-14-20">
                                    <div className="row-input">
                                        <div className="block-input-price">
                                            <PriceInputOrder
                                                ref={this.priceOrderRef}
                                                name="limit-price"
                                                subOrderTypes={""} // Hay gây lỗi
                                                quote={quote ? quote : ""}
                                                step={10}
                                                min={0}
                                                max={99999999999}
                                                value={stopLoss}
                                                orderType={"limit"}
                                                subOrderType={"LO"}
                                                valid={quotestopLossPriceValid}
                                                validStep={stepstopLossPriceValid}
                                                onChange={this.onStopLossPriceChanged}
                                                onKeyDown={this.onInputKeyDown}
                                                onBlur={this.onBlurPrice}
                                                allowZero={false}
                                                allowDecimal={true}
                                                allowNegative={false}
                                                onFocus={this.onFocusPrice}
                                                decimalScale={2}
                                                readOnly={isDisableStopLimit}
                                            // readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="row-note text-left">
                                        {"≤ "}
                                        <span id="stop-limit-price-tpsl">
                                            <FormattedPrice value={stopLimitPriceCheck} shortForm={true} isFormatZero={true} />
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="type-view">
                                <div className="content content-left text-left txt---500-14-20">
                                    <FormattedMessage id="open-position.value-totalplamt" />
                                </div>
                                <div className="content content-right">
                                    <span className={"" + (CommonUtils.getClassCheckValue(getDataShow.profitLossSL))}>
                                        <span>
                                            {getDataShow.profitLossSL > 0 && "+"}
                                        </span>
                                        <FormattedPrice value={getDataShow.profitLossSL} />
                                    </span>&nbsp;
                                    <span className={"" + (CommonUtils.getClassCheckValue(getDataShow.profitLossSLRate))}>
                                        {"("}<span>
                                            {getDataShow.profitLossSLRate > 0 && "+"}
                                        </span>
                                        <FormattedPercentage value={getDataShow.profitLossSLRate} />%{")"}
                                    </span>
                                </div>
                            </div>

                            <div className="container-action">
                                {
                                    dataDetailTPSL && dataDetailTPSL.iscancel === "Y" &&
                                    <button className="focus-item form-btn-action btn-cancel txt---500-16-24 bg-sell" onClick={this.onCancelOrder} >
                                        <FormattedMessage id="open-position.cancel-tpsl" />
                                    </button>
                                }
                                <button className={"focus-item btn-action txt---500-16-24 " + (isOpenBtnConfirm ? " btn-confirm" : " ")}
                                    disabled={!isOpenBtnConfirm}
                                    onClick={this.placeOrder}
                                    onKeyDown={this.onHandleKeyDownPriceOrder}

                                >
                                    <FormattedMessage id="common.btn-confirm" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </DraggableModal >
        );
    }

}

const mapStateToProps = (state, ownProps) => {
    const { fillDataModalTPSL } = ownProps
    const { symbol } = fillDataModalTPSL
    let quote = symbol && state.symbol.quotes[CommonOrderUtils.getSymbolOfSymbolSpecial(symbol)]
    // //console.log("binh_mapStateToProps", ownProps, quote)
    return {
        config: state.user.userInfo.config,
        quote,
        allQuotes: state.symbol.allQuotes,
        language: state.app.language,
        role: state.user.userInfo.role,
        userInfo: state.user.userInfo,
        currentDate: state.app.currentDate,

        socketSEAction: state.socketRealtime.socketSEAction,
        socketCIAction: state.socketRealtime.socketCIAction,
        actionMethodConditon: state.user.userInfo.actionMethodConditon

    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchSymbolOrderbook: (symbolId) => dispatch(actions.fetchSymbolOrderbook(symbolId)),
        updatePlaceQuickOrder: (data, typeOrder) => dispatch(actions.updatePlaceQuickOrder(data, typeOrder)),
        updateProTrade: (data, typeOrder) => dispatch(actions.updateProTrade(data, typeOrder)),
        updateSymbolProTrade: () => dispatch(actions.updateSymbolProTrade()),
        updatePlaceQuickOrderTPSL: (data, typeOrder) => dispatch(actions.updatePlaceQuickOrderTPSL(data, typeOrder)),

        updateInfoCheckAuth: (data, key) => dispatch(actions.updateInfoCheckAuth(data, key)),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
        saveTradeAuthenticated: () => dispatch(actions.saveTradeAuthenticated()),
        setTradeAuthSuccess: () => dispatch(actions.setTradeAuthSuccess()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalPlaceOrderTPSL);