import React, { Component } from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

import CustomScrollbars from "../../../components/CustomScrollbars";
import { FormattedPrice, FormattedVolume, getDiffColorClassName } from "../../../components/Formating";
import ValueChangeAnimation from "../../../components/ValueChangeAnimation";
import { CommonUtils, JsxUtils, orderActions, EXCHANGE } from "../../../utils";

import './DualOrderbook.scss';

const getPercentage = (volume, total) => {
    return _.round(volume / total, 2) * 100;
};

const getStyleClass = (instrument, price) => {
    // so sánh giá với T, S, TC -> colorClass
    // Nếu giá = 0 lấy màu giảm theo logic cũ
    if (price == null || instrument == null) {
        return '';
    }

    if (CommonUtils.isContainLetter(price)) {
        return '';
    }

    price = CommonUtils.convertToNumber(price);
    const reference = CommonUtils.convertToNumber(instrument.RE);

    if (price === reference) {
        return 'text-ref-price'
    }
    const ceil = CommonUtils.convertToNumber(instrument.CL);
    if (price === ceil) {
        return 'text-ceil-price';
    }
    const floor = CommonUtils.convertToNumber(instrument.FL);
    if (price === floor) {
        return 'text-floor-price';
    }
    if (reference) {
        if (price < reference) {
            return 'text-red';
        }
        if (price > reference) {
            return 'text-green';
        }
    }

    return '';
}

class DualOrderbookSellRow extends Component {

    shouldComponentUpdate(nextProps) {
        const props = this.props;
        const order = props.order;
        const nextOrder = nextProps.order;

        return props.onItemClick !== nextProps.onItemClick
            || !CommonUtils.isSameSellOrder(order, nextOrder)
            || (Object.keys(props.instrument).length == 0 && (!(_.isEqual((Object.keys(props.instrument)), nextProps.instrument))))
            || props.totalSell !== nextProps.totalSell
    }
    render() {
        const { order, onItemClick, totalSell, instrument } = this.props;
        let price = order ? order.SP : 0
        let color = getStyleClass(instrument, price)
        let percent = order ? getPercentage(order.SQ, totalSell) : 0;
        const isDerivativeSymbol = (instrument && instrument.EX === EXCHANGE.DERIVATIVE) ? true : false;

        return (
            <div className="row gutters-5 justify-content-between table-row" onClick={() => { onItemClick(order, orderActions.SELL) }}>
                <div className="slider" style={{ width: percent + '%' }} />
                <ValueChangeAnimation tag="div" value={order && order.SP != 0 ? order.SP : null} className={color + " txt---400-14-20"} style={{ marginLeft: "5px" }} isRender={true}>
                    <FormattedPrice value={order && order.SP != 0 ? order.SP : null} shortForm={true} isNotThousand={isDerivativeSymbol} instrument={instrument} from={"DualOrderbookSellRow"} />
                </ValueChangeAnimation>
                <ValueChangeAnimation tag="div" value={order ? order.SQ : null} className="text-right txt-near-active  txt---400-14-20" style={{ marginRight: "15px" }}>
                    <FormattedVolume value={order ? order.SQ : null} shortForm={true} />
                </ValueChangeAnimation>
                {/* <div className="col-4 text-right">
                    <FormattedVolume value={order ? order.CSV : null} shortForm={true} />
                </div> */}
            </div>
        );
    }
}

class DualOrderbookBuyRow extends Component {

    shouldComponentUpdate(nextProps) {
        const props = this.props;
        const order = props.order;
        const nextOrder = nextProps.order;
        return props.onItemClick !== nextProps.onItemClick
            || !CommonUtils.isSameBuyOrder(order, nextOrder)
            || (Object.keys(props.instrument).length == 0 && (!(_.isEqual((Object.keys(props.instrument)), nextProps.instrument))))
            || props.totalBuy !== nextProps.totalBuy
    }

    render() {
        const { order, onItemClick, totalBuy, instrument } = this.props;
        const percent = order ? getPercentage(order.BQ, totalBuy) : 0;
        let price = order ? order.BP : 0
        let color = getStyleClass(instrument, price)
        const isDerivativeSymbol = (instrument && instrument.EX === EXCHANGE.DERIVATIVE) ? true : false;

        return (
            <div className="row gutters-5 justify-content-between table-row" onClick={() => { onItemClick(order, orderActions.BUY) }}>
                <div className="slider" style={{ width: percent + '%' }} />
                {/* <div className="col-4">
                    <FormattedVolume value={order ? order.CBV : null} shortForm={true} />
                </div> */}
                <ValueChangeAnimation tag="div" value={order ? order.BQ : null} style={{ marginLeft: "15px" }} className="txt-near-active txt---400-14-20">
                    <FormattedVolume value={order ? order.BQ : null} shortForm={true} />
                </ValueChangeAnimation>
                <ValueChangeAnimation tag="div" value={order && order.BP != 0 ? order.BP : null} className={color + " txt---400-14-20"} style={{ marginRight: "5px" }} isRender={true}>
                    <FormattedPrice value={order && order.BP != 0 ? order.BP : null} shortForm={true} isNotThousand={isDerivativeSymbol} instrument={instrument} />
                </ValueChangeAnimation>
            </div>
        );
    }
}

const DualOrderbookMatchedPriceRow = (props) => {
    const { instrument } = props;
    const change = instrument.CH || 0;
    const colorClassName = getDiffColorClassName(change);
    const iconClassName = change > 0 ? 'fa-arrow-up' : (change < 0 ? 'fa-arrow-down' : '');
    return (
        <div className="matched-price">
            <div className="row gutters-5">
                <div className={JsxUtils.joinClass('col text-right', colorClassName)}>
                    {instrument.CP === 0 ? '-' : (<FormattedPrice value={instrument.CP} shortForm={true} />)}
                </div>
                <div className={JsxUtils.joinClass('col-auto', colorClassName)}>
                    {instrument.CP === 0 ? '-' : (<i className={JsxUtils.joinClass('fas', iconClassName)} />)}
                </div>
                <div className="col">
                    {instrument.CP === 0 ? '-' : (<FormattedVolume value={instrument.TT} shortForm={true} />)}
                </div>
            </div>
        </div>
    );
};

class DualOrderbook extends Component {

    emptyOrderbookArray = [0, 1, 2, 3];


    calculateData = () => {
        const { orderbook } = this.props;
        let totalBuy = 0, totalSell = 0, widthBuy = 0, widthSell = 0, zIndexBuy = 0, zIndexSell = 0;
        if (orderbook.length > 0) {
            totalBuy = orderbook.reduce((total, currentValue) => total = total + Number(currentValue.BQ), 0);
            totalSell = orderbook.reduce((total, currentValue) => total = total + Number(currentValue.SQ), 0);
            widthBuy = Math.floor((totalBuy / (totalBuy + totalSell)) * 100)
            widthSell = 100 - widthBuy
            zIndexBuy = widthBuy > widthSell ? 0 : 1
            zIndexSell = widthBuy > widthSell ? 1 : 0
        } else {
            totalBuy = 500
            totalSell = 500
            widthBuy = 50
            widthSell = 50
        }

        let validPercentage = (parseFloat(widthBuy) || widthBuy === 0) && (parseFloat(widthSell) || widthSell === 0); // check NAN

        return {
            totalBuy: totalBuy, totalSell: totalSell, widthBuy: validPercentage ? widthBuy : 50, widthSell: validPercentage ? widthSell : 50, zIndexBuy: zIndexBuy, zIndexSell: zIndexSell
        }
    }
    render() {
        const { orderbook, instrument, onItemClick } = this.props;
        const { totalBuy, totalSell, widthBuy, widthSell, zIndexBuy, zIndexSell } = this.calculateData()
        // //console.log("binh_DualOrderbook", this.props)
        return (
            <div className="dual-orderbook">
                <CustomScrollbars>
                    <div className="table-container">
                        {/* <DualOrderbookMatchedPriceRow instrument={instrument} /> */}

                        {/*<div className="container-total">
                            <div className='style-bar label-bar'>
                                <p className='txt---400-14-20 txt-not-active text-left'><FormattedMessage id="trade.depth-chart.bid" /></p>
                                <p className='headB---700-16-24 text-left'><FormattedVolume value={widthBuy} />%</p>
                            </div>
                            <div className='total-bar style-bar'>
                                {widthBuy ?
                                    <div className='bg-green-price'
                                        style={{ width: widthBuy + "%", paddingLeft: "15px", display: "flex", flexDirection: "column", alignItems: "start", zIndex: zIndexBuy }}
                                    >
                                    </div>
                                    : ""
                                }
                                {widthSell ?
                                    <div className='bg-red-price'
                                        style={{ width: widthSell + "%", display: "flex", flexDirection: "column", alignItems: "end", paddingRight: "15px", zIndex: zIndexSell }}
                                    >
                                    </div>
                                    : ""
                                }
                            </div>
                            <div className='style-bar label-bar'>
                                <p className='txt---400-14-20 txt-not-active text-right'><FormattedMessage id="trade.depth-chart.ask" /></p>
                                <p className='headB---700-16-24 text-right'><FormattedVolume value={widthSell} />%</p>
                            </div>
                        </div>*/}

                        <div className="container-detail">
                            <div className="row gutters-5 txt---400-14-20">
                                <div className="col-6">
                                    <div className="buy">
                                        <div className="header text-right text-priceboard-green">
                                            <div className="row gutters-5 justify-content-between txt-active">
                                                <div style={{ marginLeft: "15px" }} >
                                                    <FormattedMessage id="trade.orderbook.volume-buy" />
                                                </div>
                                                <div style={{ marginRight: "5px" }} >
                                                    <FormattedMessage id="trade.orderbook.price-buy" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="body text-right">
                                            {orderbook.length > 0 && orderbook.map((order, index) => {
                                                return (
                                                    <DualOrderbookBuyRow instrument={instrument} key={index} order={order} onItemClick={onItemClick} totalBuy={totalBuy} totalSell={totalSell} />
                                                );
                                            })}
                                            {orderbook.length === 0 && this.emptyOrderbookArray.map((index) => {
                                                return (
                                                    <DualOrderbookBuyRow instrument={instrument} key={index} onItemClick={onItemClick} totalBuy={totalBuy} totalSell={totalSell} />
                                                );
                                            })}
                                        </div>

                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="sell">
                                        <div className="header text-priceboard-red">
                                            <div className="row gutters-5 justify-content-between txt-active">
                                                <div style={{ marginLeft: "5px" }}>
                                                    <FormattedMessage id="trade.orderbook.price-sell" />
                                                </div>
                                                <div style={{ marginRight: "15px" }}>
                                                    <FormattedMessage id="trade.orderbook.volume-sell" />
                                                </div>
                                                {/* <div className="col-4 text-right">
                                                <FormattedMessage id="trade.orderbook.total" />
                                            </div> */}
                                            </div>
                                        </div>

                                        <div className="body">
                                            {orderbook.length > 0 && orderbook.map((order, index) => {
                                                return (
                                                    <DualOrderbookSellRow instrument={instrument} key={index} order={order} onItemClick={onItemClick} totalSell={totalSell} />
                                                );
                                            })}
                                            {orderbook.length === 0 && this.emptyOrderbookArray.map((index) => {
                                                return (
                                                    <DualOrderbookSellRow instrument={instrument} key={index} onItemClick={onItemClick} totalBuy={totalBuy} />
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </CustomScrollbars >
            </div >
        );
    }
}

export default DualOrderbook;