import React, { Component } from 'react';
import _ from "lodash";
import { connect } from 'react-redux';

import * as actions from "../../../store/actions";
import {
    CommonOrderUtils, CommonWidgetUtils, ORDER_TYPE_USE, CommonUtils, derivativeExchanges, fullSubOrderTypes,
    ToastUtil, EXCHANGE
} from "../../../utils"
import config from '../../../combineConfig';


import './MarketInfoBlock.scss';
import { FormattedPrice, FormattedVolume } from 'components/Formating';
import { FormattedMessage } from 'react-intl';
import TextToolTip from "../../../components/TextToolTip";
import { emitter } from 'utils/EventEmitter';
import { inquiryService, symbolService } from 'services';
import moment from 'moment';
class MarketInfoBlock extends Component {

    initialState = {
        sumVolumnByStepPriceBuy: 0,
        sumVolumnByStepPriceSell: 0,
        prevVolume: 0 // KLGD kỳ trước
    }

    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState
        }
    }

    getPriceHistory = () => {
        const { curSymbolTrade, currentDate } = this.props;
        if (curSymbolTrade && curSymbolTrade.id) {
            // let toDate = currentDate ? currentDate * 1000 : new Date().getTime(); // Ngày DB nếu k có lấy ngày trên máy
            // let fromDate = moment(toDate).subtract(30, 'days').toDate().getTime();
            // let todayDate = moment(toDate).format(config.app.SERVER_DATE_FORMAT);
            // symbolService.getPriceHistory(curSymbolTrade.id, fromDate, toDate)
            //     .then(data => {
            //         if (data && data[0]) {
            //             let prevVolData = data[0];
            //             if (todayDate === data[0]['tradingdate']) {
            //                 prevVolData = data[1] ? data[1] : null
            //             }
            //             this._setState({ prevVolume: prevVolData ? prevVolData.volume : 0 });
            //         } else {
            //             this._setState({ prevVolume: 0 });
            //         }
            //     }).catch(err => {
            //         //log error
            //     })

            symbolService.getVolumnHistory(curSymbolTrade.id)
                .then(data => {
                    if (data) {
                        this._setState({ prevVolume: data.volume || 0 });
                    } else {
                        this._setState({ prevVolume: 0 });
                    }
                }).catch(err => {
                    //log error
                })
        } else {
            this._setState({ prevVolume: 0 });
        }
    }

    _setState = (obj, callback) => {
        if (this.mounted) {
            this.setState(obj, callback);
        }
    }

    componentWillUnmount() {
        this._setState({
            ...this.initialState
        })
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
        this.getPriceHistory();
        this.calSumOrderBook();
        this.fetchListIndvBond();
    }


    fetchListIndvBond = () => {
        const { curSymbolTrade, isLoggedIn } = this.props
        if (isLoggedIn && CommonUtils.getOrderTypeUseLayout() == ORDER_TYPE_USE.TPRL_NM_ORDER && curSymbolTrade && curSymbolTrade["exchange"] && curSymbolTrade["exchange"] == EXCHANGE.TPRL) {
            inquiryService.getListIndvBond(curSymbolTrade["id"])
                .then(data => {
                    if (data.length > 0) {
                        this._setState({ infoSymbolTPRL: data[0] })
                    }
                }).catch(error => {
                    ToastUtil.errorApi(error, 'common.fail-to-load-security-info');
                });
        }
    };

    componentDidUpdate(prevProps, prevState) {
        const { orderbook, curSymbolTrade } = this.props;
        const { orderbook: prevOrderbook, curSymbolTrade: prevCurSymbolTrade } = prevProps;
        if (orderbook != prevOrderbook) {
            this.calSumOrderBook();
        }

        if (!_.isEqual(curSymbolTrade, prevCurSymbolTrade)) {
            this.getPriceHistory();
            this.fetchListIndvBond();
        }
    }

    /**
     * Tính tổng KL theo bước giá
     */
    calSumOrderBook = () => {
        const { orderbook } = this.props;
        let sumVolumnByStepPriceBuy = 0;
        let sumVolumnByStepPriceSell = 0;
        if (orderbook && orderbook.length > 0) {
            orderbook.forEach(element => {
                sumVolumnByStepPriceBuy += Number(element.BQ) || 0;
                sumVolumnByStepPriceSell += Number(element.SQ) || 0;
            });
        }
        this._setState({ sumVolumnByStepPriceBuy: sumVolumnByStepPriceBuy, sumVolumnByStepPriceSell: sumVolumnByStepPriceSell })
    }

    onSetPrice = (price) => {
        const { updatePlaceQuickOrder, updateProTrade } = this.props;
        let orderInputNormal = {
            limitPrice: price,
            quotePriceValid: true,
            stepPriceValid: true,
            orderType: "limit",
            subOrderType: fullSubOrderTypes.LO,
            isValidMarketPrice: true,
        }


        let orderInputPro = {
            limitPrice: price,
            orderType: 'limit',
            subOrderType: fullSubOrderTypes.LO
        }

        let getInfoOrderLayoutCurrent = CommonOrderUtils.getInfoOrderLayoutCurrent()
        const { listOrderLayoutCurrent } = getInfoOrderLayoutCurrent
        if (listOrderLayoutCurrent && listOrderLayoutCurrent.length > 0) {
            if (listOrderLayoutCurrent.includes(ORDER_TYPE_USE.NORMAL_ORDER)) {
                updatePlaceQuickOrder(orderInputNormal, ORDER_TYPE_USE.NORMAL_ORDER)
            }
            if (listOrderLayoutCurrent.includes(ORDER_TYPE_USE.PRO_TRADE_ORDER)) {
                updateProTrade(orderInputPro)
            }
        } else {
            updatePlaceQuickOrder(orderInputNormal)
        }
        emitter.emit("FOCUS_ORDER_PRICE");
    }


    render() {
        const { curSymbolTrade, mode, instrument, quote, isLoggedIn } = this.props
        const { sumVolumnByStepPriceBuy, sumVolumnByStepPriceSell, infoSymbolTPRL } = this.state;
        return (
            <div className='market-info-block'>

                <div className='volume-info-container'>
                    <div className='volume-row row' >
                        <TextToolTip
                            tooltipText={<FormattedMessage id="trade.orderbook.total-best-top3-buy-price" />}
                            targetID='tooltip-total-buy-top3-volume'
                        ></TextToolTip>
                        <div id='tooltip-total-buy-top3-volume' className='col-4 txt-left txt---700-14-20'>{sumVolumnByStepPriceBuy ? <FormattedVolume value={sumVolumnByStepPriceBuy} shortForm={true} /> : '-'}</div>
                        <div className='col-4 txt-center label txt---400-14-20'><FormattedMessage id="trade.orderbook.volume-by-step-price" /></div>
                        <TextToolTip
                            tooltipText={<FormattedMessage id="trade.orderbook.total-best-top3-sell-price" />}
                            targetID='tooltip-total-sell-top3-volume'
                        ></TextToolTip>
                        <div id='tooltip-total-sell-top3-volume' className='col-4 txt-right txt---700-14-20'>{sumVolumnByStepPriceSell ? <FormattedVolume value={sumVolumnByStepPriceSell} shortForm={true} /> : '-'}</div>
                    </div>
                    <div className='volume-row row' >
                        <TextToolTip
                            tooltipText={<FormattedMessage id="trade.orderbook.buy-volume-total-tooltip" />}
                            targetID='buy-volume-total-tooltip'
                        ></TextToolTip>
                        <div id='buy-volume-total-tooltip' className='col-4 txt-left txt---700-14-20'>{instrument.TB ? <FormattedPrice value={instrument.TB} /> : '-'}</div>
                        <div className='col-4 txt-center label txt---400-14-20'><FormattedMessage id="trade.orderbook.all-market-volume" /></div>
                        <TextToolTip
                            tooltipText={<FormattedMessage id="trade.orderbook.sell-volume-total-tooltip" />}
                            targetID='sell-volume-total-tooltip'
                        ></TextToolTip>
                        <div id='sell-volume-total-tooltip' className='col-4 txt-right txt---700-14-20'>{instrument.TO ? <FormattedPrice value={instrument.TO} /> : '-'}</div>
                    </div>
                </div>

                <div className='market-symbol-info txt---400-14-20'>
                    <div className='info-market-col' >
                        <div className='row-market'>
                            <div className='label'><FormattedMessage id="trade.symbol-info.ceil" /></div>
                            <div className='text-ceil-price price-change' onClick={() => { this.onSetPrice(quote.ceiling) }}>
                                {
                                    quote ? (quote.exchange === derivativeExchanges[0] ? CommonUtils.roundToTwo(quote.ceiling, 1) : CommonUtils.roundToTwo(quote.ceiling, 1000)) : "-"
                                }
                            </div>
                        </div>
                        <div className='row-market'>
                            <div className='label'><FormattedMessage id="trade.symbol-info.floor" /></div>
                            <div className='text-floor-price price-change' onClick={() => { this.onSetPrice(quote.floor) }}>
                                {
                                    quote ? (quote.exchange === derivativeExchanges[0] ? CommonUtils.roundToTwo(quote.floor, 1) : CommonUtils.roundToTwo(quote.floor, 1000)) : "-"
                                }
                            </div>
                        </div>
                        <div className='row-market'>
                            <div className='label'><FormattedMessage id="trade.symbol-info.reference" /></div>
                            <div className='text-ref-price price-change' onClick={() => { this.onSetPrice(quote.reference) }}>
                                {
                                    quote ? (quote.exchange === derivativeExchanges[0] ? CommonUtils.roundToTwo(quote.reference, 1) : CommonUtils.roundToTwo(quote.reference, 1000)) : "-"
                                }
                            </div>
                        </div>
                        <div className='row-market'>
                            <div className='label'><FormattedMessage id="trade.orderbook.foreigh-buy" /></div>
                            <div className=''><FormattedVolume value={instrument.FB} /></div>
                        </div>
                        <div className='row-market'>
                            <div className='label'><FormattedMessage id="trade.orderbook.trade" /></div>
                            <div className=''><FormattedVolume value={instrument.TT} /></div>
                        </div>
                        <div className='row-market'>
                            <div className='label'><FormattedMessage id="trade.orderbook.open" /></div>
                            <div className=''><FormattedPrice value={instrument.OP} instrument={instrument} /></div>
                        </div>
                    </div>
                    <div className='info-market-col' >
                        <div className='row-market'>
                            <div className='label'><FormattedMessage id="trade.orderbook.high" /></div>
                            <div className=''><FormattedPrice value={instrument.HI} instrument={instrument} /></div>
                        </div>
                        <div className='row-market'>
                            <div className='label'><FormattedMessage id="trade.orderbook.low" /></div>
                            <div className=''><FormattedPrice value={instrument.LO} instrument={instrument} /></div>
                        </div>
                        <div className='row-market'>
                            <div className='label'><FormattedMessage id="trade.symbol-info.average-price" /></div>
                            <div className=''><FormattedPrice value={instrument.AP} instrument={instrument} /></div>
                        </div>
                        <div className='row-market'>
                            <div className='label'><FormattedMessage id="trade.orderbook.foreigh-sell" /></div>
                            <div className=''><FormattedVolume value={instrument.FS} /></div>
                        </div>
                        <div className='row-market'>
                            <div className='label'><FormattedMessage id="trade.orderbook.last-vol" /></div>
                            <div className=''>{this.state.prevVolume ? <FormattedVolume value={this.state.prevVolume} /> : ''}</div>
                        </div>
                    </div>
                </div>

                {isLoggedIn && CommonUtils.getOrderTypeUseLayout() == ORDER_TYPE_USE.TPRL_NM_ORDER &&
                    <div className="market-info-tprl">
                        <div className="line"></div>
                        <div className="info-detail txt---400-14-20">
                            <div className="row-item">
                                <div className="lable text-near-active">
                                    {/* Ngày phát hành */}
                                    <FormattedMessage id='trade.corpbond-detail-modal.realease-date' />
                                </div>
                                <div className="value text-active">
                                    {infoSymbolTPRL ? CommonUtils.convertDateByType(infoSymbolTPRL.issuedate, "date", "/") : "-"}
                                </div>
                            </div>
                            <div className="row-item">
                                <div className="lable text-near-active">
                                    {/* Ngày đáo hạn */}
                                    <FormattedMessage id='trade.corpbond-detail-modal.duedate' />
                                </div>
                                <div className="value text-active">
                                    {infoSymbolTPRL ? CommonUtils.convertDateByType(infoSymbolTPRL.expdate, "date", "/") : "-"}
                                </div>
                            </div>
                            <div className="row-item">
                                <div className="lable text-near-active">
                                    {/* Mệnh giá */}
                                    <FormattedMessage id='trade.corpbond-detail-modal.unit' />
                                </div>
                                <div className="value text-active">
                                    {infoSymbolTPRL ? <FormattedPrice value={infoSymbolTPRL.parvalue} /> : "-"}
                                </div>
                            </div>
                            <div className="row-item">
                                <div className="lable text-near-active">
                                    {/* Lãi suất */}
                                    <FormattedMessage id="trade.corpbond-detail-modal.interest-rate2" />
                                </div>
                                <div className="value text-active">
                                    {infoSymbolTPRL ? infoSymbolTPRL.intcoupon : "-"}
                                </div>
                            </div>
                            <div className="row-item">
                                <div className="lable text-near-active">
                                    {/* Loại tài khoản đặt lệnh */}
                                    <FormattedMessage id="trade.corpbond-detail-modal.type-account" />
                                </div>
                                <div className="value text-active">
                                    ?
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    let quote = null
    let _currentLayoutPageActive = null
    _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
    let _curSymbolTrade = _layoutPage && _layoutPage["symbols"] && _layoutPage["symbols"]["curSymbolTrade"]
    quote = _curSymbolTrade && state.symbol.quotes[_curSymbolTrade["id"]]
    let _accountInfo = _layoutPage && _layoutPage["accountInfo"]
    const instrument = _curSymbolTrade ? state.symbol.instruments[_curSymbolTrade.id] || {} : {};
    const orderbook = _curSymbolTrade ? state.symbol.orderbooks[_curSymbolTrade.id] || [] : [];

    return {
        config: state.user.userInfo.config,
        curSymbolTrade: _curSymbolTrade,
        quote,
        language: state.app.language,
        role: state.user.userInfo.role,
        accountInfo: _accountInfo,
        instrument: instrument,
        orderbook: orderbook,
        isLoggedIn: state.user.isLoggedIn,
        socketSEAction: state.socketRealtime.socketSEAction,
        socketCIAction: state.socketRealtime.socketCIAction,
        currentDate: state.app.currentDate,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchSymbolOrderbook: (symbolId) => dispatch(actions.fetchSymbolOrderbook(symbolId)),
        updatePlaceQuickOrder: (data, typeOrder) => dispatch(actions.updatePlaceQuickOrder(data, typeOrder)),
        updateProTrade: (data, typeOrder) => dispatch(actions.updateProTrade(data, typeOrder)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(MarketInfoBlock);
