import React, { Component } from 'react';
import { connect } from 'react-redux';

import { FormattedUtcDateTime } from "../../../components/Formating";
import { userService } from '../../../services';
import {
    AiOutlineClockCircle
} from "react-icons/ai";

import './Timer.scss'

const DEFAULT_TIME_REFERENCE = 'HOSE';
const TIMER_INTERVAL = 1000;

const UPDATE_TIME_INTERVAL = 5 * 60 * 1000;

class Timer extends Component {

    runningInterval = null;
    updateTimeInterval = null;

    state = {
        time: null,
        diff: 0
    };

    fetchTime = () => {
        userService.getTime().then(time => {
            const newTime = time && time.currentTimeDb;
            const currentTime = new Date();
            this._setState({
                time: newTime,
                diff: newTime ? currentTime.getTime() - newTime : 0
            });
        }).catch(error => {
        });
    };

    updateTime = () => {
        const { diff } = this.state;
        const currentTime = new Date();
        this._setState({
            time: new Date(currentTime.getTime() - diff).getTime()
        });

    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    componentDidMount() {
        this.mounted = true
        this.fetchTime();
        if (this.runningInterval) {
            clearInterval(this.runningInterval);
        };
        if (this.updateTimeInterval) {
            clearInterval(this.updateTimeInterval);
        };

        this.runningInterval = setInterval(() => {
            this.updateTime();
        }, TIMER_INTERVAL);

        this.updateTimeInterval = setInterval(() => {
            this.fetchTime();
        }, UPDATE_TIME_INTERVAL);
    }

    componentWillUnmount() {
        if (this.runningInterval) {
            clearInterval(this.runningInterval);
        }
        if (this.updateTimeInterval) {
            clearInterval(this.updateTimeInterval);
        }
        this.mounted = false
    }

    render() {
        const { className, type, format } = this.props;
        const { time } = this.state;
        return (
            <div className={"timerContainer " + className}>
                {(!type || type !== 'TIME') && <span className="icon-clock item-center">
                    <AiOutlineClockCircle />
                </span>}
                <div className="time">
                    <FormattedUtcDateTime value={time} format={format} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {}
};

export default connect(mapStateToProps, null)(Timer);


