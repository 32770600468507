import React, { Component } from 'react';
import { push } from "connected-react-router";
import _ from "lodash";
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import * as actions from "../../../../store/actions";


import { inquiryService, transferService, userService } from "services";
import { CommonUtils, LoadStatus, ModalConfirmType, Role, ToastUtil } from "utils";
import { ReactComponent as IconPlus } from 'assets/icons_new/icon-plus-dark.svg';
import { ReactComponent as ArrowIconDark } from 'assets/icons/settingicons/arrow-icon.svg'
import { ReactComponent as ArrowIconLight } from 'assets/icons/settingicons/arrow-icon-light.svg'
import { ReactComponent as IconInfoActive } from 'assets/icons_new/icon_info_active.svg'
import RetryableContent from 'components/RetryableContent';
import BankModal from './BankModal';
import './AdditionBank.scss';
import TextTooltip from 'components/TextToolTip';


export const MODAL_BANK_TYPE = {
    VIEW: "VIEW",
    ADD: "ADD"
}

class AdditionBank extends Component {

    constructor(props) {
        super(props);
        this.transAuthRef = React.createRef();
    }

    initialState = {
        currentAccount: {},
        records: [],
        dataFill: {},

        typeModal: MODAL_BANK_TYPE.VIEW,
        isOpenModalBank: false,

        isOpenModalAddBank: false,
        bankList: [],
        rawBankList: [],
        bankBranchList: [],
        bankBranchListRaw: [],

        bankId: '',
        customerBankNumber: '',
        customerBankName: this.props.fullName,
        bankbranch: '',

        transactionId: '',
        tokenid: '',
        authtype: '',

        loadStatusCheckBank: LoadStatus.NONE,
        loadStatus: LoadStatus.NONE,
        maxRegisterBank: '',
        invalidBankBranch: true, //valid chi nhánh
        invalidBank: true, //valid ngân hàng
    };

    state = {
        ...this.initialState
    };

    loadAllData = () => {
        this.loadBankRegistered();
        this.loadBankList();
        this.loadMaxRegisterBank();
    }


    isBroker = () => {
        const { role } = this.props;
        return role === Role.BROKER;
    };

    loadMaxRegisterBank = () => {
        userService.getSysvar("MARGIN", "MAXCFOTHERACC", "BASE")
            .then(responses => {
                if (responses && responses.length > 0) {
                    this._setState({
                        maxRegisterBank: responses[0].varvalue
                    });
                }
            }).catch(error => {
                ToastUtil.errorApi(error, 'common.fail-to-load-sysvar');
            })
    }
    loadBankRegistered = async () => {
        let { currentAccount } = this.state;
        const { userInfo, curCustomer } = this.props;
        this._setState({ loadStatus: LoadStatus.LOADING });
        let custid = userInfo.custid;
        if (this.isBroker()) {
            custid = curCustomer.custid || '';
        }
        await inquiryService.getBeneficiaryList(custid)
            .then((data) => {
                this._setState({
                    records: data,
                    loadStatus: LoadStatus.LOADED
                });
            })
            .catch(error => {
                this._setState({ loadStatus: LoadStatus.FAIL });
                ToastUtil.errorApi(error, 'common.fail-to-load-bank-registered');
            });
    }

    loadBankList = async () => {
        let { currentAccount } = this.state;
        // this._setState({ loadStatus: LoadStatus.LOADING });
        await inquiryService.getBankList()
            .then((data) => {

                let dataConvert = []
                if (data && data.length > 0) {
                    dataConvert = data.map(item => {
                        return item.bankname
                    })
                }

                this._setState({
                    bankList: dataConvert,
                    rawBankList: data,
                    // loadStatus: LoadStatus.LOADED
                });
            })
            .catch(error => {
                // this._setState({ loadStatus: LoadStatus.FAIL });
                ToastUtil.errorApi(error, 'common.fail-toload-bank-list');
            });
    }

    loadBankBranch = async (bankId) => {
        let { currentAccount } = this.state;
        // this._setState({ loadStatus: LoadStatus.LOADING });
        await inquiryService.getBankBranch(bankId)
            .then((data) => {
                let dataConvert = []
                if (data && data.length > 0) {
                    dataConvert = data.map(item => {
                        return item.branchname
                    })
                }
                this._setState({
                    bankBranchList: dataConvert,
                    bankBranchListRaw: data || []
                })

            })
            .catch(error => {
                // this._setState({ loadStatus: LoadStatus.FAIL });
                ToastUtil.errorApi(error, 'common.fail-toload-bank-list');
            });
    }

    getBankNo = (branchName) => {
        const { bankBranchListRaw } = this.state;
        const branchInfo = bankBranchListRaw.find(item => item.branchname === branchName);
        return branchInfo && branchInfo.branchno ? branchInfo.branchno : null
    }
    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }
	
	componentWillUnmount() {
        this.mounted = false
    }
	
    componentDidMount() {
        this.mounted = true
        const { accounts, currentAccountId } = this.props;

        let filterAccount = accounts.find(item => item.id === currentAccountId)

        this._setState({ currentAccount: filterAccount },
            () => {
                // this.loadAllData();
            }
        );
    }

    componentDidUpdate(prevProps, prevState) {
        const { socketCIAction, socketSEAction, socketODAction } = this.props;
        const { currentAccount, bankId } = this.state;
        const { currentAccount: prevcurrentAccount, bankId: prevBankId
        } = prevState;
        const {
            socketCIAction: prevSocketCIAction, socketSEAction: prevSocketSEAction,
            socketODAction: prevSocketODAction
        } = prevProps;


        if (currentAccount !== undefined && (socketCIAction[currentAccount.id] !== prevSocketCIAction[currentAccount.id] || socketSEAction[currentAccount.id] !== prevSocketSEAction[currentAccount.id] || socketODAction[currentAccount.id] !== prevSocketODAction[currentAccount.id])) {
            this.loadBankRegistered()
        }

        if (currentAccount !== prevcurrentAccount) {
            this.loadAllData();
        }

        if (bankId !== prevBankId) { 
            this._setState({bankbranch: ''})
        }
    }

    handleViewDetail = (data) => {
        this._setState({ typeModal: MODAL_BANK_TYPE.VIEW, dataFill: data, isOpenModalBank: true })
    }

    handleCloseModalBank = () => {
        this._setState({ dataFill: {}, isOpenModalBank: false })
    }

    handleCancel = () => {
        this._setState({
            isOpenModalAddBank: false,
            bankId: '',
            //customerBankName: '',
            bankbranch: '',
            customerBankNumber: '',
            transactionId: '',
            tokenid: '',
            authtype: '',
        })
    }

    // Xóa tk thụ hưởng
    handleDelete = async (data) => {
        const { dataFill } = this.state
        // validate
        const body = {
            "autoIds": dataFill.autoid,
        }

        this._setState({ loadStatusCheckBank: LoadStatus.LOADING })
        await transferService.checkRemoveBeneficiary(body)
            .then((data) => {
                if (Object.keys(data).length > 0) {
                    const { transactionId, tokenid, authtype, authtypes, challengeQuestion, verified } = data;
                    this.props.updateInfoCheckAuth({
                        transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
                    })

                    this._setState({ loadStatusCheckBank: LoadStatus.LOADED, isOpenModalAddBank: false }, () => {
                        let _dataInfo = this.getDataInfoToggleRemovebank()
                        this.props.updateConfirmCheckAuth({
                            typeInfo: ModalConfirmType.BANK_REMOVE,
                            dataInfo: _dataInfo,
                        })

                        this.props.setIsOpenModalHaveData("ScreenConfirm", {
                            isOpenScreenConfirm: true,
                            isCheckAuth2: true,
                        })
                    });
                }
                else {
                    this._setState({ loadStatusCheckBank: LoadStatus.LOADED })
                    ToastUtil.error('common.fail-to-precheck-transactions');
                }

            })
            .catch((error) => {
                this._setState({ loadStatusCheckBank: LoadStatus.FAIL });
                ToastUtil.errorApi(error, 'common.bad-request');
            });
    }


    handleConfirm = async () => {
        const { bankId, customerBankNumber, customerBankName, bankbranch, invalidBankBranch } = this.state

        if (customerBankName === '') {
            ToastUtil.error('settings.personal.bank-addition.empty-name');
            return
        }

        if (customerBankNumber === '') {
            ToastUtil.error('settings.personal.bank-addition.empty-number');
            return
        }

        if (bankId === '') {
            ToastUtil.error('settings.personal.bank-addition.empty-bank');
            return
        }

        if (bankbranch === '') {
            ToastUtil.error('settings.personal.bank-addition.empty-bank-branch');
            return
        }

        if (invalidBankBranch == true) {
            ToastUtil.error('settings.personal.bank-addition.invalid-bank-branch');
            return
        }

        // validate
        const body = {
            "bankAccount": customerBankNumber,
            "benifitName": customerBankName,
            "bankCode": this.findBankCode(bankId),
            "citybank": bankbranch,
            "branchid": bankbranch ? this.getBankNo(bankbranch) : ''
        }

        this._setState({ loadStatusCheckBank: LoadStatus.LOADING })
        await transferService.checkRegisterAccTransfer(body)
            .then((data) => {
                if (Object.keys(data).length > 0) {
                    const { transactionId, tokenid, authtype, authtypes, challengeQuestion, verified } = data;
                    this.props.updateInfoCheckAuth({
                        transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
                    })

                    this._setState({ loadStatusCheckBank: LoadStatus.LOADED, isOpenModalAddBank: false }, () => {
                        let _dataInfo = this.getDataInfoToggleBankAddition()
                        this.props.updateConfirmCheckAuth({
                            typeInfo: ModalConfirmType.BANK_ADDITION,
                            dataInfo: _dataInfo,
                        })

                        this.props.setIsOpenModalHaveData("ScreenConfirm", {
                            isOpenScreenConfirm: true,
                            isCheckAuth2: true,
                        })
                    });
                }
                else {
                    this._setState({ loadStatusCheckBank: LoadStatus.LOADED })
                    ToastUtil.error('common.fail-to-precheck-transactions');
                }

            })
            .catch((error) => {
                this._setState({ loadStatusCheckBank: LoadStatus.FAIL });
                ToastUtil.errorApi(error, 'common.bad-request');
            });
    }

    clearInforAddBank = () => {
        this._setState({
            bankId: '',
            bankbranch: '',
            customerBankNumber: '',
        })
    }
    handleOpenModalAddBank = () => {
        if (this.isDisableBtnAdd()) return;
        this.clearInforAddBank();
        this._setState({ typeModal: MODAL_BANK_TYPE.ADD, isOpenModalAddBank: true });
    }

    handleCloseModalAddBank = () => {
        this._setState({ isOpenModalAddBank: false })
    }

    onChangeBankList = (bank) => {
        this._setState({ bankId: bank });
        const bankId = this.findBankCode(bank);
        if (bankId) this.loadBankBranch(bankId);

    };
    onChangeCustomerBankNumber = (e) => {
        this._setState({ customerBankNumber: e.target.value })
    }
    onChangeCustomerBankName = (e) => {
        this._setState({ customerBankName: e.target.value })
    }

    onChangeBankBranch = (branch, valid) => {
        this._setState({ bankbranch: branch })
        if (valid) this._setState({ invalidBankBranch: false })
        else this._setState({ invalidBankBranch: true })
    }

    handleOnlyOpenModal = () => {
        //close and NOT reset data
        this.props.setIsOpenModalHaveData("ScreenConfirm", {
            isOpenScreenConfirm: false,
        })

        this._setState({
            isOpenModalAddBank: true,
        })
    }

    handleCloseModalTransAuthRemoveBank = () => {
        //close and NOT reset data
        this.props.setIsOpenModalHaveData("ScreenConfirm", {
            isOpenScreenConfirm: false,
        })

        this._setState({
            isOpenModalBank: true
        })
    }

    processTransfer = () => {
        this.transAuthRef.current.processAuth();
    };

    findBankCode = (bankId) => {
        const { rawBankList } = this.state
        const bankInfo = rawBankList.find(item => item.bankname === bankId)
        return bankInfo && bankInfo.bankid ? bankInfo.bankid : null
    }

    genInfoProcessToggleBankAddition = () => {
        const { bankId, customerBankName, customerBankNumber, bankbranch } = this.state
        let processAuthFunc = null;
        let body = null;
        processAuthFunc = transferService.processRegisterAccTransfer;

        body = {
            "bankAccount": customerBankNumber,
            "benifitName": customerBankName,
            "bankCode": this.findBankCode(bankId),
            "citybank": bankbranch,
            "branchid": bankbranch ? this.getBankNo(bankbranch) : ''
        };

        return {
            processAuthFunc,
            body,
        }
    }

    genInfoProcessRemoveBank = () => {
        const { dataFill, customerBankNumber, customerBankName } = this.state
        let processAuthFunc = null;
        let body = null;
        processAuthFunc = transferService.processRemoveBeneficiary;

        body = {
            "autoIds": dataFill.autoid,
            "bankAccount": customerBankNumber,
            "benifitName": customerBankName,
        };

        return {
            processAuthFunc,
            body,
        }
    }

    getDataInfoToggleBankAddition = () => {
        const { bankId, customerBankName, customerBankNumber, bankbranch } = this.state;
        const { language } = this.props
        let _info = null
        let { processAuthFunc, body } = this.genInfoProcessToggleBankAddition()
        _info = {
            bankId,
            customerBankName,
            customerBankNumber,
            language,
            citybank: bankbranch,
            initFuncData: body,
            authRef: this.transAuthRef,
            processTransfer: () => { this.processTransfer() },
            processAuthFunc: processAuthFunc,
            backStep: () => { this.handleOnlyOpenModal() },
            // refresh: () => { this.refresh() },
            transactionCb: () => { this.transactionCbToggleBankAddition() },
            title: "settings.personal.title-confirm-modal",
            showOrderSwitch: false // Tạm thời truyền vào, sau sẽ lấy từ precheck trả về trường savef2
        }
        return _info || {}
    }

    getDataInfoToggleRemovebank = () => {
        const { dataFill } = this.state;
        const { language } = this.props
        let _info = null
        let { processAuthFunc, body } = this.genInfoProcessRemoveBank()
        _info = {
            dataFill,
            initFuncData: body,
            authRef: this.transAuthRef,
            processTransfer: () => { this.processTransfer() },
            processAuthFunc: processAuthFunc,
            backStep: () => { this.handleCloseModalTransAuthRemoveBank() },
            // refresh: () => { this.refresh() },
            transactionCb: () => { this.transactionCbToggleBankRemove() },
            title: "settings.personal.title-confirm-modal",
            showOrderSwitch: false // Tạm thời truyền vào, sau sẽ lấy từ precheck trả về trường savef2
        }
        return _info || {}
    }

    transactionCbToggleBankAddition = () => {
        this._setState({
            bankId: '',
            customerBankNumber: '',
            bankbranch: '',
            transactionId: '',
            tokenid: '',
            authtype: '',
        }, () => this.loadBankRegistered())
        ToastUtil.success('account.utilities.cash-transfer.success', 'payment.register-acc-success');
        this.props.setIsOpenModalHaveData("ScreenConfirm", {
            isOpenScreenConfirm: false,
            isCheckAuth2: true,
        })
    }

    transactionCbToggleBankRemove = () => {
        this._setState({
            bankId: '',
            customerBankNumber: '',
            bankbranch: '',
            transactionId: '',
            tokenid: '',
            authtype: '',
        }, () => this.loadBankRegistered())
        this.handleCloseModalBank()
        ToastUtil.success('account.utilities.cash-transfer.success', 'payment.remove-acc-success');
        this.props.setIsOpenModalHaveData("ScreenConfirm", {
            isOpenScreenConfirm: false,
            isCheckAuth2: true,
        })
    }

    isDisableBtnAdd = () => {
        const { records, maxRegisterBank } = this.state;
        if (records && records.length >= parseInt(maxRegisterBank)) return true;
        return false;
    }
    render() {
        const isBroker = this.isBroker()
        const { records, dataFill, isOpenModalBank, isOpenModalAddBank, typeModal,
            bankList, bankId, customerBankNumber, customerBankName,
            loadStatusCheckBank, loadStatus, bankbranch, maxRegisterBank, bankBranchList
        } = this.state
        const { accounts, language } = this.props
        let classBtnAdd = "";
        if (this.isDisableBtnAdd()) classBtnAdd = "disabled-btn-add"
        return (
            <RetryableContent status={loadStatus} disableRetry={true} className="retry-content-addition-bank">
                <div className='addition-bank'>
                    <div className="addition-bank_header">
                        <div className="title headB---700-16-24">
                            <FormattedMessage id={'settings.personal.bank-addition.title'} />
                            <IconInfoActive style={{ cursor: "pointer", marginLeft: "5px" }} id={'toolTipNoteMaxRegisterBank'} />
                            <TextTooltip
                                tooltipText={<FormattedMessage id="settings.personal.bank-addition.note-add-register" values={{ value: maxRegisterBank }} />}
                                targetID='toolTipNoteMaxRegisterBank'
                            />
                        </div>
                        <div className={"btn-add " + classBtnAdd} onClick={() => this.handleOpenModalAddBank()}>
                            <IconPlus disabled={true} style={{ cursor: "pointer", marginLeft: "5px" }} id={'toolTipAddBank'} />
                            <TextTooltip
                                tooltipText={<FormattedMessage id="settings.personal.bank-addition.add-bank-new" />}
                                targetID='toolTipAddBank'
                            />
                        </div>
                    </div>
                    <div className="addition-bank_body">


                        {records && records.length !== 0 && records.map(item => {
                            return (
                                <div className={(!_.isEmpty(dataFill) && dataFill.autoid === item.autoid) ? "bank-item active" : "bank-item"} onClick={() => this.handleViewDetail(item)}>
                                    <div className="bank-item-body">
                                        <div className="info">
                                            <div className="bank-name txt---400-14-20">
                                                {item.bankacc} - {item.bankacname}
                                            </div>
                                            <div className="bank-number txt---400-14-20 text-near-active">
                                                {item.bankname} - {item.citybank}
                                            </div>

                                        </div>

                                        <div className="icon">
                                            {CommonUtils.renderIconTheme(<ArrowIconDark />, <ArrowIconLight />)}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}


                    </div>

                    {isOpenModalBank && <BankModal
                        isOpen={isOpenModalBank}
                        onClose={this.handleCloseModalBank}
                        data={dataFill}
                        handleDelete={this.handleDelete}
                        typeModal={typeModal}
                    />}

                    {isOpenModalAddBank && <BankModal
                        isOpen={isOpenModalAddBank}
                        onClose={this.handleCancel}

                        handleCancel={this.handleCancel}
                        handleDelete={this.handleDelete}
                        handleConfirm={this.handleConfirm}
                        typeModal={typeModal}

                        bankList={bankList}
                        bankId={bankId}
                        customerBankNumber={customerBankNumber}
                        customerBankName={customerBankName}
                        bankbranch={bankbranch}
                        onChangeBankList={this.onChangeBankList}
                        onChangeCustomerBankNumber={this.onChangeCustomerBankNumber}
                        onChangeBankBranch={this.onChangeBankBranch}
                        bankBranchList={bankBranchList}
                        // onChangeCustomerBankName={this.onChangeCustomerBankName}
                        loadStatus={loadStatusCheckBank}
                    />}
                </div>
            </RetryableContent>
        )
    }

}

const mapStateToProps = state => {
    const userInfo = state.user.userInfo;
    const permissionInfo = userInfo.permissionInfo ? userInfo.permissionInfo : null;

    let _accountInfo = state.layout.listIsOpenModal["C&B"]["ScreenModal"].accountInfo
    let { accounts, currentAccountId, curCustomer } = { ..._accountInfo }
    const filterAccount = accounts && accounts.filter(item => item.accounttype !== "FDS");
    return {
        currentCustomer: state.customer.currentCustomer,
        userInfo: userInfo,
        fullName: userInfo.fullname,
        language: state.app.language,
        role: state.user.userInfo.role,
        accounts: filterAccount,

        socketSEAction: state.socketRealtime.socketSEAction,
        socketCIAction: state.socketRealtime.socketCIAction,
        socketODAction: state.socketRealtime.socketODAction,

        permissionInfo: permissionInfo,
        currentAccountId: currentAccountId,
        curCustomer: curCustomer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        refetchDefaultConfig: () => dispatch(actions.fetchUpdateUserDefaultConfig()),
        navigate: (path) => dispatch(push(path)),
        setConfirmModalProps: (isOpen, messageId, acceptHandle, cbProps) => dispatch(actions.setConfirmModalProps(isOpen, messageId, acceptHandle, cbProps)),
        updateInfoCheckAuth: (data, key) => dispatch(actions.updateInfoCheckAuth(data, key)),
        updateConfirmCheckAuth: (data) => dispatch(actions.updateConfirmCheckAuth(data)),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdditionBank);

