import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as socket from "../../../socket";
import { CommonUtils, Random } from "../../../utils";
import { TYPE_ACCOUNT } from './../../../utils/constants';
class BlockInfoSymbolMarket extends Component {

    callerId = Random.randomComponentId();

    subscribleToTopic = (symbolId) => {
        if (!symbolId) {
            return;
        }

        socket.registerInstrumentTopic(symbolId, this.callerId);
    };

    componentDidMount() {
        const { symbolId } = this.props;
        this.subscribleToTopic(symbolId);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { symbolId } = this.props;
        if (symbolId && symbolId !== prevProps.symbolId) {
            this.subscribleToTopic(symbolId);
        }
    }

    componentWillUnmount() {
        socket.unregisterCallerId(this.callerId);
    }

    render() {
        const { quoteInfo, typeAccount, isIndex } = this.props;
        let isFormatCP = typeAccount === TYPE_ACCOUNT.FDS || isIndex;
        return (
            <div className="info-symbol">
                <div className="custom-row">
                    {/* <div className="label txt---500-14-20 txt-active">{quoteInfo && quoteInfo["Id"]} - {quoteInfo && quoteInfo["EX"]}</div> */}
                    {
                        quoteInfo["Id"] && <div className="label txt---500-14-20 text-active">
                            {quoteInfo["Id"]}
                            {typeAccount === TYPE_ACCOUNT.BASE && <span className="text-near-active">
                                {" | "}
                                {quoteInfo["EX"]}
                            </span>}
                        </div>
                    }
                    <div className="value txt---500-14-20 text-active">
                        {isFormatCP && quoteInfo && CommonUtils.roundToTwo(quoteInfo["CP"], 1)}
                        {!isFormatCP && quoteInfo && CommonUtils.roundToTwoHaveZero(quoteInfo["CP"], 1000)}
                    </div>
                </div>
                <div className="custom-row">
                    <div className="label txt---400-12-18 txt-not-active text-left">{quoteInfo && quoteInfo["FN"]}</div>
                    <div className="value txt---400-12-18">
                        <span className={"" + (CommonUtils.getClassCheckValue(quoteInfo["CH"]))}>
                            {quoteInfo["CH"] > 0 && "+"}
                            {typeAccount === TYPE_ACCOUNT.FDS && CommonUtils.roundToTwo(quoteInfo["CH"], 1)}&nbsp;
                            {typeAccount === TYPE_ACCOUNT.BASE && CommonUtils.roundToTwo(quoteInfo["CH"], 1000)}&nbsp;
                        </span>
                        {quoteInfo["CHP"] &&
                            <span className={"" + (CommonUtils.getClassCheckValue(quoteInfo["CHP"]))}>
                                (
                                {quoteInfo["CHP"] > 0 && "+"}
                                {CommonUtils.roundToTwoHaveZero(quoteInfo["CHP"], 1)}
                                %)
                            </span>
                        }
                    </div>
                </div>
            </div>

        );
    }
}

const mapStateToProps = (state, props) => {
    const { symbolId } = props
    return {

    };
};

const mapDispatchToProps = dispatch => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockInfoSymbolMarket);