import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

import CustomScrollbars from "../../../components/CustomScrollbars";
import {
    FormattedPrice,
    FormattedVolume,
    getDiffColorClassName
} from "../../../components/Formating";
import ValueChangeAnimation from "../../../components/ValueChangeAnimation";
import { CommonUtils, JsxUtils, orderActions } from "../../../utils";

import './SingleOrderbook.scss';

const getPercentage = (volume) => {
    return _.round(volume / 1000, 2) * 100;
};

class SingleOrderbookSellRow extends Component {

    shouldComponentUpdate(nextProps) {
        const props = this.props;
        const order = props.order;
        const nextOrder = nextProps.order;

        return props.onItemClick !== nextProps.onItemClick
            || !CommonUtils.isSameSellOrder(order, nextOrder);
    }

    render() {
        const { order, onItemClick } = this.props;
        const percent = order ? getPercentage(order.SQ) : 0;
        return (
            <div className="row gutters-5" onClick={() => { onItemClick(order, orderActions.SELL) }}>
                <div className="slider" style={{ width: percent + '%' }} />
                <ValueChangeAnimation tag="div" value={order ? order.SP : null} className="col text-priceboard-red">
                    <FormattedPrice value={order ? order.SP : null} shortForm={true} />
                </ValueChangeAnimation>
                <div className="col-auto">
                    <ValueChangeAnimation tag="div" value={order ? order.SQ : null} className="vol-container">
                        <FormattedVolume value={order ? order.SQ : null} shortForm={true} />
                    </ValueChangeAnimation>
                </div>
                <div className="col text-right">
                    <FormattedVolume value={order ? order.CSV : null} shortForm={true} />
                </div>
            </div>
        );
    }
}

const SingleOrderbookSellRows = (props) => {
    const { numberOfRows, orderbook, onItemClick } = props;
    const results = [];
    const diff = numberOfRows - (orderbook ? orderbook.length : 0);
    for (let i = 0; i < numberOfRows; i++) {
        if (i > diff - 1 && orderbook) {
            const index = orderbook.length - 1 - (i - diff);
            const order = orderbook[index];
            results.push(
                <SingleOrderbookSellRow key={order.id} order={order} onItemClick={onItemClick} />
            );
        } else {
            results.push(
                <SingleOrderbookSellRow key={i} onItemClick={onItemClick} />
            );
        }
    }
    return (
        <Fragment>
            {results}
        </Fragment>
    );
};

class SingleOrderbookBuyRow extends Component {

    shouldComponentUpdate(nextProps) {
        const props = this.props;
        const order = props.order;
        const nextOrder = nextProps.order;

        return props.onItemClick !== nextProps.onItemClick
            || !CommonUtils.isSameBuyOrder(order, nextOrder);
    }

    render() {
        const { order, onItemClick } = this.props;
        const percent = order ? getPercentage(order.BQ) : 0;
        return (
            <div className="row gutters-5" onClick={() => { onItemClick(order, orderActions.BUY) }}>
                <div className="slider" style={{ width: percent + '%' }} />
                <ValueChangeAnimation tag="div" value={order ? order.BP : null} className="col text-priceboard-green">
                    <FormattedPrice value={order ? order.BP : null} shortForm={true} />
                </ValueChangeAnimation>
                <div className="col-auto">
                    <ValueChangeAnimation tag="div" value={order ? order.BQ : null} className="vol-container">
                        <FormattedVolume value={order ? order.BQ : null} shortForm={true} />
                    </ValueChangeAnimation>
                </div>
                <div className="col text-right">
                    <FormattedVolume value={order ? order.CBV : null} shortForm={true} />
                </div>
            </div>
        );
    }
}

const SingleOrderbookBuyRows = (props) => {
    const { numberOfRows, orderbook, onItemClick } = props;
    const results = [];
    for (let i = 0; i < numberOfRows; i++) {
        if (orderbook && orderbook.length > i) {
            const order = orderbook[i];
            results.push(
                <SingleOrderbookBuyRow key={order.id} order={order} onItemClick={onItemClick} />
            );
        } else {
            results.push(
                <SingleOrderbookBuyRow key={i} onItemClick={onItemClick} />
            );
        }
    }
    return (
        <Fragment>
            {results}
        </Fragment>
    );
};

const SingleOrderbookMatchedPriceRow = (props) => {
    const { instrument } = props;
    const change = instrument.CH || 0;
    const colorClassName = getDiffColorClassName(change);
    const iconClassName = change > 0 ? 'fa-arrow-up' : (change < 0 ? 'fa-arrow-down' : '');
    return (
        <div className="row gutters-5">
            <div className={JsxUtils.joinClass('col text-right', colorClassName)}>
                {!instrument.CP ? '-' : (<FormattedPrice value={instrument.CP} shortForm={true} />)}
            </div>
            <div className={JsxUtils.joinClass('col-auto', colorClassName)}>
                {!instrument.CP ? '-' : (<i className={JsxUtils.joinClass('fas', iconClassName)} />)}
            </div>
            <div className="col">
                {!instrument.CP ? '-' : (<FormattedVolume value={instrument.TT} shortForm={true} />)}
            </div>
        </div>
    );
};

class SingleOrderbook extends Component {

    render() {
        const { orderbook, instrument, contentHeight, onItemClick } = this.props;
        // Exclude height for header and center row
        const excludeHeight = 20 + 28;
        const avalableHeight = contentHeight - excludeHeight;
        const rowHeight = 20;
        let numberOfRows = Math.floor(avalableHeight / (2 * rowHeight));
        if (numberOfRows < 1) {
            numberOfRows = 1;
        } else if (numberOfRows > 10) {
            numberOfRows = 10;
        }

        return (
            <div className="single-orderbook">
                <CustomScrollbars>
                    <div className="table-container">
                        <div className="header">
                            <div className="row gutters-5">
                                <div className="col">
                                    <FormattedMessage id="trade.orderbook.price" />
                                </div>
                                <div className="col-auto">
                                    <div className="vol-container">
                                        <FormattedMessage id="trade.orderbook.volume" />
                                    </div>
                                </div>
                                <div className="col text-right">
                                    <FormattedMessage id="trade.orderbook.total" />
                                </div>
                            </div>
                        </div>

                        <div className="sell">
                            <SingleOrderbookSellRows orderbook={orderbook} numberOfRows={numberOfRows} onItemClick={onItemClick} />
                        </div>

                        <div className="matched-price">
                            <SingleOrderbookMatchedPriceRow instrument={instrument} />
                        </div>

                        <div className="buy">
                            <SingleOrderbookBuyRows orderbook={orderbook} numberOfRows={numberOfRows} onItemClick={onItemClick} />
                        </div>
                    </div>
                </CustomScrollbars>
            </div>
        );
    }
}

export default SingleOrderbook;