import React, { Component } from 'react';
import RetryableContent from 'components/RetryableContent';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalBody, } from 'reactstrap';
import { ReactComponent as ConfirmIcon_Dark } from '../../../assets/icons/screenmodal/ConfirmIcon_Dark.svg'
import { ReactComponent as WarningIcon_Dark } from '../../../assets/icons/screenmodal/WarningIcon_Dark.svg'

import "./AdvanceSaleModal.scss"
import { LoadStatus } from 'utils';

class AdvanceSaleModal extends Component {
    constructor(props) {
        super(props);
        this.TransAuthRef = React.createRef();
    };

    initialState = {
        loadStatus: LoadStatus.NONE,
    };


    state = {
        ...this.initialState
    };

    convertStatus = (status) => {
        if (status === "Y") return true
        return false
    }


    render() {
        const {
            isOpen, toggle, loadStatus, checkTransferInfo, handleChangeAdvance, handleOffAdvance, item
        } = this.props;
        const check = this.convertStatus(item.autoadvance)
        return (
            <Modal
                isOpen={isOpen}
                centered={true}
                className="advance-sale-modal"
            >
                <RetryableContent status={loadStatus} disableRetry={true}>
                    <ModalBody className="confirm-end">
                        <div className='headB---700-20-30 title'>
                            {check ? <WarningIcon_Dark /> : <ConfirmIcon_Dark />}
                        </div>
                        <div className='txt---400-16-24 content'>
                            {check ? <FormattedMessage id="service-register.noti-warning" /> : <FormattedMessage id="service-register.noti-success" />}
                        </div>
                        {/* Thao tác */}
                        <div className="row gutters-5 align-items-center h-row container-btn">
                            {/* Làm mới */}
                            <div className="col-6">
                                <button className="btn-refresh txt---500-16-24 btn"
                                    onClick={() => toggle()}
                                >
                                    <FormattedMessage id="common.btn-cancel" />
                                </button>
                            </div>
                            {/* Xác nhận */}
                            <div className="col-6">
                                <button className="btn-confirm txt---500-16-24 btn"
                                    onClick={() => handleChangeAdvance()}
                                >
                                    <FormattedMessage id="common.btn-confirm" />
                                </button>
                            </div>
                        </div>
                    </ModalBody>
                </RetryableContent >
            </Modal >

        )
    };
};

export default AdvanceSaleModal;