import React, { Component } from 'react';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { connect } from 'react-redux';
// import { emitter, emitter2 } from 'utils/EventEmitter';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { FormattedMessage } from 'react-intl';
import RetryableContent from "../../../../components/RetryableContent";
// import * as socket from '../../../../socket';
import { LoadStatus, ToastUtil } from '../../../../utils';
import { marketAnalysisService } from "../../../../services";
import _ from 'lodash';

import './ChartMarketbreadth.scss'
// import FormattedVolume from './../../../../components/Formating/FormattedVolume';

const df_ConfigChartByExchange = {
	"HOSE": {
		labels: ['=<-5%', '=<-2%', '<0%', '0%', '>0%', '>=2%', '>=5%'],
		datasets: [
			{
				// label: '=<-5%',
				// backgroundColor: "#F9B525",
				// borderColor: "#F9B525",
				// borderWidth: 1,
				// stack: 1,
				// hoverBackgroundColor: "#F9B525",
				// hoverBorderColor: "#F9B525",
				// backgroundColor: ["#F93E3E", "#F93E3E", "#F93E3E", "#F9B525", "#21DB77", "#21DB77", "#21DB77"]
				backgroundColor: "#EC932F",
				borderColor: "rgba(255,99,132,1)",
				borderWidth: 1,
				hoverBackgroundColor: "rgba(255,99,132,0.4)",
				hoverBorderColor: "rgba(255,99,132,1)",
			}
		]
	},
	"HNX": {
		labels: ['=<-7%', '=<-3%', '<0%', '0%', '>0%', '>=3%', '>=7%'],
		datasets: []
	},
	"UPCOM": {
		labels: ['=<-10%', '=<-5%', '<0%', '0%', '>0%', '>=5%', '>=10%'],
		datasets: []
	},
}


const options = {
	maintainAspectRatio: false,
	title: {
		display: false,
		// position: "top",
		// text: "Random Multicolor Bar Graph",
		// fontSize: 18,
		// fontColor: "#111"
	},
	plugins: {
		datalabels: {
			display: true,
			color: ["#F93E3E", "#F93E3E", "#F93E3E", "#F9B525", "#21DB77", "#21DB77", "#21DB77"],
			formatter: Math.round,
			anchor: "end",
			offset: -20,
			align: "start",
		},
	},
	responsive: true,
	legend: {
		display: false,
	},
	type: 'bar',
	scales: {
		y: {
			display: false,
			// ticks: {
			// 	padding: 20,
			// 	crossAlign: 'start'
			// }
			beginAtZero: true,
			//grace: '20%' // Tăng khoảng cách column với top
			grace: '100' // Tăng khoảng cách column với top
		},
		x: {
			ticks: {
				color: '#fff',
			}
		}
	}
}

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	// Legend,
	ChartDataLabels
);


const listOptionExchange = [
	{
		"title": "HSX",
		"value": "HOSE"
	},
	{
		"title": "HNX",
		"value": "HNX"
	},
	{
		"title": "UPCOM",
		"value": "UPCOM"
	},
]

class ChartMarketbreadth extends Component {

	initialState = {
		loadStatus: LoadStatus.NONE,
		exchangeSelected: "HOSE",
		records: {},
		dataChart: null,
		optionChart: {}
	};

	constructor(props) {
		super(props);
		this.state = {
			...this.initialState
		}
	};

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

	componentDidMount() {
		this.mounted = true
		this.loadData();
	}

	componentWillUnmount() {
        this._setState({
            ...this.initialState
        })
		this.mounted = false
	}

	loadData = () => {
		const { exchangeSelected, records } = this.state;
		this._setState({ loadStatus: LoadStatus.LOADING });
		marketAnalysisService.getWidthMarket(exchangeSelected)
			.then((data) => {
				if (data) {
					this._setState({ records: data[0] },
						() => {
							this.setDataChart()
						});
				}
				this._setState({ loadStatus: LoadStatus.LOADED });
			})
			.catch((error) => {
				this._setState({ loadStatus: LoadStatus.FAIL });
				ToastUtil.errorApi(error, 'common.fails-to-load-market-info-detail');
			});
	}

	setDataChart = () => {
		const { records, exchangeSelected } = this.state

		let objDatasets = {
			backgroundColor: ["#F93E3E", "#F93E3E", "#F93E3E", "#F9B525", "#21DB77", "#21DB77", "#21DB77"],
		}

		let configChart = df_ConfigChartByExchange[exchangeSelected]
		if (configChart) {
			objDatasets["data"] = Object.values(records)

			configChart.datasets[0] = objDatasets
			this._setState({ dataChart: configChart });
		}
	}


	onChangeExchange = (value) => {
		this._setState({ exchangeSelected: value }, () => {
			this.loadData();
		})
	}


	calculateData = () => {
		const { records } = this.state;
		let totalValue = 0, totalDecrease = 0, totalReference = 0, totalIncrease = 0, widthDecrease = 0, widthReference = 0, widthIncrease = 0
		//console.log("binh_check_calculateData_1", Object.values(records).length)
		if (records && Object.values(records).length > 0) {
			let arrValue = Object.values(records)
			totalValue = _.sum(arrValue) || 0;

			totalDecrease = _.sum(arrValue.slice(0, 3))
			totalReference = _.sum(arrValue.slice(3, 4))
			totalIncrease = _.sum(arrValue.slice(4, 7))
			// //console.log("binh_check_calculateData_2", _.sum(arrValue.slice(0, 2)))
			widthDecrease = (totalDecrease / totalValue) * 100
			widthReference = (totalReference / totalValue) * 100
			widthIncrease = (totalIncrease / totalValue) * 100
			//console.log("binh_check_calculateData_2", arrValue.slice(0, 2), _.sum(arrValue.slice(0, 2)))
		}
		else {
			widthDecrease = Math.floor((100 / 3))
			widthReference = Math.floor((100 / 3))
			widthIncrease = Math.floor((100 / 3))
		}
		//console.log("binh_check_calculateData_result", totalValue, widthDecrease, widthReference, widthIncrease)

		let isGap = true
		if (widthDecrease === 100 || widthReference === 100 || widthIncrease === 100) {
			isGap = false
		}

		return {
			totalValue, totalDecrease, totalReference, totalIncrease, widthDecrease, widthReference, widthIncrease, isGap
		}
	}


	render() {
		const { exchangeSelected, records, dataChart } = this.state
		//console.log("binh_check_chart", this.state)
		const { totalValue, totalDecrease, totalReference, totalIncrease, widthDecrease, widthReference, widthIncrease, isGap } = this.calculateData()

		return (
			<RetryableContent className="market-breadth" status={this.state.loadStatus} disableRetry={true}>
				<div className="market-breadth-content">
					<div class="action-container">
						<div class="list-select-exchange">
							{listOptionExchange.map((item, index) => {
								return <div tabIndex="0" className={"select-exchange-type txt---400-14-20 " + (item.value === exchangeSelected ? "bg-primary text-custom" : "")}
									onClick={() => { this.onChangeExchange(item.value) }}>
									<FormattedMessage id={item.title} />
								</div>
							})}
						</div>
					</div>
					<div className="chart-market-breadth-content" style={{ height: 'calc(100% - 91px)' }} >
						{dataChart && <Bar options={options} plugins={[ChartDataLabels]} data={dataChart} />}
					</div>
					<div className="container-total">
						<div className={'total-bar style-bar '}>
							{widthDecrease ?
								<div className='total-bar-item bg-red-custom'
									style={{ width: widthDecrease + "%" }}
								>
								</div>
								: ""
							}
							{widthReference ?
								<div className='total-bar-item bg-yellow'
									style={{ width: widthReference + "%" }}
								>
								</div>
								: ""
							}
							{widthIncrease ?
								<div className='total-bar-item bg-green-custom'
									style={{ width: widthIncrease + "%" }}
								>
								</div>
								: ""
							}
						</div>

						<div class="progress-desc">
							<div class="total-decline-desc text-red-custom">
								<FormattedMessage id={"market-watch.reduce"} />: {totalDecrease}
							</div>
							<div class="total-advance-desc text-green-custom">
								<FormattedMessage id={"market-watch.increase"} /> : {totalIncrease}
							</div>
						</div>

					</div>
				</div>
			</RetryableContent >
		);
	}
};


const mapStateToProps = (state, ownProps) => {
	return {
		lang: state.app.language,
	};
};
export default connect(mapStateToProps)(ChartMarketbreadth);