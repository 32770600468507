import React, { Component } from 'react';
import { connect } from 'react-redux';

import { TVChartContainer } from '../../TVChartContainer/index';
import { CommonUtils, CommonWidgetUtils, Random } from './../../../utils';
import './PriceChart.scss';
class PriceChart extends Component {
    callerId = Random.randomComponentId();
    render() {
        const { isCheckSymbolDataFeed, currentSymbol, accountInfo, currentLayoutPageActive } = this.props;
        const { currentAccountId } = { ...accountInfo }
        let currentData = this.props.marketIndexInfo ? { ...this.props.marketIndexInfo, id: this.props.marketIndexInfo['floorCode'] ? this.props.marketIndexInfo['floorCode'] : '' } : (this.props.currentSymbol || null)
        //console.log("binh_currentData", currentData, currentSymbol, isCheckSymbolDataFeed)
        if (Object.keys(currentData) && Object.keys(currentData).length < 1) {
            currentData = null
        }
        return (
            <div className="price-chart">
                <TVChartContainer
                    callerId={this.callerId}
                    currentSymbol={currentData ? currentData : null}
                    currentAccount={this.props.currentAccount}
                    language={this.props.language}
                    defaultTheme={this.props.defaultTheme}
                    fullscreen={this.props.fullscreen}
                    path={this.props.path}
                    isLoggedIn={this.props.isLoggedIn}
                    currentLayoutPageActive={currentLayoutPageActive}
                    isCheckSymbolDataFeed={isCheckSymbolDataFeed}
                />
            </div>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    const defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme

    let _currentLayoutPageActive = null
    _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
    let _accountInfo = _layoutPage && _layoutPage["accountInfo"];

    return {
        accountInfo: _accountInfo,
        currentAccount: state.account.currentAccount,
        language: CommonUtils.getLanguageAvailable(state.app.language),
        defaultTheme,
        isLoggedIn: state.user.isLoggedIn,
        currentLayoutPageActive: _currentLayoutPageActive,
        isCheckSymbolDataFeed: state.app.isCheckSymbolDataFeed
    }
};
export default connect(mapStateToProps, null)(PriceChart);