import React, { Component, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { CommonUtils } from "../utils";
import HeaderLogoDark from '../assets/images/headerLogo_dark.png';

import './ReloadBrowser.scss';

class ReloadBrowser extends Component {

    initialState = {
    };

    state = {
        ...this.initialState
    };

    constructor(props) {
        super(props);
    }

    updateVersion = () => {
        window.location.reload(true);
    };

    tryUpdate = () => {
        this.props.checkVersion()
    };

    skipUpdateVersion = () => {
        this.props.closePopup()
    };

    render() {
        const { isOpen, type } = this.props;
        let title, content = ""
        if (type == "updated") {
            title = "common.version-changed-title"
            content = "common.version-changed-content"
        } else {
            if (type == "waitting_update") {
                title = "common.waitting_update-version-title"
                content = "common.waitting_update-version-content"
            }
        }
        return (

            <Modal
                isOpen={isOpen}
                centered={true}
                className="modal-check-version"
            >
                <ModalBody className={"confirm-body " + (type === 'waitting_update' ? 'waiting' : '')}>
                    {type == "updated" &&
                        <Fragment>
                            <header>
                                {/* <FormattedMessage id="common.version-changed-title" /> */}
                                <button onClick={this.skipUpdateVersion}>
                                    <i className="far fa-times" />
                                </button>
                            </header>
                            <img style={{ height: '80px' }} src={CommonUtils.renderIconTheme(HeaderLogoDark, HeaderLogoDark)} alt="LOGO" />
                            <div className='headB---700-16-24 title'>
                                <FormattedMessage id={title} />
                            </div>
                            <div className='txt---400-16-24 content'>
                                <FormattedMessage id={content} />
                            </div>


                            <div className="row gutters-5 align-items-center h-row container-btn">
                                <div className="col-6">
                                    <button className="btn-refresh txt---500-16-24 btn bg-btn-submit bg-primary text-custom"
                                        onClick={this.updateVersion}
                                    >
                                        <FormattedMessage id="common.btn-update-version" />
                                    </button>
                                </div>
                                <div className="col-6">
                                    <button className="btn-refresh txt---500-16-24 btn text-custom pointer"
                                        onClick={this.skipUpdateVersion}
                                    >
                                        <FormattedMessage id="common.btn-skip-version" />
                                    </button>
                                </div>
                            </div>
                        </Fragment>
                    }
                    {/*type == "waitting_update" &&
                            <div className="row gutters-5 align-items-center h-row container-btn">
                                <div className="col-12">
                                    <div className="col-6 center">
                                        <button className="btn-refresh txt---500-16-24 btn bg-btn-submit bg-primary text-custom"
                                            onClick={this.tryUpdate}
                                        >
                                            <FormattedMessage id="common.btn-reload-system" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        */}

                    {type == "waitting_update" &&
                        <iframe src={window.location} title={'Content of tooltip '} /> // Dùng window.location do trang chính đã trả lại html nâng cấp
                    }

                </ModalBody>
            </Modal >
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
    };
};
const mapStateToProps = state => {
    return {
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(ReloadBrowser);
