import React, { Component } from 'react';
import { ReactComponent as ArrowIconDark } from 'assets/icons/settingicons/arrow-icon.svg'
import { ReactComponent as ArrowIconLight } from 'assets/icons/settingicons/arrow-icon-light.svg'
import { ReactComponent as OrderIcon_Dark } from 'assets/icons/security/OrderIcon_Dark.svg'
import { ReactComponent as PasswordIcon_Dark } from 'assets/icons/security/PasswordIcon_Dark.svg'
import { ReactComponent as PasswordBrokerIcon_Dark } from 'assets/icons/security/PasswordBrokerIcon_Dark.svg'
import { ReactComponent as PhoneCodeIcon_Dark } from 'assets/icons/security/PhoneCodeIcon_Dark.svg'
import { ReactComponent as SignUpIcon_Dark } from 'assets/icons/security/SignUpIcon_Dark.svg'
import { ReactComponent as OrderIcon_Light } from 'assets/icons/security/OrderIcon_Light.svg'
import { ReactComponent as SignUpIcon_Light } from 'assets/icons/security/SignUpIcon_Light.svg'

import RetryableContent from 'components/RetryableContent';
import { push } from "connected-react-router";
import { connect } from 'react-redux';
import { AuthenMethod, AuthenTypes, CommonUtils, KeyCodeUtils, LoadStatus, ModalConfirmType, Random, Role, ToastUtil, TradeAuthType } from "utils";
import { transferService, userService } from 'services';
import { IS_SHOW_POPUP_LOGIN, emitter } from 'utils/EventEmitter';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as actions from "../../store/actions";
import './Security.scss';

export const TYPE = {
    SIGN_UP_2_FACTOR_AUTH: '1',
    CHANGE_PASSWORD_LOGIN: '2',
    CHANGE_PASSWORD_ORDER: '3',
    CHANGE_PHONE_CODE: '4',
    // TOKEN_LIFE_TIME: '5',
};

const TAB = {
    CHANGE_PASSWORD_ORDER: 1,
    FORGET_PASSWORD_ORDER: 2,
}

const MAX_PASS_LENGTH = 20

const PHONE_CODE_LENGTH = 4

class Security extends Component {

    authTypes = AuthenTypes;
    tradeAuthType = TradeAuthType

    constructor(props) {
        super(props);
        this.TransAuthExternalRef = React.createRef();
        this.ForgetPasswordRef = React.createRef();
        this.ConfigLifetimeRef = React.createRef();
        this.changePasswordRef = React.createRef();
        this.cancelAuthBtnRef = React.createRef();
        this.confirmAuthBtnRef = React.createRef();
        this.resetBtnChangePassRef = React.createRef();
        this.confirmBtnChangePassRef = React.createRef();
        this.resetBtnForgetPassRef = React.createRef();
        this.confirmBtnForgetPassRef = React.createRef();
    }

    initialState = {
        type: this.props.role === Role.BROKER ? TYPE.CHANGE_PASSWORD_LOGIN : TYPE.SIGN_UP_2_FACTOR_AUTH,
        authType: '',
        authMethod: "",
        rawAuthMethod: "",

        // pwtType: '',
        oldPassword: '',
        password: '',
        reWritePassword: '',
        passwordLength: 8,
        pinLength: 6,

        showRawOldPassword: false,
        showPassword: false,
        showReWritePassword: false,


        tokenid: '',
        transactionId: '',
        authtype: '',

        dataUserinfo: null,

        activeTab: TAB.CHANGE_PASSWORD_ORDER,
        isLoadingCaptcha: false,
        base64captcha: '',

        captcha: '',
        captchaTransactionId: '',
        captchaAuthtype: '',

        tokenLifetime: '',
        listTokenLifeTimeConfig: [],

        activePhoneCodeTab: TAB.CHANGE_PASSWORD_ORDER,
        isUsePhoneTrade: false
    };

    state = {
        ...this.initialState
    };


    preCheckRegisterSmsOtp = async () => {
        const { authType, rawAuthMethod } = this.state

        if (authType === AuthenMethod.SMARTOTP || rawAuthMethod === AuthenMethod.SMARTOTP) {
            ToastUtil.error('CM3', 'sign-up-factor-auth.noti-1');
            return
        }

        const body = {
            toauthtype: authType
        }

        await transferService.preCheckRegisterSmsOtp(body)
            .then((data) => {
                if (Object.keys(data).length > 0) {
                    const { transactionId, tokenid, authtype, authtypes, challengeQuestion, verified } = data;
                    //console.log("data--- 1: ", data)
                    this.props.updateInfoCheckAuth({
                        transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
                    })

                    this._setState({ loadStatus: LoadStatus.LOADED }, () => {
                        let _dataInfo = this.getDataProcess()
                        this.props.updateConfirmCheckAuth({
                            typeInfo: ModalConfirmType.SIGN_UP_2_FACTOR_AUTH,
                            dataInfo: _dataInfo,
                        })

                        this.props.setIsOpenModalHaveData("ScreenConfirm", {
                            isOpenScreenConfirm: true,
                            isCheckAuth2: true,
                        })
                    });
                }

            })
            .catch((error) => {
                this._setState({ loadStatus: LoadStatus.FAIL });
                ToastUtil.errorApi(error, 'common.bad-request');
            });
    }

    handleSubmit = async () => {
        const { type, oldPassword, password, reWritePassword } = this.state

        let msgSuccess = ''
        let msgError = ''
        let msgValid = ''

        if (type === TYPE.CHANGE_PASSWORD_LOGIN) {
            msgSuccess = 'settings.personal.change-password.success'
            msgError = 'settings.personal.change-password.fail'
            msgValid = 'settings.personal.change-password.retype-password-not-match'
        } else if (type === TYPE.CHANGE_PASSWORD_ORDER) {
            msgSuccess = 'settings.personal.change-password.success'
            msgError = 'settings.personal.change-password.fail'
            msgValid = 'settings.personal.change-pin.retype-pin-not-match'
        } else if (type === TYPE.CHANGE_PHONE_CODE) {
            msgSuccess = 'settings.personal.change-phone-code.success'
            msgError = 'settings.personal.change-phone-code.fail'
            msgValid = 'settings.personal.change-phone-code.retype-phone-code-not-match'
        }



        let disableSubmit = !(oldPassword && password && reWritePassword)
        if (disableSubmit) {
            ToastUtil.error('settings.personal.change-passwords.empty');
            return
        }

        if (oldPassword === password) {
            if (type === TYPE.CHANGE_PASSWORD_LOGIN) {
                ToastUtil.error('settings.personal.exits');
                return
            }
            if (type === TYPE.CHANGE_PASSWORD_ORDER) {
                ToastUtil.error('settings.personal.exits-place-order');
                return
            }
            if (type === TYPE.CHANGE_PHONE_CODE) {
                ToastUtil.error('settings.personal.exits-phone-code');
                return
            }
        }

        if (password !== reWritePassword) {
            ToastUtil.error(msgValid);
            return
        }

        if (type === TYPE.CHANGE_PHONE_CODE) {
            if (((password && password.length !== PHONE_CODE_LENGTH) || reWritePassword && reWritePassword.length !== PHONE_CODE_LENGTH)) {
                let msg = this.props.intl.formatMessage({ id: 'security.phone-code-invalid' })
                try {
                    if (msg && msg.length !== 0) {
                        msg = msg.replace('{x}', PHONE_CODE_LENGTH);
                    }
                } catch (error) {
                }
                ToastUtil.errorRaw(msg);
                return
            }
        }

        //validate
        let pwtType = ""
        if (type === TYPE.CHANGE_PASSWORD_LOGIN) {
            pwtType = "LOGINPWD"
        }
        if (type === TYPE.CHANGE_PASSWORD_ORDER) {
            pwtType = "TRADINGPWD"
        }
        if (type === TYPE.CHANGE_PHONE_CODE) {
            pwtType = "PHONEPWD"
        }
        const data = {
            pwtType: pwtType,
            oldPassword,
            password
        }

        transferService.checkChangePassword(data)
            .then(data => {
                const { transactionId, tokenid, authtype, authtypes, challengeQuestion, verified } = data;
                this.props.updateInfoCheckAuth({
                    transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
                })

                this._setState({ loadStatus: LoadStatus.LOADED }, () => {
                    let _dataInfo = this.getDataChangePassword()
                    this.props.updateConfirmCheckAuth({
                        typeInfo: ModalConfirmType.CHANGE_PASSWORD,
                        dataInfo: _dataInfo,
                    })

                    this.props.setIsOpenModalHaveData("ScreenConfirm", {
                        isOpenScreenConfirm: true,
                        isCheckAuth2: true,
                    })

                });
            }).catch(error => {
                this._setState({ loadStatus: LoadStatus.FAIL });
                ToastUtil.errorApi(error, 'common.bad-request');
            })



    }

    getDataChangePassword = () => {
        const { type, oldPassword, password, reWritePassword } = this.state;
        const isBroker = this.isBroker();
        let msgSuccess = ''
        let titleId = ''

        if (type === TYPE.CHANGE_PASSWORD_LOGIN) {
            msgSuccess = 'settings.personal.change-password.success';
            titleId = isBroker ? 'security.login-password-broker' : 'security.change-login-password';
        } else if (type === TYPE.CHANGE_PASSWORD_ORDER) {
            msgSuccess = 'settings.personal.change-password.success';
            titleId = 'security.order-password';
        } else if (type === TYPE.CHANGE_PHONE_CODE) {
            msgSuccess = 'settings.personal.change-phone-code.success'
            titleId = 'security.phone-code';
        }


        let pwtType = ""
        if (type === TYPE.CHANGE_PASSWORD_LOGIN) {
            pwtType = "LOGINPWD"
        }
        if (type === TYPE.CHANGE_PASSWORD_ORDER) {
            pwtType = "TRADINGPWD"
        }
        if (type === TYPE.CHANGE_PHONE_CODE) {
            pwtType = "PHONEPWD"
        }
        const data = {
            pwtType: pwtType,
            oldPassword,
            password
        }

        let _info = null

        let processAuthFunc = transferService.processChangePassword;

        _info = {
            initFuncData: data,
            authRef: this.changePasswordRef,
            processTransfer: () => { this.processChangePassword() },
            processAuthFunc: processAuthFunc,
            transactionCb: () => { this.changePasswordCb(msgSuccess) },
            title: titleId,
            showOrderSwitch: false // Tạm thời truyền vào, sau sẽ lấy từ precheck trả về trường savef2
        }
        return _info || {}
    }

    processChangePassword = () => {
        this.changePasswordRef.current.processAuth();
    };

    changePasswordCb = (msgSuccess) => {
        // ToastUtil.success('common.success', 'security.forget-pass-success');
        this.cb(msgSuccess);
        this.props.setIsOpenModalHaveData("ScreenConfirm", {
            isOpenScreenConfirm: false,
            isCheckAuth2: true,
        })
    }

    handleReset = () => {
        this._setState({
            oldPassword: '',
            password: '',
            reWritePassword: '',
            showRawOldPassword: false,
            showPassword: false,
            showReWritePassword: false,

            captcha: '',
        })
    }

    processTransfer = () => {
        this.TransAuthExternalRef.current.processAuth();
    };

    genInfo = () => {
        let processAuthFunc = null;
        let body = null;
        processAuthFunc = transferService.registerSMSOtp;
        body = {
            toauthtype: this.state.authType
        };
        return {
            processAuthFunc,
            body,
        }
    }

    getDataProcess = () => {
        let _info = null
        let { processAuthFunc, body } = this.genInfo()
        const { rawAuthMethod, authType } = this.state
        _info = {
            dataFill: {
                current: this.returnMethodAuth(rawAuthMethod),
                new: this.returnMethodAuth(authType),
            },
            initFuncData: body,
            authRef: this.TransAuthExternalRef,
            processTransfer: () => { this.processTransfer() },
            processAuthFunc: processAuthFunc,
            transactionCb: () => { this.transactionCb() },
            title: "settings.personal.title-modal",
            showOrderSwitch: false // Tạm thời truyền vào, sau sẽ lấy từ precheck trả về trường savef2
        }
        return _info || {}
    }

    getCustodycd = () => {
        const { userInfo } = this.props;
        let custodycd = '';
        if (userInfo.role === Role.CUSTOMER) {
            custodycd = userInfo.custodycd;
        } if (userInfo.role === Role.BROKER) {
            custodycd = userInfo ? userInfo.username : '';
        };
        return custodycd;
    }

    isBroker = () => {
        const { role } = this.props;
        return role === Role.BROKER;
    };

    changeLoginAccountHandler = () => {
        let custodycd = this.getCustodycd();
        this.props.logoutSingleUser(custodycd, true);
        emitter.emit(IS_SHOW_POPUP_LOGIN, custodycd)
        // Thay đổi mã logout để các tab tài khoản đang đăng nhập bắt được tín hiệu để logout trên tab
        let logoutCusCode = custodycd + "|" + Random.randomLogoutId();
        localStorage.setItem('LogoutCustodycd', logoutCusCode);
    }

    onReloginTokenExpired = () => {
        let savedCustodycd = sessionStorage.getItem('activeCustID');
        this.props.setLoginPopupOnstart(true, "");
        this.props.logoutSingleUser(savedCustodycd, true);
        let logoutCusCode = savedCustodycd + "|" + Random.randomLogoutId();
        localStorage.setItem('LogoutCustodycd', logoutCusCode);
    }

    transactionCb = () => {
        const { authType } = this.state
        const title = authType === AuthenMethod.PIN ? 'security.success-auth-sms2pin' : 'security.success-auth'
        ToastUtil.success('common.success', title);
        this.props.setIsOpenModalHaveData("ScreenConfirm", {
            isOpenScreenConfirm: false,
            isCheckAuth2: true,
        })
        this.loadInfo()
        this.props.loadUserInfo()
        // const { authType, type } = this.state

        // if (type === TYPE.SIGN_UP_2_FACTOR_AUTH) {
        //     if (authType === TradeAuthType.PIN) {
        //         this.props.loadUserInfo()
        //     } else {
        //         this.onReloginTokenExpired()
        //     }
        // }
    }

    onCancel = () => {
        this.props.setIsOpenModalHaveData("ScreenModal", {
            isOpenScreenModal: false,
            curScreenModalActive: ""
        })
    }

    loadInfo = async () => {
        await userService.getUserInfo()
            .then((data) => {
                if (data) {
                    let result = data.userinfo.authtype
                    let method = this.returnMethodAuth(result)

                    const defaultSelection = this.authTypes.filter(item => item !== result).sort((a, b) => (a > b) ? 1 : -1)[0]

                    this._setState({ authMethod: method, rawAuthMethod: result, dataUserinfo: data.userinfo, authType: defaultSelection });
                }
            })
            .catch((error) => {
                ToastUtil.errorApi(error, 'common.fail-to-load-account-info');
            });
    }

    loadSysVar = async () => {
        await Promise.all([
            userService.getSysvar('SYSTEM', 'USERONLINEPASSLENGTH', 'BASE'),
            userService.getSysvar('SYSTEM', 'USERONLINEPINLENGTH', 'BASE'),
        ]
        ).then((data) => {
            const [dataPass, dataPin] = data
            if (data && data.length !== 0) {
                let resultPassLength = parseInt(dataPass[0].varvalue)
                let resultPinLength = parseInt(dataPin[0].varvalue)
                this._setState({
                    passwordLength: resultPassLength,
                    pinLength: resultPinLength,
                });
            }
        })
            .catch((error) => {
                ToastUtil.errorApi(error, 'common.fail-to-load-sysvar');
            });
    }

    loadListTokenLifeTimeConfig = async () => {
        await userService.getListExpireTimeToken()
            .then((data) => {
                //console.log('ha_check_data', data);
                if (data && data.length !== 0) {
                    this._setState({
                        listTokenLifeTimeConfig: data,
                    });
                }
            })
            .catch((error) => {
                ToastUtil.errorApi(error, 'common.fail-to-load-token-life');
            });
    }

    changeStateLoadCaptcha = (flag) => {
        this._setState({
            isLoadingCaptcha: flag
        })
    }

    reloadCaptcha = async () => {
        this.changeStateLoadCaptcha(true)
        // const { userInfo } = this.props
        await userService.genCaptchaWithAuth()
            .then((data) => {
                if (data && data.hasOwnProperty('transactionId')) {
                    this._setState({
                        captcha: '',
                        base64captcha: data.img,
                        captchaTransactionId: data.transactionId,
                        captchaAuthtype: data.authtype,
                        isLoadingCaptcha: false,
                    });
                }
            })
            .catch((error) => {
                this.changeStateLoadCaptcha(false)
                ToastUtil.errorApi(error, 'common.fail-to-load-captcha');
            });
    }

    returnMethodAuth = (auth) => {
        switch (auth) {
            case this.tradeAuthType.PIN:
                return "PIN"
            case this.tradeAuthType.TOKEN:
                return "TOKEN"
            case this.tradeAuthType.SMARTOTP:
                return "SmartOTP"
            case this.tradeAuthType.MATRIX:
                return "MATRIX"
            case this.tradeAuthType.CTS:
                return "CTS"
            case this.tradeAuthType.OTP:
                return "SMS-OTP"
            default:
                break;
        }
    }


    onAuthTypeChange = (event) => {
        this._setState({
            authType: event.target.value
        });
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
            this.setState(obj, callback);
        }
    }

    componentWillUnmount() {
        this.mounted = false
    }

    componentDidMount() {
        const { userInfo } = this.props;
        this.mounted = true
        this.loadInfo()
        this.loadSysVar()
        const tab = localStorage.getItem('TAB_ENABLE_CLICK')
        if (tab) {
            if (Object.values(TYPE).includes(tab)) {
                this.handleChangeScreen(tab)
                localStorage.removeItem('TAB_ENABLE_CLICK')
            }

        }
        this.setState({ isUsePhoneTrade: userInfo.tradetelephone && userInfo.tradetelephone === 'Y' }) // Có dùng đặt lệnh trên mobile không ?
    }

    componentDidUpdate(prevProps, prevState) {
        const { type } = this.state
        if (type !== prevState.type && (type === TYPE.CHANGE_PASSWORD_ORDER || type === TYPE.CHANGE_PHONE_CODE)) {
            this.reloadCaptcha()
        }

        // if (type !== prevState.type && type === TYPE.TOKEN_LIFE_TIME) {
        //     this.loadListTokenLifeTimeConfig()
        // }
    }

    handleChangeScreen = (type) => {
        this._setState({
            type: type,
            oldPassword: '',
            password: '',
            reWritePassword: '',
            showRawOldPassword: false,
            showPassword: false,
            showReWritePassword: false,
        })
    }

    toggleOldPassword = () => {
        this._setState({
            showRawOldPassword: !this.state.showRawOldPassword
        })
    }

    handleChangeOldPassword = (e) => {
        this._setState({
            oldPassword: e.target.value
        })
    }

    togglePassword = () => {
        this._setState({
            showPassword: !this.state.showPassword
        })
    }
    handleChangePassword = (e) => {
        this._setState({
            password: e.target.value
        })
    }

    toggleReWritePassword = () => {
        this._setState({
            showReWritePassword: !this.state.showReWritePassword
        })
    }
    handleChangeReWritePassword = (e) => {
        this._setState({
            reWritePassword: e.target.value
        })
    }

    cb = (msgSuccess) => {
        this._setState({
            oldPassword: '',
            password: '',
            reWritePassword: ''
        })
        ToastUtil.success(msgSuccess);

        const { type } = this.state

        if (type !== TYPE.CHANGE_PHONE_CODE && type !== TYPE.CHANGE_PASSWORD_ORDER) {
            this.changeLoginAccountHandler()
        }
    }

    handlerKeyDownBtnCancelFactorAuth = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            event.preventDefault();
            if (!this.cancelAuthBtnRef.current || this.cancelAuthBtnRef.current.disabled) return;
            this.cancelAuthBtnRef.current.click();
        }
    };

    handlerKeyDownBtnConfirmFactorAuth = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            event.preventDefault();
            if (!this.confirmAuthBtnRef.current || this.confirmAuthBtnRef.current.disabled) return;
            this.confirmAuthBtnRef.current.click();
        }
    };

    renderSignUp2FactorAuth = () => {
        const { authMethod, authType, rawAuthMethod } = this.state
        return (
            <div className="container h-100">
                <div className='txt---500-16-24 text-secondary-1'>
                    <FormattedMessage id="security.authentication-method" />
                </div>
                <div className="row gutters-5 align-items-center h-row">
                    <div className="col-5">
                        <label className="label txt---400-14-20">
                            <FormattedMessage id="sign-up-factor-auth.current-authentication-method" />
                        </label>
                    </div>
                    <div className="col-7">
                        <div className="custom-form-group custom-form-group-flex-center text-secondary-1 txt---400-14-20">
                            {authMethod}
                        </div>
                    </div>
                </div>
                <div className="row gutters-5 align-items-center h-row">
                    <div className="col-5">
                        <label className="label txt---400-14-20">
                            <FormattedMessage id="sign-up-factor-auth.new-authentication-method" />
                        </label>
                    </div>
                    <div className="col-7">
                        <div className="custom-form-group-select">
                            <select value={authType} onChange={this.onAuthTypeChange} className="custom-form-select txt---400-14-20" autoFocus={true}>
                                {this.authTypes && this.authTypes.filter(item => item !== rawAuthMethod).sort((a, b) => (a > b) ? 1 : -1).map((authType) => {
                                    return (
                                        <FormattedMessage key={authType} id={'sign-up-factor-auth.' + authType}>
                                            {(txt) => (
                                                <option value={authType}>{txt}</option>
                                            )}
                                        </FormattedMessage>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="row gutters-5 align-items-center h-row container-btn">
                    {/* Làm mới */}
                    <div className="col-6">
                        <button className="btn-refresh txt---500-16-24 btn"
                            onClick={() => this.onCancel()}
                            onKeyDown={this.handlerKeyDownBtnCancelFactorAuth}
                            ref={this.cancelAuthBtnRef}
                        >
                            <FormattedMessage id="common.btn-cancel-new" />
                        </button>
                    </div>
                    {/* Tiếp tục */}
                    <div className="col-6">
                        <button className="btn-confirm txt---500-16-24 btn"
                            onClick={() => this.preCheckRegisterSmsOtp()}
                            onKeyDown={this.handlerKeyDownBtnConfirmFactorAuth}
                            ref={this.confirmAuthBtnRef}
                        >
                            <FormattedMessage id="common.btn-change" />
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    onChangeTab = (id) => {
        this._setState({
            activeTab: id
        })
    }

    onChangePhoneCodeTab = (id) => {
        this._setState({
            activePhoneCodeTab: id
        })
    }

    handleChangeCaptcha = (e) => {
        this._setState({
            captcha: e.target.value
        })
    }

    onSelectTokenLifetime = (e) => {
        this._setState({
            tokenLifetime: e.target.value
        });
    };

    processForgetPassword = () => {
        this.ForgetPasswordRef.current.processAuth();
    };

    genInfoForgetPassword = () => {
        let processAuthFunc = null;
        let body = null;
        processAuthFunc = transferService.processForgetPass;
        return {
            processAuthFunc,
            body,
        }
    }

    forgetPasswordCb = () => {
        ToastUtil.success('common.success', 'security.forget-pass-success');
        // this.props.setIsOpenModalHaveData("ScreenConfirm", {
        //     isOpenScreenConfirm: false,
        //     isCheckAuth2: true,
        // })
        this.reloadCaptcha()
        this.props.loadUserInfo()
    }

    getDataProcessForgetPassword = () => {
        let _info = null
        let { processAuthFunc, body } = this.genInfoForgetPassword()
        _info = {
            initFuncData: body,
            authRef: this.ForgetPasswordRef,
            processTransfer: () => { this.processForgetPassword() },
            processAuthFunc: processAuthFunc,
            transactionCb: () => { this.forgetPasswordCb() },
            title: "security.forget-pass-trading",
            // reload captcha -> close modal
            useBtnCloseExt: true,
            handleClose: () => { this.reloadCaptcha() },
            showOrderSwitch: false // Tạm thời truyền vào, sau sẽ lấy từ precheck trả về trường savef2
        }
        return _info || {}
    }

    preCheckForgetPassword = async () => {
        const { captcha, captchaTransactionId } = this.state
        let disableSubmit = !(captcha)
        if (disableSubmit) {
            ToastUtil.error('settings.personal.change-passwords.empty');
            return
        }

        const body = {
            code: captcha,
            transactionId: captchaTransactionId,
        }

        await transferService.checkForgetPass(body)
            .then((data) => {
                //console.log('ha_check_data', data);
                if (data && data.hasOwnProperty('transactionId')) {
                    const { transactionId, tokenid, authtype, authtypes, challengeQuestion, verified } = data;
                    this.props.updateInfoCheckAuth({
                        transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
                    })

                    this._setState({ loadStatus: LoadStatus.LOADED }, () => {
                        let _dataInfo = this.getDataProcessForgetPassword()
                        this.props.updateConfirmCheckAuth({
                            typeInfo: ModalConfirmType.FORGET_PASSWORD,
                            dataInfo: _dataInfo,
                        })

                        this.props.setIsOpenModalHaveData("ScreenConfirm", {
                            isOpenScreenConfirm: true,
                            isCheckAuth2: true,
                        })
                    });
                }
            })
            .catch((error) => {
                this._setState({ loadStatus: LoadStatus.FAIL });
                ToastUtil.errorApi(error, 'common.bad-request');
            });
    }

    handleSubmitForgetPassword = async () => {
        const { captcha, captchaTransactionId, type } = this.state
        let disableSubmit = !(captcha)
        if (disableSubmit) {
            ToastUtil.error('settings.personal.change-passwords.empty');
            return
        }

        const body = {
            code: captcha,
            transactionId: captchaTransactionId,
        }
        if (type === TYPE.CHANGE_PASSWORD_ORDER) {
            await transferService.processForgetPass(body)
                .then((data) => {
                    if (data && data.ec === 0 && data.s === "ok") {
                        this._setState({
                            loadStatus: LoadStatus.LOADED,
                            captcha: '',
                            captchaTransactionId: '',
                            captchaAuthtype: '',
                        }, () => {
                            this.forgetPasswordCb()
                        });
                    }
                })
                .catch((error) => {
                    this._setState({
                        loadStatus: LoadStatus.FAIL,
                        captcha: '',
                        captchaTransactionId: '',
                        captchaAuthtype: '',
                    }, () => this.reloadCaptcha());

                    ToastUtil.errorApi(error, 'common.bad-request');
                });
        } else if (type === TYPE.CHANGE_PHONE_CODE) {
            await transferService.processForgetPassMobile(body)
                .then((data) => {
                    if (data && data.ec === 0 && data.s === "ok") {
                        this._setState({
                            loadStatus: LoadStatus.LOADED,
                            captcha: '',
                            captchaTransactionId: '',
                            captchaAuthtype: '',
                        }, () => {
                            this.forgetPasswordCb()
                        });
                    }
                })
                .catch((error) => {
                    this._setState({
                        loadStatus: LoadStatus.FAIL,
                        captcha: '',
                        captchaTransactionId: '',
                        captchaAuthtype: '',
                    }, () => this.reloadCaptcha());

                    ToastUtil.errorApi(error, 'common.bad-request');
                });

        }

    }

    handlerKeyDownBtnResetForgetPass = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            event.preventDefault();
            if (!this.resetBtnForgetPassRef.current || this.resetBtnForgetPassRef.current.disabled) return;
            this.resetBtnForgetPassRef.current.click();
        }
    };

    handlerKeyDownBtnConfirmForgetPass = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            event.preventDefault();
            if (!this.confirmBtnForgetPassRef.current || this.confirmBtnForgetPassRef.current.disabled) return;
            this.confirmBtnForgetPassRef.current.click();
        }
    };

    renderForgetPassword = () => {
        const {
            base64captcha, isLoadingCaptcha, captcha
        } = this.state
        const { language } = this.props
        return (
            <>
                <div className="password-field row gutters-5 align-items-center h-row">
                    <div className="col-5">
                        <label className="label txt---400-14-20">
                            <FormattedMessage id={"security.captcha"} />
                        </label>
                    </div>
                    <div className="col-7">
                        <div className="custom-form-group-captcha disabled txt---400-14-20">
                            <div className='captcha' dangerouslySetInnerHTML={{ __html: base64captcha }} />
                            <div className="reload">
                                <i onClick={() => this.reloadCaptcha()} className={isLoadingCaptcha === true ? "fa fa-refresh fa-spin" : "fa fa-refresh"}></i>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="password-field row gutters-5 align-items-center h-row">
                    <div className="col-5">
                        <label className="label txt---400-14-20">
                            <FormattedMessage id={"security.captcha-input"} />
                        </label>
                    </div>
                    <div className="col-7">
                        <div className="custom-form-group txt---400-14-20">
                            <input value={captcha}
                                // type={showPassword ? "text" : "password"}
                                type={"text"}
                                className={"custom-form-control "}
                                onChange={(e) => this.handleChangeCaptcha(e)}
                                autoComplete="off"
                                placeholder={CommonUtils.getPlaceHolderInput(language)}
                                autoFocus={true}

                            />
                        </div>
                    </div>
                </div>

                <div className="password-field row gutters-5 align-items-center h-row container-btn">
                    {/* Làm mới */}
                    <div className="col-5">
                        <button className="btn-refresh txt---500-16-24 btn"
                            onClick={() => this.handleReset()}
                            ref={this.resetBtnForgetPassRef}
                            onKeyDown={this.handlerKeyDownBtnResetForgetPass}
                        >
                            <FormattedMessage id="common.btn-refresh" />
                        </button>
                    </div>
                    {/* Tiếp tục */}
                    <div className="col-7">
                        <button
                            className="btn-confirm txt---500-16-24 btn"
                            onClick={() => this.handleSubmitForgetPassword()}
                            ref={this.confirmBtnForgetPassRef}
                            onKeyDown={this.handlerKeyDownBtnConfirmForgetPass}

                        >
                            <FormattedMessage id="common.btn-continue" />
                        </button>
                    </div>
                </div>
            </>
        )
    }


    processConfigLifetime = () => {
        this.ConfigLifetimeRef.current.processAuth();
    };

    genInfoConfigLifetime = () => {
        let processAuthFunc = null;
        let body = null;
        processAuthFunc = transferService.processForgetPass;
        return {
            processAuthFunc,
            body,
        }
    }

    configLifetimeCb = () => {
        ToastUtil.success('common.success', 'security.success-auth');
        this.props.setIsOpenModalHaveData("ScreenConfirm", {
            isOpenScreenConfirm: false,
            isCheckAuth2: true,
        })
    }

    getDataProcessConfigLifetime = () => {
        let _info = null
        let { processAuthFunc, body } = this.genInfoConfigLifetime()
        _info = {
            dataFill: {},

            initFuncData: body,
            authRef: this.ConfigLifetimeRef,
            processTransfer: () => { this.processConfigLifetime() },
            processAuthFunc: processAuthFunc,
            transactionCb: () => { this.configLifetimeCb() },
            title: "security.token-lifetime",
        }
        return _info || {}
    }

    preCheckConfigLifetime = async () => {
        // const { captcha, captchaTransactionId } = this.state
        // let disableSubmit = !(captcha)
        // if (disableSubmit) {
        //     ToastUtil.error('settings.personal.change-passwords.empty');
        //     return
        // }

        // const body = {
        //     code: captcha,
        //     transactionId: captchaTransactionId,
        // }

        // await transferService.checkForgetPass(body)
        //     .then((data) => {
        //         //console.log('ha_check_data', data);
        //         if (data && data.hasOwnProperty('transactionId')) {
        //             const { transactionId, tokenid, authtype, authtypes, challengeQuestion, verified } = data;
        //             this.props.updateInfoCheckAuth({
        //                 transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
        //             })

        //             this._setState({ loadStatus: LoadStatus.LOADED }, () => {
        //                 let _dataInfo = this.getDataProcessConfigLifetime()
        //                 this.props.updateConfirmCheckAuth({
        //                     typeInfo: ModalConfirmType.TOKEN_LIFETIME,
        //                     dataInfo: _dataInfo,
        //                 })

        //                 this.props.setIsOpenModalHaveData("ScreenConfirm", {
        //                     isOpenScreenConfirm: true,
        //                     isCheckAuth2: true,
        //                 })
        //             });
        //         }
        //     })
        //     .catch((error) => {
        //         this._setState({ loadStatus: LoadStatus.FAIL });
        //         ToastUtil.errorApi(error, 'common.bad-request');
        //     });
    }

    renderConfigTokenLife = () => {
        const titleType = "security.token-lifetime"
        const { userInfo } = this.props
        const { tokenLifetime, listTokenLifeTimeConfig, } = this.state
        return (
            <div className="container h-100">
                <div className='txt---500-16-24'>
                    <FormattedMessage id={titleType} />
                </div>

                <div className="password-field row gutters-5 align-items-center h-row">
                    <div className="col-5">
                        <label className="label txt---400-14-20">
                            <FormattedMessage id="common.username" />
                        </label>
                    </div>
                    <div className="col-7">
                        <div className="custom-form-group disabled txt---400-14-20">
                            {userInfo && userInfo.username ? userInfo.username : ''}
                        </div>
                    </div>
                </div>

                <div className="password-field row gutters-5 align-items-center h-row">
                    <div className="col-5">
                        <label className="label txt---400-14-20">
                            <FormattedMessage id="security.time-login" />
                        </label>
                    </div>
                    <div className="col-7">
                        <div className="custom-form-group-select">
                            <select value={tokenLifetime} onChange={(e) => this.onSelectTokenLifetime(e)} className="custom-form-select txt---400-14-20">
                                {listTokenLifeTimeConfig && listTokenLifeTimeConfig.map((time) => {
                                    return (
                                        <FormattedMessage key={time} id={'sign-up-factor-auth.' + time}>
                                            {(txt) => (
                                                <option value={time}>{txt}</option>
                                            )}
                                        </FormattedMessage>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                </div>

                <div className="password-field row gutters-5 align-items-center h-row container-btn">
                    {/* Tiếp tục */}
                    <div className="col-12">
                        <button
                            className="btn-confirm txt---500-16-24 btn"
                            onClick={() => this.preCheckConfigLifetime()}
                        >
                            <FormattedMessage id="common.btn-continue" />
                        </button>
                    </div>
                </div>

            </div>)
    }

    handlerKeyDownBtnResetChangePass = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            event.preventDefault();
            if (!this.resetBtnChangePassRef.current || this.resetBtnChangePassRef.current.disabled) return;
            this.resetBtnChangePassRef.current.click();
        }
    };

    handlerKeyDownBtnConfirmChangePass = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            event.preventDefault();
            if (!this.confirmBtnChangePassRef.current || this.confirmBtnChangePassRef.current.disabled) return;
            this.confirmBtnChangePassRef.current.click();
        }
    };

    renderChangePassword = () => {
        const { type, password, oldPassword, reWritePassword,
            showRawOldPassword,
            showPassword,
            showReWritePassword,
            activeTab,
            activePhoneCodeTab,
            passwordLength,
            pinLength,
        } = this.state
        const { language } = this.props


        const isBroker = this.isBroker()

        let titleOldPassword = ''
        let titlePassword = ''
        let titleReWritePassword = ''
        let titleType = ''
        if (type === TYPE.CHANGE_PASSWORD_LOGIN) {
            titleOldPassword = 'settings.personal.change-passwords.password'
            titlePassword = 'settings.personal.change-passwords.new-password'
            titleReWritePassword = 'settings.personal.change-passwords.re-new-password'
            titleType = isBroker ? "security.login-password-broker" : "security.change-login-password"
        } else if (type === TYPE.CHANGE_PASSWORD_ORDER) {
            titleOldPassword = 'settings.personal.change-passwords.trading-password'
            titlePassword = 'settings.personal.change-passwords.new-trading-password'
            titleReWritePassword = 'settings.personal.change-passwords.re-new-trading-password'
            titleType = "security.order-password"
        } if (type === TYPE.CHANGE_PHONE_CODE) {
            titleOldPassword = 'settings.personal.change-passwords.trading-password'
            titlePassword = 'settings.personal.change-passwords.new-trading-password'
            titleReWritePassword = 'settings.personal.change-passwords.re-new-trading-password'
            titleType = "security.phone-code"
        }

        return (
            <div className="container h-100">
                <div className='txt---500-16-24'>
                    <FormattedMessage id={titleType} />
                </div>
                {
                    type === TYPE.CHANGE_PASSWORD_ORDER && (
                        <div className="tab">
                            {
                                Object.keys(TAB).map(item => {
                                    return (
                                        <div
                                            key={item}
                                            onClick={() => this.onChangeTab(TAB[item])}
                                            className={TAB[item] === activeTab ? "sub-tab active" : "sub-tab"}>
                                            {/* {item} */}
                                            <FormattedMessage id={"sign-up-factor-auth.tab-" + item} />
                                        </div>
                                    )
                                })
                            }
                            < hr />
                        </div>)
                }

                {
                    type === TYPE.CHANGE_PHONE_CODE && (
                        <div className="tab">
                            {
                                Object.keys(TAB).map(item => {
                                    return (
                                        <div
                                            key={item}
                                            onClick={() => this.onChangePhoneCodeTab(TAB[item])}
                                            className={TAB[item] === activePhoneCodeTab ? "sub-tab active" : "sub-tab"}>
                                            <FormattedMessage id={"sign-up-factor-auth.tab-" + item} />
                                        </div>
                                    )
                                })
                            }
                            < hr />
                        </div>)
                }

                {
                    ((type === TYPE.CHANGE_PASSWORD_ORDER && activeTab === TAB.FORGET_PASSWORD_ORDER) || (type === TYPE.CHANGE_PHONE_CODE && activePhoneCodeTab === TAB.FORGET_PASSWORD_ORDER)) ? this.renderForgetPassword() : (
                        <>
                            <div className="password-field row gutters-5 align-items-center h-row">
                                <div className="col-5">
                                    <label className="label txt---400-14-20">
                                        <FormattedMessage id={titleOldPassword} />
                                    </label>
                                </div>
                                <div className="col-7">
                                    <div className="custom-form-group txt---400-14-20">
                                        <input value={oldPassword}
                                            // type={showRawOldPassword ? "text" : "password"}
                                            type="text"
                                            className={"custom-form-control " + (showRawOldPassword ? " " : "key")}
                                            onChange={(e) => this.handleChangeOldPassword(e)}
                                            maxLength={MAX_PASS_LENGTH}
                                            autoComplete={"off"}
                                            autoFocus={true}
                                        // autoCapitalize={'none'}
                                        />

                                        <button tabIndex="-1" className="btn-edit text-center" onClick={() => this.toggleOldPassword()}><i className={showRawOldPassword ? "pin-color icon-eye fas fa-eye-slash" : "pin-color icon-eye fas fa-eye"} /></button>
                                    </div>
                                </div>
                            </div>

                            <div className="password-field row gutters-5 align-items-center h-row">
                                <div className="col-5">
                                    <label className="label txt---400-14-20">
                                        <FormattedMessage id={titlePassword} />
                                    </label>
                                </div>
                                <div className="col-7">
                                    <div className="custom-form-group txt---400-14-20">
                                        <input value={password}
                                            // type={showPassword ? "text" : "password"}
                                            type={"text"}
                                            className={"custom-form-control " + (showPassword ? " " : "key")}
                                            onChange={(e) => this.handleChangePassword(e)}
                                            maxLength={MAX_PASS_LENGTH}
                                            autoComplete="off"
                                        />

                                        <button tabIndex="-1" className="btn-edit text-center" onClick={() => this.togglePassword()}><i className={showPassword ? "pin-color icon-eye fas fa-eye-slash" : "pin-color icon-eye fas fa-eye"} /></button>
                                    </div>
                                </div>
                            </div>

                            <div className="password-field row gutters-5 align-items-center h-row">
                                <div className="col-5">
                                    <label className="label txt---400-14-20">
                                        <FormattedMessage id={titleReWritePassword} />
                                    </label>
                                </div>
                                <div className="col-7">
                                    <div className="custom-form-group txt---400-14-20">
                                        <input value={reWritePassword}
                                            // type={showReWritePassword ? "text" : "password"}
                                            type={"text"}
                                            className={"custom-form-control " + (showReWritePassword ? " " : "key")}
                                            onChange={(e) => this.handleChangeReWritePassword(e)}
                                            maxLength={MAX_PASS_LENGTH}
                                            autoComplete="off"
                                        />

                                        <button tabIndex="-1" className="btn-edit text-center" onClick={() => this.toggleReWritePassword()}><i className={showReWritePassword ? "pin-color icon-eye fas fa-eye-slash" : "pin-color icon-eye fas fa-eye"} /></button>
                                    </div>
                                </div>
                            </div>

                            {
                                (type === TYPE.CHANGE_PASSWORD_ORDER) && <div className="password-field row gutters-5 align-items-center">
                                    <div className="note">
                                        <FormattedMessage id="security.pin-note" values={{ x: pinLength }} />
                                    </div>
                                </div>
                            }

                            {
                                (type === TYPE.CHANGE_PASSWORD_LOGIN) && <div className="password-field row gutters-5 align-items-center">
                                    <div className="note">
                                        <FormattedMessage id="security.password-note" values={{ x: passwordLength }} />
                                    </div>
                                </div>
                            }

                            {
                                (type === TYPE.CHANGE_PHONE_CODE) && <div className="password-field row gutters-5 align-items-center">
                                    <div className="note">
                                        <FormattedMessage id="security.phone-code-note" values={{ x: PHONE_CODE_LENGTH }} />
                                    </div>
                                </div>
                            }

                            <div className="password-field row gutters-5 align-items-center h-row container-btn">
                                {/* Làm mới */}
                                <div className="col-6">
                                    <button className="btn-refresh txt---500-16-24 btn"
                                        onClick={() => this.handleReset()}
                                        ref={this.resetBtnChangePassRef}
                                        onKeyDown={this.handlerKeyDownBtnResetChangePass}
                                    >
                                        <FormattedMessage id="common.btn-refresh" />
                                    </button>
                                </div>
                                {/* Tiếp tục */}
                                <div className="col-6">
                                    <button
                                        className="btn-confirm txt---500-16-24 btn"
                                        // disabled={disableSubmit}
                                        onClick={() => this.handleSubmit()}
                                        ref={this.confirmBtnChangePassRef}
                                        onKeyDown={this.handlerKeyDownBtnConfirmChangePass}
                                    >
                                        <FormattedMessage id="common.btn-continue" />
                                    </button>
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
        )
    }

    render() {
        const { loadStatusAll, type, dataUserinfo, isUsePhoneTrade } = this.state
        const isBroker = this.isBroker()
        return (
            <RetryableContent className="h-100" status={loadStatusAll} disableRetry={false}>
                <div className="security h-100 row gutters-5">
                    <div className="col-12 col-sm-4">
                        <div className='block'>
                            <div className='headB---700-16-24 title'>
                                <FormattedMessage id="security.security-list" />
                            </div>

                            {isBroker === false && <div className={"server-property" + (type === TYPE.SIGN_UP_2_FACTOR_AUTH ? " active" : "")} onClick={() => this.handleChangeScreen(TYPE.SIGN_UP_2_FACTOR_AUTH)}>
                                <div className="header-service">
                                    <div className="icon">
                                        {CommonUtils.renderIconTheme(< SignUpIcon_Dark />, < SignUpIcon_Light />)}
                                    </div>
                                    <div className="content">
                                        <div className="title txt---500-16-24">
                                            <FormattedMessage id="security.sign-up-for-2-factor-authentication" />
                                        </div>
                                    </div>

                                </div>
                                <div className="extra">
                                    {CommonUtils.renderIconTheme(< ArrowIconDark />, < ArrowIconLight />)}
                                </div>
                            </div>}

                            <div className={"server-property" + (type === TYPE.CHANGE_PASSWORD_LOGIN ? " active" : "")} onClick={() => this.handleChangeScreen(TYPE.CHANGE_PASSWORD_LOGIN)}>
                                <div className="header-service">
                                    <div className="icon">
                                        {isBroker ? this.props.defaultTheme === 'dark' ? <PasswordBrokerIcon_Dark /> : < PasswordBrokerIcon_Dark /> :
                                            this.props.defaultTheme === 'dark' ? <PasswordIcon_Dark /> : < PasswordIcon_Dark />
                                        }

                                    </div>
                                    <div className="content">
                                        <div className="title txt---500-16-24">
                                            <FormattedMessage id={isBroker ? "security.login-password-broker" : "security.login-password"} />
                                        </div>
                                    </div>

                                </div>
                                <div className="extra">
                                    {CommonUtils.renderIconTheme(< ArrowIconDark />, < ArrowIconLight />)}
                                </div>
                            </div>

                            {isBroker === false && dataUserinfo && dataUserinfo.authtype && dataUserinfo.authtype == 1 && <div className={"server-property" + (type === TYPE.CHANGE_PASSWORD_ORDER ? " active" : "")} onClick={() => this.handleChangeScreen(TYPE.CHANGE_PASSWORD_ORDER)}>
                                <div className="header-service">
                                    <div className="icon">
                                        {CommonUtils.renderIconTheme(< OrderIcon_Dark />, < OrderIcon_Light />)}
                                    </div>
                                    <div className="content">
                                        <div className="title txt---500-16-24">
                                            <FormattedMessage id="security.order-password" />
                                        </div>
                                    </div>

                                </div>
                                <div className="extra">
                                    {CommonUtils.renderIconTheme(< ArrowIconDark />, < ArrowIconLight />)}
                                </div>
                            </div>
                            }

                            {isUsePhoneTrade && isBroker === false && <div className={"server-property" + (type === TYPE.CHANGE_PHONE_CODE ? " active" : "")} onClick={() => this.handleChangeScreen(TYPE.CHANGE_PHONE_CODE)}>
                                <div className="header-service">
                                    <div className="icon">
                                        {this.props.defaultTheme === 'dark' ? <PhoneCodeIcon_Dark /> : <PhoneCodeIcon_Dark />}
                                    </div>
                                    <div className="content">
                                        <div className="title txt---500-16-24">
                                            <FormattedMessage id="security.phone-code" />
                                        </div>
                                    </div>
                                </div>
                                <div className="extra">
                                    {this.props.defaultTheme === 'dark' ? < ArrowIconDark /> : < ArrowIconLight />}
                                </div>
                            </div>}

                            {/* <div className={"server-property" + (type === TYPE.TOKEN_LIFE_TIME ? " active" : "")} onClick={() => this.handleChangeScreen(TYPE.TOKEN_LIFE_TIME)}>
                                <div className="header-service">
                                    <div className="icon">
                                        {this.props.defaultTheme === 'dark' ? <TokenLifeIcon_Dark /> : <TokenLifeIcon_Dark />}
                                    </div>
                                    <div className="content">
                                        <div className="title txt---500-16-24">
                                            <FormattedMessage id="security.token-lifetime" />
                                        </div>
                                    </div>
                                </div>
                                <div className="extra">
                                    {this.props.defaultTheme === 'dark' ? < ArrowIconDark /> : < ArrowIconLight />}
                                </div>
                            </div> */}

                        </div>
                    </div>
                    <div className="col-12 col-sm-8 ">
                        <div className="col-12 block-right">
                            {
                                type === TYPE.SIGN_UP_2_FACTOR_AUTH &&
                                this.renderSignUp2FactorAuth()
                            }
                            {
                                (type === TYPE.CHANGE_PASSWORD_LOGIN || type === TYPE.CHANGE_PASSWORD_ORDER || type === TYPE.CHANGE_PHONE_CODE) &&
                                this.renderChangePassword()
                            }

                            {/* {
                                type === TYPE.TOKEN_LIFE_TIME &&
                                this.renderConfigTokenLife()
                            } */}
                        </div>
                    </div>
                </div>
            </RetryableContent>
        )
    }

}

const mapStateToProps = state => {
    const userInfo = state.user.userInfo;
    const permissionInfo = userInfo.permissionInfo ? userInfo.permissionInfo : null;

    let _layoutPage = state.layout.listIsOpenModal["C&B"]["ScreenModal"]
    let _accountInfo = _layoutPage && _layoutPage.accountInfo
    let { accounts, currentAccountId, curCustomer } = { ..._accountInfo }

    const _accounts = accounts && accounts.filter(item => item.accounttype !== "FDS");
    let defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme


    return {
        userInfo: userInfo,
        language: state.app.language,
        role: state.user.userInfo.role,
        accounts: _accounts,
        permissionInfo: permissionInfo,
        currentAccountId: currentAccountId,
        curCustomer: curCustomer,
        defaultTheme: defaultTheme
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setLoginPopupOnstart: (isOpen, custodycd) => dispatch(actions.setLoginPopupOnstart(isOpen, custodycd)),
        refetchDefaultConfig: () => dispatch(actions.fetchUpdateUserDefaultConfig()),
        navigate: (path) => dispatch(push(path)),
        setConfirmModalProps: (isOpen, messageId, acceptHandle, cbProps) => dispatch(actions.setConfirmModalProps(isOpen, messageId, acceptHandle, cbProps)),
        updateInfoCheckAuth: (data, key) => dispatch(actions.updateInfoCheckAuth(data, key)),
        updateConfirmCheckAuth: (data) => dispatch(actions.updateConfirmCheckAuth(data)),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
        logoutSingleUser: (custodycd, logoutWithStore) => dispatch(actions.logoutSingleUser(custodycd, logoutWithStore)),
        loadUserInfo: () => dispatch(actions.loadUserInfo()),
    };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Security));
