import React, { Component } from 'react';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';

// import { ReactComponent as IconEditSecondaryDark } from 'assets/icons_new/icon_edit_secondary_dark.svg'
import { push } from "connected-react-router";
import _ from "lodash";
import { connect } from 'react-redux';

// import { FormattedDate } from 'react-intl';
import { FormattedDate } from 'components/Formating';


import { inquiryService, transferService } from "services";
import { LoadStatus, ModalConfirmType, Role, ToastUtil, RegexUtils, CommonUtils, KeyCodeUtils } from "utils";

import * as actions from "../../../../store/actions";

import './Information.scss';
import RetryableContent from 'components/RetryableContent';
import { FormattedMessage } from 'react-intl';

export const SEX = {
    'vi': {
        '000': '---',
        '002': 'Nữ',
        '001': 'Nam'
    },
    'en': {
        '000': '---',
        '002': 'Female',
        '001': 'Male'
    },
    'kr': {
        '000': '---',
        '002': '여성',
        '001': '남성'
    },
    'jp': {
        '000': '---',
        '002': '女性',
        '001': '男性'
    },
}

export const STATUS_TRADE_TELEPHONE = {
    'vi': {
        'Y': 'Có',
        'N': 'Không',
    },
    'en': {
        'Y': 'Yes',
        'N': 'No',
    },
}

export const TYPE_CHANGE = {
    ALL: "ALL", //Thay đổi cả 2 email và địa chỉ
    EMAIL: "EMAIL", //Thay đổi Email
    ADDRESS: "ADDRESS", //Thay đổi address
}
class Information extends Component {

    constructor(props) {
        super(props);
        this.transAuthRef = React.createRef();

        this.confirmBtnRef = React.createRef();
        this.refreshBtnRef = React.createRef();
    }

    initialState = {
        currentAccount: {},
        accountInfo: {},
        rawAccountInfo: {},
        transactionIdStep1: '',
        fldval: '',
        oldFldval: '',
        isEdit: '',

        tokenid: '',
        transactionId: '',
        authtype: '',
        isOpen: false,
        loadStatus: LoadStatus.NONE,
        resendDisabled: false,
        isOpenchangeInfor: false,
        isActiveChangeInfor: false,
        emailNew: '',
        addressNew: ''
    }

    state = {
        ...this.initialState
    };
    IS_AUTHORITY = {
        OWNER: '1',
        AUTHORITY: '2'
    }
    
    loadAllData = () => {
        this.loadDataInformation()
    }


    isBroker = () => {
        const { role } = this.props;
        return role === Role.BROKER;
    };


    loadDataInformation = async () => {
        let { currentAccount } = this.state;
        let isBroker = this.isBroker()
        //console.log("decimalScale info --- : ", currentAccount)
        if (!_.isEmpty(currentAccount)) {
            this._setState({ accountInfo: {}, rawAccountInfo: {}, loadStatus: LoadStatus.NONE });
            let accountId = '';
            if (currentAccount.cftype) {
                accountId = currentAccount.cftype === this.IS_AUTHORITY.OWNER ? currentAccount.id : '';
            }
            if (isBroker) {
                accountId = currentAccount.id;
            }
            accountId = CommonUtils.formatFDS(accountId)
            this._setState({ loadStatus: LoadStatus.LOADING });
            inquiryService.getAccountInfo(accountId)
                .then((data) => {
                    if (data) {
                        this._setState({ accountInfo: data, rawAccountInfo: data, loadStatus: LoadStatus.LOADED });
                    } else {
                        this._setState({ loadStatus: LoadStatus.FAIL });
                        ToastUtil.error('common.fail-to-load-account-info');
                    }
                })
                .catch((error) => {
                    this._setState({ loadStatus: LoadStatus.FAIL });
                    ToastUtil.errorApi(error, 'common.fail-to-load-account-info');
                });
        }

    }

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }
	
	componentWillUnmount() {
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
        const { accounts, currentAccountId } = this.props;
        let filterAccount = accounts.find(item => (item.id === currentAccountId))
        this._setState({ currentAccount: filterAccount },
            () => {
                // this.loadAllData();
            }
        );
    }


    processTransfer = () => {
        this.transAuthRef.current.processAuth();
    };

    genInfoProcessToggleChangeInfo = (typeChange) => {
        const { emailNew, addressNew } = this.state;
        let processAuthFunc = null;
        let body = null;
        processAuthFunc = transferService.processChangeCustInfo;
        if(typeChange == TYPE_CHANGE.ALL) {
            body = {
                address: addressNew,
                email: emailNew,
                desc: "Edit from web",
            };
        }
        if(typeChange == TYPE_CHANGE.EMAIL) {
            body = {
                email: emailNew,
                desc: "Edit from web",
            };
        }
        if(typeChange == TYPE_CHANGE.ADDRESS) {
            body = {
                address: addressNew,
                desc: "Edit from web",
            };
        }
        
        return {
            processAuthFunc,
            body,
        }
    }

    

    getDataInfoToggleChangeInfo = (typeChange) => {
        const { emailNew, addressNew, accountInfo } = this.state;
        //const { email, address } = this.props
        let _info = null;
        let dataSend = {};
        if(typeChange == TYPE_CHANGE.ALL) {
            dataSend = {
                emailNew: emailNew,
                addressNew: addressNew,
                emailOld: accountInfo.email,
                addressOld: accountInfo.address,
                typeChange: typeChange
            }
        }
        if(typeChange == TYPE_CHANGE.EMAIL) {
            dataSend = {
                emailNew: emailNew,
                emailOld: accountInfo.email,
                typeChange: typeChange
            }
        }
        if(typeChange == TYPE_CHANGE.ADDRESS) {
            dataSend = {
                addressNew: addressNew,
                addressOld: accountInfo.address,
                typeChange: typeChange
            }
        }
        let { processAuthFunc, body } = this.genInfoProcessToggleChangeInfo(typeChange)
        _info = {
           ...dataSend,
            initFuncData: body,
            authRef: this.transAuthRef,
            processTransfer: () => { this.processTransfer() },
            processAuthFunc: processAuthFunc,
            // refresh: () => { this.refresh() },
            transactionCb: (data) => { this.transactionCbToggleChangeInfo(data) },
            title: "settings.personal.title-modal",
            showOrderSwitch: false // Tạm thời truyền vào, sau sẽ lấy từ precheck trả về trường savef2
        }
        return _info || {}
    }

    transactionCbToggleChangeInfo = (data) => {
        this._setState({
            emailNew: '',
            addressNew: '',
            isActiveChangeInfor: false
        })

        ToastUtil.success('account.utilities.cash-transfer.success', 'iflex.success.001');
        this.props.setIsOpenModalHaveData("ScreenConfirm", {
            isOpenScreenConfirm: false,
            isCheckAuth2: true,
        });
        this.loadDataInformation();
    }

    componentDidUpdate(prevProps, prevState) {
        const { socketCIAction, socketSEAction, socketODAction } = this.props;
        const { currentAccount } = this.state;
        const { currentAccount: prevcurrentAccount
        } = prevState;
        const {
            socketCIAction: prevSocketCIAction, socketSEAction: prevSocketSEAction,
            socketODAction: prevSocketODAction
        } = prevProps;


        if (currentAccount !== undefined && (socketCIAction[currentAccount.id] !== prevSocketCIAction[currentAccount.id] || socketSEAction[currentAccount.id] !== prevSocketSEAction[currentAccount.id] || socketODAction[currentAccount.id] !== prevSocketODAction[currentAccount.id])) {
            this.loadDataInformation()
        }

        if (currentAccount !== prevcurrentAccount) {
            this.loadAllData();
        }
    }


    handleValueChange = (event) => {
        this._setState({ sms: event.target.value });
    }

    // toggleChangeInformation = () => {
    //     this._setState({ isOpenchangeInfor: !this.state.isOpenchangeInfor });
    // }

    toggleActiveChangeInfor = () => {
        const focus = () => {
            const element = document.getElementById(`refEmail`)
            if (element) {
                element.focus()
            }
        }
        this._setState({ isActiveChangeInfor: !this.state.isActiveChangeInfor, emailNew: this.state.accountInfo.email, addressNew: this.state.accountInfo.address}, () => focus())
    }
    disableActiveChangeInfor = () => {        
        this._setState({ isActiveChangeInfor: false, addressNew: '',  emailNew: ''})
    }
    handleChangeEmailNew = (e) => {
        this._setState({ emailNew: e.target.value })
    }

    handleChangeAddressNew = (e) => {
        this._setState({ addressNew: e.target.value })
    }
    validateValue = (typeChange) => {
        const { emailNew, addressNew } = this.state;
        let flag = true
        if (typeChange) {
            const isValidEmail = RegexUtils.regexEmail.test(emailNew);
            const isValidAddress = addressNew.length > 15;
            if (typeChange === TYPE_CHANGE.EMAIL) {
                if (!isValidEmail) {
                    ToastUtil.error('CM3', 'FE002001');
                    return  false;
                }
            }
            if (typeChange === TYPE_CHANGE.ADDRESS) {
                if (!isValidAddress) {
                    ToastUtil.error('CM3', 'FE002006');
                    return false;
                }
            }
            if (typeChange === TYPE_CHANGE.ALL) {
                if (!isValidEmail) {
                    ToastUtil.error('CM3', 'FE002001');
                    return  false;
                }
                if (!isValidAddress) {
                    ToastUtil.error('CM3', 'FE002006');
                    return false;
                }
            }
        }
        return flag;
    }

    precheckChangeInfor = async () => {
        const { currentAccountId } = this.props;
        const { emailNew, addressNew, accountInfo } = this.state;
        let data = {};
        let typeChange = null;
        if(emailNew == accountInfo.email && addressNew == accountInfo.address) {
            ToastUtil.error('CM3', 'settings.personal.infor-exits');
            return;
        }
        if(emailNew != accountInfo.email && addressNew == accountInfo.address) {
            data = {
                address: '',
                email: emailNew,
                desc: "Edit from web",
            }
            typeChange = TYPE_CHANGE.EMAIL
        }
        if(addressNew != accountInfo.address && emailNew == accountInfo.email) {
            data = {
                address: addressNew,
                email: '',
                desc: "Edit from web",
            }
            typeChange = TYPE_CHANGE.ADDRESS
        }
        if(emailNew != accountInfo.email && addressNew != accountInfo.address) {
            data = {
                address: addressNew,
                email: emailNew,
                desc: "Edit from web",
            }
            typeChange = TYPE_CHANGE.ALL
        }
        const isvalid = this.validateValue(typeChange);
        if(!isvalid) return;
        await transferService.checkChangeCustInfo(currentAccountId, data)
            .then((data) => {
                if (Object.keys(data).length > 0) {
                    const { transactionId, tokenid, authtype, authtypes, challengeQuestion, verified } = data;
                    this.props.updateInfoCheckAuth({
                        transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
                    })

                    this._setState({ loadStatus: LoadStatus.LOADED }, () => {
                        let _dataInfo = this.getDataInfoToggleChangeInfo(typeChange)
                        this.props.updateConfirmCheckAuth({
                            typeInfo: ModalConfirmType.CHANGE_INFO,
                            dataInfo: _dataInfo,
                        })

                        this.props.setIsOpenModalHaveData("ScreenConfirm", {
                            isOpenScreenConfirm: true,
                            isCheckAuth2: true,
                        })
                    });
                }
                else {
                    ToastUtil.error('common.fail-to-precheck-transactions');
                }

            })
            .catch((error) => {
                this._setState({ loadStatus: LoadStatus.FAIL });
                ToastUtil.errorApi(error, 'common.bad-request');
            });
    }

    onHandleKeyDownSubmit = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            event.preventDefault();
            this.confirmBtnRef.current && this.confirmBtnRef.current.click();
        }
    }

    onHandleKeyDownRefresh = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            event.preventDefault();
            this.refreshBtnRef.current && this.refreshBtnRef.current.click();
        }
    }

    render() {
        const { userInfo, language } = this.props
        const {  accountInfo, loadStatus, isActiveChangeInfor, emailNew, addressNew } = this.state
        return (
            <RetryableContent status={loadStatus} disableRetry={true} className="retry-content-settings-information-main">
                <div className='settings-information-main'>
                    <div className='settings-information-header'>
                        <div className='headB---700-16-24 title'>
                            <FormattedMessage id={'settings.personal.title'} />
                        </div>
                        <div className="btn-edit" onClick={() => this.toggleActiveChangeInfor()}>
                            <EditIcon />
                        </div>
                    </div>                    
                    <div className="body">
                        <div className="property-info">
                            <div className="label-property txt---400-14-20"><FormattedMessage id={'settings.personal.personal-info.fullname'} /></div>
                            <div className="value-property txt---400-14-20">{accountInfo.fullname}</div>
                        </div>

                        <div className="property-info">
                            <div className="label-property txt---400-14-20"><FormattedMessage id={'settings.personal.personal-info.birthday'} /></div>
                            <div className="value-property txt---400-14-20"><FormattedDate value={accountInfo.dateofbirth} /></div>
                        </div>

                        <div className="property-info">
                            <div className="label-property txt---400-14-20"><FormattedMessage id={'settings.personal.personal-info.gender'} /></div>
                            <div className="value-property txt---400-14-20">{SEX[language][accountInfo.sex]}</div>
                        </div>

                        <div className="property-info">
                            <div className="label-property txt---400-14-20"><FormattedMessage id={'settings.personal.personal-info.id-code'} /></div>
                            <div className="value-property txt---400-14-20">{accountInfo.idcode}</div>
                        </div>

                        <div className="property-info">
                            <div className="label-property txt---400-14-20"><FormattedMessage id={'settings.personal.personal-info.issued-date'} /></div>
                            <div className="value-property txt---400-14-20"><FormattedDate value={accountInfo.iddate} /></div>
                        </div>

                        <div className="property-info">
                            <div className="label-property txt---400-14-20"><FormattedMessage id={'settings.personal.personal-info.issued-at'} /></div>
                            <div className="value-property txt---400-14-20">{accountInfo.idplace}</div>
                        </div>

                        <div className="property-info">
                            <div className="label-property txt---400-14-20"><FormattedMessage id={'settings.personal.personal-info.mobile'} /></div>
                            <div className="value-property txt---400-14-20">{accountInfo.mobile1}</div>
                        </div>

                        <div className="property-info">
                            <div className="label-property txt---400-14-20"><FormattedMessage id={'settings.personal.personal-info.mobile-1'} /></div>
                            <div className="value-property txt---400-14-20">{accountInfo.mobile2}</div>
                        </div>
                        
                        <div className="property-info">
                            <div className="label-property txt---400-14-20"><FormattedMessage id={'settings.personal.personal-info.email'} /></div>
                            {isActiveChangeInfor ? (
                                <div className="combo-input">
                                <div className="custom-form-group value-property txt---400-14-20">
                                    <input
                                        id={`refEmail`}
                                        type="text"
                                        className={"custom-form-control"}
                                        value={emailNew}
                                        autoComplete="off"
                                        onChange={(e) => this.handleChangeEmailNew(e)}
                                    />
                                </div>
                            </div>
                            ):
                            (
                                <div className="value-property txt---400-14-20">{accountInfo.email}</div>
                            )}
                            
                        </div>

                        <div className="property-info">
                            <div className="label-property txt---400-14-20"><FormattedMessage id={'settings.personal.personal-info.home-add'} /></div>
                            {isActiveChangeInfor ? (
                                <div className="combo-input">
                                <div className="custom-form-group value-property txt---400-14-20 value-property-textarea">
                                    {/* <input
                                        id={`refAddress`}
                                        type="text"
                                        className={"custom-form-control"}
                                        value={addressNew}
                                        autoComplete="off"
                                        onChange={(e) => this.handleChangeAddressNew(e)}
                                    /> */}
                                    <textarea rows="4" cols="50" 
                                        id={`refAddress`}
                                        className={"custom-form-control"}
                                        value={addressNew}
                                        autoComplete="off"
                                        onChange={(e) => this.handleChangeAddressNew(e)} 
                                    />
                                </div>
                            </div>
                            ) :
                            (
                                <div className="value-property txt---400-14-20">{accountInfo.address}</div>
                            )}                           
                        </div>

                        <div className="property-info">
                            <div className="label-property txt---400-14-20"><FormattedMessage id={'settings.personal.personal-settings.trade-telephone'} /></div>
                            <div className="value-property txt---400-14-20">{STATUS_TRADE_TELEPHONE[language][userInfo.tradetelephone]}</div>
                        </div>

                        {/* Thao tác */}
                        <div className="col-add row gutters-5 align-items-center h-row container-btn">
                            {/* Làm mới */}
                            <button className="btn-refresh txt---500-16-24 btn" onClick={() => this.disableActiveChangeInfor()} ref={this.refreshBtnRef} onKeyDown={this.onHandleKeyDownRefresh}>
                                <FormattedMessage id="common.btn-cancel" />
                            </button>
                            {/* Tiếp tục */}
                            <button
                                disabled={!isActiveChangeInfor}
                                className="btn-confirm txt---500-16-24 btn"
                                onClick={() => this.precheckChangeInfor()}
                                ref={this.confirmBtnRef} 
                                onKeyDown={this.onHandleKeyDownSubmit}
                            >
                                <FormattedMessage id="common.btn-continue" />
                            </button>
                        </div>
                        {/* {
                            isOpenchangeInfor &&
                            <ModalChangeInformation
                                isOpen={isOpenchangeInfor}
                                toggle={this.toggleChangeInformation}
                                email={accountInfo.email}
                                address={accountInfo.address}
                                loadAllData = {this.loadDataInformation}
                                
                            ></ModalChangeInformation>
                        } */}                        
                    </div>
                
                </div >
            </RetryableContent>
        )
    }

}

const mapStateToProps = state => {
    const userInfo = state.user.userInfo;

    let _accountInfo = state.layout.listIsOpenModal["C&B"]["ScreenModal"].accountInfo
    let { accounts, currentAccountId } = { ..._accountInfo }
    const defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme

    return {
        currentCustomer: state.customer.currentCustomer,
        userInfo: userInfo,
        language: state.app.language,
        role: state.user.userInfo.role,
        accounts: accounts,

        socketSEAction: state.socketRealtime.socketSEAction,
        socketCIAction: state.socketRealtime.socketCIAction,
        socketODAction: state.socketRealtime.socketODAction,

        //permissionInfo: permissionInfo,
        currentAccountId: currentAccountId,

        defaultTheme
    };
};

const mapDispatchToProps = dispatch => {
    return {
        refetchDefaultConfig: () => dispatch(actions.fetchUpdateUserDefaultConfig()),
        navigate: (path) => dispatch(push(path)),
        setConfirmModalProps: (isOpen, messageId, acceptHandle, cbProps) => dispatch(actions.setConfirmModalProps(isOpen, messageId, acceptHandle, cbProps)),
        updateInfoCheckAuth: (data, key) => dispatch(actions.updateInfoCheckAuth(data, key)),
        updateConfirmCheckAuth: (data) => dispatch(actions.updateConfirmCheckAuth(data)),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Information);

