import React, { Component } from 'react';
import { connect } from 'react-redux';


import AdditionBank from './AdditionBank/AdditionBank';
import Information from './Information/Information';

import './PersonalInfo.scss';

class PersonalInfo extends Component {

    constructor(props) {
        super(props);
        this.TransAuthExternalRef = React.createRef();
    }

    initialState = {
    };

    state = {
        ...this.initialState
    };

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState) {
    }

    render() {
        return (
            <div className='settings-personal'>
                <div className='block-information-personal'>
                    <Information />
                </div>
                <div className='block-addition-bank-change-password'>
                    <AdditionBank />
                    {/* <div className="space"></div>
                    <ChangePasswords /> */}
                </div>

            </div>
        )
    }

}


export default connect(null, null)(PersonalInfo);

