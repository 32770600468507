import React, { Component } from "react";
import Bowser from "bowser";
import { FormattedMessage } from 'react-intl';
import { connect } from "react-redux";
import { Modal } from 'reactstrap';

import { KeyCodeUtils, LanguageUtils } from '../../../utils';

import './BrowserConfirmModal.scss';

class BrowserConfirmModal extends Component {

    constructor(props) {
        super(props);
        this.confirmBtnRef = React.createRef();
    }

    handlerKeyDown = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            event.preventDefault();
            if (!this.confirmBtnRef.current || this.confirmBtnRef.current.disabled) return;
            this.confirmBtnRef.current.click();
        }
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handlerKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handlerKeyDown);
    }

    render() {
        const { isOpen, toggle, language, isDefaultBrowser } = this.props;
        let bowser = Bowser.getParser(window.navigator.userAgent).getBrowser();
        return (
            <Modal
                isOpen={isOpen}
                toggle={toggle}
                className="browser-confirm-modal"
                centered={true}
            >
                <div className="header">
                    <div className="row no-gutters">
                        <div className="col">
                            <div className="title">
                                <FormattedMessage id="common.app-mesage-modal-title" />
                            </div>
                        </div>
                        <div className="col-auto">
                            <button onClick={toggle}><i className="fal fa-times" /></button>
                        </div>
                    </div>
                </div>
                <div className="body">
                    <div className="col-12 desc-content">
                        <p>
                            {isDefaultBrowser ?  <FormattedMessage values={{browser: bowser && bowser.name}} id="common.fail-to-version" /> : <FormattedMessage id="common.fail-to-default-version" />}
                        </p>
                    </div>
                    <hr />
                </div>
                <div className="footer-condition">
                    <div className="row gutters-5">
                        <div className="col-12 text-center confirm">
                            <button
                                ref={this.confirmBtnRef}
                                className="btn-ok custom-btn"
                                onClick={toggle}>
                                {LanguageUtils.getMessageByKey("trade.advance-condition-order.confirm", language)}
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }

}

const mapStateToProps = state => {
    return {
        language: state.app.language
    };
};
export default connect(mapStateToProps)(BrowserConfirmModal);