import React, { Component } from 'react';
import TableExtremeCustom2 from 'components/Table/TableExtreme/TableExtremeCustom2';
import { push } from "connected-react-router";
import _ from 'lodash';
import { connect } from 'react-redux';
import { LanguageUtils } from 'utils';

import RetryableContent from '../../../components/RetryableContent';
import RenderGridCell from "../../../components/Table/TableExtreme/RenderGridCell";
import { inquiryService, tradeApiService } from '../../../services';
import * as actions from "../../../store/actions";
import { CommonOrderUtils, CommonWidgetUtils, fullSubOrderTypes, LoadStatus, modules, ORDER_TYPE_USE, Role, ToastUtil, Random } from "../../../utils";
import CommonUtils from "../../../utils/CommonUtils";
import $ from 'jquery';
import { SELECTOR_ACCOUNT_SCREEN } from './../../../utils/constants';
import { TYPE_ACCOUNT } from './../../../utils/constants';

import './BrokerOrderBook.scss';
import ModalBrokerOrderMultiCancel from './ModalBrokerOrderMultiCancel/ModalBrokerOrderMultiCancel';
import { emitter, FOCUS_PRICE_INPUT } from 'utils/EventEmitter';
const TYPE_SELECT_CANCEL = {
    CANCEL: 1,
    CANCEL_MULTI: 2
}

// {
//     dataField: "remainamt", // Giá trị còn lại
//     dataType: "string",
//     allowReordering: true,
//     minWidth: "100",
//     width: '120',
//     visible: true,
//     alignment: "right",
//     visibleIndex: 14,
// },
const columnsDefault =
    [
        {
            dataField: "action",
            // fixed: true,
            // fixedPosition: "left", // Cố định cột
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            minWidth: "130",
            width: '150',
            visible: true,
            alignment: "center",
            visibleIndex: 0,
            allowFiltering: false
        },
        {
            dataField: "custodycd", // Tài khoản
            // fixed: true,
            // fixedPosition: "left", // Cố định cột
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 1,
        },
        {
            dataField: "afacctnoext", //Tiểu khoản
            // fixed: true,
            // fixedPosition: "left", // Cố định cột
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 2,
        },
        {
            dataField: "symbol", //Mã chứng khoán
            // fixed: true,
            // fixedPosition: "left", // Cố định cột
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 3,
        },
        {
            dataField: "side", // M/B
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 4,
        },
        {
            dataField: "status", // Trạng thái
            dataType: "string",
            allowReordering: true, // không cho di chuyển
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 5,
        },

        {
            dataField: "priceType", // Loại giá
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 6,
        },
        {
            dataField: "qtty", // Khối lượng đặt
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "right",
            visibleIndex: 7,
        },
        {
            dataField: "price", // Giá đặt
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "right",
            visibleIndex: 8,
        },
        {
            dataField: "quoteamt", // Giá trị đặt
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "right",
            visibleIndex: 9,
        },
        {
            dataField: "cumQty", // // Khối lượng khớp
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "right",
            visibleIndex: 10,
        },
        {
            dataField: "avgPx", // // Giá khớp
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "right",
            visibleIndex: 11,
        },
        {
            dataField: "execamt", // Giá trị khớp
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "right",
            visibleIndex: 12,
        },
        {
            dataField: "leavesQty", // Khối lượng còn lại
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "right",
            visibleIndex: 13,
        },
        {
            dataField: "matchtype", // Loại lệnh
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 15,
        },
        {
            dataField: "tlname", // Loại lệnh
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 16,
        },
        {
            dataField: "odorderid",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '200',
            visible: true,
            alignment: "center",
            visibleIndex: 17,
        },
        {
            dataField: "rootorderid",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '200',
            visible: true,
            alignment: "center",
            visibleIndex: 18,
        },
        // {
        //     dataField: "tlname",
        //     dataType: "string",
        //     allowReordering: true,
        //     minWidth: "150",
        //     width: '200',
        //     visible: true,
        //     alignment: "left",
        //     visibleIndex: 17,
        // },
    ]

const totalDefault =
    [
        {
            column: "qtty",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "cumQty",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "avgPx",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "quoteamt",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "execamt",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        }
    ]

// {
//     column: "remainamt",
//     summaryType: "sum",
//     displayFormat: "{0}",
//     valueFormat: ",##0",
// },

class BrokerOrderBook extends Component {

    initialState = {
        loadStatus: LoadStatus.NONE,
        accountId: "ALL",
        firstLoadStatus: false,
        listOrderIdCancel: [],
        listOrderCancel: [],

        loadStatusCancelMultiBrokerOrder: LoadStatus.NONE,
        listDataFillModal: [],
        listOrderSelected: [],
        isOpenModalBrokerOrderMultiCancel: false,
        typeSelectCancel: TYPE_SELECT_CANCEL.CANCEL,
        currentCustodycdSelected: '',
        keepSelection: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState
        }
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    isBroker = () => {
        const { role } = this.props;
        return role === Role.BROKER;
    };

    renderSwitch = (dataField) => {
        const { lang, defaultTheme } = this.props
        switch (dataField) {
            case 'action':
                return (e) => RenderGridCell.renderGridCellActionOrderActive(e, this.onEditOrder, this.onCancelOrder, 2, this.onCopyOrder)
            case 'custodycd':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.custodycd, lang);
            case 'afacctnoext':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.afacctnoext, lang);
            case 'symbol':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.symbol, lang);
            case 'side':
                return (e) => RenderGridCell.renderGridCellSideCode4(e.row.data.side, lang);
            case 'status':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.status, lang);
            case 'priceType':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.priceType, lang);
            case 'qtty':
                return (e) => RenderGridCell.renderGridCellQuantity(e.row.data.qtty);
            case 'price':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.price, "PRICE_FIXED");
            case 'quoteamt':
                return (e) => RenderGridCell.renderGridCellQuantity(e.row.data.quoteamt);
            case 'cumQty':
                return (e) => RenderGridCell.renderGridCellQuantity(e.row.data.cumQty);
            case 'avgPx':
                return (e) => RenderGridCell.renderGridCellPrice(e.row.data.avgPx, "PRICE_FIXED");;
            case 'execamt':
                return (e) => RenderGridCell.renderGridCellQuantity(e.row.data.execamt);
            case 'leavesQty':
                return (e) => RenderGridCell.renderGridCellQuantity(e.row.data.leavesQty);
            // case 'remainamt':
            //     return (e) => RenderGridCell.renderGridCellQuantity(e.row.data.remainamt);
            case 'matchtype':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.matchtype, lang);
            case 'tlname':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.tlname, lang);
            case 'odorderid':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.odorderid, lang);
            case 'rootorderid':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.rootorderid, lang);
            // case 'tlname':
            //     return (e) => RenderGridCell.renderGridCellCommon(e.row.data.tlname, lang);
            default:
                return null;
        }
    }

    returnCaption = (dataField) => {
        switch (dataField) {
            case 'action':
                return "trade.order.action";
            case 'custodycd':
                return "trade.order.custodycd";
            case 'afacctnoext':
                return "trade.order.account-id";
            case 'symbol':
                return "trade.order.symbol";
            case 'side':
                return "trade.order.side";
            case 'status':
                return "trade.order.status";
            case 'priceType':
                return "trade.order.price-type";
            case 'qtty':
                return "trade.order.qtty";
            case 'price':
                return "trade.order.price";
            case 'quoteamt':
                return "trade.order.price-value";
            case 'cumQty':
                return "trade.order.exec-qtty";
            case 'avgPx':
                return "trade.order.exec-price";
            case 'execamt':
                return "trade.order.exec-amt";
            case 'leavesQty':
                return "trade.order.remain-qtty";
            // case 'remainamt':
            //     return "trade.order.remain-amount";
            case 'matchtype':
                return "trade.order.order-type";
            case 'tlname':
                return "trade.order.tlname";
            case 'odorderid':
                return "trade.order.order-id";
            case 'rootorderid':
                return "trade.order.root-order-id";
            // case 'tlname':
            //     return "trade.order.tlname";
            default:
                return null;
        }
    }

    checkPermission = (accountid) => {
        const { permissionInfo, userInfo } = this.props;
        if (userInfo.role === Role.CUSTOMER && accountid && permissionInfo) {
            return (permissionInfo.accounts[accountid] && permissionInfo.accounts[accountid][modules.ORDINPUT]) ? true : false
        }
        if (userInfo.role === Role.BROKER && userInfo && permissionInfo) {
            return (permissionInfo.accounts[userInfo.custid] && permissionInfo.accounts[userInfo.custid][modules.ORDINPUT]) ? true : false
        }
    };


    loadData = () => {
        const { userInfo, currentCustomer } = this.props;

        const { accountInfo, socketSEActionCounter } = this.props;
        const { currentAccountId, curCustomer } = { ...accountInfo }

        const { isForBroker } = this.props; //xem co phai so lenh moi gioi khong


        let custid = null;

        if (this.isBroker()) {
            // custid = curCustomer ? curCustomer.custid : null;
            custid = userInfo ? userInfo.custid : null;
            custid && this.getAllOrder(custid, currentAccountId);
        } else {
            custid = userInfo ? userInfo.custid : null;
            this.getAllOrder(custid, currentAccountId);
        };
    };

    processOrderData = (dataArr) => {
        const { language } = this.props
        if (dataArr.length > 0) {
            for (let i = 0; i < dataArr.length; i++) {
                let element = dataArr[i];
                let cancelOrderPermission = element['isCancel'] === "Y"
                let amendOrderPermission = element['isAmend'] === "Y"
                element.cancelOrderPermission = cancelOrderPermission;
                element.amendOrderPermission = amendOrderPermission;
                element.qtty = element['orderQty'];
                element.afacctno = element['account'];
                element.afacctnoext = element['cd_afacctno'];
                element.price = element['price'];
                element.quoteamt = (element['qtty'] * element['price'])
                element.execamt = (element['cumQty'] * element['avgPx'])
                // element.remainamt = element['leavesQty'] * element['price'] // Giá trị còn lại

                element.odorderid = element['orderID']
                element.rootorderid = element['rootOrderID']
                element.sideCode = element['side']
                element.status = language == "vi" ? element['statusDesc'] : element['en_statusDesc']
                element.side = CommonUtils.convertSideByLanguage(element['side'], language)
            }
            return dataArr
        } else {
            return []
        }
    };

    // Called only one time after component loaded
    componentDidMount() {
        this.mounted = true
        this.setReloadData();
    };

    componentWillUnmount() {
        this._setState({
            ...this.initialState
        })
        this.mounted = false
    }

    onEditOrder = (order) => {
        const { setCustodycdCustomerScreen } = this.props;
        const fillEditInfo = () => {
            this.fillEditInfo(order);
        };
        if (this.isBroker()) {
            setCustodycdCustomerScreen(order['custodycd'], SELECTOR_ACCOUNT_SCREEN.LAYOUT, fillEditInfo);
        } else {
            fillEditInfo();
        }
    };


    fillEditInfo = async (order) => {
        const { accountInfo, updatePlaceQuickOrder, updateProTrade, updatedSymbolLayoutPage, setCustodycdCustomerScreen, setIsOpenModalHaveData } = this.props;
        const { curCustomer, accounts, currentAccountId } = { ...accountInfo }
        //set lai account id hien tai
        // setCurrentAccount(order['afacctno']);
        this.props.setAccountIdScreen(order['afacctno'], SELECTOR_ACCOUNT_SCREEN.LAYOUT)
        const action = CommonUtils.getOrderActionFromSideCode(order['sideCode']);
        const orderType = CommonUtils.getOrderTypeFromPriceType(order['priceType']);

        let orderInputNormal = {
            accountInfo: {
                ...accountInfo,
                currentAccountId: order['afacctno'],
            },
            action: action,
            orderType: orderType,
            quantity: Number(order['qtty']),
            quotePriceValid: true,
            stepPriceValid: true,
            editingOrder: order
        }

        if (orderType === 'limit') {
            orderInputNormal.limitPrice = order['price'];
            orderInputNormal.subOrderType = fullSubOrderTypes.LO
        } else if (orderType === 'market') {
            orderInputNormal.limitPrice = order['price'];
            orderInputNormal.orderType = 'limit';
            orderInputNormal.subOrderType = fullSubOrderTypes.LO
        };


        let orderInputPro = {
            action: action,
            orderType: orderType,
            quantity: Number(order['qtty']),
            quotePriceValid: true,
            stepPriceValid: true,
            editingOrder: order
        }

        if (orderType === 'limit') {
            orderInputPro.limitPrice = order['price'];
            orderInputPro.subOrderType = fullSubOrderTypes.LO
        } else if (orderType === 'market') {
            orderInputPro.limitPrice = order['price'];
            orderInputPro.orderType = 'limit';
            orderInputPro.subOrderType = fullSubOrderTypes.LO
        };

        updatedSymbolLayoutPage(order['symbol'], "search_layout")

        let getInfoOrderLayoutCurrent = CommonOrderUtils.getInfoOrderLayoutCurrent()
        const { listOrderLayoutCurrent } = getInfoOrderLayoutCurrent
        //console.log("fillEditInfo", orderInputNormal)
        if (listOrderLayoutCurrent && listOrderLayoutCurrent.length > 0) {
            if (listOrderLayoutCurrent.includes(ORDER_TYPE_USE.NORMAL_ORDER)) {
                updatePlaceQuickOrder(orderInputNormal, ORDER_TYPE_USE.NORMAL_ORDER)

                let timer = setTimeout(function () { emitter.emit(FOCUS_PRICE_INPUT, ""); clearTimeout(timer) }, 0);

                setIsOpenModalHaveData("ScreenModal", {
                    isOpenScreenModal: false,
                    curScreenModalActive: "",
                })
            }
            if (listOrderLayoutCurrent.includes(ORDER_TYPE_USE.PRO_TRADE_ORDER)) {
                updateProTrade({
                    typeAction: action,
                    orderType: orderInputPro.orderType,
                })
                updateProTrade({
                    ...orderInputPro
                }, action)

                setIsOpenModalHaveData("ScreenModal", {
                    isOpenScreenModal: false,
                    curScreenModalActive: "",
                })
            }
        } else {
            if (CommonUtils.isBroker()) {
                await setCustodycdCustomerScreen(order.custodycd, SELECTOR_ACCOUNT_SCREEN.QUICK_ORDER)
            }
            this.props.setAccountIdScreen(order['afacctno'], SELECTOR_ACCOUNT_SCREEN.QUICK_ORDER)

            this.props.clearDataPlaceQuickOrder()
            CommonUtils.setPlaceQuickOrder(() => { emitter.emit("onChangeTabActiveQuickOrder", 1) })
            orderInputNormal.symbolCurrent = order['symbol']
            delete orderInputNormal.accountInfo
            updatePlaceQuickOrder({ ...orderInputNormal, editOnly: true })
            let timer = setTimeout(function () { emitter.emit(FOCUS_PRICE_INPUT, ""); clearTimeout(timer) }, 0);
        }
    }


    onCancelOrder = (order) => {
        const { accountInfo, intl } = this.props;
        const { curCustomer, accounts, currentAccountId } = { ...accountInfo }
        this._setState({ typeSelectCancel: TYPE_SELECT_CANCEL.CANCEL })

        tradeApiService.initTwoFactorAuth()
            .then((data) => {
                const { transactionId, tokenid, authtypes, challengeQuestion, authtype, verified } = data;
                this.props.updateInfoCheckAuth({
                    transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
                })
                this._setState({ isOpenModalBrokerOrderMultiCancel: true })
            })
            .catch((error) => {
                ToastUtil.errorApi(error, 'common.fail-to-load-customer-acccounts-list');
                return;
            })

        let listReconfirmOrder = this.getListReconfirmOrder([{ ...order }]);
        this._setState({ listDataFillModal: listReconfirmOrder, listOrderSelected: [{ ...order }] })
    };

    onCopyOrder = async (record) => {
        // console.log('laojahackgame======> DATA RECORD', record);
        const { accountInfo, updatePlaceQuickOrder, updateProTrade, updatedSymbolLayoutPage, setCustodycdCustomerScreen } = this.props;
        const { typeAccount } = { ...accountInfo }
        //set lai account id hien tai
        // setCurrentAccount(order['afacctno']);
        const action = CommonUtils.getOrderActionFromSideCode(record['sideCode']);


        let orderInputNormal = {
            accountInfo: {
                ...accountInfo,
                currentAccountId: record['afacctno'],
            },
            action: action,
            limitPrice: record['price'],
            quantity: Number(record['qtty']),
        }


        let orderInputPro = {
            action: action,
        }


        await updatedSymbolLayoutPage(record['symbol'], "search_layout")

        let getInfoOrderLayoutCurrent = CommonOrderUtils.getInfoOrderLayoutCurrent()
        const { listOrderLayoutCurrent } = getInfoOrderLayoutCurrent
        if (listOrderLayoutCurrent && listOrderLayoutCurrent.length > 0) {
            if (listOrderLayoutCurrent.includes(ORDER_TYPE_USE.NORMAL_ORDER)) {
                updatePlaceQuickOrder({ ...orderInputNormal, symbolCurrent: record['symbol'] }, ORDER_TYPE_USE.NORMAL_ORDER)
                // updatePlaceQuickOrder(orderInputNormal, ORDER_TYPE_USE.NORMAL_ORDER)
                let timer = setTimeout(function () { emitter.emit(FOCUS_PRICE_INPUT, ""); clearTimeout(timer) }, 0);
            }
            if (listOrderLayoutCurrent.includes(ORDER_TYPE_USE.PRO_TRADE_ORDER)) {
                updateProTrade({
                    typeAction: action,
                })
                updateProTrade({
                    ...orderInputPro
                }, action)
                let timer = setTimeout(function () { emitter.emit(FOCUS_PRICE_INPUT, action); clearTimeout(timer) }, 0);
            }
        } else {
            if (CommonUtils.isBroker()) {
                await setCustodycdCustomerScreen(record.custodycd, SELECTOR_ACCOUNT_SCREEN.QUICK_ORDER)
            }
            let _accountID = record['afacctno']
            if (typeAccount === TYPE_ACCOUNT.FDS) {
                _accountID = `FDS${_accountID}`
            }
            this.props.setAccountIdScreen(_accountID, SELECTOR_ACCOUNT_SCREEN.QUICK_ORDER)

            this.props.clearDataPlaceQuickOrder()
            CommonUtils.setPlaceQuickOrder(() => { emitter.emit("onChangeTabActiveQuickOrder", 1) })
            orderInputNormal.symbolCurrent = record['symbol']
            delete orderInputNormal.accountInfo
            updatePlaceQuickOrder(orderInputNormal)
            let timer = setTimeout(function () { emitter.emit(FOCUS_PRICE_INPUT, action); clearTimeout(timer) }, 0);
        }
    }

    requestId = '';

    onCancelMultipleOrder = () => {
        const { listOrderCancel } = this.state
        const { accountInfo, intl } = this.props;
        const { curCustomer, accounts, currentAccountId } = { ...accountInfo }

        // let listOrder = this.getCheckedOrders();
        let listOrder = listOrderCancel;

        if (!listOrder || listOrder.length === 0) {
            ToastUtil.error('common.no-cancel-order-allow');
            return;
        }

        this.requestId = Random.randomOrderRequestId();

        let orderIds = listOrderCancel.map(item => {
            return {
                accountid: item.afacctno || '',
                orderid: item.odorderid || ''
            }
        })

        let precheckmultiBody = {
            orderIds,
            requestId: this.requestId
        }

        tradeApiService.checkTradeCancelMultiOrderAllCareBy(precheckmultiBody)
            .then(data => {
                tradeApiService.initTwoFactorAuth()
                    .then((data) => {
                        const { transactionId, tokenid, authtypes, challengeQuestion, authtype, verified } = data;
                        this.props.updateInfoCheckAuth({
                            transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
                        })
                        this._setState({ isOpenModalBrokerOrderMultiCancel: true })
                    })
                    .catch(error => {
                        this._setState({ loadStatus: LoadStatus.FAIL })
                        ToastUtil.errorApi(error, 'CM2');
                    });

                let listReconfirmOrder = this.getListReconfirmOrder(listOrder);
                this._setState({ listDataFillModal: listReconfirmOrder, listOrderSelected: listOrder, typeSelectCancel: TYPE_SELECT_CANCEL.CANCEL_MULTI })
            }).catch(err => {
                ToastUtil.errorApi(err, 'common.fail-to-init-transaction');
                return;
            })
    };

    setLoadStatusModal = (status) => {
        this._setState({ loadStatusCancelMultiBrokerOrder: status })
    }

    callApiCancelMultpleOrder = (authInfo) => {
        const { listDataFillModal } = this.state
        let listOrder = listDataFillModal
        let requestId = this.requestId;
        let orderIds = []
        _.forEach(listOrder, (item, index) => {
            orderIds.push({
                accountid: item.accountId,
                orderid: item.orderid
            })
        });

        let body = {
            ...authInfo,
            orderIds: orderIds,
            requestId,
        }

        this.setLoadStatusModal(LoadStatus.LOADING)
        tradeApiService.tradeCancelMultiOrder(body)
            .then((data) => {
                this.setLoadStatusModal(LoadStatus.LOADED)
                this.handleMultipleCancelFinish(listOrder, data);
            }
            ).catch(error => {
                this.setLoadStatusModal(LoadStatus.FAIL)
                ToastUtil.errorApi(error, 'trade.order.cancel-order-fail');
            });
    }

    handleMultipleCancelFinish = (listOrder, res) => {
        const { intl, language } = this.props;
        this.onFinishMultiOrderCancel();

        let successListTotal = res.filter((item) => {
            return item["errcode"] === "0"
        })

        let errListTotal = res.filter((item) => {
            return item["errcode"] !== "0"
        })

        //console.log("handleMultipleCancelFinish", listOrder, res, successListTotal, errListTotal)

        let succmsgList = successListTotal.map(succ => {
            let orderid = succ['orderid'];
            let order = _.find(listOrder, od => {
                return od['orderid'] === orderid;
            });
            let side = LanguageUtils.getMessageByKey('common.exec-types.' + order['side'], language),
                symbol = order['symbolCurrent'].id,
                accountId = order['accountId'];
            // let orderAccount = accounts.find(account => {
            //     return account.id === accountId
            // })
            return side + ' : ' + symbol;
        });
        let errmsgList = errListTotal.map(err => {
            let orderid = err['orderid'];
            let order = _.find(listOrder, od => {
                return od['orderid'] === orderid;
            });
            let side = LanguageUtils.getMessageByKey('common.exec-types.' + order['side'], language),
                symbol = order['symbolCurrent'].id,
                accountId = order['accountId'],
                errmsg = err.errmsg;
            return side + ' : ' + symbol + ` (${errmsg})`;
        });
        if (succmsgList && succmsgList.length > 0) ToastUtil.successRaw('trade.order.cancel-order-success', succmsgList);
        if (errmsgList && errmsgList.length > 0) ToastUtil.errorRaw('trade.order.cancel-order-fail', errmsgList, 15000);
    }

    onFinishMultiOrderCancel = () => {
        CommonUtils.middlewareOrder()
        this.onCloseModalBrokerOrderMultiCancel()
    };

    getCheckedOrders = () => {
        const { listOrderIdCancel, records } = this.state

        let listOrder = _.filter(this.state.records, (record) => {
            return record.checked;
        });
        return listOrder
    };

    callApiCancelOrder = (authInfo) => {
        const { listOrderSelected } = this.state
        let order = listOrderSelected[0]
        this.setLoadStatusModal(LoadStatus.LOADING)
        let requestId = Random.randomOrderRequestId()

        let body = {
            ...authInfo,
            orderId: order['odorderid'],
            requestId,
        }

        tradeApiService.tradeCancelOrder(order['afacctno'], body)
            .then(() => {
                this.setLoadStatusModal(LoadStatus.LOADED)
                this.onFinish();
                ToastUtil.success('trade.order.cancel-order-success');
            })
            .catch((error) => {
                this.setLoadStatusModal(LoadStatus.FAIL)
                ToastUtil.errorApi(error, 'trade.order.cancel-order-fail');
            });
    }

    getListReconfirmOrder = (listOrder) => {
        let listReconfirmOrder = [];
        listOrder.forEach(order => {
            const action = CommonUtils.getOrderActionFromSideCode(order['sideCode']);
            const orderType = CommonUtils.getOrderTypeFromPriceType(order['priceType']);
            let limitPrice = null;
            let stopPrice = null;
            let quantity = order['qtty'];
            listReconfirmOrder.push({
                custodycd: order['custodycd'],
                orderid: order['odorderid'],
                side: order['side'],
                action,
                currentOrderType: orderType,
                limitPrice: order['limitprice'],
                stopPrice,
                quantity,
                symbolCurrent: {
                    id: order['symbol']
                },
                marketPrice: order['price'],
                subOrderType: order['priceType'],
                cancelingOrder: true,
                accountId: order['afacctno'],
                afacctnoext: order['cd_afacctno'],
                sideCode: order['sideCode'],
                orderValue: Number(order['price']) * quantity,
                fullname: order['fullname']
            });
        });
        return listReconfirmOrder;
    };

    onFinish = () => {
        const { clearDataPlaceQuickOrder, setIsOpenModalHaveData } = this.props
        CommonUtils.middlewareOrder()
        this.onCloseModalBrokerOrderMultiCancel()
    };

    componentDidUpdate(prevProps, prevState) {
        const { firstLoadStatus } = this.state
        const { placeProTrade, accountInfo, currentSymbol, custSocketODActionCounter, language
        } = this.props;

        const { currentAccountId, typeAction } = { ...accountInfo }
        const { placeProTrade: prevPlaceProTrade, accountInfo: prevAccountInfo, currentSymbol: prevCurrentSymbol, typeAction: prevTypeAction,
            custSocketODActionCounter: prevCustSocketODActionCounter,
            language: prevlanguage
        } = prevProps;
        const { currentAccountId: prevCurrentAccountId } = { ...prevAccountInfo }

        if (language !== prevlanguage) {
            this.setReloadData()
        }

        if ((currentAccountId !== prevCurrentAccountId) || !_.isEqual(currentSymbol, prevCurrentSymbol)) {
            this.setReloadData();
        }

        if (custSocketODActionCounter !== prevCustSocketODActionCounter) {
            this.setReloadData(null, true);
        }

    }

    onSelectionChanged = ({ selectedRowKeys, selectedRowsData }, classTable, grid, selectAllCheckBox) => { // selectedRowKeys: danh sách key, selectedRowsData: danh sách row
        //console.log('onSelectionChanged 123', { selectedRowKeys, selectedRowsData, current: grid })
        let custodycdData = selectedRowsData && selectedRowsData.length > 0 ? selectedRowsData[0]['custodycd'] || '' : '';
        if (this.state.currentCustodycdSelected !== custodycdData) {
            this._setState({ currentCustodycdSelected: custodycdData }, () => {
                // grid && grid.refresh(); // Cần xem lại bởi sẽ gây load lại api
                this.hideOtherCustodycdSelection();
            })
        }

        let listOrderCancelToSet = selectedRowsData;
        let listOrderIdCancelToSet = selectedRowKeys;

        // custom lại các dòng có thể chọn trong list dữ liệu đã chọn (chỉ cho chọn một tài khoản đầu tiên)
        if (custodycdData) {
            listOrderCancelToSet = selectedRowsData.filter(item => item.custodycd === custodycdData);
            listOrderIdCancelToSet = listOrderCancelToSet && listOrderCancelToSet.length > 0 ? listOrderCancelToSet.map(item => { return item['odorderid'] }) : [];
            grid && grid.selectRows(listOrderIdCancelToSet);

            // Tích nút tích All trong trang nếu thỏa mãn điều kiện
            let dataItems = grid ? (grid.getDataSource()._items || []) : [];
            let selectableRows = dataItems.filter(item => item.custodycd === custodycdData);

            if (listOrderCancelToSet.length >= selectableRows.length) {
                selectAllCheckBox && selectAllCheckBox.option("value", true);
            }
        }
        this._setState({
            listOrderIdCancel: listOrderIdCancelToSet,
            listOrderCancel: listOrderCancelToSet
        }, () => {
            this.addBtnCancel()
        });
    }


    setReloadData = (_onReloadData, keepSelection = false) => {
        let onReloadData = _onReloadData ? _onReloadData : !this.state.onReloadData
        this._setState({ onReloadData, keepSelection })
    }

    apiLoadAll = async (obj) => {
        try {
            const { userInfo, currentCustomer } = this.props;
            const { accountInfo, socketSEActionCounter } = this.props;
            const { currentAccountId, curCustomer, typeAccount } = { ...accountInfo }
            const getOrderFunc = inquiryService.getAllOrdersBroker
            let custid = null;

            if (this.isBroker()) {
                custid = userInfo ? userInfo.custid : null;
            } else {
                custid = userInfo ? userInfo.custid : null;
            };
            this._setState({ loadStatus: LoadStatus.LOADING });
            return getOrderFunc(custid, currentAccountId)
                .then((data) => {
                    this._setState({ loadStatus: LoadStatus.LOADED });
                    data = this.processOrderData(data)
                    return data
                })
                .catch((error) => {
                    this._setState({ loadStatus: LoadStatus.FAIL });
                    ToastUtil.errorApi(error, 'common.fail-to-load-data');
                    return []
                });

        } catch (e) {
            //console.log('apiLoadAll().:Err.:', e)
            return []
        }
    }

    addBtnCancel = () => {
        const { listOrderIdCancel } = this.state
        let func = this.onCancelMultipleOrder
        let text = LanguageUtils.getMessageByKey("trade.order.cancel", this.props.language)
        let length = (listOrderIdCancel && listOrderIdCancel.length > 0 && listOrderIdCancel.length) || 0
        let btn = `<button class="btn-action txt---400-14-20 bg-sell">${text} (${length})</button>`

        let _interval = setInterval(function () {
            let _getclassfilter = $('.broker-order-book-table-tableEx .dx-datagrid-filter-row .dx-command-select')
            // let _getclassheader = $('.dx-header-row .dx-command-select')
            let _getclassheader = $('.broker-order-book-table-tableEx .dx-command-select')
            if (_getclassfilter && _getclassfilter.length > 0) {
                _getclassfilter[0].innerHTML = btn
                // _getclassfilter.addClass("dx-command-select-cancel")
                _getclassheader.addClass("dx-command-select-cancel")
                _getclassfilter[0].onclick = func
                _interval && clearInterval(_interval)
                return null
            }
        }, 200)
    }


    onCloseModalBrokerOrderMultiCancel = () => {
        this._setState({ isOpenModalBrokerOrderMultiCancel: !this.state.isOpenModalBrokerOrderMultiCancel })
    }

    twoFactorAuthCb = (authInfo) => {
        const { typeSelectCancel } = this.state
        if (typeSelectCancel === TYPE_SELECT_CANCEL.CANCEL) {
            this.callApiCancelOrder(authInfo)
        }
        if (typeSelectCancel === TYPE_SELECT_CANCEL.CANCEL_MULTI) {
            this.callApiCancelMultpleOrder(authInfo)
        }
    }

    disableConFunc = (item) => {
        const { currentCustodycdSelected } = this.state;
        // //console.log('laojahackgame======> DISABLE CONFUNC',{currentCustodycdSelected, item})
        if (!currentCustodycdSelected) return true;
        if (item['custodycd'] === currentCustodycdSelected) {
            return true
        }
        return false;
    }

    customRowStyle = (e) => {
        // gán class vào ô select theo custodycd để ẩn hiển khi tích
        if (e.rowElement) {
            const { currentCustodycdSelected } = this.state;
            if (e.data) {
                let custodycd = e.data.custodycd;
                custodycd && $(e.rowElement).addClass(custodycd);
                // Disable nút chọn nếu đã có custodycd được chọn và dòng hiện tại có custodycd không trùng

                // if (currentCustodycdSelected && currentCustodycdSelected !== custodycd) {
                //     setTimeout(() => {
                //         $(e.rowElement).find($('.dx-checkbox-container')).addClass('hidden-checkbox');
                //     }, 10)
                // }
            }

        }

    }

    hideOtherCustodycdSelection = () => {
        const { currentCustodycdSelected } = this.state;
        let rowDisplayingArr = $('.dx-row-lines');
        if (currentCustodycdSelected) {
            // //console.log('laojahackgame=====> ALL ELEMENT', { rowDisplayingArr, currentCustodycdSelected });
            if (rowDisplayingArr.length > 0) {
                for (let i = 0; i < rowDisplayingArr.length; i++) {
                    // //console.log('laojahackgame=====> ALL ELEMENT 123', { elementToHide: $(rowDisplayingArr[i]).find($('.dx-checkbox-container')), element: $(rowDisplayingArr[i]), check: $(rowDisplayingArr[i]).hasClass(currentCustodycdSelected), currentCustodycdSelected });
                    if (!$(rowDisplayingArr[i]).hasClass(currentCustodycdSelected)) {
                        $(rowDisplayingArr[i]).find($('.dx-checkbox-container')).addClass('hidden-checkbox');
                    }
                }
            }
        } else {
            // Nếu bỏ tích ======> Hiển thị lại các ô check cho phép nhập
            $('.dx-row-lines .dx-checkbox-container').removeClass('hidden-checkbox');
        }
    }

    contentReadyCallback = () => {
        this.hideOtherCustodycdSelection();
    }

    render() {
        let { records, dataUpdateTable, firstLoadStatus, onReloadData, listDataFillModal } = this.state;
        let { currentLayoutPageActive, accountInfo } = this.props
        //console.log("binh--this.state", this.state)
        let custodycd = (listDataFillModal && listDataFillModal.length > 0) ? listDataFillModal[0].custodycd : '';
        //console.log('laojahackgame======> DATA CANCEL', listDataFillModal);
        return (
            <RetryableContent className="broker-order-book" status={this.state.loadStatus} onRetry={this.loadData}>
                <div className="table-broker-order-book">
                    {this.state.isOpenModalBrokerOrderMultiCancel &&
                        <ModalBrokerOrderMultiCancel
                            isOpen={this.state.isOpenModalBrokerOrderMultiCancel}
                            twoFactorAuthCb={this.twoFactorAuthCb}
                            onClose={this.onCloseModalBrokerOrderMultiCancel}
                            listReconfirmOrder={listDataFillModal}
                            loadStatusCancelMultiBrokerOrder={this.state.loadStatusCancelMultiBrokerOrder}
                            accountInfo={accountInfo}
                            custodycd={custodycd}
                        />
                    }
                    <TableExtremeCustom2
                        OBJLAYOUT={currentLayoutPageActive}
                        keyStoreUpdate="odorderid" // key của row 
                        columnsDefault={columnsDefault} // ds cột
                        totalDefault={totalDefault} // ds sum (nếu có)
                        renderSwitch={this.renderSwitch} // func render dữ liệu
                        returnCaption={this.returnCaption} // func render column name
                        isUseSelection={true} // true/false: hiện/ẩn chọn row
                        isLoadPanel={false} // true/false: hiện/ẩn iconLoadPanel
                        isPager={false} //true/false:  hiển thị giao diện phân trang
                        addClass="broker-order-book-table" // class cha
                        onSelectionChanged={this.onSelectionChanged}
                        keyDisableCheckBox="cancelOrderPermission"
                        apiLoadAll={this.apiLoadAll}  // sử dụng Api load all: không cần trả ra totalCount
                        // apiPaging={this.apiPaging} // sử dụng Api phân trang: không trả ra totalCount
                        // apiPagingReturnTotal={this.apiPagingReturnTotal} // sử dụng Api phân trang: trả ra totalCount, có thể trả thêm sumColumn
                        onReloadData={onReloadData} // thay đổi giá trị này để render tableEx
                        // keyRealTime={keyRealTime}
                        // list Column dữ liệu RealTime theo instrument, khi sử dụng realtime=> isPager=false, isLoadPanel=false
                        // convertDataRealTime={this.convertDataRealTime} // convert dataRealTime
                        showFilter={true}
                        // isShowBtnCancelAll={false}
                        addActionToSelection={this.addBtnCancel}
                        disableConFunc={this.disableConFunc} // hàm điều kiện để ẩn hoặc hiện ô tích
                        customStyleTopAndBottomSticky={this.customRowStyle}
                        contentReadyCallback={this.contentReadyCallback}
                        keepSelection={this.state.keepSelection}
                    />
                </div>
                {/* <div className="container-action">
                    <button className="btn btn-action txt---400-14-20 bg-sell" onClick={() => this.onCancelMultipleOrder()}>
                        <FormattedMessage id="trade.order.cancel" />
                    </button>
                </div> */}

            </RetryableContent>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    let _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
    let _accountInfo = _layoutPage && _layoutPage["accountInfo"]

    const userInfo = state.user.userInfo;
    const permissionInfo = userInfo.permissionInfo ? userInfo.permissionInfo : null;
    const defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme
    return {
        config: state.user.userInfo.config,
        permissionInfo: permissionInfo,
        userInfo: userInfo,
        accountInfo: _accountInfo,
        language: state.app.language,
        lang: state.app.language,
        role: state.user.userInfo.role,
        currentLayoutPageActive: _currentLayoutPageActive,
        custSocketODActionCounter: state.socketRealtime.custSocketODActionCounter,
        defaultTheme
    };
};

const mapDispatchToProps = dispatch => {
    return {
        navigate: (path) => dispatch(push(path)),
        updatePlaceQuickOrder: (data, typeOrder) => dispatch(actions.updatePlaceQuickOrder(data, typeOrder)),
        updateProTrade: (data, typeOrder) => dispatch(actions.updateProTrade(data, typeOrder)),
        updatedLayoutPage: (data, key) => dispatch(actions.updatedLayoutPage(data, key)),
        updateInfoCheckAuth: (data, key) => dispatch(actions.updateInfoCheckAuth(data, key)),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
        setCustodycdCustomerScreen: (custodycd, typeScreen, callback) => dispatch(actions.setCustodycdCustomerScreen(custodycd, typeScreen, callback)),
        setAccountIdScreen: (accountId, typeScreen) => dispatch(actions.setAccountIdScreen(accountId, typeScreen)),
        saveTradeAuthenticated: () => dispatch(actions.saveTradeAuthenticated()),
        setTradeAuthSuccess: () => dispatch(actions.setTradeAuthSuccess()),
        updatedSymbolLayoutPage: (symbolId, from, id) => dispatch(actions.updatedSymbolLayoutPage(symbolId, from, id)),
        clearDataPlaceQuickOrder: (typeOrder, arrKeep) => dispatch(actions.clearDataPlaceQuickOrder(typeOrder, arrKeep)),

    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(BrokerOrderBook);
