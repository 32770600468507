import React, { Component } from "react";
import { push } from "connected-react-router";
import _ from 'lodash';
import Autosuggest from 'react-autosuggest';
import { connect } from "react-redux";
import { emitter, FOCUS_SYMBOL_PLACE_ORDER } from 'utils/EventEmitter';

import * as actions from "../../../../store/actions";
import { EXCHANGE, KeyCodeUtils, LoadStatus, Role, STOCKTYPE } from '../../../../utils';
import $ from "jquery";
import './SymbolSuggestTrade.scss';

function addCssUsingWidget(className, widgetClass) {
    $(document).ready(function () {
        let _html = $('.' + className)
        // let _htmlOld = $('#' + `${widgetClass}_new`)
        // _html = _html.clone()
        //console.log('addCssUsingWidget.:1=', className, widgetClass)
        // _htmlOld.remove()
        if (_html && _html.length > 0) {
            // _html = _html.clone()
            // _html.insertAfter('.symbol-info-widget')
            _html.insertAfter('.' + widgetClass)
            let timer = setTimeout(function () {
                //console.log('addCssUsingWidget.:2=', className, widgetClass)
                var e = document.getElementById(`${widgetClass}_new`) || document.getElementById(`${widgetClass}`);
                if (e) {
                    //console.log('addCssUsingWidget.:3=', className, widgetClass)
                    e.id = `${widgetClass}_new`;
                    //console.log('addCssUsingWidget.:  _html.parent', _html.parent(), _html.parent()[0].id, _html.parent().children())
                    let idParent = _html.parent() && _html.parent()[0].id
                    $(`#${idParent}`).prepend(_html)
                    $(`#${idParent}`).prepend($(`.${widgetClass}`))
                    e.classList.remove('hidden')
                }
                clearTimeout(timer)
            }, 0)

            // return null
        }

    });
}

function removeCssUsingWidget(idClass, widgetClass) {
    // $(document).ready(function () {
    // let _htmlOld = $('#' + `${ widgetClass }_new`)
    // // _html = _html.clone()
    // _htmlOld.remove()
    var e = document.getElementById(idClass);
    if (e) {
        e.parentNode.removeChild(e);
    }
    // let _htmlOld2 = $('#' + `${ idClass }`)
    // _htmlOld2.css('display', 'none')
    // //console.log('removeCssUsingWidget.:idClass=', idClass)
    // _htmlOld2.remove()
    // });
}

class SymbolSuggestTrade extends Component {


    static getDerivedStateFromProps(nextProps, prevState) {

        let changes = {};
        if (nextProps.currentSymbol !== prevState.currentSymbol) {
            changes.currentSymbol = nextProps.currentSymbol;
        }

        if (nextProps.symbolToDisplay !== prevState.symbolToDisplay) {
            changes.symbolToDisplay = nextProps.symbolToDisplay;
        }

        if (nextProps.symbolLoadStatus !== prevState.symbolLoadStatus) {
            changes.symbolLoadStatus = nextProps.symbolLoadStatus;
        }

        if (_.keys(changes).length > 0) {
            return changes;
        }

        return null;
    }


    initialState = {
        foundList: [],
        oldCurrentSymbol: "",
        ownCurrentSymbol: "",
        isDisplayOwnSymbol: false,
        onMouseOver: false,
        symbolObj: null,
        isFocusingInput: false,
        isHoveringInput: false,
        currentIsDisplayLabel: true,  // Có hiển thị chữ mã CK ở trên input không
        symbolSearchArray: [],  // Array tím kiếm khác với mã symbol để trong trường symbol của từng phần tử
        defaultSymbolList: [], // Mảng symbol mặc định khi array không được truyền vào
        moreClicked: false, // Nút sổ xuống đã được nhấn hay chưa 
        mouseOverMoreBtn: false, // Chuột đang di vào nút sổ xuống
        disableMoreBtn: false, // disable nút sổ xuống
        // Phần dưới dành cho phân trang
        rowsPerPage: 10, // Mặc định có 10 bản gi trong một trang
        pages: 1, // Số trang sẽ được cập nhật theo số lượng bản ghi được sử dụng để bỏ vào foundList
        saveSymbol: [], // Mảng symbol để dành để bắn vào foundList
        //symbolSearchArrayStr
        isDisplayError: true, // hiển thị lỗi dưới input
        errorMsgID: "", // ID của lỗi cần hiển thị,
        titleID: "", // ID của title cần hiển thị ngoài Mã CK
        isUpperCase: true, // Có upperCase cho giá trị nhập vào 
        oldValidValue: "",
        unicodeValid: false,
        isUnicodePrevented: false,
        currentActiveIndex: -1, // bằng -1 trong trường hợp không có active
        visibleTopLimit: 0,   // Khoảng cách nhìn thấy từ top đến đỉnh các bản ghi nhìn thấy
        visibleBottomLimit: 130, // Khoản cách từ đỉnh đến đuôi của các bản ghi nhìn thấy,
        suggestions: [],
        symbol: '',
        symbolValid: true,
        isTooltip: false,
        symbolFake: ''// Sử dụng khi dùng phim mũi tên down, up
    };

    constructor(props) {
        super(props);

        this.inputRef = React.createRef();
        let keyArr = Object.keys(this.props.symbolWithIndex)

        this.state = {
            ...this.initialState,
            suggestions: keyArr.map(item => this.props.symbolWithIndex[item]),

        }
        this.listenToTheEmitter()
        this.inputSymbol = React.createRef();
    }

    INVISIBLE_TOP_OFFSET = 130;  // Độ cao vùng mã nhìn thấy trong suggest box (130px)

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    componentDidMount() {
        this.mounted = true
        const { isDisplayLabel, isDisplayError, errorMsgID, titleID, isUpperCase, className, isWidget, widgetClass } = this.props;
        if (isWidget && className && widgetClass) {
            addCssUsingWidget(className, widgetClass) // fix outside widget
        }
        if (isDisplayLabel !== undefined && isDisplayLabel !== null) {
            this._setState({ currentIsDisplayLabel: isDisplayLabel });
        }

        if (isDisplayError !== undefined && isDisplayError !== null) {
            this._setState({ isDisplayError: isDisplayError });
        }


        if (errorMsgID) {
            this._setState({ errorMsgID: errorMsgID });
        }

        if (titleID) {
            this._setState({ titleID: titleID });
        }

        if (isUpperCase !== undefined && isUpperCase !== null) {
            this._setState({ isUpperCase: isUpperCase });
        }

        // document.body.addEventListener('keydown', this.handlerKeyDown);
    }


    handlerKeyDown = (event) => {
        const { suggestions, portFolioList, symbolValid, symbolFake } = this.state;
        const inputFocus = this.inputSymbol.current ? (document.activeElement === this.inputSymbol.current ? true : false) : false;
        if (!inputFocus) {
            return
        }
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.TAB && inputFocus) {
            event.preventDefault();
            this.onHandleBlurInput(event);
            this.inputSymbol.current && this.inputSymbol.current.blur();
            if (this.isBroker()) {
                emitter.emit("onTabSymbolTrade_Broker", true)
            } else {
                emitter.emit("onTabSymbolTrade_Customer", true)
            }
        }
    }

    onHandleBlurInput = (event, suggestion) => {
        //console.log("binh_handlerKeyDown", this.state, suggestion)
        const { symbolWithIndex, usePortfolioList } = this.props;
        const { suggestions, portFolioList, symbolValid, symbolFake } = this.state;
        if (symbolFake) {
            let stockFound = symbolWithIndex[symbolFake.toUpperCase()];
            if (stockFound) {
                this.props.handleInputChange(stockFound.id, true, stockFound);
                return;
            } else {
                this.reset(); return;
            }
        } else {
            if (!symbolValid) { this.reset(); return; }
        }
    }

    componentWillUnmount() {
        emitter.removeListener(FOCUS_SYMBOL_PLACE_ORDER, this.callback);
        const { idClass, isWidget } = this.props;
        if (isWidget && idClass) {
            removeCssUsingWidget(idClass) // fix outside widget
        }
        this._setState({
            ...this.initialState
        })
        this.inputSymbol = undefined
        this.inputRef = undefined
        // document.removeEventListener('keydown', this.handlerKeyDown);
        this.mounted = false
    }

    isSymbolValid = (symbol) => {
        const { symbolLoadStatus } = this.state;
        const { symbolWithIndex, dataFromInstruments } = this.props;
        // //console.log('laojahackgame=======> IS SYMBOL VALID', { symbol, symbolFound: symbolWithIndex[symbol], symbolLoadStatus, dataFromInstruments })
        if (symbolLoadStatus !== LoadStatus.LOADED && !dataFromInstruments) {
            // no stocks data from finfo-api
            return false;
        }
        if (symbol) {
            let stocksData = symbolWithIndex;
            let stockKey = Object.keys(stocksData).filter(
                sym => sym.toLowerCase() === symbol.toLowerCase()
            );
            return stockKey.length > 0;
        } else {
            return false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // if (prevState.symbolToDisplay !== this.state.symbolToDisplay && this.state.symbolToDisplay !== this.state.symbol) {
        if (prevState.currentSymbol !== this.state.currentSymbol && this.state.currentSymbol !== this.state.symbol) {
            // Chú ý đoạn này mã bị thay đổi từ bên ngoài nhưng valid chưa được cập nhật ra ngoài 
            // Case mã không hợp lệ =====> đã được xử lý set sang mã khác
            this._setState({
                symbol: this.props.symbolToDisplay,
                symbolValid: this.isSymbolValid(this.props.symbolToDisplay)
            });
        }

        if ((prevState.symbolLoadStatus !== this.state.symbolLoadStatus) && this.state.symbol) { // Đợi api load xong list symbol và state != rỗng
            this._setState({ symbolValid: this.isSymbolValid(this.state.symbol) })
        }
    }

    isBroker = () => {
        const { role } = this.props;
        return role === Role.BROKER;
    };

    sortSuggestions(suggestions, value) {
        const { isNotSort } = this.props
        var results = null
        if (isNotSort) {
            results = suggestions
        } else {
            results = _.sortBy(suggestions, (item) => {
                let _symbolToDisplay = this.getSymbolToDisplay(item)
                return _symbolToDisplay && _symbolToDisplay.toLowerCase().indexOf(value.toLowerCase());
            })
        }
        return results;
    }

    getSuggestions = symbol => {
        if (!symbol || symbol.length === 0) return [];
        const inputValue = symbol.trim().toLowerCase();
        if (!this.props.symbolWithIndex) {
            return [{ symbol: symbol.toUpperCase() }];
        };
        let symbolWithIndex = this.props.symbolWithIndex;
        var keyArr = []
        var self = this
        _.filter(symbolWithIndex, (item) => {
            let _symbolToDisplay = self.getSymbolToDisplay(item)
            if (_symbolToDisplay.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0) {
                keyArr.push(item.id)
            }
        });
        var suggestArr = keyArr.map(function (key) {
            return symbolWithIndex[key];
        });

        suggestArr = suggestArr.length > 0 && suggestArr.filter(
            symbol => (symbol && symbol.exchange && symbol.exchange === EXCHANGE.HOSE) ? symbol.StockType && symbol.StockType !== STOCKTYPE.BOND : true //huy.quang: bo cac ma trai phieu stocktype = 1
        );
        // Haki.: Ưu tiên KQ có phần đầu giống inputValue và sắp xếp, phần còn lại sắp xếp theo A-Z
        let _Results = suggestArr
        let lengthInputValue = inputValue && inputValue.length;
        if (_Results && _Results.length > 0) {
            _Results = _Results.map(function (e) {
                let symbol = e && e.symbol && e.symbol.toLowerCase()
                if (symbol) {
                    if (symbol.substring(0, lengthInputValue) == inputValue) {
                        // KQ có phần đầu giống inputValue
                        e.idsort = 0
                    }
                    else {
                        // KQ còn lại
                        e.idsort = 1
                    }
                }
                return e
            })
            // Haki.: Ưu tiên KQ có phần đầu giống inputValue và sắp xếp, phần còn lại sắp xếp theo A-Z
            _Results = _.orderBy(_Results, ['idsort', 'symbol'], ['asc', 'asc']);
        }
        this.props.isAllSymbol && _Results.unshift({ id: "All" })
        return _Results;
    };

    onSuggestionsFetchRequested = ({ value, reason }) => {
        const { symbolValid } = this.state;
        // dat.nt
        // case mã nhập cũ đã chính xác => nhập ký tự mới sẽ clear mã cũ và tìm kiếm lại
        if (symbolValid && reason === "input-changed") return true;
        this._setState({
            suggestions: this.sortSuggestions(this.getSuggestions(value), value)
        });
    };

    onSuggestionsClearRequested = () => {
        this._setState({
            suggestions: []
        });
    };
    getSymbolToDisplay(symbolObj) {
        //Off hiển thị tên tổ chức phát hành

        const { isOrder, isConditionOrder } = this.props
        let _id = (symbolObj && symbolObj.id) || ''
        let _FullName = (symbolObj && symbolObj.FullName) || ''
        let _symbolToDisplay = _id ? _id + ' | ' + _FullName : ''
        return (isConditionOrder || isOrder) ? _symbolToDisplay : _id
        // return _id
    }

    getSymbolToDisplaySelected(symbolObj) {
        let _id = (symbolObj && symbolObj.id) || ''
        return _id
    }

    getSuggestionValue = suggestion => {

        let _suggestion = this.getSymbolToDisplay(suggestion)
        return _suggestion;
    }
    renderSuggestion = suggestion => {
        const { lang } = this.props;
        let _suggestion = this.getSymbolToDisplay(suggestion)
        return (
            <>
                <div className="suggest-item">
                    {_suggestion}
                </div>
            </>
        );
    };

    onSuggestionSelected = (event, selected) => {
        let suggestion = selected.suggestion;
        let symbolToDisplay = this.getSymbolToDisplaySelected(suggestion);
        this._setState({
            symbol: symbolToDisplay,
            symbolValid: true // Nhận giá trị đã chọn =====> Hợp lệ
        });
        if (this.props.isAutoSelectValid === true) {
            let timer = setTimeout(() => {
                this.inputSymbol.current.setSelectionRange(0, symbolToDisplay.length);
                clearTimeout(timer)

            }, 0);
        }
        this.props.handleInputChange(suggestion.id, true, suggestion);
        this.handlerCorrectSymbol(suggestion);
    };

    shouldRenderSuggestions = value => {
        return true;
    };

    reset() {
        this._setState({
            symbol: ''
        });
        this.props.handleInputChange('', false, null);
        this.onSuggestionsFetchRequested({ value: '' });
    }

    handleBlurInput = (event, suggestion) => {
        const { symbolWithInde } = this.props;
        const { suggestions, symbolValid, symbol, symbolFake } = this.state;
        let symbolCur = symbolFake ? symbolFake : symbol // Nếu người dùng dùng mũi bàn phím chọn mã và blur thì lấy symbolFake nếu k thì lấy symbl
        let validSymbol = this.isSymbolValid(symbolCur)
        // //console.log("binh_handleBlurInput", { symbolCur, validSymbol }, { event, suggestion }, this.props, this.state)
        let symbolObj = {}
        if (validSymbol) {
            symbolCur = symbolCur.toUpperCase();
            symbolObj = this.props.symbolWithIndex[symbolCur];
            this.props.handleInputChange(symbolCur, true, symbolObj);
            if (this.props.isAutoSelectValid === true) {
                let timer = setTimeout(() => {
                    this.inputSymbol && this.inputSymbol.current.setSelectionRange(0, symbolCur.length);
                    clearTimeout(timer)
                }, 0);
            } else {
                this.inputSymbol.current.blur();
            }
        } else {
            this.reset();
        }
    }

    setStockonBlur = (stockFound) => {
        return; // off khi onblur tìm giá trị đúng gần nhất
        let timer = setTimeout(() => {
            let symbolToDisplay = this.getSymbolToDisplaySelected(stockFound)
            this._setState({
                symbol: symbolToDisplay,
                symbolValid: true
            });
            this.handlerCorrectSymbol(stockFound);
            this.props.handleInputChange(stockFound.id, true, stockFound);
            clearTimeout(timer)
        }, 0);
    }

    checkRef = async () => {
        if (this.inputSymbol && this.inputSymbol.current) {
            await this.inputSymbol.current.focus()
            await this.inputSymbol.current.setSelectionRange(0, 0);
        }
    }
    checkIsSymbolValidNew = (symbol) => {
        const char1 = symbol.slice(0, (symbol.length / 2));
        const char2 = symbol.slice((symbol.length / 2), symbol.length);
        if (char1 !== char2) {
            return true;
        }
        else {
            return false;
        }
    }

    isCheckSuggestionAllowValid = (value) => {
        let _suggestions = this.getSuggestions(value)
        if (_suggestions && _suggestions.length == 1) {
            return true
        } else {
            return false
        }
    }

    convertValueInput = (value) => { // AAA | CÔNG TY CỔ PHẦN NHỰA AN PHÁT XANH => AAA
        let valueConvert = value
        valueConvert = valueConvert.split("|")
        if (valueConvert && valueConvert.length > 1) {
            valueConvert = valueConvert[0].trim()
            return valueConvert
        }
        return value || ''
    }

    handleChangeInput = (event, { newValue, method }) => {
        const { symbolValid: oldSymbolValid, symbol: oldSymbol } = this.state;

        let newValueConvert = this.convertValueInput(newValue)
        if (method == 'down' || method == 'up') {
            this._setState({ symbolFake: newValueConvert })
            return
        } else {
            let _suggestions = this.getSuggestions(newValueConvert)
            if (_suggestions && _suggestions.length > 0 && _suggestions[0].id) {
                this._setState({ symbolFake: _suggestions[0].id })
            } else {
                this._setState({ symbolFake: '' })
            }
        }

        // newValue = newValue.toUpperCase();
        // let newValueConvert = this.convertValueInput(newValue)
        this._setState({
            symbol: newValue
        });
        let validSymbol = this.isSymbolValid(newValue) && this.isCheckSuggestionAllowValid(newValue);
        this._setState({ symbolValid: validSymbol });
        let symbolObj = null;
        if (validSymbol) {
            newValue = newValue.toUpperCase();
            symbolObj = this.props.symbolWithIndex[newValue];
            this.props.handleInputChange(newValue, true, symbolObj);
            if (this.props.isAutoSelectValid === true) {
                let timer = setTimeout(() => {
                    this.inputSymbol.current.setSelectionRange(0, newValue.length);
                    clearTimeout(timer)
                }, 0);
            }
            // this.inputSymbol.current.blur();
        } else {
            this.onSuggestionsFetchRequested({ value: newValue.toUpperCase() });
            this.props.handleInputChange(newValue, false, null);
            if (oldSymbolValid) {
                this._setState({
                    symbol: newValue,
                    symbolValid: false
                });
            }
        }
    };

    handlerCorrectSymbol = (correctSuggestion) => {
        const { isRedirect, updateSymbolPlaceQuickOrder } = this.props;
        if (isRedirect !== false) {
            updateSymbolPlaceQuickOrder(correctSuggestion.id);
        }
    }

    showAllSymbol = () => {
        const { isNotSort } = this.props
        if (!this.props.symbolWithIndex || this.state.symbol !== "") return;
        let stocksData = this.props.symbolWithIndex
        let stockKeys = Object.keys(stocksData);
        if (isNotSort) {
            stockKeys = stockKeys
        }
        else {
            stockKeys = _.sortBy(stockKeys);
        }
        let suggestArr = stockKeys.map(key => {
            return stocksData[key];
        })
        this._setState({ suggestions: suggestArr });
    }

    onInputFocus = (e) => {
        e.target.select();
    }

    callback = () => {
        this.inputSymbol.current.focus();
    }


    listenToTheEmitter = () => {

        emitter.on(FOCUS_SYMBOL_PLACE_ORDER, this.callback);

    }

    onHandleFocus = () => {
        this.inputSymbol.current.focus();
    }

    getInputRef = () => {
        return this.inputSymbol
    }

    onKeyDown = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            this.handleBlurInput(event, null);
        }
    };

    render() {
        const { currentIsDisplayLabel, isDisplayError, errorMsgID,
            titleID, isTooltip } = this.state;
        const { currentSymbol, suggestId, symbolToDisplay, isOrder, isConditionOrder, quote, disabled, isWidget, className, widgetClass } = this.props;
        const { suggestions, symbol, symbolValid } = this.state;
        // //console.log("binh---symbol--", this.state)
        const inputProps = {
            placeholder: this.props.placeholder ? this.props.placeholder : '',
            value: symbol ? symbol : '',
            onChange: this.handleChangeInput,
            onClick: () => {
                this.showAllSymbol();
            },
            onBlur: (event, { highlightedSuggestion }) => {
                this.handleBlurInput(event, highlightedSuggestion);
                // this.onHandleBlurInput(event, highlightedSuggestion);
                // this._setState({ isTooltip: true })
            },
            onFocus: (e) => {
                isConditionOrder && this.onInputFocus(e)
            },
            onFocus: (e) => {
                (isConditionOrder || isOrder) && this.onInputFocus(e)
            },
            onKeyDown: (e) => {
                this.onKeyDown(e)
            },
            ref: this.inputSymbol,
            disabled: disabled
        };
        let _className = isWidget ? "symbol-suggest-trade hidden" : "symbol-suggest-trade"
        return (
            <div id={widgetClass} className={_className} >
                <div className="suggest-input-container txt---500-14-20">
                    <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}  //cập nhật lại danh sách suggestion khi input has foc 
                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                        getSuggestionValue={this.getSuggestionValue}
                        renderSuggestion={this.renderSuggestion}
                        onSuggestionSelected={this.onSuggestionSelected}
                        shouldRenderSuggestions={this.shouldRenderSuggestions}
                        highlightFirstSuggestion={true}
                        inputProps={inputProps}
                        //ref={this.storeInputReference}
                        id={suggestId ? suggestId : ''}
                    />
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        symbolLoadStatus: state.symbol.allQuotesLoadStatus,
        // symbolWithIndex: state.symbol.allQuotesWithIndex,
        role: state.user.userInfo.role,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        navigate: (path) => dispatch(push(path)),
        updateSymbolPlaceQuickOrder: (symbol) => dispatch(actions.updateSymbolPlaceQuickOrder(symbol)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SymbolSuggestTrade);