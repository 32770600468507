import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import RetryableContent from "../../../components/RetryableContent";
import { userService } from "../../../services";
import * as actions from "../../../store/actions";
import { CommonWidgetUtils, LoadStatus, Role, ToastUtil } from "../../../utils";
// import LogoBIDV from '../../../assets/icons/new/logo_BIDV.png'
import { ReactComponent as LOGOTECH } from '../../../assets/icons/new/logo-techcombank.svg'
import IconArrowDark from '../../../assets/icons_new/setting_display/icon_arrow_dark.svg'
import IconArrowLight from '../../../assets/icons_new/setting_display/icon_arrow_light.svg'
import IconCopy_Dark from '../../../assets/icons/IconCopy_Dark.svg'
import IconCopy_Light from '../../../assets/icons/IconCopy_Light.svg'
import * as htmlToImage from 'html-to-image';
import './ModalPayment.scss';

import {
    canCopyImagesToClipboard,
    requestClipboardWritePermission,
    copyImageToClipboard
} from 'copy-image-clipboard'

class ModalPayment extends Component {
    constructor(props) {
        super(props);
        this.domEl = React.createRef(null);
        this.test = React.createRef(null);
    }

    initialState = {
        step: 1,                                    //Các bước thực hiện
        loadStatus: LoadStatus.NONE,
        authStatus: LoadStatus.NONE,
        qrcode: "",
        currentAccount: null
    };

    state = {
        ...this.initialState
    };

    convertb64toBlob = (data) => {
        let img = `data:image/jpeg;base64,${data}`
        return img
    }

    backStep = () => {
        this._setState({ step: 1 });
    };

    onClose = () => {
        this.props.setIsOpenPayment(false)
    };

    loadQRCode(account) {
        this._setState({ loadStatus: LoadStatus.LOADING });
        userService.getQRCode(account.acctno)
            .then((data) => {
                let qrcode = this.convertb64toBlob(data.vietQRImage)
                this._setState({
                    step: 2, qrcode: qrcode, loadStatus: LoadStatus.LOADED, currentAccount: account
                });
            })
            .catch((error) => {
                this._setState({ loadStatus: LoadStatus.FAIL });
                ToastUtil.errorApi(error, "payment.unable-to-load-qr-code");
            });
    }


    setLoadStatus = (status) => {
        this._setState({
            loadStatus: status
        });
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }
	
	componentWillUnmount() {
        this.mounted = false
    }

    componentDidMount() {
        this.mounted = true
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

    }

    componentDidUpdate(prevProps, prevState) {

    }

    //Check tài khoản môi giới
    isBroker = () => {
        const { userInfo } = this.props;
        const role = userInfo ? userInfo.role : '';
        return role === Role.BROKER;
    };


    onDetail = (account) => {
        this.loadQRCode(account)
    }

    onCopyImg = async () => {
        const imageElement = await htmlToImage.toPng(this.domEl.current);
        requestClipboardWritePermission()
            .then((result) => {
                if (!result) {
                    //console.log('qrcode Permission:', result)
                    ToastUtil.errorApi("CM2", 'COPY_QR_CODE');
                    return
                }
            })
            .catch((e) => {
                //console.log('qrcode Permission: ', e)
                localStorage.setItem('qrcode Permission', e);
            })

        const canCopy = await canCopyImagesToClipboard()
        localStorage.setItem('qrcode canCopy', canCopy);

        if (!canCopy) {
            ToastUtil.errorApi("CM2", 'COPY_QR_CODE');
            return
        }

        await copyImageToClipboard(imageElement)
            .then(() => {
                //console.log('Image Copied')
                ToastUtil.success('payment.copy-image-successful');
            })
            .catch((e) => {
                ToastUtil.errorApi("CM2", 'COPY_QR_CODE');
                localStorage.setItem('qrcode do copy', e);
                //console.log('Error: ', e.message)
            })
    }

    onSaveImg = async () => {
        const dataUrl = await htmlToImage.toPng(this.domEl.current);
        var a = document.createElement("a");
        a.href = dataUrl
        a.download = "qrcode.png";
        a.click();
        ToastUtil.success('payment.upload-image-successfully');
    }

    unsecuredCopyToClipboard = (text) => { const textArea = document.createElement("textarea"); textArea.value = text; document.body.appendChild(textArea); textArea.focus(); textArea.select(); try { document.execCommand('copy') } catch (err) { console.error('Unable to copy to clipboard', err) } document.body.removeChild(textArea) };

    onSaveTxt = (acctno) => {
        // await navigator.clipboard.writeText(acctno)
        //     .then(() => {
        //         ToastUtil.success('payment.copy-successfully');
        //     })
        //     .catch((e) => {
        //         ToastUtil.errorApi("CM2", 'COPY_TEXT');
        //         //console.log('Error: ', e.message)
        //     })
        if (window.isSecureContext && navigator.clipboard) {
            navigator.clipboard.writeText(acctno);
            //console.log("")
            ToastUtil.success('payment.copy-successfully');
        } else {
            this.unsecuredCopyToClipboard(acctno);
            ToastUtil.success('payment.copy-successfully');
        }
    }

    convertAcctnoToAccountBank = (acctno) => {
        const { userInfo } = this.props;
        if (userInfo) {
            let _accountBank = userInfo.custodycd + acctno
            return _accountBank
        }
        return '';
    }

    render() {
        const {
            loadStatus, authStatus, step, currentAccount } = this.state;
        const { language, accounts, isOpen, defaultTheme, userInfo } = this.props;
        const combinedStatus = authStatus === LoadStatus.LOADING ? LoadStatus.LOADING : loadStatus;
        return (
            <Modal
                isOpen={isOpen}
                centered={true}
                className="modal-payment"

            >
                <RetryableContent status={combinedStatus} disableRetry={true}>
                    <div className="custom-header">
                        {step == 2 &&
                            <div style={{ cursor: "pointer" }} className=" headB---700-20-30" onClick={this.backStep}>
                                <img className="icon-arrow" src={defaultTheme === "light" ? IconArrowLight : IconArrowDark} alt="" />
                            </div>
                        }
                        <div className=" headB---700-20-30">
                            <FormattedMessage id="payment.payment-information" />
                        </div>
                        <div className="btn-icon-fm" onClick={this.onClose}>
                            <i className="fal fa-times"></i>
                        </div>
                    </div>
                    <ModalBody className="custom-body">
                        {step == 1 &&
                            accounts && accounts.map((account) => {
                                return (
                                    <div className='item' ref={this.test}>
                                        <div className='left'>
                                            {/*<img src={LogoBIDV} alt="logo-bidv" width={132} height={45} style={{ objectFit: "cover" }} />*/}
                                            <LOGOTECH />
                                            <div className='content-item'>
                                                <div className='text-active'>{this.convertAcctnoToAccountBank(account.cdcontent)}</div>
                                                <div className='text-not-active'>
                                                    <FormattedMessage id="payment.acctno" values={{value: account.cdcontent}}/>
                                                </div>
                                            </div>
                                            <img src={defaultTheme === "light" ? IconCopy_Light : IconCopy_Dark} alt="copy" className="icon-copy" onClick={() => this.onSaveTxt(this.convertAcctnoToAccountBank(account.cdcontent))} />
                                        </div>
                                        {/*
                                            <div className='right' onClick={() => this.onDetail(account)}>
                                                <img className="icon-arrow" src={defaultTheme === "light" ? QRCode_Light : QRCode_Dark} alt="" />
                                            </div>
                                        */}
                                    </div>

                                )
                            })
                        }
                        {step == 2 &&
                            <div className='detail-qr'>
                                <div id="domEl" ref={this.domEl}>
                                    <div className='title txt---500-16-24'>{currentAccount.custodycd} - {currentAccount.entypename} VIX</div>
                                    <div className='custom-qrcode'><img src={this.state.qrcode} alt="logo-bidv" width={"100%"} /></div>
                                </div>
                                <div className="row gutters-5 align-items-center h-row container-btn col-12">
                                    {/* Chia sẻ ảnh */}
                                    <div className="col-6">
                                        <button className="btn-refresh txt---500-16-24 btn-qr"
                                            onClick={() => this.onCopyImg()}
                                        >
                                            <FormattedMessage id="payment.copy-photos" />
                                        </button>
                                    </div>
                                    {/* Lưu ảnh */}
                                    <div className="col-6">
                                        <button className="btn-confirm txt---500-16-24 btn-qr"
                                            onClick={() => this.onSaveImg()}
                                        >
                                            <FormattedMessage id="payment.save-image" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                    </ModalBody>

                </RetryableContent >
            </Modal >
        );
    }

}

const mapStateToProps = state => {
    const userInfo = state.user.userInfo;

    let _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    let _layoutPage = state.layout.layoutPage
    let _accountInfo = _layoutPage && _layoutPage[_currentLayoutPageActive]["accountInfo"]
    let { accounts, currentAccountId } = { ..._accountInfo }
    const _accounts = accounts.filter(item => item.accounttype !== "FDS");
    let defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme

    return {
        userInfo: userInfo,
        language: state.app.language,
        accounts: _accounts,
        isOpenPayment: state.layout.isOpenPayment,
        defaultTheme: defaultTheme
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
        setIsOpenPayment: (isOpen) => dispatch(actions.setIsOpenPayment(isOpen))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalPayment);

