import * as socket from '../../socket';
import { EXCHANGE } from "../../utils";
import { cloneDeep } from 'lodash';

// const CONNECTION_METADATA_PARAMS = {
// 	version: '__sails_io_sdk_version',
// 	platform: '__sails_io_sdk_platform',
// 	language: '__sails_io_sdk_language',
// 	clientid: 'clientid',
// 	clientsecret: 'clientsecret'
// };

// const SDK_INFO = {
// 	version: '1.2.1',
// 	platform: typeof module === 'undefined' ? 'browser' : 'node',
// 	language: 'javascript',
// 	clientid: config.api.CLIENT_ID,
// 	clientsecret: config.api.CLIENT_SECRET
// };
// let queryString = '';
// Object.keys(CONNECTION_METADATA_PARAMS).forEach(key => {
// 	queryString += '&' + CONNECTION_METADATA_PARAMS[key] + '=' + SDK_INFO[key]
// });
// if (queryString.length > 0) queryString = queryString.slice(- (queryString.length - 1));

// const socket = io(API_ROOT_URL, {
// 	path: "/realtime/socket.io",
// 	transports: ['websocket'],
// 	query: queryString,
// });
let subscribeStorage = [];

// socket.on('connect', () => {
// 	//console.log('[socket] Connected');
// });

// socket.on('disconnect', (reason) => {
// 	//console.log('[socket] Disconnected:', reason);
// });

// socket.on('error', (error) => {
// 	//console.log('[socket] Error:', error);
// });

// index by Day
let cacheIndexData = {
	// HNX: {
	// time: 0,
	// volume: 0,
	// price: 0,
	// }
}

function getUpdateBySocket(transData) {
	const symbolName = transData.SB;
	let transDate = transData.TD.split('/');
	const transTime = transData.FT.split(':');
	transTime[2] = '00' // làm tròn giây khi nhận socket
	const transUnix = new Date(parseInt(transDate[2]), parseInt(transDate[1]) - 1, parseInt(transDate[0]),
		parseInt(transTime[0]), parseInt(transTime[1]), parseInt(transTime[2])).getTime() / 1000;
	//console.log('updateLastBar socket', transData, transData.TD, transUnix)
	const parsedSymbolData = {
		time: transUnix,
		volume: parseInt(transData.FV),
		// price: isDerivativeInstrument ? parseInt(transData.FMP) : parseInt(transData.FMP) / 1000,
	};
	const subscribeData = subscribeStorage.find(_subscribe => _subscribe.name === symbolName);
	//console.log("[subscribeBars]: subscribeData====", subscribeData)
	if (subscribeData) {
		// Tạm bỏ logic "không update" điểm outdate do đang hardcode lastBar.time = eventBarData.time
		// if (transUnix < subscribeData.lastBar.time / 1000) {
		// 	alert(0)
		// 	return;
		// };
		const { symbolInfo } = subscribeData;
		const isDerivativeInstrument = symbolInfo['exchange'] && symbolInfo['exchange'] === EXCHANGE.DERIVATIVE;
		parsedSymbolData['price'] = isDerivativeInstrument ? parseFloat(transData.FMP) : parseInt(transData.FMP) / 1000;
		//console.log('updateLastBar', parsedSymbolData, transData.FMP, isDerivativeInstrument, parsedSymbolData['price'])
		const lastBar = updateLastBar(parsedSymbolData, subscribeData);

		if (lastBar) {
			subscribeData.tvCallback(lastBar); // Send data by "onRealtimeCallback"
		};
	};
}

function getUpdateIndexMarketBySocket(transData) {
	const symbolName = transData.MC;
	let transDate = transData.TD.split('/');
	const transTime = transData.IT.split(':');
	transTime[2] = '00' // làm tròn giây khi nhận socket
	const transUnix = new Date(parseInt(transDate[2]), parseInt(transDate[1]) - 1, parseInt(transDate[0]),
		parseInt(transTime[0]), parseInt(transTime[1]), parseInt(transTime[2])).getTime() / 1000;
	//console.log('updateLastBar socket', transData, cacheIndexData)

	const subscribeData = subscribeStorage.find(_subscribe => (_subscribe.name === symbolName));

	//console.log("[subscribeBars]: subscribeData====", subscribeStorage)
	if (subscribeData) {
		const { symbolInfo, subscribeUID, resolution } = subscribeData;
		if (symbolInfo && symbolInfo.type === '1') {
			const parsedSymbolData = {
				time: transUnix,
				volume: transData.TV ? cacheIndexData[subscribeUID].volume ? parseInt(transData.TV) - cacheIndexData[subscribeUID].volume : parseInt(transData.TV) : 0,
				price: transData.MI ? parseFloat(transData.MI) : cacheIndexData[subscribeUID].price,
			};
			if (resolution === 'D' || resolution === '1D') {
			} else {
				parsedSymbolData.volume = transData.TV ? parseInt(transData.TV) - cacheIndexData[`${symbolName}_#_1D`].volume : 0
			}

			const lastBar = updateLastBar(parsedSymbolData, subscribeData);

			if (lastBar) {
				cacheIndexData[subscribeUID].time = lastBar.time / 1000
				cacheIndexData[subscribeUID].price = lastBar.close ? lastBar.close : cacheIndexData[subscribeUID].price
				if (resolution === 'D' || resolution === '1D') {
					cacheIndexData[subscribeUID].volume = lastBar.volume
				} else {
					if (transData.TV && parseInt(transData.TV)) {
						cacheIndexData[`${symbolName}_#_1D`].volume = parseInt(transData.TV)
					}
				}
				subscribeData.tvCallback(lastBar); // Send data by "onRealtimeCallback"
			};
		}
	};

}

socket.on(socket.socketRoomName.trade, (data) => {
	//FT: hh:mm:ss
	//TD: dd/mm/yyyy

	// const eventData = data.split('~'); // Example response: 0~Huydeptrai~BTC~USD~2~335394436~1548837377~0.36~3504.1~1261.4759999999999~1f
	// const subscribeType = parseInt(eventData[0]);

	// if (subscribeType === 3) {
	// 	//console.log('[socket] Snapshot load event complete');
	// 	return;
	// }

	//moi lan nhan msg gia goi ham tvcallback({time, close, open, high, low, volume})

	//gọi call back (translog)

	// const parsedSymbolData = {
	// 	subs_type: subscribeType,
	// 	exchange: eventData[1],
	// 	fsym: eventData[2],
	// 	tsym: eventData[3],
	// 	trade_id: eventData[5],
	// 	time: parseInt(eventData[6]),
	// 	volume: parseFloat(eventData[7]),
	// 	price: parseFloat(eventData[8])
	// };

	// const newSubscribe = {
	// 	channelString,
	// 	subscribeUID,
	// 	resolution,
	// 	symbolInfo,
	// 	lastBar: historyData[symbolInfo.name].lastBar,
	// 	tvCallback: onRealtimeCallback,
	// };
	// const channelString = `${parsedSymbolData.subs_type}~${parsedSymbolData.exchange}~${parsedSymbolData.fsym}~${parsedSymbolData.tsym}`;

	let transData = data.d[0];
	if (!transData) return;
	getUpdateBySocket(transData)
});

socket.on(socket.socketRoomName.index, (data) => {
	let transData = data.d[0];
	if (!transData) return;
	getUpdateIndexMarketBySocket(transData)
});

function updateLastBar(eventBarData, subscribeData) {
	let lastBar = subscribeData.lastBar;
	// harcode 
	// if (subscribeData.resolution === 'D' || subscribeData.resolution === '1D') {
	// 	lastBar.time = eventBarData.time * 1000
	// } else {
	// 	lastBar.time = eventBarData.time * 1000
	// }



	const lastBarPreviousTimeInSeconds = lastBar.time;
	// const barTimeInSeconds = getBarTime(eventBarData.time, lastBar.time, subscribeData.resolution);
	let barTimeInSeconds = eventBarData.time * 1000
	// //console.log('[subscribeBars]: barTimeInSeconds===  ', eventBarData.time, lastBar.time, barTimeInSeconds);

	//eventBarData: info translog
	//subscribeData: cua thu vien

	// const parsedSymbolData = {
	// 	subs_type: subscribeType,
	// 	exchange: eventData[1],
	// 	fsym: eventData[2],
	// 	tsym: eventData[3],
	// 	trade_id: eventData[5],
	// 	time: parseInt(eventData[6]),
	// 	volume: parseFloat(eventData[7]),
	// 	price: parseFloat(eventData[8])
	// };

	// const newSubscribe = {
	// 	channelString,
	// 	subscribeUID,
	// 	resolution,
	// 	symbolInfo,
	// 	lastBar: historyData[symbolInfo.name].lastBar,
	// 	tvCallback: onRealtimeCallback,
	// };
	if (barTimeInSeconds < 0) {
		// Old or incorrect time.
		return false;
	};

	let checkIsSameDay = false;
	let isNeedUpdateCandle, isNeedCreateNewCandle;
	// Lưu ý: Check điều điện time phải đưa data về cùng định dạng của time theo data history
	if (subscribeData.resolution === 'D' || subscribeData.resolution === '1D') {
		// check theo date: dd/mm/yyyy
		barTimeInSeconds = FloorMinutesTimestamp(barTimeInSeconds, false, true)
		isNeedUpdateCandle = isSameDay(barTimeInSeconds, lastBarPreviousTimeInSeconds)
		isNeedCreateNewCandle = barTimeInSeconds > lastBarPreviousTimeInSeconds
		// alert(barTimeInSeconds + ' _____ ' + lastBarPreviousTimeInSeconds)
	} else {
		// check theo date: dd/mm/yyyy hh:mm:00
		isNeedUpdateCandle = barTimeInSeconds == lastBarPreviousTimeInSeconds
		checkIsSameDay = isSameDay(barTimeInSeconds, lastBarPreviousTimeInSeconds)
		isNeedCreateNewCandle = barTimeInSeconds > lastBarPreviousTimeInSeconds && checkIsSameDay;
	}
	// eventBarData.time => time theo socket
	// lastBar.time => time cuối cùng của đồ thị đang vẽ
	lastBar.time = barTimeInSeconds; // Requires time in ms.
	lastBar.close = eventBarData.price;
	// Check time cùng ngày
	//console.log('[subscribeBars]: isNeedUpdateCandle', isNeedUpdateCandle, isNeedCreateNewCandle, barTimeInSeconds, lastBarPreviousTimeInSeconds);

	if (isNeedUpdateCandle) {
		//console.log('[subscribeBars]: Update last bar', eventBarData, lastBar);
		if (eventBarData.price < lastBar.low) {
			lastBar.low = eventBarData.price;
		} else if (eventBarData.price > lastBar.high) {
			lastBar.high = eventBarData.price;
		};
		lastBar.volume += eventBarData.volume;
		// if (subscribeData.resolution === 'D' || subscribeData.resolution === '1D') {
		// 	lastBar.volume += eventBarData.volume;
		// } else {
		// 	lastBar.volume = eventBarData.volume;
		// }
	} else if (isNeedCreateNewCandle) {
		//console.log('[subscribeBars]: Create new bar', lastBar, eventBarData);
		lastBar.open = lastBar.close;
		lastBar.high = lastBar.close;
		lastBar.low = lastBar.close;
		lastBar.volume = eventBarData.volume;
	} else {
		//console.log('[subscribeBars]: socket out date time', barTimeInSeconds, lastBarPreviousTimeInSeconds);
	}
	return lastBar;
};

// function getBarTime(barTimeFromEvent, lastBarTime, resolution) {
// 	let resolutionInMinute = parseInt(resolution);
// 	if (resolution === 'D' || resolution === '1D') {
// 		resolutionInMinute = 1440;
// 	};

// 	const lastBarTimeInSeconds = Math.floor(lastBarTime / 1000);
// 	const differenceInSeconds = barTimeFromEvent - lastBarTimeInSeconds;

// 	if (differenceInSeconds >= 0) {
// 		// Newest data.
// 		const resolutionInSeconds = resolutionInMinute * 60;
// 		if (differenceInSeconds > resolutionInSeconds) {
// 			// Return time for create new bar.
// 			return barTimeFromEvent;
// 		};

// 		if (differenceInSeconds <= resolutionInSeconds) {
// 			// Return time for update last bar.
// 			return lastBarTimeInSeconds;
// 		};
// 	};
// 	return -1;
// };

export function subscribeOnStream(symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback, historyData) {
	// const symbolKeys = parseSymbolToKeys(symbolInfo.name);
	// const fsym = symbolKeys[0];
	// const tsym = symbolKeys[1];
	// const channelString = `0~${symbolInfo.exchange}~${fsym}~${tsym}`;
	// //console.log('[subscribeBars]: Subscribe to streaming. Channel:', channelString);
	// socket.emit(
	// 	'get',
	// 	{
	// 		method: "get",
	// 		url: API_ROOT_URL + 'client/send',
	// 		headers: {},
	// 		// subs: [channelString]
	// 		data: {
	// 			op: 'subscribe',
	// 			args: ['trade:' + symbolInfo.name]
	// 		}
	// 	}, (response) => {

	// 	}
	// );

	if (symbolInfo && symbolInfo.type === "1") {
		const room = `${socket.socketRoomName.index}:${symbolInfo.symbol}`
		socket.registerTopics([room], subscribeUID)
	} else {
		socket.registerTradeTopic(symbolInfo.name, subscribeUID);
	}

	// Subscribe to the CryptoCompare trade channel for the pair and exchange.
	subscribeStorage = new Array() // clear data when subscribeOnStream
	let newSubscribe = {
		name: symbolInfo.type === "1" ? symbolInfo.symbol : symbolInfo.name,
		subscribeUID,
		resolution,
		symbolInfo,
		lastBar: historyData[symbolInfo.name] && historyData[symbolInfo.name].lastBar,
		tvCallback: onRealtimeCallback,
	};

	if (resolution === 'D' || resolution === '1D') {
		// check theo date: dd/mm/yyyy
		newSubscribe.lastBar.time = FloorMinutesTimestamp(newSubscribe.lastBar.time, false, true)
		if (symbolInfo.type === "1") {
			const cloneLastBar = cloneDeep(newSubscribe.lastBar)
			const temp = {
				time: cloneLastBar.time / 1000,
				volume: cloneLastBar.volume,
				price: cloneLastBar.close,
			}
			cacheIndexData[`${subscribeUID}`] = temp
		}
	} else {
		newSubscribe.lastBar.time = FloorMinutesTimestamp(newSubscribe.lastBar.time, false)
		if (symbolInfo.type === "1") {
			const cloneLastBar = cloneDeep(newSubscribe.lastBar)
			const temp = {
				time: cloneLastBar.time / 1000,
				volume: cloneLastBar.volume,
				price: cloneLastBar.close,
			}
			cacheIndexData[`${subscribeUID}`] = temp
		}
	}
	//console.log('subscribeOnStream.:lastBar.: 1', subscribeStorage.length, resolution, newSubscribe.lastBar, subscribeStorage)
	subscribeStorage.push(newSubscribe);
	//console.log('subscribeOnStream.:lastBar.: 2', subscribeStorage.length, resolution, newSubscribe.lastBar, subscribeStorage)
};

export function unsubscribeFromStream(subscriberUID) {
	const subIndex = subscribeStorage.findIndex(e => e.subscribeUID === subscriberUID);

	// Nếu không tìm thấy callerId ===> unsubCallerId
	if (subIndex === -1) {
		socket.unregisterCallerId(subscriberUID);
	}

	if (subIndex !== -1) {
		const subscribe = subscribeStorage[subIndex];
		//console.log('[unsubscribeBars]: Unsubscribe from streaming. Channel:', subscribe.name);
		// socket.emit(
		// 	'get',
		// 	{
		// 		method: "get",
		// 		url: API_ROOT_URL + 'client/send',
		// 		headers: {},
		// 		// subs: [channelString]
		// 		data: {
		// 			op: 'unsubscribe',
		// 			args: ['trade:' + subscribe.name]
		// 		}
		// 	}, (response) => {

		// 	}
		// );
		const { symbolInfo } = subscribe
		if (symbolInfo && symbolInfo.type === '1') {
			//console.log('[unsubscribeBars]: Unsubscribe from streaming. Channel:', subscribe.symbol);
			const room = `${socket.socketRoomName.index}:${symbolInfo.symbol}`
			socket.unregisterTopics([room], subscriberUID)
		} else {
			socket.unregisterTradeTopic(subscribe.name, subscriberUID);
			subscribeStorage.splice(subIndex, 1);
		}
	};
};

export function parseSymbolToKeys(symbolName) {
	let fsym = '';
	let tsym = '';

	if (symbolName.length === 6) { // E.g. BTCUSD
		fsym = symbolName.slice(0, 3); // BTC
		tsym = symbolName.slice(3); // USD
	};

	return [fsym, tsym];
};


function isSameDay(unix1, unix2) {
	let date1 = FloorMinutesTimestamp(unix1, true);
	let date2 = FloorMinutesTimestamp(unix2, true);
	return date1 === date2
}
function FloorMinutesTimestamp(timestamp, isGetDate, isDate) {
	let dateFromLastBar = new Date(timestamp);
	let year = dateFromLastBar.getFullYear()
	let month = dateFromLastBar.getMonth()
	let day = dateFromLastBar.getDate()
	let hours = dateFromLastBar.getHours()
	let minutes = dateFromLastBar.getMinutes()
	if (isGetDate) {
		day = day < 10 ? `0${day}` : day
		let realMonth = month + 1 < 10 ? `0${month + 1}` : month + 1

		return `${day}/${realMonth}/${year}`
	} else {
		if (isDate) {
			return new Date(year, month, day, 7, 0, 0).getTime()
		}
		return new Date(year, month, day, hours, minutes).getTime()
	}
}

// setTimeout(function () {
// 	let data = {
// 		d: [{
// 			SB: 'SSI',
// 			TD: '18/04/2023',
// 			FT: '13:14:39',
// 			FV: 10000000000,
// 			FMP: 21330,
// 		}]
// 	}
// 	test(data)
// }, 5000)
// setTimeout(function () {
// 	let data = {
// 		d: [{
// 			SB: 'SSI',
// 			TD: '18/04/2023',
// 			FT: '14:29:59',
// 			FV: 20000000000,
// 			FMP: 21380,
// 		}]
// 	}
// 	test(data)
// }, 10000)
// setTimeout(function () {
// 	let data = {
// 		d: [{
// 			SB: 'SSI',
// 			TD: '18/04/2023',
// 			FT: '16:14:50',
// 			FV: 50000000000,
// 			FMP: 21390,
// 		}]
// 	}
// 	test(data)
// }, 15000)
// setTimeout(function () {
// 	let data = {
// 		d: [{
// 			SB: 'SSI',
// 			TD: '18/04/2023',
// 			FT: '17:14:50',
// 			FV: 70000000000,
// 			FMP: 21390,
// 		}]
// 	}
// 	test(data)
// }, 20000)
// function test(data) {
// 	let transData = data.d[0];
// 	if (!transData) return;
// 	getUpdateBySocket(transData)
// }