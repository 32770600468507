import React, { Component, Fragment } from 'react';
import { ReactComponent as ArrowIconDark } from 'assets/icons/settingicons/arrow-icon.svg'
import { ReactComponent as SMSIconDark } from 'assets/icons/settingicons/sms-icon.svg'
import { ReactComponent as ArrowIconLight } from 'assets/icons/settingicons/arrow-icon-light.svg'
import { ReactComponent as SMSIconLight } from 'assets/icons/settingicons/sms-icon-light.svg'
import { ReactComponent as MarginRegisterIconDark } from 'assets/icons/settingicons/margin-register-dark.svg'
import { ReactComponent as FinancialProductDark } from 'assets/icons/settingicons/financial-product.svg'
import { ReactComponent as FinancialProductLight } from 'assets/icons/settingicons/financial-product-light.svg'
import { ReactComponent as InfoFadeDark } from 'assets/icons/settingicons/info-fade.svg'
import { ReactComponent as InfoFadeLight } from 'assets/icons/settingicons/info-fade-light.svg'
import { ReactComponent as PointerRightDark } from 'assets/icons/settingicons/pointer-right.svg'
import { ReactComponent as PointerRightLight } from 'assets/icons/settingicons/pointer-right-light.svg'

import RetryableContent from 'components/RetryableContent';
import { push } from "connected-react-router";
import { connect } from 'react-redux';
import { CustomInput } from 'reactstrap';
import { ACTION_METHOD_CONDITION, CommonWidgetUtils, LoadStatus, ModalConfirmType, ToastUtil, Role, SELECTOR_ACCOUNT_SCREEN, mDate, CommonUtils, TransferType, LanguageUtils } from "utils";
import { inquiryService, transferService } from 'services';
import * as actions from "../../../store/actions";
import './ServiceRegister.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import AdvanceSaleModal from './AdvanceSaleModal';
import userService from './../../../services/userService';
import SelectorAccountCustomer from 'components/SelectorAccount/SelectorAccountCustomer';
import SelectorAccountId from 'components/SelectorAccount/SelectorAccountId';
import { DatePicker } from "../../../components/Input";
import TableExtremeCustom2 from 'components/Table/TableExtreme/TableExtremeCustom2';
import RenderGridCell from "../../../components/Table/TableExtreme/RenderGridCell";
import moment from "moment/moment";
import _ from 'lodash';
import ModalPDFRules from 'containers/Account/Utilities/CashInAdvanceNew/ModalPDFRules';

const listActionMethodCondition = [
    {
        title: "action-method-condition.final_matched_price",
        value: ACTION_METHOD_CONDITION.FINAL_MATCH_PRICE,
    },
    {
        title: "action-method-condition.top_wait_watch",
        value: ACTION_METHOD_CONDITION.TOP_WAIT_MATCH,
    },
]

const TYPE = {
    SMS_SERVICE: '1',
    ADVANCE_SALE: '2',
    METHOD_ACTION: '3',
    MARGIN_REGISTER: '4',
    FINANCIAL_PRODUCT: '5'
};

const PRODUCT_TAB = {
    PRODUCT_REGISTER: '1', // Tab đăng ký sản phẩm
    REGISTER_HISTORY: '2' // Tab lịch sử đăng ký sản phẩm
}

const columnsDefault =
    [
        {
            dataField: "custodycd", // Tài khoản
            // fixed: true,
            // fixedPosition: "left", // Cố định cột
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '100',
            visible: true,
            alignment: "center",
            visibleIndex: 0,
        },
        {
            dataField: "afacctno", //Tiểu khoản
            // fixed: true,
            // fixedPosition: "left", // Cố định cột
            dataType: "string",
            allowReordering: true,
            minWidth: "80",
            width: '80',
            visible: true,
            alignment: "center",
            visibleIndex: 1,
        },
        {
            dataField: "prmname", //Tên sản phẩm
            // fixed: true,
            // fixedPosition: "left", // Cố định cột
            dataType: "string",
            allowReordering: true,
            minWidth: "200",
            width: '200',
            visible: true,
            alignment: "center",
            visibleIndex: 2,
        },
        {
            dataField: "regdate", // Ngày đăng ký
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '100',
            visible: true,
            alignment: "center",
            visibleIndex: 3,
        },
        {
            dataField: "expdate", // Ngày hết hạn
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '100',
            visible: true,
            alignment: "center",
            visibleIndex: 4,
        },
        {
            dataField: "unregdate", // Ngày Hủy đăng ký
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '100',
            visible: true,
            alignment: "center",
            visibleIndex: 5,
        },
        {
            dataField: "status", // Trạng thái
            dataType: "string",
            allowReordering: true, // không cho di chuyển
            minWidth: "100",
            width: '100',
            visible: true,
            alignment: "center",
            visibleIndex: 6,
        },
        {
            dataField: "action",
            // fixed: true,
            // fixedPosition: "left", // Cố định cột
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            minWidth: "50",
            width: '50',
            visible: true,
            alignment: "center",
            visibleIndex: 7,
            allowFiltering: false
        },
    ]
class ServiceRegister extends Component {

    constructor(props) {
        super(props);
        this.TransAuthExternalRef = React.createRef();
        this.TransAuthRegisterMarginRef = React.createRef();
        this.TransAuthRegisterSMSref = React.createRef();


        // Trunc current date
        const currentDate = new Date(this.props.currentDate * 1000);
        currentDate.setHours(0, 0, 0, 0);

        //this.state.fromDate = moment(currentDate).subtract(30, 'days').toDate();
        this.state.toDate = currentDate;
        this.state.fromDate = moment(currentDate).subtract(30, 'days').toDate();;

        this.minDate = moment(currentDate).subtract(mDate, 'days').toDate();
        this.searchBtnRef = React.createRef();
        this.searchBtnRef = React.createRef();
        this.selectAccountCustomerRef = React.createRef();

    }

    initialState = {
        loadStatusListVoucher: LoadStatus.NONE,
        loadStatusListRegisteredVoucher: LoadStatus.NONE,
        loadStatusAll: LoadStatus.NONE,

        accountSend: {},
        listVoucher: [],
        listRegisteredVoucher: [],

        isOpenModalRegisterVoucher: false,
        titleModal: 'utilities.register-voucher.title-modal',

        dataVoucher: {},

        validRegisterVoucherAmount: false,
        registerVoucherAmount: '',

        basis: {},

        type: TYPE.SMS_SERVICE,
        listSMSRegisterBasic: [],
        listAccountAdvanceSale: [],
        listCheckChangeSMS: [],
        listCheckChaneAccountSale: [],
        isOpen: false,                      //Đóng mở Modal
        item: null,
        actionSelect: ACTION_METHOD_CONDITION.FINAL_MATCH_PRICE,
        autotrf: "N",
        isEnable: false,
        isDisableRegisterMargin: false,
        productList: null,

        productTab: PRODUCT_TAB.PRODUCT_REGISTER,
        onReloadRegisterHistory: true,

        fromDate: null,
        toDate: null,

        currentAccount: null,

        selectedProduct: null,

        isOpenModalRules: false,
        pdf_url: '',
        page: null,

        isCheckAgreeRegisterProduct: false,

        onlinestart: '', // thời gian thực hiện yêu cầu
        onlineend: '', // thời gian hết thực hiện yêu cầu,

        currentAplyingProduct: '' // Sản phẩm đang áp dụng
    };

    state = {
        ...this.initialState
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
            this.setState(obj, callback);
        }
    }

    refreshRegisterProduct = (cb) => {
        this._setState({
            isCheckAgreeRegisterProduct: false,
            selectedProduct: null,
            productList: [],
            productTab: PRODUCT_TAB.PRODUCT_REGISTER
        }, cb)
    }

    componentWillUnmount() {
        this.mounted = false
    }


    //Đóng mở Popup xác nhận
    toggleConfirm = () => {
        this._setState({
            isOpen: !this.state.isOpen
        });
    }

    onChangeActionMethod = (e) => {
        this._setState({ actionSelect: e.target.value })
    }

    getAccountInfo = () => {
        const { currentAccountId, typeAccount } = this.props;
        if (typeAccount === 2) {
            this._setState({ autotrf: "N", isEnable: true })
            return
        }
        if (currentAccountId) {
            inquiryService.getAccountInfo(currentAccountId)
                .then(data => {
                    if (data) {
                        this._setState({ autotrf: data.autotrf, isEnable: data.corebank === "Y" || data.iscorebank === "Y" ? false : true })
                    }
                }).catch(error => {
                    this._setState({ autotrf: "N", isEnable: false })
                    ToastUtil.errorApi(error, 'common.fail-to-load-afacctno-info');
                })
        }
    }

    loadAllEmailSMSRegister = () => {
        inquiryService.getEmailSMSRegister()
            .then(data => {
                if (data && data.length > 0) {
                    const realData = data.filter(item => item.type !== "E" && item.allowchange === "Y")
                    this._setState({ listSMSRegisterBasic: realData, listCheckChangeSMS: realData });
                } else {
                    this._setState({ listSMSRegisterBasic: [], listCheckChangeSMS: [] });
                }
                this._setState({ news: data });
            }).catch(error => {
                this._setState({ listSMSRegisterBasic: [] });
                ToastUtil.errorApi(error, 'common.fail-to-load-data');
            });
    }

    loadAllCashInAdvance = async () => {
        const { accounts } = this.props;
        const newAccounts = []
        let requestCount = Object.keys(accounts).length;

        Object.keys(accounts).map((key) => {
            inquiryService.getInfoForAdvance(accounts[key].id)
                .then((data) => {
                    requestCount -= 1;
                    newAccounts.push({
                        ...accounts[key],
                        autoadvance: data['autoadv'] || ''
                    })
                    if (requestCount === 0) {
                        this._setState({ listAccountAdvanceSale: newAccounts, listCheckChaneAccountSale: newAccounts });
                    }
                })
                .catch((error) => {
                    ToastUtil.errorApi(error, 'common.fail-to-load-data');
                });
        })
    }



    loadAllData = () => {
        this.loadAllEmailSMSRegister()
        this.loadAllCashInAdvance()
        this.checkRegisterMargin()
    }

    checkRegisterMargin = async () => {
        try {
            const result = await userService.checkMarginRegister()
            if (result && result.length !== 0) {
                const cvData = result[0].ismargin === 'Y' ? true : false
                this._setState({
                    isDisableRegisterMargin: cvData
                })
            } else {
                ToastUtil.error('common.fail-to-load-data');
            }
        } catch (error) {
            ToastUtil.errorApi(error, 'common.fail-to-load-data');
        }
    }

    loadInitData = () => {
        const { accounts, language } = this.props;

        let marginAccounts = accounts.filter(item => { return item.mrtype === 'T' }); // chỉ áp dụng với đăng ký sản phẩm (chỉ được đăng ký với tiểu khoản margin)
        this._setState({ currentAccount: marginAccounts && marginAccounts.length > 0 ? marginAccounts[0] : null }, () => {
            // if (this.state.currentAccount) {
            //     inquiryService.getTransferParam(this.state.currentAccount.id, TransferType.EXTERNAL)
            //         .then((data) => {
            //             this._setState({ onlinestart: data[0]['onlinestart_trf_time'] || '', onlineend: data[0]['onlineend_trf_time'] || '' });
            //         })
            //         .catch((error) => {
            //             ToastUtil.errorApi(error, 'common.fail-to-load-transfer-params');
            //         });
            // }
            userService.getSysvar('SYSTEM', 'ONLINEEND_TRF_TIME', 'BASE')
                .then(data => {
                    if (data && data[0]) {
                        let hour = data[0].varvalue ? data[0].varvalue.substring(0, 2) : '';
                        let minute = data[0].varvalue ? data[0].varvalue.substring(2, 4) : '';
                        this.setState({ onlineend: hour + ':' + minute });
                    }
                }).catch(err => {

                })

            userService.getSysvar('SYSTEM', 'ONLINESTART_TRF_TIME', 'BASE')
                .then(data => {
                    if (data && data[0]) {
                        let hour = data[0].varvalue ? data[0].varvalue.substring(0, 2) : '';
                        let minute = data[0].varvalue ? data[0].varvalue.substring(2, 4) : '';
                        this.setState({ onlinestart: hour + ':' + minute });
                    }
                }).catch(err => {

                })

        });
    };


    async componentDidMount() {
        this.mounted = true
        this.loadAllData()
        const { actionMethodConditon } = this.props;
        this._setState({ actionSelect: actionMethodConditon });
        this.loadInitData();
        this.getRegisterProduct();
    }

    handleConfirm = () => {
        const { actionSelect } = this.state
        const { isLoggedIn } = this.props;

        if (actionSelect) {
            if (isLoggedIn) {
                userService.changeActionMethodCondition(actionSelect)
                    .then((data) => {
                        ToastUtil.success('account.utilities.cash-transfer.success', 'service-register.update-successful');
                        this.props.setActionMethodCondition(actionSelect);
                    })
                    .catch((error) => {
                        ToastUtil.errorApi(error);
                    });
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { accountInfo } = this.props;
        const { currentAccountId, typeAccount } = accountInfo
        const {
            accountInfo: prevAccountInfo
        } = prevProps;

        const {
            currentAccountId: prevCurrentAccountId,
            typeAccount: prevTypeAccount
        } = prevAccountInfo

        //console.log("change-screen---abc: 1", currentAccountId, prevCurrentAccountId)
        if (currentAccountId !== prevCurrentAccountId || typeAccount !== prevTypeAccount) {
            this.getAccountInfo();
            //console.log("change-screen---abc: 2")
        }

        if (!_.isEqual(this.state.currentAccount, prevState.currentAccount) && this.state.type === TYPE.FINANCIAL_PRODUCT && this.state.productTab === PRODUCT_TAB.PRODUCT_REGISTER) {
            this.refreshRegisterProduct(this.getRegisterProduct);
        }

        if (prevState.type !== this.state.type && this.state.type === TYPE.FINANCIAL_PRODUCT) {
            this.refreshRegisterProduct(this.getRegisterProduct);
        }

        if (prevState.productTab !== this.state.productTab && this.state.productTab === PRODUCT_TAB.PRODUCT_REGISTER) {
            this.refreshRegisterProduct(this.getRegisterProduct);
        }

        if (prevState.productTab !== this.state.productTab && this.state.productTab === PRODUCT_TAB.REGISTER_HISTORY) {
            this.setReloadData();
        }

        if (prevState.type === TYPE.SMS_SERVICE && prevState.type !== this.state.type) {
            this.loadAllEmailSMSRegister()
        }

    }

    // Lấy thông tin sản phẩm đăng ký
    getRegisterProduct = () => {
        // gọi api ở đây
        const { currentAccount } = this.state;
        const { userInfo, currentCustomer } = this.props;
        const { accountInfo, socketSEActionCounter } = this.props;
        const { currentAccountId, curCustomer, typeAccount } = { ...accountInfo }
        let custid = null;

        if (this.isBroker()) {
            custid = userInfo ? userInfo.custid : null;
        } else {
            custid = userInfo ? userInfo.custid : null;
        };
        if (currentAccount && currentAccount.id) {
            inquiryService.getProducts(currentAccount.id, custid)
                .then(data => {
                    this.setState({ productList: data });
                    if (data && data.length > 0) {
                        let convertData = _.filter(data, item => { return item.prmid !== null });
                        let currentApplyingProductRow = _.find(data, item => { return item.prmid === null });
                        this.setState({ currentAplyingProduct: currentApplyingProductRow && currentApplyingProductRow.typename || '', productList: convertData });
                    } else {
                        this.setState({ currentAplyingProduct: '', productList: [] });
                    }
                }).catch(err => {
                    ToastUtil.errorApi(err, 'product-register.fail-to-load-product');
                })
        }
    }

    handleChangeScreen = (type) => {
        if (type === TYPE.METHOD_ACTION) this.getAccountInfo()
        this._setState({ type: type })
    }

    convertStatus = (status) => {
        if (status === "Y") return true
        return false
    }

    splitSubject = (subject) => {
        const { language } = this.props;
        const subject_array = subject.split('||');
        if (language === "vi") return subject_array[0]
        return subject_array[1]
    }

    changeSMSRegistered = (item) => {
        let updatedList = this.state.listSMSRegisterBasic.map(i => {
            if (i.code == item.code) {
                return { ...i, registered: i.registered === "Y" ? "N" : "Y" };
            }
            return i;
        });
        this._setState({ listSMSRegisterBasic: updatedList })
    }

    changeAutoTransferMoney = () => {
        this.preCheckAutoTranferMoney()
    }

    changeAdvanceSale = (item) => {
        let checkAccountMR = item.typename.includes("MR")
        if (checkAccountMR) {
            ToastUtil.error('CM3', 'service-register.warning-mr-account');
        } else {
            this._setState({ item: item }, () => {
                this.toggleConfirm()
            })
        }
    }

    preCheckAutoTranferMoney = () => {
        const {
            autotrf
        } = this.state;
        const { currentAccountId } = this.props
        let body = '';
        let isregCheck = autotrf === "Y" ? "N" : "Y"
        body = {
            isreg: this.convertStatus(isregCheck),
            desc: this.convertStatus(isregCheck) ? "Đăng ký tự động chuyển tiền @BIDV" : "Huỷ đăng ký tự động chuyển tiền @BIDV"
        };
        this._setState({ loadStatus: LoadStatus.LOADING });
        transferService.checkRegisterAutoCashtransCorebank(currentAccountId, body)
            .then((result) => {
                if (Object.keys(result).length > 0) {
                    const { transactionId, tokenid, refamt, authtype, authtypes, feeamt, vatamt, fee, vat, challengeQuestion, verified } = result;
                    this.props.updateInfoCheckAuth({
                        transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
                    })

                    this._setState({ loadStatus: LoadStatus.LOADED, step: 2, refamt: refamt, vatamt: vatamt, feeamt: feeamt, transactionId: transactionId }, () => {
                        let _dataInfo = this.getDataInfo()
                        this.props.updateConfirmCheckAuth({
                            typeInfo: ModalConfirmType.REGISTER_AUTO_CASH_TRANS_CORE_BANK,
                            dataInfo: _dataInfo,
                        })

                        this.props.setIsOpenModalHaveData("ScreenConfirm", {
                            isOpenScreenConfirm: true,
                            isCheckAuth2: true,
                        })
                    });

                } else {
                    ToastUtil.error('common.fail-to-precheck-transactions'); // Lỗi không lấy được dữ liệu transactionId để thực hiện giao dịch
                }

            })
            .catch((error) => {
                this._setState({ loadStatus: LoadStatus.FAIL });
                ToastUtil.errorApi(error, 'common.bad-request');
            });
    };


    processTransfer = () => {
        this.TransAuthExternalRef.current.processAuth();
    };

    genInfo = () => {
        const {
            autotrf
        } = this.state;
        const { currentAccountId } = this.props
        let processAuthFunc = null;
        let transferBody = null;
        let isregCheck = autotrf === "Y" ? "N" : "Y"
        transferBody = {
            accountId: currentAccountId,
            isreg: this.convertStatus(isregCheck),
            desc: this.convertStatus(isregCheck) ? "Đăng ký tự động chuyển tiền @BIDV" : "Huỷ đăng ký tự động chuyển tiền @BIDV"
        };
        processAuthFunc = transferService.processRegisterAutoCashtransCorebank;

        return {
            processAuthFunc,
            transferBody,
        }
    }

    //Xử lý data truyền vào redux
    getDataInfo = () => {
        const { autotrf } = this.state;
        let _info = null
        let { processAuthFunc, transferBody } = this.genInfo()
        let isregCheck = autotrf === "Y" ? "N" : "Y"
        let title = this.convertStatus(isregCheck) ? "security.title-confirm-auth" : "security.title-cancel-auth"
        _info = {
            initFuncData: transferBody,
            authRef: this.TransAuthExternalRef,
            processTransfer: () => { this.processTransfer() },
            processAuthFunc: processAuthFunc,
            transactionCb: () => { this.transactionCb() },
            title: title,
        }
        return _info || {}
    }

    transactionCb = () => {
        this.getAccountInfo()
        ToastUtil.success('common.success', 'security.register-success');
        this.props.setIsOpenModalHaveData("ScreenConfirm", {
            isOpenScreenConfirm: false,
            isCheckAuth2: true,
        })
    };

    registerProductCb = () => {
        this.refreshRegisterProduct(this.getRegisterProduct);
        ToastUtil.success('common.success', 'product-register.register-success');
        this.props.setIsOpenModalHaveData("ScreenConfirm", {
            isOpenScreenConfirm: false,
            isCheckAuth2: true,
        })
    };

    cancelRegisterProductCb = () => {
        ToastUtil.success('common.success', 'product-register.cancel-register-success');
        this.props.setIsOpenModalHaveData("ScreenConfirm", {
            isOpenScreenConfirm: false,
            isCheckAuth2: true,
        })
        this.setReloadData();
    };


    getDataRegisterSMS = (transferBody) => {
        const { userInfo } = this.props
        let _info = null
        // let { processAuthFunc, transferBody } = this.genInfoRegisterMargin()
        let processAuthFunc = transferService.processRegisterSMSService;
        _info = {
            initFuncData: transferBody,
            authRef: this.TransAuthRegisterSMSref,
            processTransfer: () => { this.processRegisterSMS() },
            processAuthFunc: processAuthFunc,
            transactionCb: () => { this.transactionCbRegisterSMS() },
            title: "service-register.sms-service",
            //showOrderSwitch: false // Tạm thời truyền vào, sau sẽ lấy từ precheck trả về trường savef2
        }
        return _info || {}
    }

    processRegisterSMS = () => {
        this.TransAuthRegisterSMSref.current.processAuth();
    };

    transactionCbRegisterSMS = () => {
        this.loadAllEmailSMSRegister()
        ToastUtil.success('account.utilities.cash-transfer.success', 'service-register.update-successful');
        this.props.setIsOpenModalHaveData("ScreenConfirm", {
            isOpenScreenConfirm: false,
            isCheckAuth2: true,
        })
    };

    handleConfirmSMS = () => {
        const { listSMSRegisterBasic, listCheckChangeSMS } = this.state;
        let result = listSMSRegisterBasic.filter(o1 => listCheckChangeSMS.some(o2 => o1.code === o2.code && o1.registered !== o2.registered));
        let code = null
        let registered = null
        if (result.length > 0) {
            Object.keys(result).map(key => {
                if (code === null) {
                    code = result[key].code
                    registered = result[key].registered
                } else {
                    code = code + "," + result[key].code
                    registered = registered + "," + result[key].registered
                }
            })
        }
        if (result.length > 0) {

            const dataRegister = {
                codeRegister: code,
                registered: registered
            }

            transferService.checkRegisterSMSService({})
                .then((data) => {
                    const { transactionId, tokenid, authtype, authtypes, challengeQuestion, verified } = data;
                    this.props.updateInfoCheckAuth({
                        transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
                    })

                    this._setState({ loadStatus: LoadStatus.LOADED }, () => {
                        let _dataInfo = this.getDataRegisterSMS(dataRegister)
                        this.props.updateConfirmCheckAuth({
                            typeInfo: ModalConfirmType.REGISTER_SMS,
                            dataInfo: _dataInfo,
                        })

                        this.props.setIsOpenModalHaveData("ScreenConfirm", {
                            isOpenScreenConfirm: true,
                            isCheckAuth2: true,
                        })
                    });
                }

                ).catch(error => {
                    ToastUtil.errorApi(error, 'common.fail-to-load-data');
                })
        }
    }

    handlePreAdvance = () => {
        const { item } = this.state;

        let data = {
            isregis: this.convertStatus(item.autoadvance) ? "N" : "Y"
        }
        transferService.checkRegisterOrCancelAdvance(item.id, data)
            .then((data) => {
                this.handleChangeAdvance(data)
            })
            .catch((error) => {
                ToastUtil.errorApi(error, 'common.fail-to-load-data');
            });
    }

    handleChangeAdvance = (result) => {
        const { item } = this.state;
        let data = {
            isregis: this.convertStatus(item.autoadvance) ? "N" : "Y",
            accountId: item.id,
            code: result.code,
            tokenid: result.tokenid,
            transactionId: result.transactionId
        }
        transferService.processRegisterOrCancelAdvance(data)
            .then((data) => {
                this.loadAllCashInAdvance()
                this.toggleConfirm()
                this._setState({ item: null })
                ToastUtil.success('account.utilities.cash-transfer.success', 'service-register.update-successful');
            })
            .catch((error) => {
                this.toggleConfirm()
                ToastUtil.errorApi(error, 'common.fail-to-load-data');
            });
    }

    handleConfirmAndvanceSale = () => {
        const { listAccountAdvanceSale, listCheckChaneAccountSale } = this.state;
        let result = listAccountAdvanceSale.filter(o1 => listCheckChaneAccountSale.some(o2 => o1.id === o2.id && o1.autoadvance !== o2.autoadvance));
        if (result.length > 0) {
            Object.keys(result).map((key) => {
                let data = {
                    isregis: result[key].autoadvance
                }
                transferService.checkRegisterOrCancelAdvance(result[key].id, data)
                    .then((data) => {
                        this.loadAllCashInAdvance()
                        ToastUtil.success('account.utilities.cash-transfer.success', 'service-register.update-successful');
                    })
                    .catch((error) => {
                        ToastUtil.errorApi(error, 'common.fail-to-load-data');
                    });
            })
        }
    }

    processRegisterMargin = () => {
        this.TransAuthRegisterMarginRef.current.processAuth();
    };

    genInfoRegisterMargin = () => {
        let processAuthFunc = null;
        let transferBody = null;
        processAuthFunc = transferService.processRegisterMarginSubAccount;
        return {
            processAuthFunc,
            transferBody,
        }
    }

    //Xử lý data truyền vào redux
    getDataInfoRegisterMargin = () => {
        const { userInfo } = this.props
        let _info = null
        let { processAuthFunc, transferBody } = this.genInfoRegisterMargin()
        _info = {
            dataFill: {
                custodycd: userInfo.custodycd
            },
            initFuncData: transferBody,
            authRef: this.TransAuthRegisterMarginRef,
            processTransfer: () => { this.processRegisterMargin() },
            processAuthFunc: processAuthFunc,
            transactionCb: () => { this.transactionCbRegisterMargin() },
            title: "service-register.title-modal-auth",
            //showOrderSwitch: false // Tạm thời truyền vào, sau sẽ lấy từ precheck trả về trường savef2
        }
        return _info || {}
    }

    transactionCbRegisterMargin = () => {
        this.getAccountInfo()
        this.checkRegisterMargin();
        ToastUtil.success('common.success', 'security.register-success');
        this.props.setIsOpenModalHaveData("ScreenConfirm", {
            isOpenScreenConfirm: false,
            isCheckAuth2: true,
        })
    };

    preCheckMarginRegister = () => {
        const { isDisableRegisterMargin } = this.state
        if (isDisableRegisterMargin === true) {
            return
        }

        this._setState({ loadStatus: LoadStatus.LOADING });
        transferService.checkRegisterMarginSubAccount()
            .then((result) => {
                if (Object.keys(result).length > 0) {
                    const { transactionId, tokenid, refamt, authtype, authtypes, challengeQuestion, verified } = result;
                    this.props.updateInfoCheckAuth({
                        transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
                    })

                    this._setState({ loadStatus: LoadStatus.LOADED }, () => {
                        let _dataInfo = this.getDataInfoRegisterMargin()
                        this.props.updateConfirmCheckAuth({
                            typeInfo: ModalConfirmType.REGISTER_MARGIN_SUB_ACCOUNT,
                            dataInfo: _dataInfo,
                        })

                        this.props.setIsOpenModalHaveData("ScreenConfirm", {
                            isOpenScreenConfirm: true,
                            isCheckAuth2: true,
                        })
                    });

                } else {
                    ToastUtil.error('common.fail-to-precheck-transactions'); // Lỗi không lấy được dữ liệu transactionId để thực hiện giao dịch
                }

            })
            .catch((error) => {
                this._setState({ loadStatus: LoadStatus.FAIL });
                ToastUtil.errorApi(error, 'common.bad-request');
            });
    };

    isBroker = () => {
        const { role } = this.props;
        return role === Role.BROKER;
    };

    onChangeProductTabActive = (tabId) => {
        this.setState({ productTab: tabId })
    }

    setReloadData = (_onReloadData) => {
        let onReloadData = _onReloadData ? _onReloadData : !this.state.onReloadRegisterHistory
        this._setState({ onReloadRegisterHistory: onReloadData })
    }

    apiLoadRegisterHistory = () => {
        try {
            const { fromDate, toDate, currentAccount } = this.state;
            const { userInfo, currentCustomer } = this.props;
            const { accountInfo, socketSEActionCounter } = this.props;
            const { currentAccountId, curCustomer, typeAccount } = { ...accountInfo }
            let custid = null;

            let checkDateResult = CommonUtils.checkDate(fromDate, toDate)

            if (!checkDateResult) {
                return [];
            }

            if (this.isBroker()) {
                custid = userInfo ? userInfo.custid : null;
            } else {
                custid = userInfo ? userInfo.custid : null;
            };
            this._setState({ loadStatus: LoadStatus.LOADING });
            if (currentAccount) {
                return inquiryService.getProductRegisterHistory(currentAccount.id, custid, fromDate, toDate)
                    .then((data) => {
                        this._setState({ loadStatus: LoadStatus.LOADED });
                        return data
                    })
                    .catch((error) => {
                        this._setState({ loadStatus: LoadStatus.FAIL });
                        ToastUtil.errorApi(error, 'common.fail-to-load-data');
                        return []
                    });
            }

        } catch (e) {
            console.log('apiLoadAll().:Err.:', e)
            return []
        }
    }

    onCancelProduct = (record) => {
        const { currentAccount } = this.state;
        const { userInfo } = this.props;

        let checkRegisterBody = {
            regautoid: record.autoid
        }
        transferService.checkProductCancelRegister(currentAccount.id, checkRegisterBody)
            .then((result) => {
                if (Object.keys(result).length > 0) {
                    const { transactionId, tokenid, refamt, authtype, authtypes, feeamt, vatamt, fee, vat, challengeQuestion, verified } = result;

                    this.props.updateInfoCheckAuth({
                        transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
                    })

                    this._setState({ loadStatus: LoadStatus.LOADED, step: 2, refamt: refamt, vatamt: vatamt, feeamt: feeamt, transactionId: transactionId }, () => {

                        let _dataInfo = {
                            authRef: this.TransAuthExternalRef,
                            custodycd: userInfo.custodycd || '',
                            cdcontent: currentAccount.cdcontent || '',
                            prmname: record.prmname || '',
                            initFuncData: {
                                regautoid: record.autoid,
                                accountId: currentAccount.id,
                            },
                            processTransfer: () => { this.processTransfer() },
                            processAuthFunc: transferService.processCancelRegisterProduct,
                            // refresh: () => { this.refresh() },
                            transactionCb: () => { this.cancelRegisterProductCb() },
                            // setLoadStatus: (status) => { this.setLoadStatus(status) },
                            title: "product-register.cancel-register-auth",
                            showOrderSwitch: false // Tạm thời truyền vào, sau sẽ lấy từ precheck trả về trường savef2
                        }

                        this.props.updateConfirmCheckAuth({
                            typeInfo: ModalConfirmType.REGISTER_PRODUCT, // Thông tin màn xác nhận của đăng ký và hủy đăng ký dịch vụ như nhau
                            dataInfo: _dataInfo,
                        })

                        this.props.setIsOpenModalHaveData("ScreenConfirm", {
                            isOpenScreenConfirm: true,
                            isCheckAuth2: true,
                        })
                    });

                } else {
                    ToastUtil.error('common.fail-to-precheck-transactions'); // Lỗi không lấy được dữ liệu transactionId để thực hiện giao dịch
                }

            })
            .catch((error) => {
                this._setState({ loadStatus: LoadStatus.FAIL });
                ToastUtil.errorApi(error, 'common.bad-request');
            });
    }


    renderSwitch = (dataField) => {
        const { lang } = this.props;
        switch (dataField) {
            case 'custodycd':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.custodycd);
            case 'afacctno':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.afacctno);
            case 'prmname':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.prmname);
            case 'regdate':
                return (e) => RenderGridCell.renderGridCellDate(e.row.data.regdate);
            case 'expdate':
                return (e) => RenderGridCell.renderGridCellDate(e.row.data.expdate);
            case 'unregdate':
                return (e) => RenderGridCell.renderGridCellDate(e.row.data.unregdate);
            case 'status':
                return (e) => RenderGridCell.renderGridCellContent(e.row.data.status);
            case 'action':
                return (e) => RenderGridCell.renderGridCellActionCancelProduct(e, this.onCancelProduct)
            default:
                return null;
        }
    }

    returnCaption = (dataField) => {
        switch (dataField) {
            case 'custodycd':
                return "product-register.custodycd";
            case 'afacctno':
                return "product-register.sub-account";
            case 'prmname':
                return "product-register.product";
            case 'regdate':
                return "product-register.reg-date";
            case 'expdate':
                return "product-register.exp-date";
            case 'unregdate':
                return "product-register.unreag-date";
            case 'status':
                return "order-history.order-status";
            default:
                return null;
        }

    }


    onFromDateChanged = (dates) => {
        this._setState({ fromDate: dates[0] });
    };

    onToDateChanged = (dates) => {
        this._setState({ toDate: dates[0] });
    };

    search = () => {
        this.setReloadData();
    }


    setCurrentAccount = (event) => {
        const { accounts } = this.props;
        let found = accounts.find(item => item.id === event.target.value)
        this._setState({ currentAccount: found })
    };

    onSelectProduct = (product) => {
        this.setState({ selectedProduct: product });
    }

    onToggleOpenModalRules = () => {
        const { isOpenModalRules } = this.state;
        this._setState({ isOpenModalRules: !isOpenModalRules });
    }

    onOpenPdfModal = (item) => {
        if (item && item.note) {
            this.setState({
                pdf_url: item.note
            }, () => {
                this.onToggleOpenModalRules();
            })
        }
    }

    onCheckAgreeRegisterProduct = () => {
        const { isCheckAgreeRegisterProduct } = this.state;
        this.setState({ isCheckAgreeRegisterProduct: !isCheckAgreeRegisterProduct });
    }


    preCheckProductRegister = () => {
        const {
            selectedProduct, isCheckAgreeRegisterProduct, currentAccount
        } = this.state;


        //Check sản phẩm muốn đăng ký
        if (!selectedProduct) {
            ToastUtil.error('common.fail-to-can-not-make-transaction', 'product-register.check-product');
            return;
        }

        // //Check khối lượng chuyển
        if (!isCheckAgreeRegisterProduct) {
            ToastUtil.error('common.fail-to-can-not-make-transaction', 'product-register.check-agree-warnning');
            return;
        }

        if (!currentAccount || !currentAccount.id) {
            ToastUtil.error('common.fail-to-can-not-make-transaction', 'common.no-account');
            return;
        }

        this.checkRegisterProduct()
    }

    checkRegisterProduct = () => {
        const { currentAccount, selectedProduct } = this.state;
        const { userInfo } = this.props;

        let checkRegisterBody = {
            prmid: selectedProduct.prmid
        }
        transferService.checkProductRegister(currentAccount.id, checkRegisterBody)
            .then((result) => {
                if (Object.keys(result).length > 0) {
                    const { transactionId, tokenid, refamt, authtype, authtypes, feeamt, vatamt, fee, vat, challengeQuestion, verified } = result;

                    this.props.updateInfoCheckAuth({
                        transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
                    })

                    this._setState({ loadStatus: LoadStatus.LOADED, step: 2, refamt: refamt, vatamt: vatamt, feeamt: feeamt, transactionId: transactionId }, () => {

                        let _dataInfo = {
                            authRef: this.TransAuthExternalRef,
                            custodycd: userInfo.custodycd || '',
                            cdcontent: currentAccount.cdcontent || '',
                            prmname: selectedProduct.prmname || '',
                            initFuncData: {
                                prmid: selectedProduct.prmid,
                                accountId: currentAccount.id,
                            },
                            processTransfer: () => { this.processTransfer() },
                            processAuthFunc: transferService.processRegisterProduct,
                            // refresh: () => { this.refresh() },
                            transactionCb: () => { this.registerProductCb() },
                            // setLoadStatus: (status) => { this.setLoadStatus(status) },
                            title: "product-register.register-auth",
                            showOrderSwitch: false // Tạm thời truyền vào, sau sẽ lấy từ precheck trả về trường savef2
                        }

                        this.props.updateConfirmCheckAuth({
                            typeInfo: ModalConfirmType.REGISTER_PRODUCT,
                            dataInfo: _dataInfo,
                        })

                        this.props.setIsOpenModalHaveData("ScreenConfirm", {
                            isOpenScreenConfirm: true,
                            isCheckAuth2: true,
                        })
                    });

                } else {
                    ToastUtil.error('common.fail-to-precheck-transactions'); // Lỗi không lấy được dữ liệu transactionId để thực hiện giao dịch
                }

            })
            .catch((error) => {
                this._setState({ loadStatus: LoadStatus.FAIL });
                ToastUtil.errorApi(error, 'common.bad-request');
            });

    }



    render() {
        const { loadStatusAll, type, listSMSRegisterBasic, listAccountAdvanceSale, isOpen, isOpenModalRules, pdf_url, onlinestart, onlineend, currentAplyingProduct } = this.state
        const { actionSelect, autotrf, isEnable, isDisableRegisterMargin, productList, productTab, onReloadRegisterHistory, currentAccount, selectedProduct, isCheckAgreeRegisterProduct } = this.state
        const { config, curCustomer, accountInfo, currentLayoutPageActive, accounts, intl, language } = this.props
        //console.log("listAccountAdvanceSale", listAccountAdvanceSale)
        // const { userInfo } = this.props

        const isBroker = this.isBroker();

        let timeExec = intl.formatMessage({ id: "product-register.time-exec2" }, {
            fromTime: onlinestart ? onlinestart : '',
            toTime: onlineend ? onlineend : '',
        });

        // let defaultProductTxt = LanguageUtils.getMessageByKey("product-register.default-product", language)

        const marginAccountList = _.filter(accounts, item => { return item.mrtype === 'T' });
        return (
            <RetryableContent className="h-100" status={loadStatusAll} disableRetry={false}>
                <div className="screen-service-register h-100 row gutters-5">
                    <div className="col-12 col-sm-4">
                        <div className='block'>
                            <div className='headB---700-16-24 title'>
                                <FormattedMessage id="service-register.service-list" />
                            </div>
                            <div className={"server-property" + (type === TYPE.SMS_SERVICE ? " active" : "")} onClick={() => this.handleChangeScreen(TYPE.SMS_SERVICE)}>
                                <div className="header-service">
                                    <div className="icon">
                                        {this.props.defaultTheme === 'dark' ? < SMSIconDark /> : < SMSIconLight />}
                                    </div>
                                    <div className="content">
                                        <div className="title txt---500-16-24">
                                            <FormattedMessage id="service-register.sms-service" />
                                        </div>
                                    </div>

                                </div>
                                <div className="extra">
                                    {this.props.defaultTheme === 'dark' ? < ArrowIconDark /> : < ArrowIconLight />}
                                </div>
                            </div>

                            {/* <div className={"server-property" + (type === TYPE.ADVANCE_SALE ? " active" : "")} onClick={() => this.handleChangeScreen(TYPE.ADVANCE_SALE)}>
                                <div className="header-service">
                                    <div className="icon">
                                        {this.props.defaultTheme === 'dark' ? < BankIconDark /> : < BankIconLight />}
                                    </div>
                                    <div className="content">
                                        <div className="title txt---500-16-24">
                                            <FormattedMessage id="service-register.advance-for-sale" />
                                        </div>
                                    </div>

                                </div>
                                <div className="extra">
                                    {this.props.defaultTheme === 'dark' ? < ArrowIconDark /> : < ArrowIconLight />}
                                </div>
                            </div> */}

                            {/* <div className={"server-property" + (type === TYPE.METHOD_ACTION ? " active" : "")} onClick={() => this.handleChangeScreen(TYPE.METHOD_ACTION)}>
                                <div className="header-service">
                                    <div className="icon">
                                        {this.props.defaultTheme === 'dark' ? < MethodActionDark /> : < MethodActionLight />}
                                    </div>
                                    <div className="content">
                                        <div className="title txt---500-16-24">
                                            <FormattedMessage id="service-register.method-action" />
                                        </div>
                                    </div>

                                </div>
                                <div className="extra">
                                    {this.props.defaultTheme === 'dark' ? < ArrowIconDark /> : < ArrowIconLight />}
                                </div>
                            </div> */}

                            <div className={"server-property" + (type === TYPE.MARGIN_REGISTER ? " active" : "")} onClick={() => this.handleChangeScreen(TYPE.MARGIN_REGISTER)}>
                                <div className="header-service">
                                    <div className="icon">
                                        {this.props.defaultTheme === 'dark' ? < MarginRegisterIconDark /> : <MarginRegisterIconDark />}
                                    </div>
                                    <div className="content">
                                        <div className="title txt---500-16-24">
                                            <FormattedMessage id="service-register.margin-register" />
                                        </div>
                                    </div>

                                </div>
                                <div className="extra">
                                    {this.props.defaultTheme === 'dark' ? < ArrowIconDark /> : < ArrowIconLight />}
                                </div>
                            </div>

                            <div className={"server-property" + (type === TYPE.FINANCIAL_PRODUCT ? " active" : "")} onClick={() => this.handleChangeScreen(TYPE.FINANCIAL_PRODUCT)}>
                                <div className="header-service">
                                    <div className="icon">
                                        {this.props.defaultTheme === 'dark' ? < FinancialProductDark /> : < FinancialProductLight />}
                                    </div>
                                    <div className="content">
                                        <div className="title txt---500-16-24">
                                            <FormattedMessage id="product-register.financial-product-register" />
                                        </div>
                                    </div>

                                </div>
                                <div className="extra">
                                    {this.props.defaultTheme === 'dark' ? < ArrowIconDark /> : < ArrowIconLight />}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-sm-8 ">
                        <div className={"col-12 block-right " + (type === TYPE.FINANCIAL_PRODUCT ? 'no-bg no-pd' : '')}>
                            {
                                type === TYPE.SMS_SERVICE &&
                                <div className="gutters-5 h-row">
                                    {this.state.listSMSRegisterBasic.length > 0 &&
                                        this.state.listSMSRegisterBasic.map((item, i) => (
                                            <div className="sms-server-property">
                                                <div className="header-service">
                                                    <div className="txt---400-16-24">
                                                        {this.splitSubject(item.subject)}
                                                    </div>
                                                </div>
                                                <div className="extra">
                                                    <CustomInput
                                                        className="service-register-switch"
                                                        type="switch"
                                                        id={item.code}
                                                        onChange={() => this.changeSMSRegistered(item)}
                                                        checked={this.convertStatus(item.registered)}
                                                    />
                                                </div>
                                            </div>
                                        ))}

                                    <button className="btn-custom btn-confirm txt---500-16-24" onClick={() => this.handleConfirmSMS()}>
                                        <FormattedMessage id="service-register.confirm" />
                                    </button>
                                </div>
                            }
                            {
                                type === TYPE.ADVANCE_SALE &&
                                <div className="gutters-5 h-row">
                                    {this.state.listAccountAdvanceSale.length > 0 &&
                                        this.state.listAccountAdvanceSale.map((item, i) => (
                                            <div className="sms-server-property">
                                                <div className="header-service">
                                                    <div className="txt---400-16-24">
                                                        {item.entypename} - {item.id}
                                                    </div>
                                                </div>
                                                <div className="extra">
                                                    <CustomInput
                                                        className="service-register-switch"
                                                        type="switch"
                                                        id={item.id}
                                                        onChange={() => this.changeAdvanceSale(item)}
                                                        checked={this.convertStatus(item.autoadvance)}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            }
                            {
                                type === TYPE.METHOD_ACTION &&
                                <div className="gutters-5" >
                                    <div className="row gutters-5 align-items-center h-method">
                                        <div className="col-6">
                                            <div className="label txt---400-14-20">
                                                <FormattedMessage id="sign-up-factor-auth.conditional-command-activation-method" />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="custom-form-group">
                                                <select
                                                    onChange={(e) => this.onChangeActionMethod(e)}
                                                    className="custom-form-control txt---400-14-20"
                                                >
                                                    {listActionMethodCondition && listActionMethodCondition.map((item, key) => {
                                                        return (
                                                            <FormattedMessage key={key} id={item.title}>
                                                                {(txt) => (
                                                                    <option key={key} value={item.value} selected={actionSelect == item.value ? true : false}>{txt}</option>
                                                                )}
                                                            </FormattedMessage>

                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row gutters-5 align-items-center h-method style-auto-tranfer">
                                        <div>
                                            <div className="label txt---400-14-20">
                                                <FormattedMessage id="security.auto-transfer" />
                                            </div>
                                        </div>
                                        <div >
                                            <CustomInput
                                                className="service-register-switch"
                                                type="switch"
                                                id="input-change-tranfer-money"
                                                onChange={() => this.changeAutoTransferMoney()}
                                                checked={this.convertStatus(autotrf)}
                                                disabled={isEnable}
                                            />
                                        </div>
                                    </div>

                                    <button className="btn-custom btn-confirm txt---500-16-24" onClick={() => this.handleConfirm()}>
                                        <FormattedMessage id="service-register.confirm" />
                                    </button>
                                </div>
                            }

                            {
                                type === TYPE.MARGIN_REGISTER &&
                                <div className="gutters-5 h-row">
                                    <div className="margin-register">
                                        <div className="header-service">
                                            <div className="txt---400-16-24">
                                                <FormattedMessage id="service-register.open-margin-account" />
                                            </div>
                                        </div>
                                        <div className="combo-action">
                                            <button onClick={() => this.preCheckMarginRegister()} disabled={isDisableRegisterMargin} className="btn-confirm txt---500-16-24 btn">
                                                <FormattedMessage id="service-register.register" />
                                            </button>

                                            {/* <div href={config && config.configData && config.configData.margin_contract ? config.configData.margin_contract : '#'} className="icon">
                                                {this.props.defaultTheme === 'dark' ? < DownloadIconLight /> : < DownloadIconDark />}
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            }


                            {
                                type === TYPE.FINANCIAL_PRODUCT &&
                                <div className="gutters-5 h-row financial-product">
                                    <div className='headB---700-16-24 title-tab'>
                                        <div

                                            className={productTab === PRODUCT_TAB.PRODUCT_REGISTER ? "active" : "not-active"}
                                            onClick={() => this.onChangeProductTabActive(PRODUCT_TAB.PRODUCT_REGISTER)}
                                        >
                                            <FormattedMessage id="product-register.financial-product-register-short" />
                                        </div>
                                        <div
                                            className={productTab === PRODUCT_TAB.REGISTER_HISTORY ? "active" : "not-active"}
                                            onClick={() => this.onChangeProductTabActive(PRODUCT_TAB.REGISTER_HISTORY)}
                                        >
                                            <FormattedMessage id="product-register.product-register-history-short" />
                                        </div>

                                    </div>

                                    {productTab === PRODUCT_TAB.PRODUCT_REGISTER && (
                                        <Fragment>
                                            <div className="action-container account-select-block">
                                                <div className={'d-flex container-select ' + (this.props.isDeskTop ? 'flex-wrap' : '')}>
                                                    {
                                                        isBroker ?
                                                            <div className="custom-form-group-n padding-top-0">
                                                                <SelectorAccountCustomer
                                                                    ref={this.selectAccountCustomerRef}
                                                                    curCustomer={curCustomer}
                                                                    selectorAcountScreen={SELECTOR_ACCOUNT_SCREEN.SCREEN_MODAL}
                                                                    disabled={false}
                                                                    className="custom-form-control txt---400-14-20"
                                                                />
                                                            </div>
                                                            :
                                                            <div className="custom-form-group txt---400-14-20">
                                                                <input autoFocus={true} className='custom-form-control custom-form-control-custodycd' value={this.props.userInfo && this.props.userInfo.custodycd} readOnly />
                                                            </div>
                                                    }

                                                    <div className="custom-form-group-n">
                                                        {/* <div className='align-self-center label txt---400-14-20'><FormattedMessage id="order-history.fillter-sub-account" /></div> */}
                                                        <select value={currentAccount ? currentAccount.id : ''} onChange={this.setCurrentAccount} className="custom-form-control txt---400-14-20">
                                                            {(!accounts || accounts.length === 0 || !currentAccount) && (
                                                                <FormattedMessage key="empty" id="header.choose-account">
                                                                    {(txt) => (
                                                                        <option key="empty" value="" disabled>{txt}</option>
                                                                    )}
                                                                </FormattedMessage>
                                                            )}

                                                            {marginAccountList && marginAccountList.length > 0 && marginAccountList.map((account) => {
                                                                return (account.accounttype === "ALL" ? <option key={account.id} value={account.id}>{account.id}</option> :
                                                                    (<option key={account.id} value={account.id}>{account.cdcontent}</option>)
                                                                )
                                                            })}
                                                        </select>
                                                    </div>

                                                    <div className="custom-form-group-n applying-product">
                                                        <div className='align-self-center label txt---400-14-20'>
                                                            <FormattedMessage id="product-register.applying-product" />
                                                        </div>
                                                        <input
                                                            className='custom-form-control icon-control'
                                                            type='text'
                                                            readOnly={true}
                                                            value={currentAplyingProduct}
                                                        />
                                                        {/*this.props.defaultTheme === 'dark' ? < InfoFadeDark /> : < InfoFadeLight />*/}
                                                    </div>
                                                    {/*isBroker && <div className="custom-form-group-n fullname">
                                                <div className='align-self-center txt---400-14-20'>
                                                    {fullName}
                                                </div>
                                            </div>*/}

                                                </div>
                                            </div>

                                            {(marginAccountList && marginAccountList.length > 0 && productList && productList.length > 0) ? (
                                                <div className='register-product'>
                                                    <div className='sub-title txt---600-16-24'><FormattedMessage id="product-register.new-product-register" /></div>
                                                    <div className='product-list-container'>
                                                        {productList && productList.length > 0 && productList.map(item => {
                                                            return (
                                                                <div className={'item-product custom-form-group ' + (item.tickboxreg === 'N' ? 'no-tick' : '')}>
                                                                    <input type='radio' name='financial-product'
                                                                        checked={selectedProduct && selectedProduct.prmid === item.prmid}
                                                                        onChange={() => this.onSelectProduct(item)}
                                                                        disabled={item.tickboxreg === 'N'}
                                                                    >
                                                                    </input>
                                                                    <label className="custom-label-radio txt---400-14-20" >{item.prmname} </label><br></br>
                                                                    {item.note && <span onClick={() => this.onOpenPdfModal(item)} className="detail txt---400-14-20"><FormattedMessage id="product-register.detail" /> {this.props.defaultTheme === 'dark' ? < PointerRightDark /> : < PointerRightLight />}</span>}
                                                                </div>
                                                            )
                                                        })}

                                                        {/*productList && productList.length === 0 && (
                                                            <div className={'item-product custom-form-group '}>
                                                                <label className="txt-reduce custom-label-radio txt---400-14-20" > <FormattedMessage id="product-register.no-new-product" /></label><br></br>
                                                            </div>
                                                        )*/}
                                                        {productList && productList.length > 0 && productList[0].tickboxreg === 'N' && (
                                                            <div className={'item-product custom-form-group '}>
                                                                <label className="txt-reduce custom-label-radio txt---400-14-20" > <FormattedMessage id="product-register.already-register-product" /></label><br></br>
                                                            </div>
                                                        )}

                                                    </div>

                                                    <div className='item-agree custom-form-group'>
                                                        <input type='checkbox'
                                                            checked={isCheckAgreeRegisterProduct}
                                                            onChange={() => this.onCheckAgreeRegisterProduct()}
                                                        >
                                                        </input>
                                                        <label className="custom-label-radio" ><FormattedMessage id="product-register.agree" /> </label><br></br>
                                                    </div>

                                                    <div className='item-warning custom-form-group txt---400-14-20'>
                                                        <div className='warnning-box'>
                                                            <span className='label-warnning'> <FormattedMessage id="product-register.time-exec1" /> </span>
                                                            <span> {timeExec}</span>
                                                        </div>
                                                        <button className='btn btn-confirm' onClick={() => this.preCheckProductRegister()} ><FormattedMessage id="product-register.register" /></button>
                                                    </div>
                                                </div>
                                            )
                                                :
                                                (
                                                    <div className='register-product'>
                                                        <div className='product-list-container'>
                                                            <div className={'item-product custom-form-group '}>
                                                                <label className="txt-reduce custom-label-radio txt---400-14-20" > <FormattedMessage id="product-register.no-new-product" /></label><br></br>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </Fragment>
                                    )}

                                    {productTab === PRODUCT_TAB.REGISTER_HISTORY && (
                                        <div className='register-product-history'>
                                            <div className='sub-title txt---600-16-24'><FormattedMessage id="product-register.product-register-history" /></div>

                                            <div className="action-container">
                                                <div className={'d-flex container-select ' + (this.props.isDeskTop ? 'flex-wrap' : '')}>
                                                    {
                                                        isBroker ?
                                                            <div className="custom-form-group-n padding-top-0">
                                                                <SelectorAccountCustomer
                                                                    ref={this.selectAccountCustomerRef}
                                                                    curCustomer={curCustomer}
                                                                    selectorAcountScreen={SELECTOR_ACCOUNT_SCREEN.SCREEN_MODAL}
                                                                    disabled={false}
                                                                    className="custom-form-control txt---400-14-20"
                                                                />
                                                            </div>
                                                            :
                                                            <div className="custom-form-group txt---400-14-20">
                                                                <input autoFocus={true} className='custom-form-control custom-form-control-custodycd' value={this.props.userInfo && this.props.userInfo.custodycd} readOnly />
                                                            </div>
                                                    }

                                                    <div className="custom-form-group-n">
                                                        {/* <div className='align-self-center label txt---400-14-20'><FormattedMessage id="order-history.fillter-sub-account" /></div> */}
                                                        <select value={currentAccount ? currentAccount.id : ''} onChange={this.setCurrentAccount} className="custom-form-control txt---400-14-20">
                                                            {(!accounts || accounts.length === 0 || !currentAccount) && (
                                                                <FormattedMessage key="empty" id="header.choose-account">
                                                                    {(txt) => (
                                                                        <option key="empty" value="" disabled>{txt}</option>
                                                                    )}
                                                                </FormattedMessage>
                                                            )}
                                                            {accounts && accounts.map((account) => {
                                                                if (account.mrtype === 'T') { // Chỉ hiển thị danh sách tiểu khoản margin
                                                                    return (account.accounttype === "ALL" ? <option key={account.id} value={account.id}>{account.id}</option> :
                                                                        (<option key={account.id} value={account.id}>{account.cdcontent}</option>)
                                                                    )
                                                                }
                                                            })}
                                                        </select>
                                                    </div>

                                                    <div className="custom-form-group-n">
                                                        <div className='align-self-center label txt---400-14-20'><FormattedMessage id="common.from-date" /></div>
                                                        <DatePicker
                                                            className="custom-form-control text-left txt---400-14-20"
                                                            value={this.state.fromDate}
                                                            onChange={this.onFromDateChanged}
                                                            minDate={this.minDate}
                                                        />
                                                    </div>

                                                    <div className="custom-form-group-n">
                                                        <div className='align-self-center label txt---400-14-20'><FormattedMessage id="common.to-date" /></div>
                                                        <DatePicker
                                                            className="custom-form-control text-left txt---400-14-20"
                                                            value={this.state.toDate}
                                                            onChange={this.onToDateChanged}
                                                            minDate={this.minDate}
                                                        />
                                                    </div>

                                                    <div>
                                                        <button ref={this.searchBtnRef} className="txt---400-14-20 btn-search"
                                                            onClick={this.search}
                                                        // onKeyDown={this.handlerKeyDown}
                                                        >
                                                            <FormattedMessage id="common.search" />
                                                        </button>
                                                    </div>

                                                </div>


                                            </div>

                                            <div className='h-table'>
                                                <TableExtremeCustom2
                                                    OBJLAYOUT={currentLayoutPageActive}
                                                    keyStoreUpdate="odorderid" // key của row 
                                                    columnsDefault={columnsDefault} // ds cột
                                                    totalDefault={[]} // ds sum (nếu có)
                                                    renderSwitch={this.renderSwitch} // func render dữ liệu
                                                    returnCaption={this.returnCaption} // func render column name
                                                    isUseSelection={false} // true/false: hiện/ẩn chọn row
                                                    isLoadPanel={false} // true/false: hiện/ẩn iconLoadPanel
                                                    isPager={false} //true/false:  hiển thị giao diện phân trang
                                                    addClass="broker-order-book-table" // class cha
                                                    apiLoadAll={this.apiLoadRegisterHistory}  // sử dụng Api load all: không cần trả ra totalCount
                                                    onReloadData={onReloadRegisterHistory} // thay đổi giá trị này để render tableEx
                                                    showFilter={true}
                                                />
                                            </div>
                                        </div>
                                    )}

                                </div>
                            }

                        </div>
                    </div>
                    {
                        isOpen &&
                        <AdvanceSaleModal
                            isOpen={isOpen}
                            toggle={this.toggleConfirm}
                            item={this.state.item}
                            handleChangeAdvance={this.handlePreAdvance}
                            handleOffAdvance={this.handleOffAdvance}
                            convertStatus={this.convertStatus}
                        ></AdvanceSaleModal>
                    }

                    {isOpenModalRules &&
                        <ModalPDFRules
                            titleId={'product-register.detail-info'}
                            isOpen={isOpenModalRules}
                            toggle={this.onToggleOpenModalRules}
                            checked={true}
                            pdf_url={pdf_url}
                            noFooter={true}
                        >
                        </ModalPDFRules>}
                </div>
            </RetryableContent>
        )
    }

}

const mapStateToProps = state => {
    let _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive];
    const _accountInfo = _layoutPage && _layoutPage["accountInfo"]
    let { accounts, curCustomer } = { ..._accountInfo }
    const _accounts = accounts && accounts.filter(item => item.accounttype !== "FDS");
    let defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme

    let currentAccountId = _layoutPage.accountInfo && _layoutPage.accountInfo['currentAccountId'] ? _layoutPage.accountInfo['currentAccountId'] : "";
    let typeAccount = _layoutPage.accountInfo && _layoutPage.accountInfo['typeAccount'] ? _layoutPage.accountInfo['typeAccount'] : "";
    //console.log("change-screen: ", currentAccountId, typeAccount)
    return {
        currentAccountId: currentAccountId,
        language: state.app.language,
        accounts: _accounts,
        defaultTheme: defaultTheme,
        actionMethodConditon: state.user.userInfo.actionMethodConditon,
        typeAccount: typeAccount,
        accountInfo: _accountInfo,
        isLoggedIn: state.user.isLoggedIn,
        userInfo: state.user.userInfo,
        config: state.app.config,
        role: state.user.userInfo.role,
        curCustomer: curCustomer,
        currentDate: state.app.currentDate,
        currentLayoutPageActive: _currentLayoutPageActive,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateInfoCheckAuth: (data, key) => dispatch(actions.updateInfoCheckAuth(data, key)),
        updateConfirmCheckAuth: (data) => dispatch(actions.updateConfirmCheckAuth(data)),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
        refetchDefaultConfig: () => dispatch(actions.fetchUpdateUserDefaultConfig()),
        navigate: (path) => dispatch(push(path)),
        setActionMethodCondition: (data) => dispatch(actions.setActionMethodCondition(data)),
    };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ServiceRegister));
