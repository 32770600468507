import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import './ModalNormalOrderMultiCancel.scss';
import DraggableModal from '../../../../components/Modal/DraggableModal';
import _ from 'lodash';
import { connect } from 'react-redux';
import { TYPE_ACCOUNT, LoadStatus } from '../../../../utils';
import { FormattedPrice } from '../../../../components/Formating';
import CustomScrollbars from '../../../../components/CustomScrollbars';
import TradeAuth2 from '../../../../components/TradeAuth2/TradeAuth2';
import { tradeApiService } from 'services';
import RetryableContent from '../../../../components/RetryableContent';
import FormattedAmount from './../../../../components/Formating/FormattedAmount';
import CustomRowItem from "components/CustomRowItem/CustomRowItem";

const textTypeAction = {
    BUY: "buy",
    SELL: "sell"
};

class ModalNormalOrderMultiCancel extends Component {

    initialState = {
        loadStatus: LoadStatus.NONE,
    };

    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState
        }
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }
	
	componentWillUnmount() {
        this.mounted = false
    }
	
    componentDidMount() {
        this.mounted = true
	}

    setLoadStatus = (status) => {
        this._setState({
            loadStatus: status
        });
    };


    processTradeAuth = (authInfo) => {
        return new Promise(function (resolve, reject) {
            return resolve(authInfo);
        });
    };

    twoFactorAuthCb = (data) => {
        const { twoFactorAuthCb } = this.props
        twoFactorAuthCb(data);
    };


    onFinish = () => {
        const { onClose } = this.props;
        onClose()
    };


    UNSAFE_componentWillReceiveProps(nextProps) {
        const { loadStatusCancelMultiNormalOrder } = nextProps
        this.setLoadStatus(loadStatusCancelMultiNormalOrder ? loadStatusCancelMultiNormalOrder : this.state.loadStatus)
    }

    render() {
        const { actionSelect } = this.state
        const { isOpen, onClose, listReconfirmOrder, onHandleCancelOrder, accountInfo, custodycd } = this.props;
        let _className = 'modal-normal-order-multi-cancel'
        let titleId = 'trade.auth.cancel-title'

        const genAuthFunc = tradeApiService.resendTwoFactorCode;    // gen lại mã xác thực ở tradeApi
        const twoFactorAuthFunc = this.processTradeAuth // check biến (transFunction) để biết xem nên gọi function validate mã xác thực ở server nào (trade, transaction)
        const initAuthFunc = tradeApiService.initTwoFactorAuth; // check biến (transFunction) để biết xem nên gọi function init Transaction (trade, transaction)
        //console.log("binh_actionSelect", listReconfirmOrder)
        let _listReconfirmOrder = _.cloneDeep(listReconfirmOrder)
        const { typeAccount } = accountInfo
        let fullname = listReconfirmOrder && listReconfirmOrder.length>0 && listReconfirmOrder[0].fullname;
        return (
            <DraggableModal
                isOpen={isOpen}
                onClose={onClose}
                className={_className}
                titleId={titleId}
                toggle={onClose}
            >
                <div className="body">
                    <CustomRowItem
                        showLabel="trade.order.custodycd2"
                        showValue={`${custodycd || ''}`}
                    />
                    <CustomRowItem
                        showLabel="trade.order.customer"
                        showValue={`${fullname || ''}`}
                    />
                    <RetryableContent status={this.state.loadStatus} disableRetry={true} className="trade-auth-modal-normal-order-multi-cancel">
                        <div className="wrap-body">
                            <CustomScrollbars
                                className="table-order"
                                autoHeight={true}
                                autoHeightMin={200}
                                autoHeightMax={400}
                            >
                                <table className="basic-table">
                                    <thead>
                                        <tr>
                                            <th className="text-center"><FormattedMessage id='trade.order.order' /></th>
                                            <th className="text-center acc-col"><FormattedMessage id='trade.order.account' /></th>
                                            <th className="text-center"><FormattedMessage id='trade.order.symbol' /></th>
                                            <th className="text-center"><FormattedMessage id='trade.order.qtty-short' /></th>
                                            <th className="text-center"><FormattedMessage id='trade.order.price-short' /></th>
                                            <th className="text-center"><FormattedMessage id='trade.order.amt-value' /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listReconfirmOrder && listReconfirmOrder.length > 0 && listReconfirmOrder.map((orderInfo, index) => {
                                            let { action, quantity, orderTypeShort, symbolCurrent,
                                                orderType, marketPrice, subOrderType, afacctnoext, orderValue } = { ...orderInfo };
                                            return (
                                                <tr key={index} >
                                                    <td className={"text-center text-uppercase " + (action === textTypeAction.BUY ? "text-green" : "text-red")}>
                                                        {action && action === textTypeAction.BUY ? <FormattedMessage id="trade.order.buy" /> : <FormattedMessage id="trade.order.sell" />}
                                                    </td>
                                                    <td className="text-center acc-col">{afacctnoext}</td>
                                                    <td className="text-center">{symbolCurrent && symbolCurrent.id}</td>
                                                    <td className="text-center"><FormattedAmount value={quantity} /></td>
                                                    <td className="text-center">
                                                        <FormattedPrice value={marketPrice} shortForm={true} isNotThousand={typeAccount === TYPE_ACCOUNT.FDS ? true : false} />
                                                    </td>
                                                    <td className="text-center">
                                                        <FormattedPrice value={orderValue} shortForm={true} isNotThousand={typeAccount === TYPE_ACCOUNT.FDS ? true : false} />
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </CustomScrollbars>
                            <TradeAuth2
                                containerClass="row gutters-5 align-items-center auth-container"
                                labelClass="col-5"
                                inputClass="col-7 custom-form-group"
                                inputClassAuth="col-7 custom-form-group-effect"
                                btnContainerClass=""
                                onFinish={this.onFinish}

                                initAuthFunc={initAuthFunc}
                                genAuthFunc={genAuthFunc}
                                twoFactorAuthFunc={twoFactorAuthFunc}
                                twoFactorAuthCb={this.twoFactorAuthCb}
                                setLoadStatus={status => this.setLoadStatus(status)}
                                // autoCb={autoCb}
                                isDisableConfirmBtn={this.state.loadStatus == LoadStatus.LOADING ? true : false}
                                showOrderSwitch={true}
                                autoFocus={true}
                                fillPassword={true}

                                isOrder={true}
                                noteMessageClass="txt---400-14-20 text-not-active font-italic"
                            />
                        </div>
                    </RetryableContent>
                </div>
            </DraggableModal>
        );
    }

}

const mapStateToProps = (state, ownProps) => {
    return {
        defaultTheme: state.user.userInfo && state.user.userInfo.defaultTheme
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ModalNormalOrderMultiCancel);

