import _ from 'lodash'

// import { dispatch } from 'store/actions/appActions.js';
import * as actions from './../../store/actions';
import reduxStore, { dispatch } from 'redux.js';
import { emitter } from 'utils/EventEmitter';
import * as LayoutUtils from '../../containers/LayoutPage/LayoutUtils';
import actionTypes from "../../store/actions/actionTypes";
import { CommonWidgetUtils } from "../../utils";
import { subscribeOnStream, unsubscribeFromStream } from './streaming.js';
// import $ from "jquery";

const globalVar = window._env_

const FSS_DataFeed_Url = globalVar.api.API_CHART_DATA_URL;
// const FSS_News_Url = config.api.API_NEWS_SERVICE;
// const FSS_DataFeed_Url = 'http://localhost:1335/';
const SUPPORTED_RESOLUTIONS = ['1', '3', '5', '15', '30', '60', '120', '240', 'D'];

let crrTo = parseInt(new Date().getTime() / 1000);

const history = {}; // Last bar data storage.
let dataExchanges = [];
let instrument

// let trade

/**
 * Check cùng ngày theo time unix
 * @param {*} unix1 // 1680493453177
 * @param {*} unix2 
 */


function fillSymbol(symbol) {
	if (!symbol) {
		return
	}
	// dispatch(actions.updatedSymbolLayoutPage(symbol))
}

function changeIndexVisibleSectionIds(visibleSectionIds, lastSectionIdActive, from) {
	if (visibleSectionIds) {
		let index = _.findIndex(visibleSectionIds, (e) => e == lastSectionIdActive)
		//console.log('changeIndexWidget.:index', visibleSectionIds, index, lastSectionIdActive, from)
		if (index > -1) {
			if (index != visibleSectionIds.length - 1) {
				//nếu index không nằm ở vị trí cuối cùng thì thực hiện đổi chỗ
				visibleSectionIds.splice(index, 1)
				visibleSectionIds.push(lastSectionIdActive)
			} else {
				return false
			}
		} else {
			return false
		}
	}
	return visibleSectionIds
}

function changeIndexWidget(id) {
	let from = "datafeed.:changeIndexWidget"
	// return null
	// alert('changeIndexWidget ')
	// let { id, isDeskTop } = this.props
	// //console.log('changeIndexWidget==this.props=======', this.props)
	// return null;
	id = 'priceChart' // hardcode
	//console.log('datafeed.:changeIndexWidget 1=', id)
	let getLayoutPageCurrent = CommonWidgetUtils.getLayoutPageCurrent();
	let OBJLAYOUT = getLayoutPageCurrent.currentLayoutPageActive;
	let layoutCurrent = getLayoutPageCurrent.layoutCurrent;
	let LayoutPageCurrent = getLayoutPageCurrent.LayoutPageCurrent;
	let isLayoutDefautl = LayoutUtils.isLayoutDefautl(OBJLAYOUT)
	if (isLayoutDefautl) {
		if (LayoutPageCurrent.id_tmp) {
			layoutCurrent.id = LayoutPageCurrent.id_tmp
		}
		// else {
		// 	// Nếu chưa tồn tại LayoutPageCurrent.id_tmp => chưa có sự thay đổi layoutDefault => không xử lý
		// 	return;
		// }
		let _visibleSectionIds = CommonWidgetUtils.changeIndexVisibleSectionIdsByCSS(from, id, layoutCurrent.visibleSectionIds)
		if (_visibleSectionIds == false) {
			// Nếu index thì k xử lý
			return null;
		}
	}
	else {
		//console.log('datafeed.:changeIndexWidget 2=', layoutCurrent, LayoutPageCurrent)

		if (layoutCurrent && layoutCurrent.visibleSectionIds) {
			// layoutCurrent.visibleSectionIds = changeIndexVisibleSectionIds(layoutCurrent.visibleSectionIds, id)
			let _visibleSectionIds = CommonWidgetUtils.changeIndexVisibleSectionIdsByCSS(from, id, layoutCurrent.visibleSectionIds)
			if (_visibleSectionIds == false) {
				// Nếu index thì k xử lý
				return null;
			}
			layoutCurrent.visibleSectionIds = _visibleSectionIds
			let layoutCustom = { ...layoutCurrent }
			//console.log('changeIndexWidget.:layoutCustom=', layoutCustom)
			//    bắt buộc setTimeout để fix lỗi kéo thả widget merge bị chìm  
			// var _section = $('#section_priceChart')
			// _section.addClass('z-index')
			// _section.css('z-index', 1)
			// _section.css('disable', 'none')

			dispatch({
				type: actionTypes.UPDATED_LAYOUTS_CUSTOM,
				layoutCustom: layoutCustom,
				from: 'datafeed.js.:changeIndexWidget'
			});
		}
	}
}

let symbolId = undefined
let isCheckSymbolId = false

export default {
	onReady: function (callback) {
		try {
			var iframe = document.querySelector('[id^="tradingview_"]')
			// //console.log('iframe======', iframe.src)
			let defaultTheme = iframe.src.split('&theme=')
			defaultTheme = defaultTheme[1]
			emitter.emit('onReadyChartByDefaultTheme', defaultTheme);
			// iframe.style.display = 'none'; // tạm fix lỗi css tool_bar khi bị reload lại chart
			if (iframe) {
				iframe.contentWindow.addEventListener('click', () => changeIndexWidget());
			}
			//console.log('[onReady]: Method call');
			let urlSearch = `${FSS_DataFeed_Url}/config`;
			// if (urlSearch) {
			fetch(urlSearch).then(result => result.json()).then(configurationData => {
				dataExchanges = configurationData && configurationData.exchanges ? configurationData.exchanges : []
				callback(configurationData)
				// $('.TVChartContainer').css('display', 'inherit')
				// iframe.css('disable', 'inherit !important;')
				// let timer = setTimeout(function () {
				// 	iframe.style.display = 'inherit'; // tạm fix lỗi css tool_bar khi bị reload lại chart
				// 	clearTimeout(timer)
				// }, 200)
			});
			// }
		} catch (e) {
			//console.log("[onReady]: Method call.:Error=", e)
		}
	},
	searchSymbols: function (userInput, exchange, symbolType, onResultReadyCallback) {
		//console.log('[searchSymbols]: Method call');
		try {
			// For implementation this method, we use this API reference:
			//     https://min-api.cryptocompare.com/documentation?key=Other&cat=allExchangesV2Endpoint
			const state = reduxStore.getState();
			const _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
			const _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
			const _accountInfo = _layoutPage && _layoutPage["accountInfo"]
			const { typeAccount } = { ..._accountInfo }
			const isPermissionLayout = _currentLayoutPageActive.includes("CS#TRADE#CB") || _currentLayoutPageActive.includes("CS#TRADE#NC")
			//console.log("_currentLayoutPageActive---: ", isPermissionLayout)
			const isLoggedIn = state.user.isLoggedIn
			let checkExchangeNameLength = false
			if (symbolType === 'stock') {
				onResultReadyCallback([]); // Return empty search result.
			} else {
				let exchangeName = dataExchanges.filter((exchangeItem) => {
					return exchangeItem && exchangeItem.value ? exchangeItem.value === exchange : ''
				})
				let arrayExchangeName = ''
				if (isLoggedIn && exchangeName.length === 0 && isPermissionLayout) {
					if (typeAccount === 1) {
						arrayExchangeName = 'HNX,HOSE,UPCOM'
					} else if (typeAccount === 2) {
						arrayExchangeName = 'XHNF'
					}
					checkExchangeNameLength = true
				} else {
					checkExchangeNameLength = false
				}
				//console.log("symbols---123 typeAccount: ", typeAccount)
				//console.log("symbols---123 isLoggedIn: ", isLoggedIn)
				//console.log("symbols---123 exchangeName: ", exchangeName)
				//console.log("symbols---123 checkExchangeNameLength: ", checkExchangeNameLength)
				//console.log("symbols---123 arrayExchangeName: ", arrayExchangeName)
				let UpperUserInput = (userInput !== '' || userInput !== null || userInput !== undefined) ? userInput.toString().toUpperCase().trim() : ''
				let urlSearch = `${FSS_DataFeed_Url}/search?query=${UpperUserInput}&type=${symbolType}&exchange=${checkExchangeNameLength ? arrayExchangeName : exchangeName && exchangeName.length > 0 ? exchangeName[0].name : ''}`;
				// let urlSearch = `${FSS_DataFeed_Url}/search?query=${userInput}&type=${symbolType}&exchange=${exchange}`;
				fetch(urlSearch).then(result => result.json()).then(data => {
					const symbols = [];
					// const dataSearch = data && data.d && data.d.length > 0 ? data.d : []
					const dataSearch = data

					for (const item of dataSearch) {
						symbols.push({
							symbol: item.symbol,
							full_name: item.name,
							description: item.description == "" ? " " : item.description,
							exchange: item.exchange,
							ticker: item.ticker,
							type: item.symbol_type
						});
					}
					isCheckSymbolId = true;

					if (isPermissionLayout) {
						if (isLoggedIn && exchangeName.length === 0) {
							if (symbols.length > 0) {
								dispatch(actions.setSymbolSearchDataFeed(true))
							} else {
								dispatch(actions.setSymbolSearchDataFeed(false))
							}
						}
					} else {
						dispatch(actions.setSymbolSearchDataFeed(true))
					}

					onResultReadyCallback(symbols);
				}).catch(rejected => {
					//console.log('[searchSymbols]: rejected=', rejected);
				});;
			}
		}
		catch (e) {
			//console.log('[searchSymbols]: e=', e);
		}
	},
	resolveSymbol: function (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) {
		//console.log('[resolveSymbol]: Method call', symbolName);

		if (symbolName.indexOf(':') > 0) {
			const symbolParts = symbolName.split(':');
			symbolName = symbolParts[1];
		}
		if (symbolName == " " || symbolName == "" || !symbolName) {
			onSymbolResolvedCallback({});
			return;
		}
		console.debug("resolveSymbol.:symbolName.: ", { symbolName })

		let urlResolve = `${FSS_DataFeed_Url}/symbols?symbol=${symbolName}`;
		fetch(urlResolve).then(result => result.json()).then(symbolinfo => {
			const state = reduxStore.getState();
			instrument = _.cloneDeep(state.symbol.instruments[symbolinfo.name]) || {};
			// fillSymbol(symbolName && symbolName)
			// let symbolinfo =data && data.d; //format response thay đổi
			const info = {
				name: symbolinfo.name,
				description: symbolinfo.description == "" ? " " : symbolinfo.description,
				type: symbolinfo.type,
				session: symbolinfo.sesion,
				timezone: symbolinfo.timezone,
				ticker: symbolName,
				exchange: symbolinfo.exchange || instrument['EX'],
				minmov: symbolinfo.minmov,
				pricescale: symbolinfo.pricescale,
				has_intraday: symbolinfo.has_intraday,
				intraday_multipliers: symbolinfo.intraday_multipliers,
				supported_resolution: symbolinfo.supported_resolutions || SUPPORTED_RESOLUTIONS,
				volume_precision: symbolinfo.volume_precision,
				data_status: symbolinfo.data_status,
				symbol: symbolinfo.symbol
			};

			onSymbolResolvedCallback(info);
		});
	},
	getBars: function (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) {
		let { from, to, firstDataRequest } = periodParams;
		//console.log('[getBars]: Method call', 'from', from, 'to', to, 'firstDataRequest', firstDataRequest);
		// dispatch(setTradeCurrentSymbolById(symbolInfo.name))
		// dispatch(setSymbolDetailModalOpenState(true))
		// fillSymbol(symbolInfo && symbolInfo.name)
		if (isCheckSymbolId) {
			if (symbolInfo) {
				if (symbolId != symbolInfo.name) {
					fillSymbol(symbolInfo.name)
					symbolId = symbolInfo.name
					isCheckSymbolId = false
				}
			}
			else {
				symbolId = undefined
			}
		}

		if (to < 0) {
			onHistoryCallback([], { noData: true }); // "noData" should be set if there is no data in the requested period.
			return
		}
		if (!firstDataRequest) {
			// update lại to date để fix lỗi zoom nhỏ đồ thị > chuyển mã bị mất data history do limit và from+to bị lệnh nhau khi zoom
			to = crrTo
			if (!to) {
				// ngừng call api do dữ liệu đã chạm đáy
				onHistoryCallback([], { noData: true });
				return
			}
		}
		if (symbolInfo.symbol) {
			let limit = 100 // giảm limit 300 => 100 để tránh lỗi zoom > chuyển mã mất data
			let urlHistory = `${FSS_DataFeed_Url}/history?resolution=${resolution}&symbol=${symbolInfo.symbol}&limit=${limit}&to=${to}`;
			// let urlHistory = `${FSS_DataFeed_Url}/history?resolution=${resolution}&symbol=${symbolInfo.name}&limit=${400}&to=${to}`;
			fetch(urlHistory).then(result => {
				return result.json();
			})
				.then(async (historyDataResponse) => {
					if (historyDataResponse && (historyDataResponse.s != 'ok' || historyDataResponse.s == 'no_data')) {
						onHistoryCallback([], { noData: true }); // "noData" should be set if there is no data in the requested period.
						// return;
					}
					let historyData = historyDataResponse;
					let lengthOfHistoryData = historyData.t && historyData.t.length && historyData.t[0] !== 'null' ? historyData.t.length : 0
					let bars = [];
					if (lengthOfHistoryData) {
						for (let i = 0; i < lengthOfHistoryData; i++) {
							bars.push({
								time: parseInt(historyData.t[i]) * 1000, // Requires candle time in ms.
								low: +historyData.l[i],
								high: +historyData.h[i],
								open: +historyData.o[i],
								close: +historyData.c[i],
								volume: +historyData.v[i]
							})
						}
					} else {
						onHistoryCallback([], { noData: true }); // "noData" should be set if there is no data in the requested period.
					}
					if (firstDataRequest) {
						history[symbolInfo.name] = { lastBar: bars[bars.length - 1] }; // Save last bar data about current symbol.
					}
					if (bars && bars.length > 0) {
						let lastTo = bars[0] && parseInt(bars[0].time / 1000)
						crrTo = (lastTo != crrTo) ? lastTo : undefined
					}
					else {
						crrTo = undefined
					}
					onHistoryCallback(bars, { noData: false });

				}).catch(error => {
					//console.log('[getBars]: Get error', error);
					onErrorCallback(error);
				});
		} else {
			onHistoryCallback([], { noData: true });
		}
	},
	subscribeBars: function (symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback) {
		//console.log('[subscribeBars]: Method call with subscribeUID:', subscribeUID);
		subscribeOnStream(symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback, history);
	},
	unsubscribeBars: function (subscriberUID) {
		//console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID);
		unsubscribeFromStream(subscriberUID);
	},
};