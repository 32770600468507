import React, { Component } from 'react';
import { push } from "connected-react-router";
import BrokerOrderBook from './BrokerOrderBook';
import AllCareByOrderBook from './AllCareByOrderBook';
import { connect } from 'react-redux';
import { ReactComponent as CloseIconDark } from '../../../assets/icons/header/CloseIcon_Dark.svg';
import { ReactComponent as CloseIconLight } from '../../../assets/icons/header/CloseIcon_Light.svg';

import * as actions from "../../../store/actions";

// import RightOffPerform from './RightOffPerform';

import './BrokerOrder.scss';
import { FormattedMessage } from 'react-intl';

const TAB_SCREEN = {
    BROKER_ORDERBOOK: 'BROKER_ORDERBOOK',
    ALL_CAREBY_ORDER: 'ALL_CAREBY_ORDER'
}
class RightOffRegister extends Component {

    constructor(props) {
        super(props);
    }

    initialState = {
        tabActive: TAB_SCREEN.BROKER_ORDERBOOK
    };

    state = {
        ...this.initialState
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }
	
	componentWillUnmount() {
        this.mounted = false
    }
	
    componentDidMount() {
        this.mounted = true
	}	

    onChangeTabActive = (tab) => {
        this._setState({ tabActive: tab })
    }


    genderIcon = (keyIcon) => {
        let { defaultTheme } = this.props
        switch (keyIcon) {
            case 1:
                return defaultTheme === 'dark' ? < CloseIconDark /> : < CloseIconLight />
                break;
            default:
                break;
        }
    }

    closeScreenModal = () => {
        const { setIsOpenModalHaveData } = this.props;
        setIsOpenModalHaveData("ScreenModal", {
            isOpenScreenModal: false,
            curScreenModalActive: "",
        })
    };

    render() {
        const { tabActive } = this.state
        return (
            <div className="order-book">
                <div className="order-book-look-up">
                    <div className="block" style={{ marginBottom: "5px" }}>
                        <div className='headB---700-16-24 title'>
                            <div className={tabActive === TAB_SCREEN.BROKER_ORDERBOOK ? "active" : "not-active"} onClick={() => this.onChangeTabActive(TAB_SCREEN.BROKER_ORDERBOOK)}>
                                <FormattedMessage id='menu-sidebar.title-11' />
                            </div>
                            <div className={tabActive === TAB_SCREEN.ALL_CAREBY_ORDER ? "active" : "not-active"} onClick={() => this.onChangeTabActive(TAB_SCREEN.ALL_CAREBY_ORDER)}>
                                <FormattedMessage id="trade.place-order.all-careby-orderbook" />
                            </div>
                            <div className='close-btn'>
                                <span onClick={() => { this.closeScreenModal() }}>{this.genderIcon(1)}</span>
                            </div>
                        </div>
                        {
                            tabActive === TAB_SCREEN.BROKER_ORDERBOOK && <BrokerOrderBook />
                        }
                        {
                            tabActive === TAB_SCREEN.ALL_CAREBY_ORDER && <AllCareByOrderBook />
                        }
                    </div>
                </div>
            </div>
        );
    }

}

const mapStateToProps = state => {
    const userInfo = state.user.userInfo;
    const permissionInfo = userInfo.permissionInfo ? userInfo.permissionInfo : null;

    let _accountInfo = state.layout.listIsOpenModal["C&B"]["ScreenModal"].accountInfo
    let { accounts, currentAccountId } = { ..._accountInfo }
    const filterAccount = accounts && accounts.filter(item => item.accounttype !== "FDS");
    const defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme

    return {
        currentCustomer: state.customer.currentCustomer,
        userInfo: userInfo,
        language: state.app.language,
        role: state.user.userInfo.role,
        accounts: filterAccount,
        permissionInfo: permissionInfo,
        currentAccountId: currentAccountId,
        defaultTheme: defaultTheme
    };
};

const mapDispatchToProps = dispatch => {
    return {
        refetchDefaultConfig: () => dispatch(actions.fetchUpdateUserDefaultConfig()),
        navigate: (path) => dispatch(push(path)),
        setConfirmModalProps: (isOpen, messageId, acceptHandle, cbProps) => dispatch(actions.setConfirmModalProps(isOpen, messageId, acceptHandle, cbProps)),
        updateInfoCheckAuth: (data, key) => dispatch(actions.updateInfoCheckAuth(data, key)),
        updateConfirmCheckAuth: (data) => dispatch(actions.updateConfirmCheckAuth(data)),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RightOffRegister);

