import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
// import ReloadIconDark from '../../../../assets/icons/ReloadIcon_Dark.svg'
import { ReactComponent as Call_Chilling_Icon } from '../../../assets/icons/Call_Chilling_Icon.svg'
import { ReactComponent as Call_Email_Icon } from '../../../assets/icons/Call_Email_Icon.svg'
// import { inquiryService, transferService } from "../../../../services";
// import * as actions from "../../../../store/actions";
import { LoadStatus } from "../../../utils";

import './ContactMobileModal.scss';
import DraggableModal from 'components/Modal/DraggableModal';

class ContactMobileModal extends Component {


    constructor(props) {
        super(props);
    }

    initialState = {

    };

    state = {
        ...this.initialState
    };

    close = () => {
        this.props.onClose();
    }




    render() {
        const { isOpen, contactInfos, onClose, toggle } = this.props;
        const combinedStatus = LoadStatus.LOADED;
        return (
            <DraggableModal
                isOpen={isOpen}
                toggle={toggle}
                onClose={toggle}
                className={"trading-money-bidv"}
                titleId={"contact-mobile.contact"}
            >
                <div className="body">
                    <div className="row gutters-5 align-items-center h-row">
                        <div className="col-6">
                            <div className="row gutters-5 align-items-center h-row">
                                <div className="col-3">
                                    <div className='icon-hotline'>
                                        <Call_Chilling_Icon />
                                    </div>
                                </div>
                                <div className="col-9 info">
                                    <div className='txt---500-16-24'>
                                        {contactInfos.contact_north ? contactInfos.contact_north : ''}
                                    </div>
                                    <div className='txt---400-14-20 text-not-active'>
                                        <FormattedMessage id="contact-mobile.hotline" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="row gutters-5 align-items-center h-row">
                                <div className="col-3">
                                    <div className='icon-hotline'>
                                        <Call_Email_Icon />
                                    </div>
                                </div>
                                <div className="col-9 info">
                                    <div className='txt---500-16-24'>
                                        {contactInfos.email ? contactInfos.email : ''}
                                    </div>
                                    <div className='txt---400-14-20 text-not-active'>
                                        <FormattedMessage id="contact-mobile.email" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-contact">
                    <button className="btn-action btn-back-contact txt---500-16-24 btn-refresh" onClick={() => this.close()}>
                        <FormattedMessage id="common.btn-back" />
                    </button>
                </div>
            </DraggableModal>
        );
    }

}

const mapStateToProps = state => {
    const contactInfos = state.app.contactInfos ? state.app.contactInfos : {};
    return {
        contactInfos: contactInfos,
    };
};



export default connect(mapStateToProps, null)(ContactMobileModal);