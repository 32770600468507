import React, { Component } from 'react';
import _ from 'lodash'
import { FormattedMessage, injectIntl } from 'react-intl';
import { execTypes, LoadStatus, Role, ToastUtil, LanguageUtils, KeyCodeUtils, reportTypes, CommonUtils, Random, SELECTOR_ACCOUNT_SCREEN, fullSubOrderTypes, CommonOrderUtils, ORDER_TYPE_USE, execTypeSearch, PREFIX_CUSTODYCD } from "../../../utils";
// import config from '../../../combineConfig';
import RetryableContent from "../../../components/RetryableContent";
import moment from "moment/moment";
import { fdsInquiryService, inquiryService, tradeApiService, userService } from "../../../services";
import { connect } from "react-redux";
import * as actions from '../../../store/actions'
// import { FormattedAmount, FormattedPercentage, FormattedPrice, FormattedQuantity } from "../../../components/Formating";
// import { DatePicker } from "../../../components/Input";
// import TextToolTip from "../../../components/TextToolTip";
// import { ReactComponent as DatetimeIconLight } from '../../../assets/icons/new/DatetimeIcon.svg'
// import { ReactComponent as DatetimeIconDark } from '../../../assets/icons/new/DatetimeIcon_Dark.svg'
// import { ReactComponent as DownloadIconLight } from '../../../assets/icons/new/DownloadIcon.svg'
// import { ReactComponent as DownloadIconDark } from '../../../assets/icons/new/DownloadIcon_Dark.svg'
import TableExtremeCustom2 from 'components/Table/TableExtreme/TableExtremeCustom2';
import RenderGridCell from 'components/Table/TableExtreme/RenderGridCell';
import { emitter, FOCUS_PRICE_INPUT } from 'utils/EventEmitter';
import $ from 'jquery';

import './AllCareByOrderBook.scss';
import SelectorAccountCustomer from 'components/SelectorAccount/SelectorAccountCustomer';
import SymbolSuggestTrans from 'components/SymbolSuggestTrans';
import ModalNormalOrderMultiCancel from '../OrderActive/ModalNormalOrderMultiCancel/ModalNormalOrderMultiCancel';
import { TYPE_ACCOUNT } from './../../../utils/constants';


const TYPE_SELECT_CANCEL = {
    CANCEL: 1,
    CANCEL_MULTI: 2
}

const columnsDefault =
    [
        {
            dataField: "action",
            // fixed: true,
            // fixedPosition: "left", // Cố định cột
            dataType: "string",
            allowReordering: true,  // không cho di chuyển
            minWidth: "130",
            width: '150',
            visible: true,
            alignment: "center",
            visibleIndex: 0,
            allowFiltering: false
        },
        {
            dataField: "custodycd", // Tài khoản
            // fixed: true,
            // fixedPosition: "left", // Cố định cột
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 1,
        },
        {
            dataField: "afacctnoext", //Tiểu khoản
            // fixed: true,
            // fixedPosition: "left", // Cố định cột
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 2,
        },
        {
            dataField: "symbol", //Mã chứng khoán
            // fixed: true,
            // fixedPosition: "left", // Cố định cột
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 3,
        },
        {
            dataField: "side", // M/B
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 4,
        },
        {
            dataField: "status", // Trạng thái
            dataType: "string",
            allowReordering: true, // không cho di chuyển
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 5,
        },

        {
            dataField: "pricetype", // Loại lệnh
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "right",
            visibleIndex: 6,
        },
        {
            dataField: "orderqtty", // Khối lượng đặt
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 7,
        },
        {
            dataField: "price", // Giá đặt
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "right",
            visibleIndex: 8,
        },
        {
            dataField: "quoteamt", // Giá trị đặt
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "right",
            visibleIndex: 9,
        },
        {
            dataField: "execqtty", // // Khối lượng khớp
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "right",
            visibleIndex: 10,
        },
        {
            dataField: "execprice", // // Giá khớp
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "right",
            visibleIndex: 11,
        },
        {
            dataField: "execamt", // Giá trị khớp
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "right",
            visibleIndex: 12,
        },
        {
            dataField: "remainqtty", // Khối lượng còn lại
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "right",
            visibleIndex: 13,
        },
        {
            dataField: "remainamt", // Giá trị còn lại
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "right",
            visibleIndex: 14,
        },
        {
            dataField: "matchtype", // Loại lệnh
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 15,
        },
        {
            dataField: "tlname", // Loại lệnh
            dataType: "string",
            allowReordering: true,
            minWidth: "100",
            width: '120',
            visible: true,
            alignment: "center",
            visibleIndex: 16,
        },
        {
            dataField: "odorderid",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '200',
            visible: true,
            alignment: "center",
            visibleIndex: 17,
        },
        {
            dataField: "rootorderid",
            dataType: "string",
            allowReordering: true,
            minWidth: "150",
            width: '200',
            visible: true,
            alignment: "center",
            visibleIndex: 18,
        },
    ]


const totalDefault =
    [
        {
            column: "orderqtty",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "execqtty",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        },
        {
            column: "execamt",
            summaryType: "sum",
            displayFormat: "{0}",
            valueFormat: ",##0",
        }
    ]

class OrderHistoryNew extends Component {
    keyIdRequest = Random.randomOrderRequestId(this.props.userInfo && this.props.userInfo.username && this.props.userInfo.username);

    initialState = {

        symbol: 'ALL',
        execType: execTypes[0],
        orderStatus: 'ALL',
        records: [],
        loadStatus: LoadStatus.NONE,
        orderHistoryStatus: [],
        onReloadData: true,
        listAccounts: [],
        listOrderIdCancel: [],
        listOrderCancel: [],
        typeSelectCancel: TYPE_SELECT_CANCEL.CANCEL,
        isOpenModalNormalOrderMultiCancel: false,
        loadStatusCancelMultiNormalOrder: LoadStatus.NONE,
        userPlaceOrder: '',
        currentCustodycdSelected: '',
        maxCancelQty: 100
    };

    ORDER_ID_LENGTH = 6;

    orderHistoryAllCode = {
        cdtype: "FE",
        cdname: "ORSTATUS"
    };

    orderHistoryDisPlayAllCode = {  // Trạng thái dùng để map lên cột trạng thái trong bảng
        cdtype: "OD",
        cdname: "GRODSTATUS"
    };


    isBroker = () => {
        const { role } = this.props;
        return role === Role.BROKER;
    };


    constructor(props) {
        super(props);
        // Trunc current date
        let currentDate = new Date(this.props.currentDate * 1000);
        currentDate.setHours(0, 0, 0, 0);
        this.state = {
            ...this.initialState
        }
        this.selectorAccountCustomerRef = React.createRef();
        this.searchBtnRef = React.createRef();
    }

    _setState = (obj, callback) => {
        if (this.mounted) {
			this.setState(obj, callback);
		}
    }

    onCancelOrder = (order) => {
        const { accountInfo, intl } = this.props;
        const { curCustomer, accounts, currentAccountId } = { ...accountInfo }
        this._setState({ typeSelectCancel: TYPE_SELECT_CANCEL.CANCEL })
        tradeApiService.initTwoFactorAuth()
            .then((data) => {
                const { transactionId, tokenid, authtypes, challengeQuestion, authtype, verified } = data;
                this.props.updateInfoCheckAuth({
                    transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
                })
                this._setState({ isOpenModalNormalOrderMultiCancel: true })
            })
            .catch((error) => {
                ToastUtil.errorApi(error, 'common.fail-to-load-customer-acccounts-list');
                return;
            })

        let listReconfirmOrder = this.getListReconfirmOrder([{ ...order }]);
        this._setState({ listDataFillModal: listReconfirmOrder, listOrderSelected: [{ ...order }] })
    };

    onCopyOrder = async (record) => {
        // console.log('laojahackgame======> DATA RECORD', record);
        const { accountInfo, updatePlaceQuickOrder, updateProTrade, updatedSymbolLayoutPage, setCustodycdCustomerScreen } = this.props;
        const { typeAccount } = { ...accountInfo }
        //set lai account id hien tai
        // setCurrentAccount(order['afacctno']);
        const action = CommonUtils.getOrderActionFromSideCode(record['sideCode']);


        let orderInputNormal = {
            accountInfo: {
                ...accountInfo,
                currentAccountId: record['afacctno'],
            },
            action: action,
            limitPrice: record['price'],
            quantity: Number(record['qtty']),
        }


        let orderInputPro = {
            action: action,
        }


        await updatedSymbolLayoutPage(record['symbol'], "search_layout")

        let getInfoOrderLayoutCurrent = CommonOrderUtils.getInfoOrderLayoutCurrent()
        const { listOrderLayoutCurrent } = getInfoOrderLayoutCurrent
        if (listOrderLayoutCurrent && listOrderLayoutCurrent.length > 0) {
            if (listOrderLayoutCurrent.includes(ORDER_TYPE_USE.NORMAL_ORDER)) {
                updatePlaceQuickOrder({ ...orderInputNormal, symbolCurrent: record['symbol'] }, ORDER_TYPE_USE.NORMAL_ORDER)
                // updatePlaceQuickOrder(orderInputNormal, ORDER_TYPE_USE.NORMAL_ORDER)
                let timer = setTimeout(function () { emitter.emit(FOCUS_PRICE_INPUT, ""); clearTimeout(timer) }, 0);
            }
            if (listOrderLayoutCurrent.includes(ORDER_TYPE_USE.PRO_TRADE_ORDER)) {
                updateProTrade({
                    typeAction: action,
                })
                updateProTrade({
                    ...orderInputPro
                }, action)
                let timer = setTimeout(function () { emitter.emit(FOCUS_PRICE_INPUT, action); clearTimeout(timer) }, 0);
            }
        } else {
            if (CommonUtils.isBroker()) {
                await setCustodycdCustomerScreen(record.custodycd, SELECTOR_ACCOUNT_SCREEN.QUICK_ORDER)
            }
            let _accountID = record['afacctno']
            if (typeAccount === TYPE_ACCOUNT.FDS) {
                _accountID = `FDS${_accountID}`
            }
            this.props.setAccountIdScreen(_accountID, SELECTOR_ACCOUNT_SCREEN.QUICK_ORDER)

            this.props.clearDataPlaceQuickOrder()
            CommonUtils.setPlaceQuickOrder(() => { emitter.emit("onChangeTabActiveQuickOrder", 1) })
            orderInputNormal.symbolCurrent = record['symbol']
            delete orderInputNormal.accountInfo
            updatePlaceQuickOrder(orderInputNormal)
            let timer = setTimeout(function () { emitter.emit(FOCUS_PRICE_INPUT, action); clearTimeout(timer) }, 0);
        }
    }

    onEditOrder = (order) => {
        const { setCustodycdCustomerScreen } = this.props;
        const fillEditInfo = () => {
            this.fillEditInfo(order);
        };
        if (this.isBroker()) {
            setCustodycdCustomerScreen(order['custodycd'], SELECTOR_ACCOUNT_SCREEN.LAYOUT, fillEditInfo);
        } else {
            fillEditInfo();
        }

    };

    onCloseModalNormalOrderMultiCancel = () => {
        this._setState({ isOpenModalNormalOrderMultiCancel: !this.state.isOpenModalNormalOrderMultiCancel })
    }

    onFinishMultiOrderCancel = () => {
        CommonUtils.middlewareOrder()
        this.onCloseModalNormalOrderMultiCancel()
        this.setReloadData();
    };

    closeScreenModal = () => {
        const { setIsOpenModalHaveData } = this.props;
        setIsOpenModalHaveData("ScreenModal", {
            isOpenScreenModal: false,
            curScreenModalActive: "",
        })
    };


    fillEditInfo = async (order) => {
        const { accountInfo, updatePlaceQuickOrder, updateProTrade, updatedSymbolLayoutPage, setCustodycdCustomerScreen, setIsOpenModalHaveData } = this.props;
        const { curCustomer, accounts, currentAccountId, typeAccount } = { ...accountInfo }
        //set lai account id hien tai
        // setCurrentAccount(order['afacctno']);
        this.props.setAccountIdScreen(order['afacctno'], SELECTOR_ACCOUNT_SCREEN.LAYOUT)
        const action = CommonUtils.getOrderActionFromSideCode(order['sidecode']);
        const orderType = CommonUtils.getOrderTypeFromPriceType(order['pricetype']);

        let orderInputNormal = {
            accountInfo: {
                ...accountInfo,
                currentAccountId: order['afacctno'],
            },
            action: action,
            orderType: orderType,
            quantity: Number(order['orderqtty']),
            quotePriceValid: true,
            stepPriceValid: true,
            editingOrder: order
        }

        if (orderType === 'limit') {
            orderInputNormal.limitPrice = order['price'];
            orderInputNormal.subOrderType = fullSubOrderTypes.LO
        } else if (orderType === 'market') {
            orderInputNormal.limitPrice = order['price'];
            orderInputNormal.orderType = 'limit';
            orderInputNormal.subOrderType = fullSubOrderTypes.LO
        };

        let orderInputPro = {
            action: action,
            orderType: orderType,
            quantity: Number(order['orderqtty']),
            quotePriceValid: true,
            stepPriceValid: true,
            editingOrder: order
        }

        if (orderType === 'limit') {
            orderInputPro.limitPrice = order['price'];
            orderInputPro.subOrderType = fullSubOrderTypes.LO
        } else if (orderType === 'market') {
            orderInputPro.limitPrice = order['price'];
            orderInputPro.orderType = 'limit';
            orderInputPro.subOrderType = fullSubOrderTypes.LO
        };

        await updatedSymbolLayoutPage(order['symbol'], "search_layout")

        let getInfoOrderLayoutCurrent = CommonOrderUtils.getInfoOrderLayoutCurrent()
        const { listOrderLayoutCurrent } = getInfoOrderLayoutCurrent
        if (listOrderLayoutCurrent && listOrderLayoutCurrent.length > 0) {
            if (listOrderLayoutCurrent.includes(ORDER_TYPE_USE.NORMAL_ORDER)) {
                updatePlaceQuickOrder(orderInputNormal, ORDER_TYPE_USE.NORMAL_ORDER)
                let timer = setTimeout(function () { emitter.emit(FOCUS_PRICE_INPUT, ""); clearTimeout(timer) }, 0);

                setIsOpenModalHaveData("ScreenModal", {
                    isOpenScreenModal: false,
                    curScreenModalActive: "",
                })
            }
            if (listOrderLayoutCurrent.includes(ORDER_TYPE_USE.PRO_TRADE_ORDER)) {
                updateProTrade({
                    typeAction: action,
                    orderType: orderInputPro.orderType,
                })
                updateProTrade({
                    ...orderInputPro
                }, action)
                let timer = setTimeout(function () { emitter.emit(FOCUS_PRICE_INPUT, action); clearTimeout(timer) }, 0);

                setIsOpenModalHaveData("ScreenModal", {
                    isOpenScreenModal: false,
                    curScreenModalActive: "",
                })
            }
        } else {
            if (CommonUtils.isBroker()) {
                await setCustodycdCustomerScreen(order.custodycd, SELECTOR_ACCOUNT_SCREEN.QUICK_ORDER)
            }
            let _accountID = order['afacctno']
            // if (typeAccount === TYPE_ACCOUNT.FDS) {
            //     _accountID = `FDS${_accountID}`
            // }
            this.props.setAccountIdScreen(_accountID, SELECTOR_ACCOUNT_SCREEN.QUICK_ORDER)

            this.props.clearDataPlaceQuickOrder()
            CommonUtils.setPlaceQuickOrder(() => { emitter.emit("onChangeTabActiveQuickOrder", 1) })
            orderInputNormal.symbolCurrent = order['symbol']
            delete orderInputNormal.accountInfo
            updatePlaceQuickOrder({ ...orderInputNormal, editOnly: true });
            let timer = setTimeout(function () { emitter.emit(FOCUS_PRICE_INPUT, ""); clearTimeout(timer) }, 0);
        }
    }

    setReloadData = (_onReloadData) => {
        let onReloadData = _onReloadData ? _onReloadData : !this.state.onReloadData
        this._setState({ onReloadData })
    }


    renderSwitch = (dataField) => {
        const { currentAccount } = this.state;
        const isDerivative = currentAccount && currentAccount.accounttype === "FDS" ? true : false
        const lang = this.props.language;

        switch (dataField) {
            case 'action':
                return (e) => {
                    const record = e.row.data;
                    if (record && record.cd_afacctno && record.cd_afacctno == "06") { // Ẩn nút mua bán ở màn sổ lênh toàn bộ MG với loại tiểu khoản TPRL
                        return <></>
                    }
                    return RenderGridCell.renderGridCellActionAllCarebyOrder(e, this.onEditOrder, this.onCancelOrder, 2, this.onCopyOrder)
                }
            case 'custodycd':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.custodycd, lang);
            case 'afacctnoext':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.afacctnoext, lang);
            case 'symbol':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.symbol, lang);
            case 'side':
                return (e) => RenderGridCell.renderGridCellSideCode4(e.row.data.side, lang);
            case 'status':
                return (e) => RenderGridCell.renderGridCellCommonByLanguage(lang, e.row.data.status, e.row.data.en_status);
            case 'pricetype':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.pricetype, lang);
            case 'orderqtty':
                return (e) => RenderGridCell.renderGridCellQuantity(e.row.data.orderqtty);
            case 'price':
                return (e) => RenderGridCell.renderGridCellPriceConvert(e.row.data.price);
            case 'quoteamt':
                return (e) => RenderGridCell.renderGridCellQuantity(e.row.data.quote);
            case 'execqtty':
                return (e) => RenderGridCell.renderGridCellQuantity(e.row.data.execqtty);
            case 'execprice':
                return (e) => RenderGridCell.renderGridCellPriceConvert(e.row.data.excecprice);
            case 'execamt':
                return (e) => RenderGridCell.renderGridCellQuantity(e.row.data.execamt);
            case 'remainqtty':
                return (e) => RenderGridCell.renderGridCellQuantity(e.row.data.remainqtty);
            case 'remainamt':
                return (e) => RenderGridCell.renderGridCellQuantity(e.row.data.remainamt);
            case 'matchtype':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.matchtype, lang);
            case 'tlname':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.tlname, lang);
            case 'odorderid':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.odorderid, lang);
            case 'rootorderid':
                return (e) => RenderGridCell.renderGridCellCommon(e.row.data.reforderid, lang);
            default:
                return null;
        }


    }

    returnCaption = (dataField) => {
        const { currentAccount } = this.state;
        const isDerivative = currentAccount && currentAccount.accounttype === "FDS" ? true : false
        const lang = this.props.language;
        switch (dataField) {
            case 'action':
                return "trade.order.action";
            case 'custodycd':
                return "trade.order.custodycd";
            case 'afacctnoext':
                return "trade.order.account-id";
            case 'symbol':
                return "trade.order.symbol";
            case 'side':
                return "trade.order.side";
            case 'status':
                return "trade.order.status";
            case 'pricetype':
                return "trade.order.price-type";
            case 'orderqtty':
                return "trade.order.qtty";
            case 'price':
                return "trade.order.price";
            case 'quoteamt':
                return "trade.order.price-value";
            case 'execqtty':
                return "trade.order.exec-qtty";
            case 'execprice':
                return "trade.order.exec-price";
            case 'execamt':
                return "trade.order.exec-amt";
            case 'remainqtty':
                return "trade.order.remain-qtty";
            case 'remainamt':
                return "trade.order.remain-amount";
            case 'matchtype':
                return "trade.order.order-type";
            case 'tlname':
                return "trade.order.tlname";
            case 'odorderid':
                return "trade.order.order-id";
            case 'rootorderid':
                return "trade.order.root-order-id";
            default:
                return null;
        }


    }

    search = () => {
        this.setReloadData();
    }

    renderConfirmsts = (row) => {
        if (row === "Không") return "No"
        return "Yes"
    }

    processOrderData = (data) => { // convert data phái sinh giống cơ sở
        let dataArr = data.d || [];
        if (dataArr.length > 0) {
            for (let i = 0; i < dataArr.length; i++) {
                let element = dataArr[i];
                let cancelOrderPermission = (element['iscancel'] === "Y" && element['cd_afacctno'] != "06");
                let amendOrderPermission = (element['isadmend'] === "Y" && element['cd_afacctno'] != "06");
                element.cancelOrderPermission = cancelOrderPermission;
                element.amendOrderPermission = amendOrderPermission;
                element.afacctnoext = element['cd_afacctno']
            }
            //console.log("dataArr---: ", dataArr)
            return { ...data, d: dataArr }
        } else {
            return data;
        }
    };


    apiPaging = async (obj) => {
        const { fromDate, toDate, currentAccount, symbol, orderStatus, execType, userPlaceOrder } = this.state;
        const momentFromDate = moment(fromDate);
        const momentToDate = moment(toDate);
        const lang = this.props.language;
        const checkVi = lang === "vi" ? true : false
        if (!momentFromDate.isValid() || !momentToDate.isValid()) {
            ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid');
            return;
        }

        if (momentToDate.isBefore(momentFromDate)) {
            ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid-1');
            return;
        }

        const { userInfo, curCustomer, language } = this.props;
        const { accountInfo, socketSEActionCounter } = this.props;
        const { currentAccountId, typeAccount } = { ...accountInfo }
        const getOrderFunc = this.isBroker() ? inquiryService.getOrders : inquiryService.getOrders;
        const getOrderFDSFunc = this.isBroker() ? fdsInquiryService.getAllOrdersFDS : fdsInquiryService.getAllOrdersFDS;



        // if (!this.checkDate()) {
        //     ToastUtil.error('common.fail-to-load-data', 'common.date-range-invalid-2');
        //     return
        // }

        let accountId = (currentAccount && currentAccount.accounttype !== 'ALL') ? currentAccount.id : 'ALL';
        let custodycdSearch = this.selectorAccountCustomerRef.current ? (this.selectorAccountCustomerRef.current.getStateCustodycd() || '') : 'ALL';
        let carebyCustodycd = curCustomer ? curCustomer.custodycd : custodycdSearch;
        if (carebyCustodycd === PREFIX_CUSTODYCD || !carebyCustodycd) {
            carebyCustodycd = 'ALL'
        }

        // if (!carebyCustodycd) return [];
        try {
            let self = this
            this._setState({ loadStatus: LoadStatus.LOADING });
            return inquiryService.getAllCareByOrder(carebyCustodycd, accountId, symbol, execType, orderStatus === 'ALL' ? '' : orderStatus, userPlaceOrder, obj.page, obj.limitRow).then((data) => {
                if (data && data.d) {
                    data = this.processOrderData(data)
                    // let sumData={}
                    // if (data.length > 0) {
                    //     sumData = data.shift();
                    // }
                }
                this._setState({ loadStatus: LoadStatus.LOADED });
                return data
            })
                .catch((error) => {
                    this._setState({ loadStatus: LoadStatus.FAIL });
                    ToastUtil.errorApi(error, 'ORDER_HISTORY');
                    return []
                });
        } catch (e) {
            //console.log('apiPaging().:Err.:', e)
            return []
        }
    }

    genExcel = () => {
        this.genReport(reportTypes.XLSX);
    };

    genPdf = () => {
        this.genReport(reportTypes.PDF);
    };

    // run only once when component is mounted
    loadInitData = (check, setFirstAccount) => {
        // Lấy danh sách trạng thái để tìm kiếm
        const { currentAccount } = this.state;
        const { accounts, language } = this.props;

        let newRow = CommonUtils.getNewRowLanguage(language)
        let found = [newRow, ...accounts]
        this._setState({ currentAccount: check ? currentAccount : (setFirstAccount ? accounts[0] || newRow : newRow), listAccounts: found });
    };

    // Called only one time after component loaded
    componentDidMount() {
        this.mounted = true
        this.loadInitData();
        this.setReloadData();
        this.loadAllCodeOrderStatus();
        //focus vào ô nhập tài khoản KH khi vào màn hình
        if (this.isBroker()) {
            CommonUtils.onFirstRefFocusAccountSelector(this.selectorAccountCustomerRef);
        }
    }
    componentWillUnmount() {
        this._setState({
            ...this.initialState
        })
        this.selectorAccountCustomerRef = undefined;
        this.searchBtnRef = undefined;
        this.mounted = false
    }
    // run only once when component is mounted
    loadAllCodeOrderStatus = () => {
        // Lấy danh sách trạng thái để tìm kiếm
        userService.getAllCode(this.orderHistoryDisPlayAllCode.cdname, this.orderHistoryDisPlayAllCode.cdtype)
            .then((data) => {
                data = data.filter(d => d.cdval !== 'T');
                this._setState({ orderHistoryStatus: data });
            }).catch(error => {
                ToastUtil.errorApi(error, 'common.fail-toload-order-history-status');
            });
    };

    // Called everytime props or state changed
    componentDidUpdate(prevProps, prevState) {
        const { currentAccount } = this.state;
        const { currentAccount: prevCurrentAccount } = prevState;
        const { language, currentAccountId } = this.props;
        const { language: prevlanguage, currentAccountId: prevCurrentAccountId } = prevProps;

        if (language !== prevlanguage) {
            this.setReloadData()
            this.loadInitData(true)
        }

        if (this.isBroker() === true && JSON.stringify(this.props.curCustomer) !== JSON.stringify(prevProps.curCustomer)) {
            this.loadInitData(false, true)
        }

    }


    onSymbolChanged = (symbol) => {
        this._setState({ symbol: symbol });
    };

    onExecTypeChanged = (event) => {
        this._setState({ execType: event.target.value });
    };

    onStatusChanged = (event) => {
        this._setState({ orderStatus: event.target.value });
    };

    //Set tiểu khoản chuyển
    setCurrentAccount = (event) => {
        const { listAccounts } = this.state;
        let found = listAccounts.find(item => item.id === event.target.value)
        this._setState({ currentAccount: found })
    };

    formatFDS = (text) => {
        let check = String(text).search("FDS")
        if (!check) {
            return String(text).replace("FDS", '')
        }
        return text
    }

    onSelectionChanged = ({ selectedRowKeys, selectedRowsData }, classTable, grid, selectAllCheckBox) => { // selectedRowKeys: danh sách key, selectedRowsData: danh sách row
        //console.log('onSelectionChanged 123', { selectedRowKeys, selectedRowsData, current: grid })
        let custodycdData = selectedRowsData && selectedRowsData.length > 0 ? selectedRowsData[0]['custodycd'] || '' : '';
        if (this.state.currentCustodycdSelected !== custodycdData) {
            this._setState({ currentCustodycdSelected: custodycdData }, () => {
                // grid && grid.refresh(); // Cần xem lại bởi sẽ gây load lại api
                this.hideOtherCustodycdSelection();
            })
        }

        let listOrderCancelToSet = selectedRowsData;
        let listOrderIdCancelToSet = selectedRowKeys;

        // custom lại các dòng có thể chọn trong list dữ liệu đã chọn (chỉ cho chọn một tài khoản đầu tiên)
        if (custodycdData) {
            listOrderCancelToSet = selectedRowsData.filter(item => item.custodycd === custodycdData);
            listOrderIdCancelToSet = listOrderCancelToSet && listOrderCancelToSet.length > 0 ? listOrderCancelToSet.map(item => { return item['odorderid'] }) : [];
            grid && grid.selectRows(listOrderIdCancelToSet);

            // Tích nút tích All trong trang nếu thỏa mãn điều kiện
            let dataItems = grid ? (grid.getDataSource()._items || []) : [];
            let selectableRows = dataItems.filter(item => item.custodycd === custodycdData);

            if (listOrderCancelToSet.length >= selectableRows.length) {
                selectAllCheckBox && selectAllCheckBox.option("value", true);
            }
        }

        this._setState({
            listOrderIdCancel: listOrderIdCancelToSet,
            listOrderCancel: listOrderCancelToSet
        }, () => {
            this.addBtnCancel()
        });
    }

    getListReconfirmOrder = (listOrder) => {
        let listReconfirmOrder = [];
        listOrder.forEach(order => {
            const action = CommonUtils.getOrderActionFromSideCode(order['sidecode']);
            const orderType = CommonUtils.getOrderTypeFromPriceType(order['pricetype']);
            let limitPrice = null;
            let stopPrice = null;

            let quantity = order['remainqtty'];
            listReconfirmOrder.push({
                orderid: order['odorderid'],
                side: order['sidecode'],
                action,
                currentOrderType: orderType,
                limitPrice: order['limitprice'],
                stopPrice,
                quantity,
                symbolCurrent: {
                    id: order['symbol']
                },
                marketPrice: order['price'],
                orderValue: Number(order['price']) * quantity,
                subOrderType: order['pricetype'],
                cancelingOrder: true,
                accountId: order['afacctno'],
                afacctnoext: order['cd_afacctno'],
                custodycd: order['custodycd'],
                fullname: order['fullname']
            });
        });
        return listReconfirmOrder;
    };

    requestId = '';

    onCancelMultipleOrder = () => {
        const { listOrderCancel } = this.state
        const { accountInfo, intl } = this.props;
        const { curCustomer, accounts, currentAccountId } = { ...accountInfo }
        let listOrder = listOrderCancel;

        if (!listOrder || listOrder.length === 0) {
            ToastUtil.error('common.no-cancel-order-allow');
            return;
        }

        this.requestId = Random.randomOrderRequestId();

        let orderIds = listOrderCancel.map(item => {
            return {
                accountid: item.afacctno || '',
                orderid: item.odorderid || ''
            }
        })

        let precheckmultiBody = {
            orderIds,
            requestId: this.requestId
        }

        tradeApiService.checkTradeCancelMultiOrderAllCareBy(precheckmultiBody)
            .then(data => {
                // Số lệnh tối đa mỗi request
                const { maxCancelQty } = data;
                this._setState({ maxCancelQty: parseFloat(maxCancelQty) ? parseFloat(maxCancelQty) : 100 });
                tradeApiService.initTwoFactorAuth({ accountId: currentAccountId })
                    .then((data) => {
                        const { transactionId, tokenid, authtypes, challengeQuestion, authtype, verified } = data;
                        this.props.updateInfoCheckAuth({
                            transactionId: transactionId, tokenid: tokenid, listAuthType: authtypes, challengeQuestion, authtype, verified,
                        })
                        this._setState({ isOpenModalNormalOrderMultiCancel: true })
                    })
                    .catch((error) => {
                        ToastUtil.errorApi(error, 'common.fail-to-init-transaction');
                        return;
                    })

                let listReconfirmOrder = this.getListReconfirmOrder(listOrder);
                this._setState({ listDataFillModal: listReconfirmOrder, listOrderSelected: listOrder, typeSelectCancel: TYPE_SELECT_CANCEL.CANCEL_MULTI })

            }).catch(err => {
                ToastUtil.errorApi(err, 'common.fail-to-init-transaction');
                return;
            })


    };

    addBtnCancel = () => {
        const { listOrderIdCancel } = this.state
        let func = this.onCancelMultipleOrder
        let text = LanguageUtils.getMessageByKey("trade.order.cancel", this.props.language)
        let length = (listOrderIdCancel && listOrderIdCancel.length > 0 && listOrderIdCancel.length) || 0
        let btn = `<button class="btn-action txt---400-14-20 bg-sell">${text} (${length})</button>`

        let _interval = setInterval(function () {
            let _getclassfilter = $('.all-careby-order-table-tableEx .dx-datagrid-filter-row .dx-command-select')
            // let _getclassheader = $('.dx-header-row .dx-command-select')
            let _getclassheader = $('.all-careby-order-table-tableEx .dx-command-select')
            if (_getclassfilter && _getclassfilter.length > 0) {
                _getclassfilter[0].innerHTML = btn
                // _getclassfilter.addClass("dx-command-select-cancel")
                _getclassheader.addClass("dx-command-select-cancel")
                _getclassfilter[0].onclick = func
                _interval && clearInterval(_interval)
                return null
            }
        }, 200)
    }

    twoFactorAuthCb = (authInfo) => {
        const { typeSelectCancel } = this.state
        if (typeSelectCancel === TYPE_SELECT_CANCEL.CANCEL) {
            this.callApiCancelOrder(authInfo)
        }
        if (typeSelectCancel === TYPE_SELECT_CANCEL.CANCEL_MULTI) {
            this.callApiCancelMultpleOrder(authInfo)
        }
    }

    setLoadStatusModal = (status) => {
        this._setState({ loadStatusCancelMultiNormalOrder: status })

    }

    onFinish = () => {
        const { clearDataPlaceQuickOrder, setIsOpenModalHaveData } = this.props
        CommonUtils.middlewareOrder()
        this.onCloseModalNormalOrderMultiCancel()
    };


    handleMultipleCancelFinish = (listOrder, res, isCancelLoop) => {
        const { intl, language } = this.props;
        !isCancelLoop && this.onFinishMultiOrderCancel();

        let successListTotal = res.filter((item) => {
            return item["errcode"] === "0"
        })

        let errListTotal = res.filter((item) => {
            return item["errcode"] !== "0"
        })

        let succmsgList = successListTotal.map(succ => {
            let orderid = succ['orderid'];
            let order = _.find(listOrder, od => {
                return od['orderid'] === orderid;
            });
            let side = LanguageUtils.getMessageByKey('common.exec-types.' + order['side'], language),
                symbol = order['symbolCurrent'].id,
                accountId = order['accountId'];
            // let orderAccount = accounts.find(account => {
            //     return account.id === accountId
            // })
            return side + ' : ' + symbol;
        });
        let errmsgList = errListTotal.map(err => {
            let orderid = err['orderid'];
            let order = _.find(listOrder, od => {
                return od['orderid'] === orderid;
            });
            let side = LanguageUtils.getMessageByKey('common.exec-types.' + order['side'], language),
                symbol = order['symbolCurrent'].id,
                accountId = order['accountId'],
                errmsg = err.errmsg;
            return side + ' : ' + symbol + `(${errmsg})`;
        });
        if (succmsgList && succmsgList.length > 0) ToastUtil.successRaw('trade.order.cancel-order-success', succmsgList);
        if (errmsgList && errmsgList.length > 0) ToastUtil.errorRaw('trade.order.cancel-order-fail', errmsgList, 15000);
        this._setState({ successListTotal: [], errListTotal: [] });
    }

    callApiCancelOrder = (authInfo) => {
        const { listOrderSelected } = this.state
        let order = listOrderSelected[0]
        this.setLoadStatusModal(LoadStatus.LOADING)
        let requestId = Random.randomOrderRequestId()

        let body = {
            ...authInfo,
            orderId: order['odorderid'],
            requestId,
        }
        tradeApiService.tradeCancelOrder(order['afacctno'], body)
            .then(() => {
                this.setLoadStatusModal(LoadStatus.LOADED)
                this.onFinish();
                ToastUtil.success('trade.order.cancel-order-success');
            })
            .catch((error) => {
                this.setLoadStatusModal(LoadStatus.FAIL)
                ToastUtil.errorApi(error, 'trade.order.cancel-order-fail');
            });
    }

    callApiMultilLoop = (requestNumb, orderIds, authInfo, fromIndex, maxCancelQty, listOrder) => {
        let requestId = this.requestId; // requestid gen ở precheck đối với hủy nhiều lệnh
        requestNumb = requestNumb - 1;
        let allIds = [...orderIds];
        let orderIdtoCancel = allIds.splice(fromIndex, fromIndex + maxCancelQty);
        // //console.log('laojahackgame=====> DATA TO CANCEL', { orderIdtoCancel, orderIds });
        let body = {
            ...authInfo,
            orderIds: orderIdtoCancel,
            requestId,
        }
        // setTimeout(() => {
        //     if (requestNumb !== 0) {
        //         this.callApiMultilLoop(requestNumb, orderIds, authInfo, fromIndex + maxCancelQty, maxCancelQty, listOrder);
        //     }
        // }, 3000)


        tradeApiService.tradeCancelMultiOrder(body)
            .then((data) => {
                if (requestNumb === 0) {
                    this.setLoadStatusModal(LoadStatus.LOADED)
                    this.handleMultipleCancelFinish(listOrder, data, false);
                } else {
                    this.callApiMultilLoop(requestNumb, orderIds, authInfo, fromIndex + maxCancelQty, maxCancelQty, listOrder);
                    this.handleMultipleCancelFinish(listOrder, data, true);
                }
            }
            ).catch(error => {
                if (requestNumb > 0) {
                    // Nếu vẫn còn lệnh ở trang cần hủy ===> bắn thông báo lỗi ====> Tiếp tục gọi hủy
                    this.callApiMultilLoop(requestNumb, orderIds, authInfo, fromIndex + maxCancelQty, maxCancelQty, listOrder);
                    ToastUtil.errorApi(error, 'trade.order.cancel-order-fail');
                } else {
                    this.setLoadStatusModal(LoadStatus.FAIL)
                    ToastUtil.errorApi(error, 'trade.order.cancel-order-fail');
                }
            });
    }

    callApiCancelMultpleOrder = (authInfo) => {
        const { listDataFillModal, maxCancelQty } = this.state;
        let listOrder = listDataFillModal
        let requestId = this.requestId; // requestid gen ở precheck đối với hủy nhiều lệnh
        let orderIds = []
        _.forEach(listOrder, (item, index) => {
            orderIds.push({
                accountid: item.accountId,
                orderid: item.orderid
            })
        });

        let body = {
            ...authInfo,
            orderIds: orderIds,
            requestId,
        }

        this.setLoadStatusModal(LoadStatus.LOADING)
        tradeApiService.tradeCancelMultiOrder(body)
            .then((data) => {
                this.setLoadStatusModal(LoadStatus.LOADED)
                this.handleMultipleCancelFinish(listOrder, data);
            }
            ).catch(error => {
                this.setLoadStatusModal(LoadStatus.FAIL)
                ToastUtil.errorApi(error, 'trade.order.cancel-order-fail');
            });

        // let maxCancelQty = 2;

        // off: Chia request để gọi nếu số lệnh hủy lớn hơn giới hạn lệnh dc hủy tối đa với mỗi request
        // if (listOrder.length > maxCancelQty) {
        //     //Chia request để gọi với case số lượng lệnh vượt quá số lượng có thể hủy trong một request
        //     let requestNumb = Math.floor(listOrder.length / Number(maxCancelQty));
        //     requestNumb = (listOrder.length % Number(maxCancelQty) > 0) ? requestNumb + 1 : requestNumb;
        //     // //console.log('laojahackgame======> REQUEST NUMBER TO CALL', requestNumb);
        //     this.setLoadStatusModal(LoadStatus.LOADING)

        //     this.callApiMultilLoop(requestNumb, orderIds, authInfo, 0, maxCancelQty, listOrder);

        // } else {
        //     let body = {
        //         ...authInfo,
        //         orderIds: orderIds,
        //         requestId,
        //     }
        //     this.setLoadStatusModal(LoadStatus.LOADING)
        //     tradeApiService.tradeCancelMultiOrder(body)
        //         .then((data) => {
        //             this.setLoadStatusModal(LoadStatus.LOADED)
        //             this.handleMultipleCancelFinish(listOrder, data);
        //         }
        //         ).catch(error => {
        //             this.setLoadStatusModal(LoadStatus.FAIL)
        //             ToastUtil.errorApi(error, 'trade.order.cancel-order-fail');
        //         });
        // }
    }

    onPlaceOrderUserChange = (e) => {
        this._setState({ userPlaceOrder: e.target.value });
    }

    disableConFunc = (item) => {
        const { currentCustodycdSelected } = this.state;
        // //console.log('laojahackgame======> DISABLE CONFUNC',{currentCustodycdSelected, item})
        if (!currentCustodycdSelected) return true;
        if (item['custodycd'] === currentCustodycdSelected) {
            return true
        }
        return false;
    }

    customRowStyle = (e) => {
        // gán class vào ô select theo custodycd để ẩn hiển khi tích
        if (e.rowElement) {
            const { currentCustodycdSelected } = this.state;
            if (e.data) {
                let custodycd = e.data.custodycd;
                custodycd && $(e.rowElement).addClass(custodycd);
                // Disable nút chọn nếu đã có custodycd được chọn và dòng hiện tại có custodycd không trùng

                // if (currentCustodycdSelected && currentCustodycdSelected !== custodycd) {
                //     setTimeout(() => {
                //         $(e.rowElement).find($('.dx-checkbox-container')).addClass('hidden-checkbox');
                //     }, 10)
                // }
            }

        }

    }

    hideOtherCustodycdSelection = () => {
        const { currentCustodycdSelected } = this.state;
        let rowDisplayingArr = $('.dx-row-lines');
        if (currentCustodycdSelected) {
            // //console.log('laojahackgame=====> ALL ELEMENT', { rowDisplayingArr, currentCustodycdSelected });
            if (rowDisplayingArr.length > 0) {
                for (let i = 0; i < rowDisplayingArr.length; i++) {
                    // //console.log('laojahackgame=====> ALL ELEMENT 123', { elementToHide: $(rowDisplayingArr[i]).find($('.dx-checkbox-container')), element: $(rowDisplayingArr[i]), check: $(rowDisplayingArr[i]).hasClass(currentCustodycdSelected), currentCustodycdSelected });
                    if (!$(rowDisplayingArr[i]).hasClass(currentCustodycdSelected)) {
                        $(rowDisplayingArr[i]).find($('.dx-checkbox-container')).addClass('hidden-checkbox');
                    }
                }
            }
        } else {
            // Nếu bỏ tích ======> Hiển thị lại các ô check cho phép nhập
            $('.dx-row-lines .dx-checkbox-container').removeClass('hidden-checkbox');
        }
    }

    contentReadyCallback = () => {
        this.hideOtherCustodycdSelection();
    }

    handlerKeyDownSearch = (event) => {
        const keyCode = event.which || event.keyCode;
        if (keyCode === KeyCodeUtils.ENTER) {
            event.preventDefault();
            if (!this.searchBtnRef.current || this.searchBtnRef.current.disabled) return;
            this.searchBtnRef.current.click();
        }
    };

    render() {
        const { language, accountInfo, userInfo } = this.props;
        const { currentAccount, records, listAccounts, onReloadData, userPlaceOrder, listDataFillModal } = this.state;
        const isBroker = this.isBroker();
        const { curCustomer } = { ...accountInfo }
        const isDerivative = currentAccount && currentAccount.accounttype === "FDS" ? true : false
        // Precheck đã check chặn hủy nhiều lệnh trên nhiều tìa khoản nên chỉ cần lấy custodycd của lệnh Hủy đầu sẽ lấY được đúng tài khoản cần hiển thị
        let custodycd = (listDataFillModal && listDataFillModal.length > 0) ? listDataFillModal[0].custodycd : '';

        return (
            <RetryableContent className="order-history report-content" status={this.state.loadStatus} disableRetry={true}>
                <div className="order-history-content">
                    {this.state.isOpenModalNormalOrderMultiCancel &&
                        <ModalNormalOrderMultiCancel
                            isOpen={this.state.isOpenModalNormalOrderMultiCancel}
                            twoFactorAuthCb={this.twoFactorAuthCb}
                            onClose={this.onCloseModalNormalOrderMultiCancel}
                            listReconfirmOrder={this.state.listDataFillModal}
                            loadStatusCancelMultiNormalOrder={this.state.loadStatusCancelMultiNormalOrder}
                            accountInfo={accountInfo}
                            custodycd={custodycd}
                        />
                    }
                    <div className="action-container">
                        <div className={'d-flex container-select ' + (this.props.isDeskTop ? 'flex-wrap' : '')}>
                            {
                                isBroker ?
                                    <div className="custom-form-group-n padding-top-0">
                                        <SelectorAccountCustomer
                                            ref={this.selectorAccountCustomerRef}
                                            curCustomer={curCustomer}
                                            selectorAcountScreen={SELECTOR_ACCOUNT_SCREEN.SCREEN_MODAL}
                                            disabled={false}
                                            className="custom-form-control txt---400-14-20"
                                        />
                                    </div>
                                    :
                                    <div className="custom-form-group-n txt---400-14-20">
                                        {this.props.userInfo && this.props.userInfo.custodycd}
                                    </div>
                            }
                            <div className="custom-form-group-n">
                                <select value={currentAccount ? currentAccount.id : ''} onChange={this.setCurrentAccount} className="custom-form-control txt---400-14-20">
                                    {(!listAccounts || listAccounts.length === 0 || !currentAccount) && (
                                        <FormattedMessage key="empty" id="header.choose-account">
                                            {(txt) => (
                                                <option key="empty" value="" disabled>{txt}</option>
                                            )}
                                        </FormattedMessage>
                                    )}
                                    {listAccounts && listAccounts.map((account) => {
                                        return (account.accounttype === "ALL" ? <option key={account.id} value={account.id}>{account.id}</option> :
                                            (<option key={account.id} value={account.id}>{account.cdcontent}</option>)
                                        )
                                    })}
                                </select>
                            </div>


                            <SymbolSuggestTrans
                                handleInputChange={this.onSymbolChanged}
                                currentSymbol={this.state.symbol}
                                symbolToDisplay={this.state.symbol}
                                suggestId="order-history-report "
                                isRedirect={false}
                                isDisplayError={false}
                                placeholder={LanguageUtils.getMessageByKey("order-history.symbol", this.props.language)}
                            />


                            <div className="custom-form-group-n">
                                <div className='align-self-center label txt---400-14-20'><FormattedMessage id="order-history.tlname" /></div>
                                <input
                                    className='custom-form-control txt---400-14-20'
                                    onChange={this.onPlaceOrderUserChange}
                                    value={userPlaceOrder}
                                ></input>
                            </div>

                            <div className="custom-form-group-n">
                                <div className='align-self-center label txt---400-14-20'><FormattedMessage id="order-history.fillter-order-status" /></div>
                                <select
                                    value={this.state.orderStatus}
                                    onChange={this.onStatusChanged}
                                    className="custom-form-control txt---400-14-20">
                                    <option value="ALL">
                                        {LanguageUtils.getMessageByKey("common.exec-types.ALL", language)}
                                    </option>
                                    {this.state.orderHistoryStatus.map((status) => {
                                        return (
                                            <option value={status.cdval}>
                                                {language === 'en' ? status.en_cdcontent : status.cdcontent}
                                            </option>
                                        );
                                    })}

                                </select>
                            </div>

                            <div className="custom-form-group-n">
                                <div className='align-self-center label txt---400-14-20'><FormattedMessage id="order-history.fillter-BS" /></div>
                                <select
                                    value={this.state.execType}
                                    onChange={this.onExecTypeChanged}
                                    className="custom-form-control txt---400-14-20">
                                    {execTypeSearch.map((execType) => {
                                        return (
                                            <FormattedMessage id={'common.exec-types.' + execType} key={execType}>
                                                {(txt) => (
                                                    <option
                                                        value={execType}
                                                    >{txt}</option>
                                                )}
                                            </FormattedMessage>
                                        );
                                    })}

                                </select>
                            </div>

                            <div>
                                <button ref={this.searchBtnRef} className="txt---400-14-20 btn-search" onClick={this.search} onKeyDown={this.handlerKeyDownSearch}>
                                    <FormattedMessage id="common.search" />
                                </button>
                            </div>

                        </div>
                        {/*<div className="export-container text-right">
                            <div className="dropdown">
                                <TextToolTip
                                    tooltipText={<FormattedMessage id="common.export-tooltip" />}
                                    targetID='toolTipExportBtn'
                                ></TextToolTip>
                                <button className="btn-icon-fm-2" id={'toolTipExportBtn'}>
                                    {this.props.defaultTheme === 'dark' ? < DownloadIconLight /> : < DownloadIconDark />}
                                </button>
                                <div className="dropdown-menu dropdown-menu-right txt---400-14-20" aria-labelledby="dropdownMenuButton">
                                    <FormattedMessage id='common.export-to-pdf'>
                                        {(txt) => (
                                            <a className="dropdown-item" onClick={this.genPdf}>{txt}</a>
                                        )}
                                    </FormattedMessage>
                                    <FormattedMessage id='common.export-to-excel'>
                                        {(txt) => (
                                            <a className="dropdown-item" onClick={this.genExcel}>{txt}</a>
                                        )}
                                    </FormattedMessage>
                                </div>
                            </div>
                        </div>*/}
                    </div>
                    <div className='h-block-table p-table-10'>
                        <TableExtremeCustom2
                            keyStoreUpdate="odorderid" // key của row 
                            columnsDefault={columnsDefault} // ds cột
                            totalDefault={totalDefault} // ds sum (nếu có)
                            renderSwitch={this.renderSwitch} // func render dữ liệu
                            returnCaption={this.returnCaption} // func render column name
                            isUseSelection={true} // true/false: hiện/ẩn chọn row
                            isLoadPanel={false} // true/false: hiện/ẩn iconLoadPanel
                            addClass="all-careby-order-table" // class cha
                            isPager={true} // hiển thị giao diện phân trang
                            onReloadData={onReloadData} // thay đổi giá trị này để render tableEx
                            showFilter={true}
                            onSelectionChanged={this.onSelectionChanged}
                            keyDisableCheckBox="cancelOrderPermission"
                            // apiLoadAll={this.apiLoadAll}  // sử dụng Api load all: không cần trả ra totalCount
                            addActionToSelection={this.addBtnCancel}
                            // apiPaging={this.apiPaging} // sử dụng Api phân trang: không trả ra totalCount
                            apiLoadAllByPagingRes={this.apiPaging}
                            defaultPageSizes={100}  //Truyền 
                            disableConFunc={this.disableConFunc} // hàm điều kiện để ẩn hoặc hiện ô tích
                            customStyleTopAndBottomSticky={this.customRowStyle}
                            contentReadyCallback={this.contentReadyCallback}
                            disableCheckAll={true}
                        />
                    </div>
                </div>
            </RetryableContent >

        );
    }

}

const mapStateToProps = state => {
    let accountInfo = state.layout.listIsOpenModal["C&B"]["ScreenModal"].accountInfo
    let { accounts, currentAccountId, curCustomer } = { ...accountInfo }
    let defaultTheme = state.user.userInfo && state.user.userInfo.defaultTheme

    // let _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive()
    // let _layoutPage = state.layout.layoutPage[_currentLayoutPageActive]
    // let accountInfo = _layoutPage && _layoutPage["accountInfo"]
    // let { accounts, currentAccountId, curCustomer } = { ...accountInfo }

    return {
        isDeskTop: state.app.isDeskTop,
        userInfo: state.user.userInfo,
        currentCustomer: state.customer.currentCustomer,
        role: state.user.userInfo.role,
        language: state.app.language,
        accounts: accounts,
        currentAccountId: currentAccountId,
        currentDate: state.app.currentDate,
        curCustomer: curCustomer,
        accountInfo: accountInfo,
        userInfo: state.user.userInfo,
        defaultTheme: defaultTheme
    };
};
const mapDispatchToProps = dispatch => {
    return {
        setExcelExportData: (filename, data) => dispatch(actions.setScreenExportData({ filename, data })),
        updatePlaceQuickOrder: (data, typeOrder) => dispatch(actions.updatePlaceQuickOrder(data, typeOrder)),
        updateProTrade: (data, typeOrder) => dispatch(actions.updateProTrade(data, typeOrder)),
        updatedLayoutPage: (data, key) => dispatch(actions.updatedLayoutPage(data, key)),
        updateInfoCheckAuth: (data, key) => dispatch(actions.updateInfoCheckAuth(data, key)),
        setIsOpenModalHaveData: (key, data) => dispatch(actions.setIsOpenModalHaveData(key, data)),
        setCustodycdCustomerScreen: (custodycd, typeScreen, callback) => dispatch(actions.setCustodycdCustomerScreen(custodycd, typeScreen, callback)),
        setAccountIdScreen: (accountId, typeScreen) => dispatch(actions.setAccountIdScreen(accountId, typeScreen)),
        saveTradeAuthenticated: () => dispatch(actions.saveTradeAuthenticated()),
        setTradeAuthSuccess: () => dispatch(actions.setTradeAuthSuccess()),
        updatedSymbolLayoutPage: (symbolId, from, id) => dispatch(actions.updatedSymbolLayoutPage(symbolId, from, id)),
        clearDataPlaceQuickOrder: (typeOrder, arrKeep) => dispatch(actions.clearDataPlaceQuickOrder(typeOrder, arrKeep)),
    }
};
export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(OrderHistoryNew));
