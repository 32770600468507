import React, { Component } from "react";

const keyGoogleMap = window._env_ && window._env_.keyGoogleMap ? window._env_.keyGoogleMap : null
class MapIframe extends Component {
    constructor(props) {
        super(props);
        this.myRefIframe = React.createRef();
    }

    componentDidMount() {
        // const iframeItem = myRefIframe.contentWindow.;
        ////console.log("iframe---: ", iframeItem)
        //const anchors = iframeItem.contentWindow.getElementsByTagName("a")
        // document.querySelector("iframe").contentWindow.document.querySelector("body").style.display = "none"
        //document.getElementById("myIframe").getElementsByClassName("bottom-actions").style.display = "none"
        // const iframe = document.getElementById("myIframe");
        // const iWindow = iframe.contentWindow;
        // const iDocument = iWindow.document;
        // const element = iDocument.getElementsByClassName("bottom-actions")[0];
        // //console.log("element aaa---: ", element)
        // element.style.display = "none";
        // let iframe = document.getElementById("myIframe")
        // let doc = iframe.contentDocument
        // // let iframeItem = myRefIframe.contentWindow;
        // let elmnt = iframe.contentWindow.document.querySelector(".gm-style");
        // //console.log("element aaa--- iframeItem: ", doc)
        // //console.log("element aaa--- iframe: ", iframe)
        // //console.log("element aaa---: elmnt ", elmnt)
        // elmnt.setAttribute("style", "display:none;");
        // document
        //     .querySelector("iframe")
        //     .contentWindow.document.querySelector("h1#firstHeading").style.color =
        //     "red";
        // const myIframe = document.getElementById("iframe");

        // const iframeWindow = myIframe.contentWindow;
        // const iframeDocument = myIframe.contentDocument;
        // const iframe = myIframe.contentWindow || myIframe.contentDocument.document || myIframe.contentDocument;
        // //console.log("iframe log iframeWindow: ", iframeWindow)
        // //console.log("iframe log iframeDocument: ", iframeDocument)
        // const bodyStyle = iframeDocument.getElementsByTagName('body')[0]
        // bodyStyle.style.color = "red"
        // //console.log("iframe log iframe: ", iframe.document.querySelector('.mw-page-container'))
        // // iframe.document.querySelector('.mw-page-container').style.display = "none"

        // //iframeDocument.body.style.display = "none"
        // document.querySelector('iframe').onload = function () {
        //     alert(1)
        // };
        //         const myIframe = document.getElementById("iframe");
        //         const iframeWindow = myIframe.contentWindow;
        //         const iframeDocument = myIframe.contentDocument;
        //         const iframe = myIframe.contentWindow || myIframe.contentDocument.document || myIframe.contentDocument;
        //         //console.log("iframe log iframeWindow 1: ", iframeWindow)
        //         //console.log("iframe log iframeDocument 1: ", iframeDocument)
        //         //console.log("iframe log iframe 1: ", iframe)
        //         myIframe.addEventListener("load", function () {
        // =            const iframeWindow = myIframe.contentWindow;
        //             const iframeDocument = myIframe.contentDocument;
        //             const iframe = myIframe.contentWindow || myIframe.contentDocument.document || myIframe.contentDocument;
        //             //console.log("iframe log iframeWindow: ", iframeWindow)
        //             //console.log("iframe log iframeDocument: ", iframeDocument)
        //             //console.log("iframe log iframe: ", iframe)
        //         });
    }

    componentDidUpdate(prevProps, prevState) {

    }

    checkIframeLoaded = () => {
        // Get a handle to the iframe element
        var iframe = document.getElementById('iframe');
        var iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

        // Check if loading is complete
        if (iframeDoc.readyState == 'complete') {
            //iframe.contentWindow.alert("Hello");
            iframe.contentWindow.onload = function () {
                //console.log("aloadide")
            };
            // The loading is complete, call the function we want executed once the iframe is loaded
            this.afterLoading();
            return;
        }

        // If we are here, it is not loaded. Set things up so we check   the status again in 100 milliseconds
        let timer = window.setTimeout(() => { this.checkIframeLoaded(); clearTimeout(timer) }, 100);
    }

    afterLoading = () => {
        // alert("I am here");
    }

    render() {
        const { lat, lng } = this.props;
        let src = `https://www.google.com/maps/embed/v1/place?key=${keyGoogleMap}&q=${Number(lat)},${Number(lng)}&center=${Number(lat)},${Number(lng)}`
        return (
            <div>
                <iframe
                    data-isloaded="0"
                    onload={this.checkIframeLoaded}
                    ref={this.myRefIframe}
                    allowfullscreen
                    class="maps"
                    frameborder="0"
                    id="iframe"
                    name="iframe"
                    referrerpolicy="no-referrer-when-downgrade"
                    src={src}
                //src="https://en.wikipedia.org/wiki/Herbie_Hancock" //test
                >
                </iframe>
            </div>
        );
    }
}
export default MapIframe;
