import React, { Component } from 'react';
import { connect } from "react-redux";

import ResizeSensor from "../../../components/ResizeSensor";
import * as socket from "../../../socket";
import * as actions from "../../../store/actions";
import { orderActions, Random, CommonWidgetUtils, ORDER_TYPE_USE, fullSubOrderTypes, CommonUtils } from "../../../utils";

import DualOrderbook from "./DualOrderbook";
import SingleOrderbook from "./SingleOrderbook";

import './DualOrderbook.scss';
const logDebug = CommonUtils.logDebug
// logDebug("MixedOrderbook.:logDebug")
class MixedOrderbook extends Component {

    callerId = Random.randomComponentId();

    initialState = {
        contentHeight: 0
    };

    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState
        }
    }

    onResize = (dimensions) => {
        const newHeight = dimensions.height;
        if (newHeight !== this.state.contentHeight) {
            this._setState({ contentHeight: newHeight });
        }
    };

    onItemClick = (orderbook, side) => {
        const { setOrderInput, currentLayoutPageActive, isOpenQuickOrder, updatePlaceQuickOrder, updateProTrade } = this.props;
        if (orderbook && side) {
            const price = side === orderActions.BUY ? orderbook['BP'] : orderbook['SP'];
            const isMarketPrice = CommonUtils.isContainLetter(price);

            if (currentLayoutPageActive === 'C#CS#TRADE#NC' || currentLayoutPageActive === 'B#CS#TRADE#NC') {
                // Cập nhật loại lệnh cho lệnh pro
                updateProTrade({
                    orderType: isMarketPrice ? "market" : "limit",
                });

                // updateProTrade({
                //     limitPrice: isMarketPrice ? "" : (price || 0),
                //     quotePriceValid: true,
                //     stepPriceValid: true,
                //     orderType: isMarketPrice ? "market" : "limit",
                //     subOrderType: isMarketPrice ? price : fullSubOrderTypes.LO
                // })


                updateProTrade({
                    limitPrice: isMarketPrice ? "" : (price || 0),
                    // quotePriceValid: true,
                    // stepPriceValid: true,
                    orderType: isMarketPrice ? "market" : "limit",
                    subOrderType: isMarketPrice ? price : fullSubOrderTypes.LO
                }, orderActions.BUY)


                updateProTrade({
                    limitPrice: isMarketPrice ? "" : (price || 0),
                    // quotePriceValid: true,
                    // stepPriceValid: true,
                    orderType: isMarketPrice ? "market" : "limit",
                    subOrderType: isMarketPrice ? price : fullSubOrderTypes.LO
                }, orderActions.SELL)

                // Cập nhật dữ liệu giá cho lệnh pro
                // if (side === orderActions.BUY) {
                //     updateProTrade({
                //         limitPrice: isMarketPrice ? "" : (price || 0),
                //         quotePriceValid: true,
                //         stepPriceValid: true,
                //         orderType: isMarketPrice ? "market" : "limit",
                //         subOrderType: isMarketPrice ? price : fullSubOrderTypes.LO
                //     }, orderActions.BUY)
                //     updateProTrade({
                //         subOrderType: 'MP' // Mặc định giá cho lệnh còn lại của pro
                //     }, orderActions.BUY)
                // } else if (side === orderActions.SELL) {
                //     updateProTrade({
                //         limitPrice: isMarketPrice ? "" : (price || 0),
                //         quotePriceValid: true,
                //         stepPriceValid: true,
                //         orderType: isMarketPrice ? "market" : "limit",
                //         subOrderType: isMarketPrice ? price : fullSubOrderTypes.LO
                //     })
                //         updateProTrade({
                //             subOrderType: 'MP' // Mặc định giá cho lệnh còn lại của pro
                //         }, orderActions.SELL)
                // }
            } else if (currentLayoutPageActive === 'C#CS#TRADE#CB' || currentLayoutPageActive === 'B#CS#TRADE#CB') {
                updatePlaceQuickOrder({
                    limitPrice: isMarketPrice ? price : (price || 0),
                    // quotePriceValid: true,
                    orderType: isMarketPrice ? "market" : "limit",
                    subOrderType: isMarketPrice ? price : fullSubOrderTypes.LO,
                    // action: side === orderActions.BUY ? orderActions.SELL : orderActions.BUY // Nhấn dư mua => fill lệnh bán và ngược lại
                }, ORDER_TYPE_USE.NORMAL_ORDER)
            } else if (isOpenQuickOrder) {
                updatePlaceQuickOrder({
                    symbolCurrent: orderbook['SB'] || '',
                    limitPrice: isMarketPrice ? "" : (price || 0),
                    // quotePriceValid: true,
                    orderType: isMarketPrice ? "market" : "limit",
                    subOrderType: isMarketPrice ? price : fullSubOrderTypes.LO,
                    // action: side === orderActions.BUY ? orderActions.SELL : orderActions.BUY // Nhấn dư mua => fill lệnh bán và ngược lại
                }, ORDER_TYPE_USE.QUICK_ORDER)
            }

            // setOrderInput({
            //     action: side,
            //     stopPrice: price,
            //     limitPrice: price
            // });
        }
    };

    _setState = (obj, callback) => {
        if (this.mounted) {
            this.setState(obj, callback);
        }
    }

    componentDidMount() {
        this.mounted = true
        const { currentSymbol } = this.props;
        if (currentSymbol) {
            socket.registerOrderbookTopic(currentSymbol.id, this.callerId);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { currentSymbol } = this.props;
        const { currentSymbol: prevCurrentSymbol } = prevProps;
        if (currentSymbol !== prevCurrentSymbol) {
            if (currentSymbol) {
                socket.registerOrderbookTopic(currentSymbol.id, this.callerId);
            } else {
                socket.unregisterCallerId(this);
            }
        }
    }

    componentWillUnmount() {
        socket.unregisterCallerId(this.callerId);
        this._setState({
            ...this.initialState
        })
        this.mounted = false
    }

    render() {
        const { orderbook, instrument, isDisplayInSidebar, mode } = this.props;
        // logDebug("MixedOrderbook.:orderbook=", { orderbook })
        const { contentHeight } = this.state;
        return (
            <ResizeSensor onResize={this.onResize}>
                {mode === 'single' ? (
                    <SingleOrderbook
                        orderbook={orderbook}
                        instrument={instrument}
                        contentHeight={contentHeight}
                        onItemClick={this.onItemClick}
                        isDisplayInSidebar={isDisplayInSidebar}
                    />
                ) : null}
                {mode === 'dual' ? (
                    <DualOrderbook
                        orderbook={orderbook}
                        instrument={instrument}
                        onItemClick={this.onItemClick}
                        isDisplayInSidebar={isDisplayInSidebar}
                    />
                ) : null}
            </ResizeSensor>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const { currentSymbol: currentSymbolProps, mode: currentMode } = ownProps
    const currentSymbol = currentSymbolProps ? currentSymbolProps : state.app.tradeCurrentSymbol;
    const orderbook = currentSymbol ? state.symbol.orderbooks[currentSymbol.id] || [] : [];
    let instrument = currentSymbol ? state.symbol.instruments[currentSymbol.id] || {} : {};
    if (currentMode === 'single') {
        instrument = {
            SB: instrument.SB,
            EX: instrument.EX,
            RE: instrument.RE,
            CL: instrument.CL,
            FL: instrument.FL,
            CH: instrument.CH,
            CP: instrument.CP,
            TT: instrument.TT,
        }
    } else {
        if (currentMode === 'dual') {
            instrument = {
                SB: instrument.SB,
                EX: instrument.EX,
                RE: instrument.RE,
                CL: instrument.CL,
                FL: instrument.FL
            }
        }

    }
    const _currentLayoutPageActive = CommonWidgetUtils.getReplaceLayoutPageActive();
    return {
        currentSymbol: currentSymbol,
        instrument: instrument,
        orderbook: orderbook,
        currentLayoutPageActive: _currentLayoutPageActive,
        isOpenQuickOrder: state.layout.listIsOpenModal["C&B"]['QuickOrder']['isOpenQuickOrder']
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setOrderInput: (orderInput) => dispatch(actions.setTradeOrderInput(orderInput)),
        updateProTrade: (data, typeOrder) => dispatch(actions.updateProTrade(data, typeOrder)),
        updatePlaceQuickOrder: (data, typeOrder) => dispatch(actions.updatePlaceQuickOrder(data, typeOrder)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MixedOrderbook);
